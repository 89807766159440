import React from "react";
import clsx from "clsx";
import Tooltip, { Placement } from "@components/Tooltip";

import styles from "./Label.module.css";
import { FaQuestionCircle } from "react-icons/fa";

type LabelProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
> & {
  helpCallout?: React.ReactNode;
  helpCalloutPlacement?: Placement;
  classes?: {
    label?: string;
    helpCallout?: string;
    helpCalloutPopup?: string;
  };
};

const Label = ({
  helpCallout,
  className,
  children,
  helpCalloutPlacement,
  classes = {},
  ...rest
}: LabelProps) => {
  return (
    <label className={clsx(className, styles.Label, classes.label)} {...rest}>
      {children}
      {helpCallout && (
        <Tooltip
          arrow
          placement={helpCalloutPlacement}
          title={helpCallout}
          className={clsx(
            styles["Help-callout-popup"],
            classes.helpCalloutPopup
          )}
        >
          <span>
            <FaQuestionCircle
              className={clsx(styles["Help-callout"], classes.helpCallout)}
            />
          </span>
        </Tooltip>
      )}
    </label>
  );
};

export default Label;
