import React from 'react';
import { FaEdit } from 'react-icons/fa';

import { PaymentMethodData } from '@api/types/index';
import styles from './CreditCardInfo.module.css';

type CreditCardInfoProps = PaymentMethodData & {
  onEdit: () => void,
  headerLabel: string,
  endingInLabel: string,
  expiresLabel: string,
};

const CreditCardInfoView = ({
  cardHolderName,
  cardNumber,
  cardType,
  expirationMonth,
  expirationYear,
  paymentMethodId,
  onEdit,
  headerLabel,
  endingInLabel,
  expiresLabel,
}: CreditCardInfoProps) => (
  <div className={styles['CreditCardInfo-container']}>
    <div className={styles['CreditCardInfo-label']}>{headerLabel}</div>
    <div>
      {cardHolderName}'s {cardType}
      <span className={styles['CreditCardInfo-label']}>{endingInLabel}</span>
      {cardNumber.substr(-4)}
    </div>
    <div>
      <span className={styles['CreditCardInfo-label']}>{expiresLabel}</span>
      {expirationMonth}/{expirationYear}
    </div>
    <button className={styles['CreditCardInfo-button']} onClick={onEdit}>
      <FaEdit className={styles['CreditCardInfo-icon']} />
    </button>
  </div>
);

export default CreditCardInfoView;
