import { Label, LabelDictionaryProps } from '@components/LabelDictionary/LabelDictionary';
import React, { useEffect, useMemo } from 'react';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import { Helmet } from 'react-helmet';
import useLabels from '@hooks/useLabels';
import useSetPreferences from '@redux/hooks/preferences';

type Favicon = {
  favicon: ContentfulFile;
  name: string;
  size: string;
};

export type GlobalPreferencesContent = {
  pageTitle: string;
  faviconList: Favicon[];
  keywords: string;
  pageDescription: string;
  sharedProperties: LabelDictionaryProps[];
};

type GlobalPreferencesProps = {
  content?: GlobalPreferencesContent;
};

const GlobalPreferences = ({
  content: { pageTitle, keywords, pageDescription, faviconList, sharedProperties } = {},
}: GlobalPreferencesProps) => {
  const setPreferences = useSetPreferences();

  const combinedLabels = useMemo(
    () => sharedProperties?.reduce((acc, labelsDictionary) => [...acc, ...labelsDictionary.content], [] as Label[]),
    [sharedProperties],
  );
  const labels = useLabels(combinedLabels).getAllPrimaries();

  useEffect(() => {
    setPreferences(labels);
  }, [labels]);

  const addFavicons = () => {
    return faviconList.map((item, index) => (
      <link
        rel="icon"
        type={item?.favicon?.file?.contentType}
        href={item?.favicon.file?.url}
        sizes={item.size}
        key={`meta-item-${index}`}
      />
    ));
  };

  return (
    <Helmet titleTemplate={`%s - ${pageTitle}`} defaultTitle={pageTitle}>
      {addFavicons()}
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default GlobalPreferences;
