import React from "react";
import styles from "../services.module.css";
import { ModelConnector } from "../../ApiConnectorHOCs/";
import { useSelector } from "react-redux";
import { labelReducer } from "@utils/filter-label-dictionary";
import { formatDateByLocale } from '@cv/portal-common-lib/utils';

type SubscriptionPackageProps = {
  marketingName: string;
  autoRenewText: string;
  termEndDate: string;
};

type SubscriptionProps = {
  data: Array<any>;
  header: string;
  suscriptionItems: Array<DataType>;
};

type DataType = {
  componentType: string;
  description?: string;
  content: Array<any>;
  expirationDate?: string;
  name: string;
  title?: string;
};

const SubscriptionPackage = ({
  marketingName,
  autoRenewText,
  termEndDate,
}: SubscriptionPackageProps) => (
    <div key={marketingName} className={styles.SubscriptionPackage}>
      <div className={styles.SubscriptionPackageDescription}>{marketingName}</div>
      <div className={styles.SubscriptionPackageExpirationDate}>
        {autoRenewText} {formatDateByLocale(termEndDate)}
      </div>
    </div>
  );

const Subscription = ({
  data,
  header,
  suscriptionItems,
}: SubscriptionProps) => {
  const subscriptionLabel = labelReducer(
    suscriptionItems?.filter(
      (item) => item.componentType === "portalLabelDictionary"
    )[0]
  );
  const packages = data.services || [];
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  return (
    <div className={styles.Wrapper}>
      <div className={styles.ServiceContainer}>
        <h1 className={styles.ServiceHeader}>{header}</h1>
        <span className={styles.SubscriptionPackageTitle}>
          {`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}
          {packages?.length ? (
            packages.map(({ name, autoRenew, expiry }: any) => (
              <SubscriptionPackage
                key={name}
                marketingName={subscriptionLabel?.packagePrefixLabel ? subscriptionLabel.packagePrefixLabel.concat(name) : name}
                autoRenewText={
                  autoRenew
                    ? subscriptionLabel?.renews
                    : subscriptionLabel?.expires
                }
                termEndDate={expiry}
              />
            ))
          ) : (
              <div className={styles.SubscriptionPackageDescription}>
                {subscriptionLabel?.nopackagesfound}
              </div>
            )}
          <div className={styles.SubscriptionPackageExpirationDate}>{
            subscriptionLabel?.labelTrialExpiry}{formatDateByLocale(data?.defaultPkg?.expiry)}
          </div>
        </span>
      </div>
    </div>
  );
};

export default ModelConnector(Subscription);
