import React from 'react';

import ExpandingContainer from '@components/ExpandingContainer/';
import Grid from '@components/Grid/';
import './ExpandingList.css';

type ExpandingListProps = {
  values: Array<string>;
  numberOfRows: string;
  numberOfColumns: '2' | '3';
  expandLabel: string;
  hideLabel: string;
};

function ExpandingList({ values, numberOfColumns, numberOfRows, ...restProps }: ExpandingListProps) {
  const numItems = Number.parseInt(numberOfRows, 10) * Number.parseInt(numberOfColumns, 10);

  const visibleItems = (
    <Grid className="ExpandingList-grid" columns={numberOfColumns}>
      {values.slice(0, numItems).map((item, i) => (
        <span key={`item-${i}`}>{item}</span>
      ))}
    </Grid>
  );
  const hiddenItems = (
    <Grid className="ExpandingList-grid" columns={numberOfColumns}>
      {values.slice(numItems).map((item, i) => (
        <span key={`item-${i}`}>{item}</span>
      ))}
    </Grid>
  );

  return (
    <ExpandingContainer visibleContent={visibleItems} extraContent={hiddenItems} itemsCount={numItems} {...restProps} />
  );
}

ExpandingList.defaultProps = {
  numberOfColumns: '2',
  numberOfRows: '3',
};

export default ExpandingList;
