import { RemoteCommand, DOOR_ID } from '@components/Map/constants';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { isSuccessHttpCode } from '@cv/portal-common-lib/ajax/helper';
import { updateHornLightsByVehicleId, getHornLightsByVehicleId } from '@cv/portal-rts-lib';
import { State } from '@cv/portal-rts-lib/horn-lights/enums';
import { GetHornLightsRequest, UpdateHornLightsRequest } from '@cv/portal-rts-lib/horn-lights/models/horn-lights';
import initiatePolling, { PollingStatus } from '@utils/polling';

export type HornLightsRequestPayload = {
  validationCallback: () => PollingStatus;
  successHandlerCallback: () => void;
  errorHandlerCallback: (result?: object) => void;
  remoteCommand?: string;
};
export default class HornLightsService {
  private environment: Environment;
  constructor(environment = Environment.DEVINT) {
    this.environment = environment;
  }
  makeHornLightsAPICall(payload: HornLightsRequestPayload, accessToken: string, vehicleId: string) {
    const updateRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId: vehicleId,
      },
      headers: {
        Authorization: accessToken,
      },
      data: {
        hornLights: {
          state: payload.remoteCommand === RemoteCommand.LIGHT ? State.FLASHING : State.FLASHING_HONKING,
          id: DOOR_ID,
        },
      },
    } as UpdateHornLightsRequest;

    const request = {
      environment: this.environment,
      pathParams: {
        vehicleId: vehicleId,
      },
      headers: {
        Authorization: accessToken,
      },
    } as GetHornLightsRequest;
    return updateHornLightsByVehicleId(updateRequest)
      .then((result) => {
        isSuccessHttpCode(result.status)
          ? initiatePolling({
              pollingFunc: getHornLightsByVehicleId.bind(null, request),
              validationCallback: payload.validationCallback,
              successCallback: payload.successHandlerCallback,
              errorCallback: payload.errorHandlerCallback,
            })
          : payload.errorHandlerCallback();
      })
      .catch((err) => {
        payload.errorHandlerCallback(err);
      });
  }
}
