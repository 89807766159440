import React, { useEffect } from 'react';

import { ModelConnector } from '@components/ApiConnectorHOCs/';
import styles from './SubscriptionOverview.module.css';
import { useApi } from '@api';

export function SubscriptionModelInfo({ data }) {
  const api = useApi();

  useEffect(() => {
    if (data) {
      api.storeService.setVehicleData(data);
    }
  }, [data]);

  return (
    <>
      <div className={styles['SubscriptionOverview-vehicle-name']}>{data?.model}</div>
      <div className={styles['SubscriptionOverview-vin']}>{data?.vin}</div>
    </>
  );
}

export default ModelConnector(SubscriptionModelInfo);
