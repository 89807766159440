import 'react-phone-number-input/style.css';

import PhoneInput, { isSupportedCountry } from 'react-phone-number-input';
import React, { useRef } from 'react';
import { getIn, useField } from 'formik';

import Field from '@components/Field';
import nextId from '@utils/next-id';
import { useSelector } from 'react-redux';

type FormikPhoneFieldProps = {
  name: string;
  fieldValue?: string;
  placeholder?: string;
  helpCallout?: React.ReactNode;
  form: {
    errors: any[];
    handleBlur: (e: any) => void;
    setFieldValue: (name: string, value: string) => void;
    touched: boolean;
    values?: any;
  };
  label?: string;
  id?: string;
  onChange?: (value: string) => void;
  fieldPhoneCountry?: string;
  phoneFormat?: "international" | "national";
  classes: {
    field?: {
      label?: string;
      wrapper?: string;
      helpText?: string;
      error?: string;
    };
    input?: string;
  };
};

const FormikPhoneField = (props: FormikPhoneFieldProps) => {
  const {
    id = nextId(),
    name,
    fieldValue,
    placeholder,
    helpCallout,
    form: { errors, handleBlur, setFieldValue, touched },
    label,
    onChange,
    fieldPhoneCountry,
    classes,
    phoneFormat = "international"
  } = props;
  const input = useRef(null);
  const locale: string = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const isError = getIn(touched, name) && getIn(errors, name);
  const error = getIn(errors, name);
  const isCurrentElementActive = input.current === document.activeElement;
  const errorMessage = isCurrentElementActive ? '' : error;
  const [field] = useField({ name });
  const value = fieldValue || field.value;
  const countryFormat = locale.split('-')[1] || fieldPhoneCountry;
  const hasSpecificCountry: boolean = Boolean(countryFormat) && isSupportedCountry(countryFormat);
  const correctCountry = hasSpecificCountry ? countryFormat : "US";

  const disableCountryProps = hasSpecificCountry
    ? {
        countrySelectProps: { disabled: true },
      }
    : {};

  const onValueChange = (phoneNumber: string) => {
    setFieldValue(name, phoneNumber || ''); // if empty, use "" instead of undefined
    if (onChange && typeof onChange === 'function') {
      onChange(phoneNumber);
    }
  };

  return (
    <Field
      label={label}
      helpCallout={helpCallout}
      error={isError}
      helpText={errorMessage}
      htmlFor={id}
      classes={classes?.field}
    >
      <PhoneInput
        initialValueFormat={phoneFormat}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onValueChange}
        defaultCountry={correctCountry}
        onBlur={handleBlur}
        id={id}
        international={phoneFormat === "international"}
        className={classes?.input}
        ref={input}
        {...disableCountryProps}
      />
    </Field>
  );
};

export default FormikPhoneField;
