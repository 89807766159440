import React from "react";

import { FaCheck } from "react-icons/fa";

import RoundToggleButton from "@components/RoundToggleButton";
import ControlLabel from "@components/ControlLabel";

import styles from "./DaysSelect.module.css";

export type PossibleValues =
  | "SU"
  | "MO"
  | "TU"
  | "WE"
  | "TH"
  | "FR"
  | "SA"
  | "DO"
  | "LU"
  | "MA"
  | "MI"
  | "JU"
  | "VI";

type DaysSelectProps = {
  selectedDays?: PossibleValues[];
  onChange: (day: PossibleValues[], isSelected: boolean) => void;
  disabledDays?: PossibleValues[];
  days: PossibleValues[];
  helpers?: any,
  checkboxLabel?: string;
};

const DaysSelect = ({
  days,
  selectedDays = [],
  onChange,
  disabledDays,
  helpers = null,
  checkboxLabel = '',
}: DaysSelectProps) => {
  const areAllDaysSelected = selectedDays.length === days.length;

  const handleToggle = (day: PossibleValues) => (isSelected: boolean) => {
    if (disabledDays?.includes(day)) {
      return;
    }
    onChange([day], isSelected);
    if (helpers) {
      const { setValue } = helpers;
      setValue(isSelected ? [...selectedDays, day] : selectedDays.filter((selectedDay) => selectedDay !== day))
    }
  };

  const handleToggleAll = () => {
    const toggledDays = days.filter((day) => !disabledDays?.includes(day));

    onChange(toggledDays, !areAllDaysSelected);
    if (helpers) {
      const { setValue } = helpers;

      setValue(!areAllDaysSelected ? toggledDays : []);
    }
  };

  return (
    <div className={styles["content-container"]}>
      <div className={styles["days-container"]}>
        {days.map((day) => (
          <RoundToggleButton
            key={day}
            name={day}
            checked={selectedDays.includes(day)}
            onToggle={handleToggle(day)}
            disabled={disabledDays?.includes(day)}
          >
            {day.slice(0, 1).toUpperCase()}
          </RoundToggleButton>
        ))}
      </div>

      <ControlLabel
        label={checkboxLabel}
        control={
          <RoundToggleButton
            checked={areAllDaysSelected}
            onToggle={handleToggleAll}
            classes={{
              input: styles["toggle-all-button"],
            }}
          >
            <FaCheck />
          </RoundToggleButton>
        }
      />
    </div>
  );
};

export default DaysSelect;
