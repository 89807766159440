import React from 'react';
import clsx from 'clsx';

import { useVehicleConditionContext } from './VehicleConditionContext';
import ScheduleServiceButton from '@components/ScheduleServiceButton';

import styles from './VehicleCondition.module.css';

type VehicleConditionContainerProps = {
  children?: React.ReactNode;
  expandAllLabel: string;
  collapseAllLabel: string;
  scheduleServiceButtonLabel: string;
  scheduleServiceButtonUrl: string;
  displayViewMode: 'default' | 'warning';
};

function VehicleConditionContainer({
  children,
  expandAllLabel,
  collapseAllLabel,
  scheduleServiceButtonLabel,
  scheduleServiceButtonUrl,
  displayViewMode,
}: VehicleConditionContainerProps) {
  const { isExpandAll, toggleExpandAll } = useVehicleConditionContext();

  const isDefaultViewMode = displayViewMode === 'default';
  const isWarningViewMode = displayViewMode === 'warning';

  return (
    <div
      className={clsx(
        { [styles['VehicleCondition']]: isDefaultViewMode },
        { [styles['VehicleCondition--warning']]: isWarningViewMode },
      )}
    >
      {isDefaultViewMode && (
        <button className={styles['VehicleCondition-expand-all']} onClick={() => toggleExpandAll(!isExpandAll)}>
          {isExpandAll ? collapseAllLabel : expandAllLabel}
        </button>
      )}
      <div
        className={clsx(
          { [styles['VehicleCondition-wrapper']]: isDefaultViewMode },
          { [styles['VehicleCondition-wrapper--warning']]: isWarningViewMode },
        )}
      >
        {children}
      </div>

      {isWarningViewMode && (
        <ScheduleServiceButton
          url={scheduleServiceButtonUrl}
          label={scheduleServiceButtonLabel}
          displayViewMode={displayViewMode}
        />
      )}
    </div>
  );
}

export default VehicleConditionContainer;
