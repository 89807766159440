import { isEqual } from 'lodash';
import { PointOfInterest } from '../components/Map/types';


export const itemInFavorites = (item: PointOfInterest, favorites: Array<PointOfInterest>) => {
    if (!item.geoAddress) return null;

    const checkFavorites = favorites.find((favorite: PointOfInterest) => {
      const favCoordinates = favorite.geoAddress.coordinate;
      const itemCoordinates = item.geoAddress.coordinate;

      return isEqual(favCoordinates, itemCoordinates);
    });

    return checkFavorites;
  };