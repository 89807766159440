import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

import { CreatorForm } from '@components/EntryCreator/';
import Dialog from '@components/Dialog';
import useToggle from '@hooks/useToggle';

import ApiRequestPayload from '@types/ApiRequestPayload';
import InfoBox from './InfoBoxView';
import './InfoBox.css';

type InfoBoxEditableWrapperProps = {
  isEditable?: boolean;
  isFormOpen?: boolean;
  isRemovable?: boolean;
  onFormOpen: () => void;
  onFormClose: () => void;
  onFormConfirm: (data?: ApiRequestPayload) => void;
  onRemove: (data?: ApiRequestPayload) => void;
  formInitialValues: ApiRequestPayload;
  displayedValues: ApiRequestPayload;
  numberOfColumns: number;
  index?: number;
  modalHeader?: string;
  modalText?: string;
  modalConfirm?: string;
  modalCancel?: string;
  showPreview?: boolean;
};

const InfoBoxEditableWrapper = ({
  isEditable,
  isFormOpen,
  isRemovable,
  onFormOpen,
  onFormClose,
  onFormConfirm,
  onRemove,
  formInitialValues,
  displayedValues,
  numberOfColumns,
  modalHeader,
  modalText,
  modalConfirm,
  modalCancel,
  showPreview,
  index,
  ...props
}: InfoBoxEditableWrapperProps) => {
  const [isDialogOpen, toggleDialog] = useToggle(false);

  const onDialogConfirmation = () => {
    if (onRemove && typeof onRemove === 'function') {
      onRemove();
    }
    toggleDialog();
  };

  const showInfoBox = !isFormOpen || showPreview;

  return (
    <div className="InfoBox-edit-container">
      {showInfoBox && (
        <InfoBox
          entries={displayedValues}
          columns={numberOfColumns}
          isEditable={isEditable && !isFormOpen}
          onEdit={onFormOpen}
          index={index}
        />
      )}
      {isFormOpen && (
        <>
          <CreatorForm
            onFormClose={onFormClose}
            onFormConfirm={onFormConfirm}
            initialValues={formInitialValues}
            {...props}
          />
          {isRemovable && (
            <>
              <button className="InfoBox-button InfoBox-remove-button" onClick={toggleDialog}>
                <FaTimes className={'InfoBox-edit-icon'} />
              </button>
              { isDialogOpen && (
                <Dialog
                  title={modalHeader}
                  labelOk={modalConfirm}
                  labelCancel={modalCancel}
                  onOk={onDialogConfirmation}
                  onCancel={toggleDialog}
                >
                  <div className="InfoBox-modal-content">{modalText}</div>
                </Dialog>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InfoBoxEditableWrapper;
