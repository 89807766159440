import { VEHICLE_ACTIONS } from "@redux/actions";
import { setLoadingStatus } from "@redux/actions/loading";
import { useDispatch } from "react-redux";
import { useApi } from "../api";

const useFetchVehicleHealth = () => {
  const api = useApi();
  const dispatch = useDispatch();

  const fetchVehicleHealth = () => {
    dispatch(setLoadingStatus(true));
    // TODO add error handler for this API..check how legacy does it
    api.getVehicleHealth().then(
      data => dispatch({type: VEHICLE_ACTIONS.SET_VEHICLE_HEALTH, data})
    ).finally(
      () => dispatch(setLoadingStatus(false))
    );
  }

  return { fetchVehicleHealth };
}

export default useFetchVehicleHealth;
