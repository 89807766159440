import React, { MouseEvent } from "react";
import styles from './DynamicLink.module.css';

interface DynamicLinkProps {
  text: string;
  handleLinkCallback: (e?: MouseEvent) => void;
}

const DynamicLink = ({text, handleLinkCallback }: DynamicLinkProps) => {
  return (
    <div className={styles["Dynamiclink-container"]} onClick={handleLinkCallback} dangerouslySetInnerHTML={{ __html: text}}></div>
  );
};

export default DynamicLink;
