import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Link, { LinkProps } from "@components/Link";
import { useNavigation, Path } from "@components/Navigation";
import styles from "./Navigation.module.css";
// footerStyles added to have correct styles nesting in sit (we change classNames to hashes in prod build)
import footerStyles from '@components/Footer/Footer.module.css';
import linkStyles from "@components/Link/Link.module.css";
import { isServiceSubscribed } from "@utils/checkIsServiceSubscribed";
import { ACTIVE, STOLEN_VEHICLE } from "@components/Header/constant";
import clsx from 'clsx';
type NavigationLabel = {
  labelKey: string,
  labelValue: string,
};
type NavigationProps = {
  content?: Array<LinkProps>;
  name: string;
  navigationAlignment?: "left" | "right" | "center" | "justify";
  orientation?: "horizontal" | "vertical";
  className?: string,
  isNavigationMachine?: boolean,
  stateMachineLabels?: NavigationLabel[],
  hiddenTabsPageList?: string[],
  hideTabsOnNoVehicleDataPages?: string[]
};
const Navigation = ({
  content,
  navigationAlignment = 'left',
  orientation = 'horizontal',
  className = '',
  isNavigationMachine = false,
  hiddenTabsPageList = [],
  hideTabsOnNoVehicleDataPages = [] }: NavigationProps) => {
  const { paths } = useNavigation();
  const listAlignmentClass = styles[`Navigation-list--${navigationAlignment}`];
  const listOrientationClass = orientation === "vertical" ? styles["Navigation-list--vertical"] : "";
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  const { svl } = useSelector(({ stolenVehicleReducer }) => stolenVehicleReducer);
  const [showHeaderNav, setShowHeaderNav] = useState<boolean>(false)
  const location = useLocation();
  useEffect(() => {
    setShowHeaderNav(!(hiddenTabsPageList?.includes(location.pathname) || (hideTabsOnNoVehicleDataPages?.includes(location.pathname) && !vehicle?.vin)));
  }, [location])
  const renderLink = (path: Path) => {
    if (svl && svl.status === ACTIVE && svl.type === STOLEN_VEHICLE && path.label !== 'PROFILE') {
      return null;
    } else if (!path || path.hiddenInNavigation || (path.services && !isServiceSubscribed(path.services, vehicle?.activeServices))) {
      return null;
    }
    return showHeaderNav && (
      <li className={styles["Navigation-item"]} key={path.page}>
        <NavLink className={linkStyles.Link} activeClassName={linkStyles["Link--active"]} to={path.url}>
          {(path.label || path.page).toUpperCase()}
        </NavLink>
      </li>
    );
  };
  return (
    <nav className={clsx(styles.Navigation, className, footerStyles['Navigation-block'])}>
      <ul className={clsx(styles["Navigation-list"], listAlignmentClass, listOrientationClass)}>
        {isNavigationMachine && paths.map((page) => renderLink(page))}
        {!isNavigationMachine &&
          content?.map((item) => (
            <li className={styles["Navigation-item"]} key={item.name}>
              <Link {...item} />
            </li>
          ))
        }
      </ul>
    </nav>
  );
}
export default Navigation;
