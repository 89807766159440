import { AlertType, GeofenceType } from '@cv/portal-rts-lib/geofences/enums/geofence.enum';
import {
  CreateGeofencePayload,
  Geofence,
  GetGeofenceResponse,
  UpdateGeofencePayload,
} from '@cv/portal-rts-lib/geofences/models';
import React, { useEffect, useState } from 'react';
import { State, UoM } from '@cv/portal-rts-lib/enums';
import { clearMapBoundaries, clearMapPins } from '@utils/clearMapEntities';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { useDispatch, useSelector } from 'react-redux';
import { format, addYears } from 'date-fns';

import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import ActiveView from '../ActiveView';
import AlertsCount from '../AlertsCount';
import BoundaryForm from '../Forms/BoundaryForm';
import Button from '@components/Button';
import { DIALOG_ACTIONS } from '@redux/actions';
import InputSwitch from '@components/InputSwitch';
import ManageCardItem from '../ManageCardItem';
import ModalContainer from '@components/ModalContainer/';
import SettingSelect from '../SettingSelect';
import { Status } from '@cv/portal-rts-lib/doors/enums';
import TabHeader from '../TabHeader';
import TabInfo from '../TabInfo';
import bluePin from '../../../../assets/pin.png';
import { formatPins } from '@utils/formatPins';
import { pick } from 'lodash';
import { pollingTime } from '../constants';
import selectedPin from '../../../../assets/Pin_select.png';
import { setLoadingStatus } from '@redux/actions/loading';
import styles from '../Forms/Forms.module.css';
import stylesMonitoring from '../../MonitoringAlerts/MonitoringAlerts.module.css';
import { useApi } from '@api';

type TabBoundaryProps = {
  defaultViewAlertsCountLabel: string;
  defaultViewAlertsCountPreposition: string;
  cancelButton: string;
  confirmButton: string;
  defaultViewButtonLabel: string;
  defaultViewContent: string;
  fieldErrorMessage: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  formViewDescription: string;
  formViewTitle: string;
  infoBoxContent: string;
  title: string;
  editBoundary: string;
  boundaryNameLabel: string;
  radiusLabel: string;
  alertInVehicleLabel: string;
  boundaryAlertLabel: string;
  searchResultsLabel: string;
  adjustBoundarySizeLabel: string;
  boundarySizeContent: string;
  setBoundaryLabel: string;
  alertSettingsLabel: string;
  arrivalLabel: string;
  arrivalContent: string;
  departureLabel: string;
  departureContent: string;
  settingsDescription: string;
  nextButton: string;
  saveButton: string;
  createSuccessMsg: string;
  createErrorMsg: string;
  updateSuccessMsg: string;
  updateErrorMsg: string;
  deleteSuccessMsg: string;
  deleteErrorMsg: string;
  successDialogHeader: string;
  errorDialogHeader: string;
  enteringLabel: string;
  exitingLabel: string;
  addBoundary: () => void;
  distanceUnit: string;
  alertLabel: string;
  maxBoundaryLimit: number;
  displayAlertToggleText: string;
  alertDescription: string;
  nameAlertTitle: string;
  setLocationButtonText: string;
  locationErrorText: string;
  searchErrorText: string;
};

export default function TabBoundary({
  defaultViewAlertsCountLabel,
  defaultViewAlertsCountPreposition,
  cancelButton,
  confirmButton,
  defaultViewButtonLabel,
  defaultViewContent,
  fieldErrorMessage,
  fieldLabel,
  fieldPlaceholder,
  formViewDescription,
  formViewTitle,
  infoBoxContent,
  title,
  editBoundary,
  boundaryNameLabel,
  radiusLabel,
  alertInVehicleLabel,
  boundaryAlertLabel,
  searchResultsLabel,
  adjustBoundarySizeLabel,
  boundarySizeContent,
  setBoundaryLabel,
  alertSettingsLabel,
  arrivalLabel,
  arrivalContent,
  departureLabel,
  departureContent,
  settingsDescription,
  nextButton,
  saveButton,
  createSuccessMsg,
  createErrorMsg,
  updateSuccessMsg,
  updateErrorMsg,
  deleteSuccessMsg,
  deleteErrorMsg,
  successDialogHeader,
  errorDialogHeader,
  enteringLabel,
  exitingLabel,
  distanceUnit,
  alertLabel,
  maxBoundaryLimit,
  displayAlertToggleText,
  alertDescription,
  nameAlertTitle,
  setLocationButtonText,
  locationErrorText,
  searchErrorText,
}: TabBoundaryProps) {
  const api = useApi();
  const dispatch = useDispatch();
  const [view, setView] = useState('default');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [boundary, updateBoundary] = useState({});
  const [arrivalAlert, setArrivalAlert] = useState(true);
  const [departureAlert, setDepartureAlert] = useState(false);
  const [boundaryToEdit, setBoundaryToEdit] = useState(null);
  const [inVehicleWarning, setInvehicleWarning] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [poiSearchError, setPoiSearchError] = useState(false);
  const [displayToggleModal, setDisplayToggleModal] = useState(false);
  const [selectedBoundaryLocation, setSelectedBoundaryLocation] = useState(null);
  const geofences = useSelector(({ boundaryReducer }) => boundaryReducer.boundaries);
  const radius = useSelector(({ boundaryReducer }) => boundaryReducer.radius);
  const map = useSelector(({ mapReducer }) => mapReducer.map);
  const boundaryCoordinates = useSelector(({ boundaryReducer }) => boundaryReducer.boundaryCoordinates);
  const selectedPointOfInterest = useSelector(({ mapReducer }) => mapReducer.selectedPointOfInterest);
  const locale = useSelector(({ settingsReducer }) => settingsReducer.locale);
  let boundaryId = '';

  useEffect(() => {
    clearMapPins(map);
    getBoundaries();
  }, []);

  const boundaryCenter = (boundary: Geofence) => pick(boundary.geometry.center, ['latitude', 'longitude']);

  const getBoundaries = async () => {
    try {
      const { data } = await api.getGeofence();
      const nonValetBoundaries = data.geofences.filter(
        (geofence) => geofence.alertType !== AlertType.Valet
      );
      const multipleBoundaryCoordinates = nonValetBoundaries.map(boundaryCenter);

      dispatch({ type: 'SET_MULTIPLE_BOUNDARY_COORDINATES', multipleBoundaryCoordinates });
      dispatch({ type: 'SET_BOUNDARIES', boundaries: nonValetBoundaries });
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  const formatBoundaryToSave = (boundaryName: string, alertType: AlertType) => {
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const currentDatePlusYears = addYears(new Date(), 3);
    const endDate = format(currentDatePlusYears, 'yyyy-MM-dd')
    const { coordinate } = selectedLocation.geoAddress;
    const geofence: CreateGeofencePayload = {
      geofence: {
        name: boundaryName,
        inVehicleWarning,
        schedules: [
          {
            startDate: currentDate,
            startTime: "01:00:00.000Z",
            endDate: endDate,
            endTime: "01:00:00.000Z"
          }
        ],
        geometry: {
          radius: {
            UoM: UoM.Kilometers,
            value: radius.toString(),
          },
          center: {
            latitude: coordinate.latitude,
            longitude: coordinate.longitude,
            UoM: 'DEGREES',
          },
        },
        alertType,
        type: GeofenceType.Circular,
        state: 'ACTIVE',
      },
    };

    return geofence;
  };

  const validationCallback = (response: APIResponse<GetGeofenceResponse>) => {
    const geofence = response.data.svcRequests.find((item) => item.id === boundaryId);
    const geofenceStatus = geofence?.status;

    if (geofenceStatus?.includes(Status.SUCCESS) || geofence === undefined) {
      const nonValetBoundaries =
        response.data.geofences?.filter((geofence) => geofence.alertType !== AlertType.Valet) || [];
      dispatch({ type: 'SET_BOUNDARIES', boundaries: nonValetBoundaries });

      return PollingStatus.SUCCESS;
    }

    if (geofenceStatus?.includes(Status.FAILED)) {
      return PollingStatus.ERROR;
    }

    return PollingStatus.PENDING;
  };

  const successCallback = (message: string, multipleAlertTypes: boolean, boundaryName: string) => {
    if (multipleAlertTypes) {
      setArrivalAlert(!arrivalAlert);
      saveBoundary(boundaryName, AlertType.On_exit);
    } else {
      getBoundaries();
      setView('default');
      setDepartureAlert(false);
      setArrivalAlert(true);
      dispatch({ type: 'RESET_BOUNDARY_AND_RADIUS' });
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: successDialogHeader } });
      dispatch(setLoadingStatus(false));
    }
  };

  const errorCallback = (message: string) => {
    setView('default');
    dispatch(setLoadingStatus(false));
    dispatch({ type: 'RESET_BOUNDARY_AND_RADIUS' });
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: errorDialogHeader } });
  };

  const startPolling = (successMsg: string, errorMsg: string, multipleAlertTypes: boolean, boundaryName: string) => {
    initiatePolling({
      pollingFunc: api.getGeofence.bind(api),
      validationCallback,
      successCallback: successCallback.bind(this, successMsg, multipleAlertTypes, boundaryName),
      errorCallback: errorCallback.bind(this, errorMsg),
      timeout: pollingTime,
    });
  };

  const saveBoundary = async (boundaryName: string, alertType: AlertType, multipleAlerts?: boolean) => {
    const multipleAlertTypes = multipleAlerts || false;
    try {
      const boundary = formatBoundaryToSave(boundaryName, alertType);
      dispatch(setLoadingStatus(true));
      const { data } = await api.createGeofence(boundary);
      boundaryId = data.svcReqId;
      startPolling(createSuccessMsg, createErrorMsg, multipleAlertTypes, boundaryName);
    } catch (err) {
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: createErrorMsg, title: errorDialogHeader } });
    } finally {
      clearMapPins(map);
      dispatch({
        type: 'SET_POINTS_OF_INTEREST',
        pointsOfInterest: [],
      });
    }
  }

  const handleSaveClick = async (name: { search: string }) => {
    let alertType = AlertType.On_entry;
    const multipleAlerts = arrivalAlert && departureAlert;

    if (!arrivalAlert && departureAlert) {
      alertType = AlertType.On_exit;
    }

    saveBoundary(name.search, alertType, multipleAlerts);
  };

  const getBoundaryLocations = async (searchTerm: any) => {
    try {
      dispatch(setLoadingStatus(true));
      const res = await api.getPoiItems(searchTerm);
      if (res.data) {
        clearMapBoundaries(map);
        dispatch({ type: 'SET_MULTIPLE_BOUNDARY_COORDINATES', multipleBoundaryCoordinates: [] });
        const formattedDestinations = formatPins(res.data, dispatch);
        dispatch({
          type: 'SET_POINTS_OF_INTEREST',
          pointsOfInterest: formattedDestinations,
        });
        setLocations(formattedDestinations);

        setView('selectLocation');
      } else {
        setPoiSearchError(true);
        setView('selectLocation');
      }
    } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      console.log('error api', err);
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };

  const handleCancelClick = () => {
    resetBoundaries()
  };

  const formConfirm = (searchTerm: any) => {
    getBoundaryLocations(searchTerm.search);
  };

  const onMouseEnterOrLeaveHandler = (pinId, poiIndex, icon) => {
    dispatch({
      type: 'SET_SELECTED_POINT_OF_INTEREST',
      selectedPointOfInterest: pinId,
      poiIndex,
      icon,
    });
  };

  const renderBoundaryLocations = () => {
    const handleLocationClick = (location: Geofence) => {
      setSelectedLocation(location);
    };

    if (poiSearchError) {
      return (
        <div className={stylesMonitoring['poi-list-item']} style={{ borderBottom: 'none'}}>
          <h2 className={stylesMonitoring['card-item-label']} style={{ width: '70%' }}>{searchErrorText}</h2>
        </div>
      )
    }

    if (locations.length) {
      return locations.map((location: any, index) => {
        const { street, city, state, postalCode, country} = location.geoAddress.address;
        const isSelected = location.pinId === selectedPointOfInterest || location === selectedLocation ? '-selected' : '';
        return (
          <div
            className={stylesMonitoring[`poi-list-item${isSelected}`]}
            onMouseEnter={() => onMouseEnterOrLeaveHandler(location.pinId, index, selectedPin)}
            onMouseLeave={() => onMouseEnterOrLeaveHandler(null, index, bluePin)}
            id={location.pinId}
            onClick={() => handleLocationClick(location)}
          >
            <h2 style={{ fontSize: 14, fontWeight: 'bold' }}>{location.name}</h2>
            <h3 style={{ fontSize: 12 }}>{`${street}, ${city}, ${state},`}</h3>
            <h3 style={{ fontSize: 12 }}>{`${postalCode}, ${country}`}</h3>
          </div>
        );
      });
    }
  };

  const formatBoundaryToUpdate = (boundary: Geofence) => {
    const { alertType, geometry, name, lastSvcReqId, state } = boundary;
    const {
      radius: { UoM: unit, value },
      center: { latitude, longitude },
    } = geometry;
    const geofence: UpdateGeofencePayload = {
      geofence: {
        name,
        id: lastSvcReqId,
        geometry: {
          radius: {
            UoM: unit,
            value: value.toString(),
          },
          center: {
            latitude,
            longitude,
            UoM: 'DEGREES',
          },
        },
        alertType,
        type: GeofenceType.Circular,
        state
      },
    };

    return geofence;
  };

  const handleBoundaryToggle = async (boundary: Geofence) => {
    const { state } = boundary;
    const updatedState = state === State.Active ? State.Inactve : State.Active;
    const updatedBoundaries :Geofence = { ...boundary, state: updatedState };
    try {
      const updatedBoundary = formatBoundaryToUpdate(updatedBoundaries);
      const res = await api.updateGeofence(updatedBoundary);
      setDisplayToggleModal(true)
      getBoundaries()
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  const handleBoundaryUpdate = async (name: string) => {
    boundaryToEdit.schedules.name = name.search;
    boundaryToEdit.geometry.radius.value = radius;
    setBoundaryToEdit(boundaryToEdit);
    try {
      dispatch(setLoadingStatus(true));
      const updatedBoundary = formatBoundaryToUpdate(boundaryToEdit);
      const {
        data: { geofences },
      } = await api.updateGeofence(updatedBoundary);
      boundaryId = geofences[0].lastSvcReqId;
      startPolling(updateSuccessMsg, updateErrorMsg);
    } catch (err) {
      dispatch(setLoadingStatus(false));
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: updateErrorMsg, title: errorDialogHeader } });
    }
  };

  const cardLabel = (boundary: Geofence) => {
    const { alertType, geometry, name } = boundary;
    const {
      radius: { UoM: unit, value },
    } = geometry;
    const unitConvert = {
      KILOMETERS: 'km',
      MILES: 'miles',
    };
    const alertConvert = {
      ON_EXIT: exitingLabel,
      ON_ENTRY: enteringLabel,
    };
    return (
      <>
        <div className={stylesMonitoring['card-label-name']}>{alertConvert[alertType]}</div>
        <div className={stylesMonitoring['card-label-days']}>{name}</div>
        <div className={stylesMonitoring['card-label-hours']}>{`${radiusLabel} ${Number(value).toFixed(2)} ${unitConvert[unit]}`}</div>
      </>
    );
  };

  const onItemEdit = (boundary: Geofence) => {
    clearMapBoundaries(map);
    dispatch({ type: 'SET_RADIUS', radius: boundary.geometry.radius.value})
    dispatch({ type: 'RESET_MULTIPLE_BOUNDARY_COORDINATES' })
    dispatch({ type: 'RESET_USER_LOCATION' })
    setBoundaryToEdit(boundary);

    dispatch({ type: 'SET_BOUNDARY_COORDINATES', boundaryCoordinates: boundaryCenter(boundary) });
    setView('editBoundary');
  };

  const onItemDelete = async (boundary: Geofence) => {
    try {
      dispatch(setLoadingStatus(true));
      const res = await api.deleteGeofence(boundary.lastSvcReqId);
      boundaryId = boundary.lastSvcReqId;
      startPolling(deleteSuccessMsg, deleteErrorMsg);
    } catch (err) {
      dispatch(setLoadingStatus(false));
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: deleteErrorMsg, title: errorDialogHeader } });
    }
  };

  const renderCardItems = () => {
    const isEditView = view === 'editList';

    return (
      <div className={stylesMonitoring['card-list']}>
        {geofences?.map((boundary) => {
          const { state } = boundary;
          return (
            <ManageCardItem
              key={cardLabel(boundary)}
              label={cardLabel(boundary)}
              handlers={isEditView ? 'edit' : 'switch'}
              isChecked={state === State.Active ? true :false}
              onSwitchToggle={!isEditView ? () => handleBoundaryToggle(boundary) : undefined}
              onItemEdit={isEditView ? () => onItemEdit(boundary) : undefined}
              onItemRemove={isEditView ? () => onItemDelete(boundary) : undefined}
            />
          );
        })}
      </div>
    );
  };

  const renderAlertsCount = () => {
    if (!geofences || !geofences.length) return null;

    return (
      <AlertsCount
        label={defaultViewAlertsCountLabel}
        countCurrent={geofences.length}
        countMax={maxBoundaryLimit}
        preposition={defaultViewAlertsCountPreposition}
      />
    );
  };

  const renderDefaultScreen = () => {
    const content = geofences?.length ? '' : defaultViewContent;
    const disabled = geofences?.length >= maxBoundaryLimit;
    const variant = disabled ? 'outlined' : 'filled';
    const buttonContainerHeight = geofences?.length ? '75px' : '250px';

    return (
      <>
        <TabHeader
          label={title}
          onClickInfo={() => setView('tabInfo')}
          onEdit={() => setView('editList')}
          content={content}
          displayEditButton={boundariesCount > 0}
        />
        {renderCardItems()}
        <div className={stylesMonitoring['button-container--boundary']} style={{ height: buttonContainerHeight }}>
          <Button disabled={disabled} variant={variant} onClick={() => setView('searchLocation')}>
            {defaultViewButtonLabel}
          </Button>
        {renderAlertsCount()}
        </div>
      </>
    );
  };

  const renderBoundaryEdit = () => {
    if (!boundaryToEdit) return null;

    const { inVehicleWarning, name, geometry, alertType } = boundaryToEdit;
    const alertConvert = {
      ON_EXIT: exitingLabel,
      ON_ENTRY: enteringLabel,
    };
    geometry.radius.value = radius

    const renderInnerElements = () => {
      return (
        <div className={styles['inner-elements-container']}>
          <p className={styles['input-switch-title']}>
            <span>{radiusLabel}</span>
            <span>{` ${Math.round(radius)} `}</span>
            <span>{distanceUnit}</span>
          </p>
          <InputSwitch
            label={alertInVehicleLabel}
            className={styles['form-valet-switch']}
            classes={{
              label: styles['input-switch-label'],
            }}
            type="wide"
            checked={inVehicleWarning}
            onChange={() =>
              setBoundaryToEdit({ ...boundaryToEdit, inVehicleWarning: !boundaryToEdit.inVehicleWarning })
            }
          />
        </div>
      );
    };

    return (
      <>
        <TabHeader label={editBoundary} />
        <div>
          <div className={styles['alert-labels-container']}>
            <p className={styles['alerts-alert-label']}>{alertLabel}</p>
            <p className={styles['alerts-alert-type']}>{alertConvert[alertType]}</p>
          </div>
          <BoundaryForm
            label={boundaryNameLabel}
            placeholder={name}
            errorMessage={fieldErrorMessage}
            initialValues={{ search: name }}
            onFormClose={handleCancelClick}
            cancelButtonLabel={cancelButton}
            confirmButtonLabel={saveButton}
            onFormConfirm={handleBoundaryUpdate}
            innerElements={renderInnerElements}
          />
        </div>
      </>
    );
  };

  const resetBoundaries = (activeView = 'default') => {
    dispatch({
      type: 'SET_POINTS_OF_INTEREST',
      pointsOfInterest: [],
    });
    clearMapPins(map);
    setSelectedLocation(null);
    dispatch({ type: 'SET_BOUNDARY_COORDINATES', boundaryCoordinates: null });
    getBoundaries();
    setView(activeView);
  }

  // WE HAVE RUN OUT OF SPACE ON CONTENTFUL TO TEXT ITEMS FOR TAB BOUNDARY
  // THIS IS VERY TEMPORARY
  const modalText = () => {
    const isEnglish = locale === 'en-US'
    const title = isEnglish ? 'SUCCESS!' : '¡EXCELENTE!';
    const body = isEnglish ? 'Boundary Alert is Updated Successfully' : 'La alerta de geocerca se actualizó correctamente';

    return { title, body };
  }

  const handleSettingsSelect = (settingType: 'arrival' | 'departure') => {
    const setting = {
      'arrival': {
        func: setArrivalAlert,
        value: arrivalAlert
      },
      'departure': {
        func: setDepartureAlert,
        value: departureAlert
      }
    }
    const { func, value } = setting[settingType];

    const oppositeAlert = Object.keys(setting).find((key) => key !== settingType);
    const { func: oppFunc, value: oppValue } = setting[oppositeAlert];

    func(!value);
    
    if (geofences.length === Number(maxBoundaryLimit) - 1) {
      oppFunc(!oppValue);
    }

  }

  const boundariesCount = geofences?.length || 0;

  return (
    <>
      <ModalContainer
        show={displayToggleModal}
        header={{ text: modalText().title, position: 'center' }}
        onCloseHandler={() => setDisplayToggleModal(false)}
        size="fl"
      >
        <>
          <div className="text-center">
            {<p>{modalText().body}</p>}
            <button className={stylesMonitoring['modal-button']} onClick={() => setDisplayToggleModal(false)}>
              Ok
            </button>
          </div>
        </>
      </ModalContainer>
      <ActiveView currentView={view} activeView={'default'}>
        {renderDefaultScreen()}
      </ActiveView>

      <ActiveView currentView={view} activeView={'editList'}>
        <TabHeader label={boundaryAlertLabel} onEdit={() => setView('default')} displayEditButton={boundariesCount > 0} />
        {renderCardItems()}
        <div className={stylesMonitoring['button-container']}>
          <Button variant="outlined" onClick={() => setView('default')}>
            Cancel
          </Button>
        </div>
      </ActiveView>

      <ActiveView currentView={view} activeView={'editBoundary'}>
        {renderBoundaryEdit()}
      </ActiveView>

      <ActiveView currentView={view} activeView={'searchLocation'}>
        <TabHeader label={formViewTitle} content={formViewDescription} />
        <BoundaryForm
          label={fieldLabel}
          placeholder={fieldPlaceholder}
          errorMessage={fieldErrorMessage}
          initialValues={{ search: '' }}
          onFormClose={() => setView('default')}
          cancelButtonLabel={cancelButton}
          confirmButtonLabel={confirmButton}
          onFormConfirm={formConfirm}
        />
      </ActiveView>

      <ActiveView currentView={view} activeView={'selectLocation'}>
        <TabHeader label={searchResultsLabel} />
        <div id="poi_list" className={stylesMonitoring['poi-item-list']}>
          {renderBoundaryLocations()}
        </div>
        <div style={{ textAlign: 'center' }}>
          {locationError && !selectedLocation && <p className={stylesMonitoring['error-text']}>{locationErrorText}</p>}
        </div>
        <div className={stylesMonitoring['button-container']}>
          <Button
            className={stylesMonitoring['boundary-button']} variant="outlined" onClick={() => resetBoundaries('searchLocation')}>
            {confirmButton}
          </Button>
          <Button
            className={stylesMonitoring['boundary-button']}
            variant="filled"
            onClick={() => {
              if (selectedLocation) {
                const { address, coordinate } = selectedLocation.geoAddress;
                setView('adjustBoundarySize');
                updateBoundary({ ...boundary, address, center: { coordinate } });
                dispatch({ type: 'SET_BOUNDARY_COORDINATES', boundaryCoordinates: coordinate });
                dispatch({ type: 'RESET_MULTIPLE_BOUNDARY_COORDINATES' });
              } else {
                setLocationError(true);
              }
            }}
          >
            {setLocationButtonText}
          </Button>
        </div>
      </ActiveView>

      <ActiveView currentView={view} activeView={'adjustBoundarySize'}>
        <>
          <TabHeader label={adjustBoundarySizeLabel} content={boundarySizeContent} />
          <div>
            <p>{`${radiusLabel} ${Math.round(radius)} ${distanceUnit}`}</p>
          </div>
          <div className={styles['button-container']}>
            <Button className={stylesMonitoring['boundary-button']} variant="outlined" onClick={handleCancelClick}>
              {cancelButton}
            </Button>
            <Button
              className={stylesMonitoring['boundary-button']}
              variant="filled"
              onClick={() => setView('boundarySettings')}
            >
              {setBoundaryLabel}
            </Button>
          </div>
        </>
      </ActiveView>

      <ActiveView currentView={view} activeView={'boundarySettings'}>
        <TabHeader label={alertSettingsLabel} />
        <SettingSelect
          title={arrivalLabel}
          body={arrivalContent}
          onClick={() => handleSettingsSelect('arrival')}
          selected={arrivalAlert}
        />
        <div className={stylesMonitoring['settings-divider']}></div>
        <SettingSelect
          title={departureLabel}
          body={departureContent}
          onClick={() => handleSettingsSelect('departure')}
          selected={departureAlert}
        />
        <div className={stylesMonitoring['settings-description']} dangerouslySetInnerHTML={{ __html: settingsDescription }} />
        <div className={stylesMonitoring['button-container']}>
          <Button variant="outlined" onClick={handleCancelClick}>
            {cancelButton}
          </Button>
          <Button variant="filled" onClick={() => setView('nameBoundary')}>
            {nextButton}
          </Button>
        </div>
      </ActiveView>

      <ActiveView currentView={view} activeView={'nameBoundary'}>
        <TabHeader label={nameAlertTitle} />
        <BoundaryForm
          label={boundaryNameLabel}
          placeholder={''}
          errorMessage={fieldErrorMessage}
          initialValues={{ search: '' }}
          onFormClose={handleCancelClick}
          cancelButtonLabel={cancelButton}
          confirmButtonLabel={saveButton}
          onFormConfirm={handleSaveClick}
          innerElements={() => {
            return (
              <>
                <div className={stylesMonitoring['alert-description']}>{alertDescription}</div>
                <div className={stylesMonitoring['input-switch-container']}>
                  <div className={stylesMonitoring['input-switch-label']}>{displayAlertToggleText}</div>
                  <InputSwitch
                    checked={inVehicleWarning}
                    onChange={() => {
                      setInvehicleWarning(!inVehicleWarning);
                    }}
                  />
                </div>
              </>
            );
          }}
        />
      </ActiveView>

      <ActiveView currentView={view} activeView={'tabInfo'}>
        <TabInfo onClose={() => setView('default')}>
          <TabHeader label={title} content={infoBoxContent} showInfoIcon />
        </TabInfo>
      </ActiveView>
    </>
  );
}
