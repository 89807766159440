
export enum AppDynamics {
  URL = '//cdn.appdynamics.com/adrum/adrum-latest.js',
  ADDRUM_EXT_URL = '//cdn.appdynamics.com',
  BEACON_URL = '//col.eum-appdynamics.com',
  ADDRUM_START_TIME = 'adrum-start-time',
  ADDRUM_CONFIG = 'adrum-config'
}

const loadAppDynamics = (appDynamicsKey: string) => {
  window[AppDynamics.ADDRUM_START_TIME] = new Date().getTime();
  ((config) => {
    config.appKey = appDynamicsKey;
    config.adrumExtUrlHttp = AppDynamics.ADDRUM_EXT_URL;
    config.adrumExtUrlHttps = AppDynamics.ADDRUM_EXT_URL;
    config.beaconUrlHttp = AppDynamics.BEACON_URL;
    config.beaconUrlHttps = AppDynamics.BEACON_URL;
    config.isZonePromise = true;
    config.xd = { enable: false };
    config.spa = { spa2: true };
  })(window[AppDynamics.ADDRUM_CONFIG] || (window[AppDynamics.ADDRUM_CONFIG] = {}));
  const script = document.createElement('script');
  script.src = AppDynamics.URL;
  document.head.appendChild(script);
};

export default loadAppDynamics;
