import React from 'react';

import { ContentfulFile } from '@app/types/ContentfulComponent';

import styles from './AppLink.module.css';

interface AppLinkProps {
  name: string;
  image: ContentfulFile;
  url: string;
  newTab?: boolean;
}

const AppLink = ({
  name,
  image: {
    file: { url: imageUrl },
  },
  url,
  newTab,
}: AppLinkProps): JSX.Element => {
  const backgroundImage = {
    backgroundImage: `url(${imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  const target = newTab ? '_blank' : undefined;

  return (
    <span className={styles.wrapper} style={backgroundImage}>
      <a className={styles.url} href={url} target={target} rel="noreferrer">
        <span className="visually-hidden">{name}</span>
      </a>
    </span>
  );
};

export default AppLink;
