export enum TOKEN_EVENTS {
  SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
  SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
  SET_ID_TOKEN = "SET_ID_TOKEN",
}

export const setAccessToken = (payload: string) => {
  return {
    type: TOKEN_EVENTS.SET_ACCESS_TOKEN,
    payload,
  };
};

export const setRefreshToken = (payload: string) => {
  return {
    type: TOKEN_EVENTS.SET_REFRESH_TOKEN,
    payload,
  };
};

export const setIdToken = (payload: string) => {
  return {
    type: TOKEN_EVENTS.SET_ID_TOKEN,
    payload,
  };
};
