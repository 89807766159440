import React, { ReactNode } from 'react';
import clsx from 'clsx';

import Modal from '@components/ModalContainer';
import Button from '@components/Button';

import styles from './Dialog.module.css';

type DialogProps = {
  title?: string;
  children: ReactNode;
  labelOk?: string;
  labelCancel?: string;
  onOk?: () => void;
  onCancel?: () => void;
  classes?: {
    dialog?: string;
    header?: string;
    title?: string;
    body?: string;
  };
};

const Dialog = ({ title, children, labelOk, labelCancel, onOk, onCancel = () => {}, classes = {} }: DialogProps) => {
  return (
    <Modal
      size="md"
      height="auto"
      position="center"
      classes={{
        ...classes,
        dialog: clsx(styles.dialog, classes.dialog),
        header: clsx(styles.header, classes.header),
      }}
      show
    >
      {title && <h1>{title}</h1>}
      <div className={styles.body}>{children}</div>
      <div className={styles.buttons}>
        {labelCancel && (
          <Button variant="outlined" onClick={onCancel}>
            {labelCancel}
          </Button>
        )}
        {labelOk && (
          <Button variant="filled" onClick={onOk}>
            {labelOk}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default Dialog;
