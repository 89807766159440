// import { AlertsData, Alerts } from '@api/types';

import { Geofence, GetGeofenceResponse } from "@cv/portal-rts-lib/geofences/models";
import { BOUNDARY_ACTIONS } from "../actions";

type boundaryCoordinates = {
  latitude: number;
  longitude: number;
  latlongUOM: string;
};

type BoundariesState = {
  boundaries: GetGeofenceResponse;
  radius: number | null;
  temporaryBoundary: boundaryCoordinates | null;
  multipleBoundaryCoordinates: Array<boundaryCoordinates>
};

const boundaryState: BoundariesState = {
  boundaries: [],
  radius: 2,
  temporaryBoundary: null,
  multipleBoundaryCoordinates: [],
};

type BoundaryAction = {
  type: BOUNDARY_ACTIONS;
  boundaries: GetGeofenceResponse;
  selectedBoundary: Geofence;
  boundaryCoordinates: boundaryCoordinates;
  multipleBoundaryCoordinates: Array<boundaryCoordinates>;
  radius: number
};

export default (state = boundaryState, action: BoundaryAction) => {
  switch (action.type) {
    case BOUNDARY_ACTIONS.SET_BOUNDARIES:
      return {
        ...state,
        boundaries: action.boundaries,
      };
    case BOUNDARY_ACTIONS.ADD_BOUNDARY:
      return {
        ...state,
        boundaries: [...state.boundaries, action.selectedBoundary],
      };
    case BOUNDARY_ACTIONS.UPDATE_BOUNDARY:
      const updatedBoundaries = state.boundaries.map((boundary) => {
        if (boundary.lastSvcReqId === action.selectedBoundary.lastSvcReqId) {
          return action.selectedBoundary
        } else {
          return boundary
        }
      });

      return {
        ...state,
        boundaries: updatedBoundaries
      }
    case BOUNDARY_ACTIONS.DELETE_BOUNDARY:
      return {
        ...state,
        boundaries: state.boundaries.filter(
          (boundary) => boundary.lastSvcReqId !== action.selectedBoundary.lastSvcReqId
        ),
      };
    case BOUNDARY_ACTIONS.SET_BOUNDARY_COORDINATES:
      return {
        ...state,
        boundaryCoordinates: action.boundaryCoordinates
      }
    case BOUNDARY_ACTIONS.SET_MULTIPLE_BOUNDARY_COORDINATES:
      return {
        ...state,
        multipleBoundaryCoordinates: action.multipleBoundaryCoordinates
      }
    case BOUNDARY_ACTIONS.RESET_MULTIPLE_BOUNDARY_COORDINATES:
      return {
        ...state,
        multipleBoundaryCoordinates: null
      }
    case BOUNDARY_ACTIONS.UPDATE_BOUNDARY_COORDINATES:
      return {
        ...state,
        boundaryCoordinates: {...state.temporaryBoundary, ...action.boundaryCoordinates}
      }
    case BOUNDARY_ACTIONS.SET_RADIUS:
      return {
        ...state,
        radius: action.radius
      }
    case BOUNDARY_ACTIONS.DELETE_BOUNDARY_COORDINATES:
      return {
        ...state,
        boundaryCoordinates: null
      }
    case BOUNDARY_ACTIONS.RESET_BOUNDARY_AND_RADIUS:
      return {
        ...state,
        boundaryCoordinates: null,
        radius: 2
      }
    default:
      return state;
  }
};
