import Button from '@components/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Link.module.css';

export type LinkProps = {
  name: string;
  label: string;
  url: string;
  newTab?: boolean;
  navigationLink?: boolean;
  showAsButton?: boolean;
};

const Link = ({ label, url, newTab, navigationLink, showAsButton }: LinkProps) => {
  const history = useHistory();
  const handleAnchorTag = () => {
    history.push(`${url}`);
  };

  if (showAsButton) {
    return <Button onClick={handleAnchorTag}>{label}</Button>;
  }

  if (navigationLink) {
    return (
      <a className={styles.Link} onClick={handleAnchorTag}>
        {label}
      </a>
    );
  }

  return (
    <a className={styles.Link} href={url} target={newTab ? '_blank' : undefined}>
      {label}
    </a>
  );
};

export default Link;
