import React, { ReactNode } from 'react';
import styles from './ModalContainer.module.css';
import clsx from 'clsx';
import ReactDOM from 'react-dom';

export type HeaderPosition = 'left' | 'center' | 'right';
export type Position = 'top' | 'center' | 'bottom';
export type Size = 'sm' | 'md' | 'lg' | 'fl';
export type HeightType = 'auto' | 'px';
export type OverflowY = 'auto' | 'visible';

type Header = {
  text?: string;
  position?: HeaderPosition;
  showXButton?: boolean;
  showTopBorder?: boolean;
};

type ModalContainerProps = {
  onCloseHandler?: () => void;
  show?: boolean;
  header?: Header;
  children: ReactNode;
  size?: Size;
  height?: HeightType;
  overflowY?: OverflowY;
  position?: Position;
  classes?: {
    dialog?: string;
    header?: string;
    title?: string;
    body?: string;
  };
};

const ModalContainer = ({
  onCloseHandler,
  show = false,
  children,
  size = 'sm',
  height = 'px',
  overflowY = 'auto',
  position = 'top',
  classes = {},
  header: { text = '', position: headerPosition = 'left', showXButton = true, showTopBorder = true } = {},
}: ModalContainerProps) => {
  return ReactDOM.createPortal(
    show && (
      <div role="dialog" className={clsx(styles['modal'], styles[`modal-${position}`])}>
        <section
          className={clsx(
            styles['modal-dialog'],
            styles[`modal-${size}`],
            styles[`modal-${height}`],
            styles[`modal-overflowY--${overflowY}`],
            { [styles['modal-border--top']]: showTopBorder },
            classes.dialog,
          )}
        >
          {(text || showXButton) && (
            <div className={clsx(styles['modal-header'], classes.header)}>
              {!!onCloseHandler && showXButton && (
                <button className={styles['close']} onClick={onCloseHandler} aria-label="Close">
                  <span aria-hidden="true">x</span>
                </button>
              )}
              {!!text && (
                <h5 className={clsx(styles['modal-title'], classes.title)} style={{ textAlign: headerPosition }}>
                  {text}
                </h5>
              )}
            </div>
          )}
          <div className={clsx(styles['modal-body'], classes.body)}>{children}</div>
        </section>
      </div>
    ),
    window.document.body,
  );
};

export default ModalContainer;
