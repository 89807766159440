import React, { useEffect, TouchEvent } from 'react';
import nextId from '@utils/next-id';
import clsx from 'clsx';

import styles from './InputSwitch.module.css';
import useToggle from '@hooks/useToggle';

type InputSwitchProps = {
  label?: string | React.ReactChildren;
  checked?: boolean;
  disabled?: boolean;
  onChange?: null | ((event: Event) => void);
  className?: string;
  type?: 'default' | 'wide';
  classes?: {
    label?: string;
    input?: string;
  };
};

export default function InputSwitch({
  label = '',
  checked = false,
  disabled = false,
  onChange = null,
  className = '',
  type = 'default',
  classes = {},
  ...restProps
}: InputSwitchProps) {
  const inputId = nextId();
  const [isChecked, setIsChecked] = useToggle(checked);

  useEffect(() => {
    if (isChecked !== checked) {
      setIsChecked();
    }
  }, [checked])

  const inputClassName = `${styles.InputSwitch} ${isChecked ? `${styles['InputSwitch--checked']}` : ''} ${
    disabled ? `${styles['InputSwitch--disabled']}` : ''
  } ${className} ${styles[`InputSwitch--${type}`]}`;

  const handleOnChange = (event: any) => {
    if (typeof onChange === 'function') {
      onChange(event);
      setIsChecked();
    }
  };

  return (
    <div className={inputClassName}>
      {label && (
        <label className={clsx(styles['InputSwitch-label'], classes.label)} htmlFor={inputId}>
          {label}
        </label>
      )}
      <input
        className={clsx(styles['InputSwitch-input'], 'visually-hidden', classes.input)}
        id={inputId}
        type="checkbox"
        defaultChecked={isChecked}
        disabled={disabled}
        onChange={handleOnChange}
        {...restProps}
      />
      <label className={styles['InputSwitch-toggle']} htmlFor={inputId} />
    </div>
  );
}
