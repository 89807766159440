import * as yup from 'yup';
import truncate from 'lodash/truncate';
import { AccountData, useApi } from '@api';
import { Form, Formik, FormikProps } from 'formik';
import { FormikInputField, FormikPhoneField } from '@components/FormikFields';
import React, { useEffect, useState } from 'react';
import {
  ServiceNotificationPreference,
  preferencesData,
} from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models';
import useLabels, { Label } from '@hooks/useLabels';

import Button from '@components/Button';
import { CarrierCodes, ChannelName } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/enums';
import { FaEdit } from 'react-icons/fa';
import Grid from '@components/Grid';
import { InfoBox } from '@components/InfoBox';
import InputSelect from '@components/InputSelect';
import { ModelConnector } from '@components/ApiConnectorHOCs';
import SettingsGrid from '@components/SettingsGrid';
import styles from './NotificationPreferences.module.css';
import useDialogActions from '@redux/hooks/dialog';
import useLoadingActions from '@redux/hooks/loading';

export type NotificationPreferencesProps = {
  data?: AccountData;
  labels: { content: Label[] };
  timeZoneValues: string[];
};

type Values = {
  [index: string]: string | undefined;
};

type Channel = {
  push: boolean;
  text: boolean;
  email: boolean;
};

type ChangeData = Channel & {
  serviceName: string;
  telematicsServiceName: string;
};

type Preferences = Omit<preferencesData, 'serviceNotificationPreferences'> & {
  serviceNotificationPreferences: ChangeData[];
};

function transform_data(data: ServiceNotificationPreference[] = [], labels: { [key: string]: string }): ChangeData[] {
  return data.filter(item => item.displayOnUI)
    .map((item) => ({
      telematicsServiceName: item.telematicsServiceName,
      serviceName: labels[item.telematicsServiceName],
      ...(item.channels.reduce(
        (acc, item) => ({
          ...acc,
          [item.channel.toLowerCase()]: item.enabled,
        }),
        {},
      ) as Channel),
    }));
}

function revert_data(data: ChangeData[]) {
  const channels = ['EMAIL', 'TEXT', 'PUSH'];
  return data.map((item) => ({
    telematicsServiceName: item.telematicsServiceName,
    channels: channels.map((channel) => ({
      channel: channel as ChannelName,
      enabled: item[channel.toLowerCase() as 'text' | 'push' | 'email'],
    })),
  }));
}

function NotificationPreferences({ data, labels: { content }, timeZoneValues }: NotificationPreferencesProps) {
  const api = useApi();
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [preferences, setPreferences] = useState<Partial<Preferences>>({});
  const { showDialog } = useDialogActions();
  const { showLoading, hideLoading } = useLoadingActions();

  useEffect(() => {
    api.getNotificationPreferences().then((preferences) => {
      handleUpdate(preferences?.data);
    });
  }, []);

  const timeZoneOptions = timeZoneValues.map((option: string) => {
    const [value, label] = option.split('::');
    if (label) {
      return { value, label };
    }
    return { value: option, label: option };
  });

  const labels = useLabels(content).getAllPrimaries();
  const { email, text, timeZone } = preferences;
  const { phone } = text || { phone: '' };

  const validationSchema = yup.object().shape({
    email: yup.string().trim().email(labels.errorEmailInvalid).required(labels.errorEmailRequired),
    phone: yup
      .string()
      .trim()
      .matches(/^\+?[1-9]\d{10,14}$/, { message: labels.errorPhoneInvalid }),
  });

  const handleEditClick = () => {
    setMode('edit');
  };

  const handleCancelClick = () => {
    setMode('view');
  };

  const { givenName, fathersName, mothersName } = data?.userName || {
    givenName: '',
    fathersName: '',
    mothersName: '',
  };
  const fullName = [givenName, fathersName, mothersName].join(' ');
  const truncateSettings = { length: 25 };
  const contacts = [
    { label: labels.name, value: truncate(fullName, truncateSettings) },
    { label: labels.primaryEmail, value: truncate(email, truncateSettings) },
    { label: labels.primaryPhone, value: phone },
    { label: labels.timeZone, value: timeZone },
  ];

  const fields = {
    serviceName: labels.notificationType,
    email: labels.notificationTypeEmail,
    push: labels.notificationTypePush,
  };

  const handleUpdate = (updateData: preferencesData = {}) => {
    // We can not use default values with destruction since API may return null
    // (preferencesData is not properly typed at the moment)
    const { serviceNotificationPreferences, email, text, timeZone } = updateData;
    setPreferences({
      email: email || '',
      text: text || { phone: '', carrier: CarrierCodes.UNKNOWN },
      timeZone: timeZone || '',
      serviceNotificationPreferences: transform_data(
        serviceNotificationPreferences || [],
        labels,
      ),
    });
  };

  const handleSettingsChange = (serviceName: string, name: string, value: boolean) => {
    const notificationPreferences = preferences?.serviceNotificationPreferences;
    if (!notificationPreferences) {
      return;
    }

    const item = notificationPreferences.find((service) => service.serviceName === serviceName);
    if (!!item && ['email', 'push'].includes(name)) {
      item[name as 'email' | 'push'] = value;

      setPreferences({ ...preferences });
    }
  };

  const handleSubmit = async (values: any) => {
    showLoading();
    const notificationPreferences = preferences?.serviceNotificationPreferences;
    if (!notificationPreferences) {
      return;
    }

    if (values.phone) {
      preferences.text = {
        phone: values.phone,
        carrier: CarrierCodes.UNKNOWN,
      };
    }

    try {
      const updateResponse = await api.updateNotificationPreferences({
        ...preferences,
        timeZone: values.timeZone,
        email: values.email,
        serviceNotificationPreferences: revert_data(notificationPreferences),
      });
      handleUpdate(updateResponse?.data);
      showDialog({ title: labels.success, message: labels.successMessage });
    } catch (e) { }
    setMode('view');
    hideLoading();
  };

  return (
    <div className={styles['notification-preferences']}>
      {mode === 'view' && (
        <>
          <button className={styles['edit-button']} onClick={handleEditClick}>
            <FaEdit className={styles['edit-icon']} />
          </button>
          <p>{labels.helpText}</p>
          <InfoBox entries={contacts} />
          <SettingsGrid indexing="serviceName" data={preferences.serviceNotificationPreferences} fields={fields} />
          <p className={styles['push-footnote']}>{labels.pushFootnote}</p>
        </>
      )}
      {mode === 'edit' && (
        <Formik initialValues={{ email, phone, timeZone }} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {(props: FormikProps<Values>) => (
            <Form className={styles['form']}>
              <Grid className={styles['grid']} columns="2">
                <div>
                  <div className={styles['label']}>{labels.name}</div>
                  <div>{fullName}</div>
                </div>
                <FormikInputField
                  name="email"
                  type="email"
                  label={labels.primaryEmail}
                  placeholder={labels.primaryEmail}
                  className={styles['input']}
                />
                <FormikPhoneField
                  name="phone"
                  label={labels.primaryPhone}
                  placeholder={labels.primaryPhone}
                  classes={{ input: styles['input-field'] }}
                  form={{
                    errors: props.errors,
                    handleBlur: props.handleBlur,
                    setFieldValue: props.setFieldValue,
                    touched: props.touched,
                  }}
                />

                <FormikInputField
                  name="timeZone"
                  type="timeZone"
                  label={labels.timeZone}
                  placeholder={labels.timeZone}
                  onChange={() => { }}
                  options={timeZoneOptions}
                  InputComponent={InputSelect}
                  className={styles['drop-down-field']}
                />
              </Grid>
              <SettingsGrid
                indexing="serviceName"
                data={preferences.serviceNotificationPreferences}
                fields={fields}
                mode="edit"
                onChange={handleSettingsChange}
              />
              <p className={styles['push-footnote']}>{labels.pushFootnote}</p>
              <div className={styles['buttons']}>
                <Button variant="outlined" onClick={handleCancelClick}>
                  {labels.cancel}
                </Button>
                <Button variant="filled" type="submit">
                  {labels.save}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default ModelConnector(NotificationPreferences, { showUiOnError: true });
