import React, { useState } from 'react';
import { Formik, Form, FormikProps, FormikValues } from 'formik';
import { FaCheck } from 'react-icons/fa';

import { timeOptions } from '../constants';
import { FormikInputField } from '@components/FormikFields';
import Button from '@components/Button';
import ControlLabel from '@components/ControlLabel';
import DaysSelect from '@components/DaysSelect';
import InputSwitch from '@components/InputSwitch';
import RoundToggleButton from '@components/RoundToggleButton';
import Select from '@components/InputSelect';

import styles from './Forms.module.css';
import stylesInput from '@components/Input/Input.module.css';

type Values = {
  name: string;
  dayOfTheWeek: string[];
  inVehicleWarning: boolean;
  allDay: boolean;
  [key: number]: string;
};

type CurfewFormProps = {
  label?: string;
  description?: string;
  switchLabel?: string;
  onFormClose?: () => void;
  onFormConfirm?: (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    setStatus: (status?: object | string) => void,
  ) => void;
  initialValues: Values;
  errorMessage: string;
  placeholder?: string;
  tabDescription: string;
  days: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  dayLabel: string;
  daysSelectDayEndLabel: string;
  daysSelectDayStartLabel: string;
  daysValidationErrorMessage: string;
  weekLabel: string;
  formNameError: string;
};

export default function CurfewForm({
  label = '',
  switchLabel,
  onFormClose,
  onFormConfirm,
  initialValues,
  errorMessage,
  placeholder,
  tabDescription,
  days,
  cancelButtonLabel,
  confirmButtonLabel,
  dayLabel,
  daysSelectDayEndLabel,
  daysSelectDayStartLabel,
  daysValidationErrorMessage,
  weekLabel,
  formNameError,
}: CurfewFormProps) {
  const [selectedDays, setSelectedDays] = useState(initialValues.dayOfTheWeek);
  const [selectedHours, setSelectedHours] = useState(false);
  const [curfewNameError, setCurfewNameError] = useState(false);
  const [curfewName, setCurfewName] = useState(initialValues.name || '');
  const getDaysLabel = days.split(' ');

  const handleSelectedDay = (days: string[], isSelected: boolean) => {
    setSelectedDays((previouslySelected) => {
      const newSelected = previouslySelected.filter((day) => !days.includes(day));
      if (isSelected) {
        newSelected.push(...days);
      }
      return newSelected;
    });
  };

  const appendValues = (values: FormikValues) => ({
    ...values,
    allDay: selectedHours,
    name: curfewName,
  });

  return (
    <Formik
      key={label}
      initialValues={initialValues}
      validateOnMount
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        if (curfewName) {
          onFormConfirm && onFormConfirm(appendValues(values), setSubmitting, setStatus);
        } else {
          setCurfewNameError(true);
        }
      }}
    >
      {(props: FormikProps<Values>) => (
        <Form onSubmit={props.handleSubmit} className={styles['form-curfew']}>
          <div className={styles['form-curfew-field']}>
            <FormikInputField
              name="name"
              label={label}
              placeholder={placeholder}
              className={`${stylesInput['input-dark']}`}
              onChange={(e) => {
                setCurfewName(e.target.value);
                setCurfewNameError(false);
              }}
              value={curfewName}
            />
            {curfewNameError && <p className={styles['name-error']}>{formNameError}</p>}
          </div>
          <div className={styles['form-curfew-title']}>{tabDescription}</div>
          <FormikInputField
            name="dayOfTheWeek"
            InputComponent={DaysSelect}
            selectedDays={selectedDays}
            onChange={handleSelectedDay}
            checkboxLabel={weekLabel}
            days={getDaysLabel}
          />
          <div className={styles['form-curfew-row']}>
            <div className={styles['form-curfew-row-item']}>
              <FormikInputField
                name="startTime"
                label={daysSelectDayStartLabel}
                InputComponent={Select}
                nativeDropdown={true}
                onChange={() => {}}
                options={timeOptions}
              />
              <FormikInputField
                name="endTime"
                label={daysSelectDayEndLabel}
                InputComponent={Select}
                nativeDropdown={true}
                onChange={() => {}}
                options={timeOptions}
              />
            </div>
            <ControlLabel
              label={dayLabel}
              control={
                <RoundToggleButton
                  checked={selectedHours}
                  onToggle={setSelectedHours}
                  classes={{ input: styles['toggle-all-button'] }}
                >
                  <FaCheck />
                </RoundToggleButton>
              }
            />
          </div>

          <InputSwitch
            label={switchLabel}
            checked={initialValues.inVehicleWarning}
            className={styles['input-switch--bottom']}
            type="wide"
            onChange={() => props.setFieldValue('inVehicleWarning', !initialValues.inVehicleWarning)}
          />

          <div className={styles['button-container']}>
            <Button variant="outlined" onClick={onFormClose} type="button">
              {cancelButtonLabel}
            </Button>
            <Button variant="filled" className={styles['button-save']} type="submit">
              {confirmButtonLabel}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
