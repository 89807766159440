import { getVehicleById, searchVehicle } from '@cv/portal-cps-lib/vehicle';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { SearchVehicleRequest, VehicleRequest } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { formatVehicleDetails } from '../formatters/vehicleDetails';
import { ActiveCriteria } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';
import { queryManagedVehicle } from '@cv/portal-idm-lib/vehicle';
import { QueryManagedVehicleRequest } from '@cv/portal-idm-lib/vehicle/models';
import { QueryFilterKeys } from '@cv/portal-idm-lib/models';
import { SubscribedServices } from '../types';

type VehicleDetailsArgs = {
  accessToken: string;
  locale: string;
};

type GetVehicleDetailsArgs = VehicleDetailsArgs & {
  vehicleId: string;
  subscribedServices?: SubscribedServices[];
  include?: string;
  tenantId: string;
};

type SearchVehicleArgs = VehicleDetailsArgs & {
  vin: string;
  activeCriteria?: ActiveCriteria;
  deviceId?: string;
  telematicsProgramId?: string;
  tenantId: string;
};

type QueryVehicleArgs = VehicleDetailsArgs & {
  filter: QueryFilterKeys;
  fields?: string[];
};

export default class VehicleDetailsService {
  private environment: Environment;

  constructor(environment = Environment.SIT) {
    this.environment = environment;
  }

  getVehicleDetails({
    vehicleId,
    subscribedServices = [],
    include = 'vehicle_services',
    accessToken,
    locale,
    tenantId,
  }: GetVehicleDetailsArgs) {
    const payload: VehicleRequest = {
      data: {
        id: vehicleId,
      },
      queryParams: {
        include,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
    };
    console.log('🚀 ~ file: VehicleDetailsService.ts ~ line 52 ~ VehicleDetailsService ~ payload', payload);

    return getVehicleById(payload).then((response) => ({
      data: formatVehicleDetails(response.data, subscribedServices),
    }));
  }

  searchVehicle({
    vin,
    activeCriteria = ActiveCriteria.All,
    deviceId = '',
    telematicsProgramId = '',
    accessToken,
    locale,
    tenantId,
  }: SearchVehicleArgs) {
    const payload: SearchVehicleRequest = {
      data: {
        vin,
        activeCriteria,
        deviceId,
        telematicsProgramId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
    };
    return searchVehicle(payload);
  }

  queryVehicle({ filter, fields = [], accessToken, locale }: QueryVehicleArgs) {
    const payload: QueryManagedVehicleRequest = {
      queryParams: {
        _queryFilter: filter,
        _fields: fields.concat(['*']).join(','),
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };

    return queryManagedVehicle(payload);
  }
}
