import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { differenceInMinutes } from 'date-fns';

import { UserSecurityQuestionObject } from '@cv/portal-idm-lib/models';
import { APIErrorResponse } from '@cv/portal-common-lib/ajax/models';
import { ResetPinPayload, ResetPinByKBAPayload } from '@cv/portal-idm-lib/pin/models';
import { UpdateSecurityQuestionsByPinRequest } from '@cv/portal-idm-lib/security-questions-pin/models';
import { SetupPinAndSecurityQuestionsRequest } from '@cv/portal-idm-lib/security-questions-pin/models';

import { useApi } from '@api';
import usePreferencesSelector from '@redux/selectors/preferences';
import { CreatorForm } from '@components/EntryCreator';
import { setUserPinStatus, setUserPinLockedTimestamp } from '@redux/actions';
import useLabels, { Label } from '@hooks/useLabels';
import Grid from '@components/Grid';
import ContentRenderer from '@components/ContentRenderer';
import Loader from '@components/Loader';
import Button from '@components/Button';

import styles from './SecurityInfoBox.module.css';
import Dialog from '@components/Dialog';

type ContentRendererProps = {
  componentType: string;
};

type SecurityInfoBoxProps = {
  pinLabel?: string;
  pinValue: ContentRendererProps;
  pinForm: React.ReactNode;
  securityQuestionLabel: string;
  securityQuestionValue: ContentRendererProps;
  securityQuestionForm: React.ReactNode;
  pinResetLabel?: string;
  pinResetForm: React.ReactNode;
  securityAnswerLabel?: string;
  securityAnswerValue: ContentRendererProps;
  errorLabel?: string;
  configurePinDescription?: string;
  configurePinButtonLabel: string;
  configurePinFormDescription: string;
  configurePinForm: React.ReactNode;
  labels: { content: Label[] };
};

type SecurityBoxForm = {
  [key: string]: SecurityBoxFormProps;
};

type SecurityBoxFormProps = {
  form: React.ReactNode;
  onFormConfirm: Function;
  description?: string;
};

type UpdateSecurityQuestionsData = {
  currentPin: string;
  securityQuestion: string;
  securityAnswer: string;
};

type SetupPinData = {
  newPinField: string;
  securityQuestion: string;
  securityAnswer: string;
};

type UpdatePinData = {
  currentPin: string;
  newPinField: string;
};

type ResetPinData = {
  securityAnswer: string;
  newPinField: string;
};

enum DialogType {
  PinLocked = 'pinLocked',
  CustomerCare = 'customerCare',
}

export default function SecurityInfoBox({
  pinLabel,
  pinValue,
  pinForm,
  securityQuestionLabel,
  securityQuestionForm,
  pinResetLabel,
  pinResetForm,
  securityAnswerLabel,
  securityAnswerValue,
  errorLabel,
  configurePinDescription,
  configurePinButtonLabel,
  configurePinFormDescription,
  configurePinForm,
  labels,
}: SecurityInfoBoxProps) {
  const api = useApi();
  const globalPreferences = usePreferencesSelector();
  const [activeForm, setActiveForm] = useState<string | null>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [invalidPinAttempts, setInvalidPinAttempts] = useState<number>(0);
  const [passwordData, setPasswordData] = useState<UserSecurityQuestionObject | null>(null);
  const [activeDialog, setActiveDialog] = useState<DialogType | null>(null);
  const [error, setError] = useState('');
  const [securityQuestion, setSecurityQuestion] = useState({
    question: '',
    tenantQuestionId: '',
  });
  const [filledFormData, setFilledFormData] = useState<Record<string, unknown> | null>(null);
  const { isPinConfigured, pinLockedTimestamp } = useSelector(({ userReducer }) => userReducer);
  const dispatch = useDispatch();
  const dict = useLabels([labels].map((labelsSet) => labelsSet.content).flat());

  const maximumPinAttempts = 4;
  const pinLockedForMinutes = 10;
  const incorrectPinCode = 'RESPONSE.ERROR.INPUT.INCORRECT_PIN';
  const pinLockedCode = 'RESPONSE.ERROR.DATAERROR.PIN_LOCKED';
  const isPinLocked =
    (pinLockedTimestamp && differenceInMinutes(Date.now(), pinLockedTimestamp) < pinLockedForMinutes) ||
    invalidPinAttempts > maximumPinAttempts;

  const handleFormClose = () => {
    setActiveForm(null);
    setStatus('idle');
    setFilledFormData(null);
    setError('');
  };

  const handleCancel = () => {
    setActiveDialog(null);
  };

  const handleError = (
    e: Error & { data?: APIErrorResponse },
    formValues?: Record<string, unknown>,
    setFieldError?: (fieldName: string, message: string) => void,
    setSubmitting?: (isSubmitting: boolean) => void,
    fieldName?: string,
  ) => {
    if (formValues) {
      setFilledFormData(formValues);
    }

    setStatus('error');
    setSubmitting && setSubmitting(false);

    if (e?.data?.detail?.code === incorrectPinCode) {
      setInvalidPinAttempts((prevState) => prevState + 1);
    }

    // lock pin for 10 minutes based on the PIN code from the API response
    // or on the UI side if the code doesn't exist in the response
    if (e?.data?.detail?.code === pinLockedCode || invalidPinAttempts > maximumPinAttempts) {
      setActiveForm(null);
      setActiveDialog(DialogType.PinLocked);
      dispatch(setUserPinLockedTimestamp(Date.now()));
    } else if (e.data) {
      setFieldError && fieldName && setFieldError(fieldName, e.message || e.data.message);
      setError(e.message || e.data.message);
    } else {
      setFieldError && fieldName && setFieldError(fieldName, errorLabel || e.message || 'Unknown error');
      setError(errorLabel || e.message || 'Unknown error');
    }
  };

  useEffect(() => {
    if (pinLockedTimestamp && differenceInMinutes(Date.now(), pinLockedTimestamp) >= pinLockedForMinutes) {
      setActiveForm(null);
      setActiveDialog(null);
      setInvalidPinAttempts(0);
      dispatch(setUserPinLockedTimestamp(0));
    }
  }, [pinLockedTimestamp, isPinLocked]);

  const setupPin = async (formValues: SetupPinData) => {
    const payload: SetupPinAndSecurityQuestionsRequest['data'] = {
      input: {
        kbaInfo: [
          {
            tenantQuestionId: formValues.securityQuestion,
            answer: formValues.securityAnswer,
          },
        ],
        pin: formValues.newPinField,
      },
    };

    try {
      setStatus('loading');
      await api.setupSecurityPin(payload);
      handleFormClose();
      dispatch(setUserPinStatus(true));
    } catch (e) {
      handleError(e, formValues);
    }
  };

  const updatePin = async (formValues: UpdatePinData, setErrors: any, setSubmitting: any) => {
    const payload: ResetPinPayload = {
      input: {
        oldPin: formValues.currentPin,
        pin: formValues.newPinField,
      },
    };

    try {
      setStatus('loading');
      await api.updatePin(payload);
      handleFormClose();
    } catch (e) {
      handleError(e, formValues, setErrors, setSubmitting, 'currentPin');
    }
  };

  const updateSecurityQuestions = async (
    formValues: UpdateSecurityQuestionsData,
    setErrors: any,
    setSubmitting: any,
  ) => {
    const payload: UpdateSecurityQuestionsByPinRequest['data'] = {
      input: {
        kbaInfo: [
          {
            tenantQuestionId: formValues.securityQuestion,
            answer: formValues.securityAnswer,
          },
        ],
        pin: formValues.currentPin,
      },
    };

    try {
      setStatus('loading');
      await api.updateSecurityQuestions(payload);
      handleFormClose();
    } catch (e) {
      handleError(e, formValues, setErrors, setSubmitting, 'currentPin');
    }
  };

  const resetPin = async (formValues: ResetPinData) => {
    const payload: ResetPinByKBAPayload = {
      input: {
        answer: [
          {
            tenantQuestionId: securityQuestion.tenantQuestionId,
            answer: formValues.securityAnswer,
          },
        ],
        pin: formValues.newPinField,
      },
    };

    try {
      setStatus('loading');
      await api.resetPin(payload);
      handleFormClose();
    } catch (e) {
      handleError(e, formValues);
    }
  };

  const forms: SecurityBoxForm = {
    pin: {
      form: pinForm,
      onFormConfirm: updatePin,
    },
    securityQuestion: {
      form: securityQuestionForm,
      onFormConfirm: updateSecurityQuestions,
    },
    pinReset: {
      form: pinResetForm,
      onFormConfirm: resetPin,
    },
    configurePin: {
      form: configurePinForm,
      onFormConfirm: setupPin,
      description: configurePinFormDescription,
    },
  };

  const fetchPasswordData = async () => {
    if (!passwordData) {
      try {
        const { data: passwordData } = await api.getPasswordData();
        setPasswordData(passwordData);
        return passwordData;
      } catch (e) {
        handleError(e);
      }
    } else {
      return passwordData;
    }
  };

  // Replace security question list with data from API
  // and inject security question to the reset pin form
  // TODO: figure out how to avoid manual forms patching
  useEffect(() => {
    const formHasSecurityQuestion = activeForm === 'securityQuestion' || activeForm === 'configurePin';
    const populateSecurityQuestions = (data: UserSecurityQuestionObject | null, formWithSecurityQuestion: any) => {
      const securityQuestionsField = formWithSecurityQuestion?.formFields.find(
        (field) => field.fieldName === 'securityQuestion',
      );
      securityQuestionsField.fieldValues = data
        ? Object.entries(data.tenantQuestions).map(([key, value]) => `${key}::${value}`)
        : [];
      setSecurityQuestion({ question: '', tenantQuestionId: '' });
    };

    const updateSecurityQuestion = (
      passwordData: UserSecurityQuestionObject,
      formWithSecurityQuestion: SecurityBoxFormProps,
    ) => {
      // Populate the list of security questions with data from API
      populateSecurityQuestions(passwordData, formWithSecurityQuestion);

      const { tenantQuestionId, customQuestionId } = passwordData.savedResponses?.[1] || '';
      const question = passwordData.tenantQuestions[tenantQuestionId || customQuestionId] || '';

      setSecurityQuestion({
        question,
        tenantQuestionId,
      });
    };

    const loadSecurityQuestions = async (formWithSecurityQuestion: SecurityBoxFormProps) => {
      populateSecurityQuestions(null, formWithSecurityQuestion);
      const passwordData = await fetchPasswordData();
      passwordData && updateSecurityQuestion(passwordData, formWithSecurityQuestion);
    };

    if (formHasSecurityQuestion) {
      loadSecurityQuestions(forms[activeForm].form);
    }
  }, [api, activeForm]);

  useEffect(() => {
    const addStaticSecurityQuestion = async (passwordData: UserSecurityQuestionObject | null) => {
      const questionData = passwordData || (await fetchPasswordData());

      const { tenantQuestionId, customQuestionId } = questionData?.savedResponses?.[1] || '';
      const question = questionData?.tenantQuestions[tenantQuestionId || customQuestionId] || '';

      // display previously set security question when pin is configured
      setSecurityQuestion({ question, tenantQuestionId });

      // Add security question to the reset pin form as a static text
      pinResetForm?.formFields.splice(1, 0, {
        componentType: 'portalFormField',
        fieldLabel: securityQuestionLabel,
        fieldPlaceholder: question,
        fieldType: 'static',
      });
    };

    addStaticSecurityQuestion(passwordData);
  }, []);

  const renderLabel = (label: string | undefined) =>
    label ? <div className={styles['security-info-label']}>{label}</div> : null;
  const renderDescription = (description?: string) =>
    description && <div className={styles['security-info-description']}>{description}</div>;

  const renderEditButton = (formName: string) => {
    return (
      <button className={styles['button-edit']} onClick={() => setActiveForm(formName)}>
        <FaEdit className={styles['edit-icon']} />
      </button>
    );
  };

  return (
    <div className={styles['security-info-box']}>
      {!isPinConfigured && !activeForm && (
        <>
          {renderDescription(configurePinDescription)}
          <Button className={styles['security-button']} onClick={() => setActiveForm('configurePin')}>
            {configurePinButtonLabel}
          </Button>
        </>
      )}
      {isPinConfigured && !activeForm && (
        <Grid className={styles['grid']} columns={'2'}>
          <div className={styles['grid-item']}>
            {renderLabel(pinLabel)}

            <div className={styles['security-info-value']}>
              <ContentRenderer name="pinValue" content={[pinValue]} />
            </div>
            {!isPinLocked && renderEditButton('pin')}
          </div>

          <div className={styles['grid-item']}>
            {renderLabel(securityQuestionLabel)}

            <div className={styles['security-info-value']}>
              <p>{securityQuestion.question}</p>
            </div>
            {!isPinLocked && renderEditButton('securityQuestion')}
          </div>

          <div className={styles['grid-item']}>
            {!isPinLocked && (
              <button className={styles['security-info-button']} onClick={() => setActiveForm('pinReset')}>
                {pinResetLabel}
              </button>
            )}
          </div>

          <div className={styles['grid-item']}>
            {renderLabel(securityAnswerLabel)}

            <div className={styles['security-info-value']}>
              <ContentRenderer name="pinValue" content={[securityAnswerValue]} />
            </div>
          </div>
        </Grid>
      )}
      {status === 'loading' && (
        <div className={styles['loader-wrapper']}>
          <Loader />
        </div>
      )}
      {activeForm && (
        <>
          {Boolean(forms[activeForm]?.description) && (
            <strong>{renderDescription(forms[activeForm]?.description)}</strong>
          )}
          <CreatorForm
            onFormConfirm={forms[activeForm]?.onFormConfirm as (formValues: unknown) => void}
            onFormClose={handleFormClose}
            initialValues={filledFormData}
            {...forms[activeForm]?.form}
          />
        </>
      )}
      {isPinLocked && activeDialog === DialogType.PinLocked && (
        <Dialog
          labelOk="OK"
          onOk={handleCancel}
          title={dict.getPrimary('entriesTitle')}
          classes={{ body: styles['security-info-dialog'] }}
        >
          <p>{dict.getPrimary('entriesDescriptionPrimary')}</p>
          <p>{dict.getPrimary('entriesDescriptionSecondary')}</p>
          <button
            className={styles['security-info-dialog-button']}
            onClick={() => setActiveDialog(DialogType.CustomerCare)}
          >
            {dict.getPrimary('entriesLinkLabel')}
          </button>
        </Dialog>
      )}
      {isPinLocked && activeDialog === DialogType.CustomerCare && (
        <Dialog
          labelOk="OK"
          onOk={handleCancel}
          title={dict.getPrimary('customerTitle')}
          classes={{ body: styles['security-info-dialog'] }}
        >
          <p>
            {dict.getPrimary('customerDescription')}
            <br />
            {globalPreferences?.customerCareNumber}
          </p>
        </Dialog>
      )}
    </div>
  );
}
