import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './Card.module.css';

type CardProps = {
  children?: ReactNode;
  className?: string;
  type?: 'default' | 'main';
  classes?: {
    card?: string;
    inner?: string;
  };
};

function Card({ className, type = 'default', children, classes }: CardProps) {
  return (
    <div className={clsx(styles.Card, styles[`Card--${type}`], className, classes?.card)}>
      <div className={clsx(styles['Card-inner'], classes?.inner)}>{children}</div>
    </div>
  );
}

export default Card;
