import { useMemo } from 'react';

export type ServerLabel = {
  labelKey: string;
  labelValue?: string;
  additionalLabelValue?: string;
  name: string;
};

export type Label = {
  primary: string;
  secondary?: string;
}

type UseLabelsReturn = {
  get(key: string): Label;
  getAll(): { [key: string]: Label };
  getPrimary(key: string, defaultValue?: string): string,
  getAllPrimaries(): { [key:string]: string };
};

const useLabels = (content: ServerLabel[] = []): UseLabelsReturn => {
  const labels = useMemo(
    () =>
      content.reduce((acc, item) => {
        const { labelValue, labelKey, additionalLabelValue } = item;

        let values: Label = { primary: '[UNKNOWN]' };
        if (labelValue && additionalLabelValue) {
          values = { primary: labelValue, secondary: additionalLabelValue };
        } else if (labelValue) {
          values = { primary: labelValue };
        } else if (additionalLabelValue) {
          values = { ...values, secondary: additionalLabelValue };
        }

        return {
          ...acc,
          [labelKey]: values,
        };
      }, {} as { [key: string]: Label }),
    [content],
  );

  const primariesLabels = useMemo(() => {
    return Object.entries(labels).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value.primary
    }), {} as { [key: string]: string });
  }, [labels]);

  return {
    get(key) {
      return key in labels ? labels[key] : { primary: '[UNKNOWN]' };
    },
    getAll() {
      return labels;
    },
    getPrimary(key, defaultValue = '[UNKNOWN]') {
      return key in labels ? labels[key].primary : defaultValue;
    },
    getAllPrimaries() {
      return primariesLabels;
    }
  };
};

export default useLabels;
