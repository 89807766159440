import React, { useState } from 'react';
import clsx from 'clsx';

import LanguagePicker from '@components/LanguagePicker/';
import HeaderLogo from './HeaderLogo';
import HeaderService from './HeaderService';
import HeaderHamburger from './HeaderHamburger';
import HeaderUser from './HeaderUser';
import HeaderMobileMenu from './HeaderMobileMenu';
import HeaderMenuBottomNav from './HeaderMenuBottomNav';
import Navigation from '../Navigation';
import styles from './Header.module.css';
import StolenVehicleLocator from './ HeaderStolenVehicleLocator';
import { useSelector } from 'react-redux';
import { ACTIVE, STOLEN_VEHICLE } from './constant';
import { useNavigation } from '@components/Navigation';

export type HeaderProps = {
  content: {
    logo: object;
    url: string;
    service?: object;
    mobileMenuNavigation?: object;
    userMenuNavigation?: object;
    userPrefix?: string;
    navigation?: object;
    logoutLabel?: string;
    pagesToDisplayLogout?: string[];
    pagesToHideDropdown: string[];
    pagesToHideTcLinkInUserDropDown?: string[];
    stolenVehicleLocator: {
      header: string;
      firstText: string;
      secondText: string;
      thirdText: string;
      url: string;
    };
    languagePreference: {
      languages: string[];
    };
  };
  withNavigation?: boolean;
  withUser?: boolean;
};

function Header({ content, withNavigation, withUser }: HeaderProps) {
  const isAuthenticated = useSelector(({ accessTokenReducer }) => !!accessTokenReducer.accessToken);
  const { logo, url, service, mobileMenuNavigation, userMenuNavigation, userPrefix, stolenVehicleLocator } = content;
  const { svl } = useSelector(({ stolenVehicleReducer }) => stolenVehicleReducer);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const { currentPath } = useNavigation();
  const currentLocation = currentPath.page;

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const renderNavItems = (style: string) => {
    if (content && content['navigation']) {
      return (
        <Navigation name="mainNavigation" className={styles[style]} {...content['navigation']} isNavigationMachine />
      );
    }
  };
  const renderHeaderUser = () => (
    <HeaderUser
      userPrefix={userPrefix}
      navigation={userMenuNavigation}
      logoutLabel={content?.logoutLabel}
      pagesToDisplayLogout={content?.pagesToDisplayLogout}
      pagesToHideDropdown={content.pagesToHideDropdown}
      pagesToHideTcLinkInUserDropDown={content?.pagesToHideTcLinkInUserDropDown}
    />
  );

  const renderSvlHeader = () => {
    if (
      (currentLocation === 'profile' || currentLocation === 'support') &&
      svl &&
      svl.status === ACTIVE &&
      svl.type === STOLEN_VEHICLE
    ) {
      return <StolenVehicleLocator stolenVehicleLocator={stolenVehicleLocator} />;
    }
  };

  return (
    <>
      <header className={clsx(styles['Header-container'], { [styles['Header--with-nav']]: withNavigation })}>
        <div className={styles['Header-wrapper']}>
          {logo && <HeaderLogo logo={logo} url={url}></HeaderLogo>}

          <div className={styles['Header-inner']}>
            {service && <HeaderService imageData={service} />}
            {withNavigation && renderNavItems('Header-main-navigation')}
            {content.languagePreference && <LanguagePicker languagePreference={content.languagePreference} />}
            {withUser && renderHeaderUser()}
            {(withNavigation || withUser) && <HeaderHamburger onClick={toggleMobileMenu} />}
          </div>
        </div>
        {(withNavigation || withUser) && (
          <HeaderMobileMenu onClose={() => setIsMobileMenuVisible(false)} isVisible={isMobileMenuVisible}>
            {withUser && renderHeaderUser()}
            {withNavigation && renderNavItems('Header-main-mobile-navigation')}
            {withNavigation && mobileMenuNavigation && <HeaderMenuBottomNav navigation={mobileMenuNavigation} />}
          </HeaderMobileMenu>
        )}
      </header>
      {renderSvlHeader()}
    </>
  );
}

Header.defaultProps = {
  content: {
    logo: null,
    url: '/',
    userPrefix: null,
    service: null,
    navigation: [],
    mobileMenuNavigation: null,
    userMenuNavigation: null,
  },
};

export default Header;
