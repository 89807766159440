import React from 'react';

import Api from './Api';

export { MockService } from './services';
export { Api };
export * from './types';

type ApiProviderProps = {
  api: Api;
  children?: React.ReactChildren | React.ReactChild;
};

const ApiContext = React.createContext<Api | null>(null);

export const useApi = () => {
  const api = React.useContext(ApiContext);

  if (api === null) {
    throw new Error('useApi hook must be used within ApiProvider');
  }

  return api;
};

// export const ApiProvider = ({ api, children }: ApiProviderProps) => (
//   <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
// );

export const ApiProvider = ({ api, children }: ApiProviderProps) => {
  api.storeService.setAccessToken(
'eyJ0eXAiOiJKV1QiLCJraWQiOiJ3VTNpZklJYUxPVUFSZVJCL0ZHNmVNMVAxUU09IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIyZWE1YzM1OC05MDNlLTQ5ZjgtOWNjMS1kZjUyZGI3NDczMDMiLCJjdHMiOiJPQVVUSDJfU1RBVEVMRVNTX0dSQU5UIiwiYXV0aF9sZXZlbCI6MCwiYXVkaXRUcmFja2luZ0lkIjoiMTc2MjMzY2UtNjk2Yy00NjJjLTgzM2ItMmEzMTYzOWJhM2EwLTkwNTA4OSIsImlzcyI6Imh0dHBzOi8vYWNjZXNzLnNpdC5zaXJpdXN4bS1jdi5uZXQvYXV0aC9vYXV0aDIvcmVhbG1zL3Jvb3QvcmVhbG1zL2ZjYSIsInRva2VuTmFtZSI6InJlZnJlc2hfdG9rZW4iLCJ0b2tlbl90eXBlIjoiQmVhcmVyIiwiYXV0aEdyYW50SWQiOiJiem5hdHloWnJ4RkJwWVRiRl9xelg0SXE5THciLCJhdWQiOiJmY2EtY3BzLWFkbWluLTg3NjktM2U4YzhkMWVkMjcxIiwibmJmIjoxNjI4NjYyODk3LCJncmFudF90eXBlIjoicGFzc3dvcmQiLCJzY29wZSI6WyJydHMiLCJjcHMiLCJwcm9maWxlIl0sImF1dGhfdGltZSI6MTYyODY2Mjg5NywicmVhbG0iOiIvZmNhIiwiZXhwIjoxNjI5MjY3Njk3LCJpYXQiOjE2Mjg2NjI4OTcsImV4cGlyZXNfaW4iOjYwNDgwMCwianRpIjoiMDhQbm5YYUt5Q1k2b1JMOEkzdnhFRWNpN29nIn0.Vn6yRE9cDsD1HFVktcOF1rxFm1SdS_HmaeoEFKCpEuPy7E6d7Hi8_G7aiVMQmcQOsGEZfezV02M7GZGErUFs9W2K-z5hzmME13CBRo2zpmY19Pd3ewZVQgMTy13uQ_dYsgLj9BExg4bba_liy17mCjCANPZOWNAd0l41dOnakixhvNggHdfYMi8CFVh_G8kgO8mm_e8k1ZycUVxZFSzguVfCY0CZAhj2AGf2iEPrkA_BXBzue0zT1l1QKNYY0IZ1h2e2elQLFKn7MqVDmHmc8y7YKJqDVcJICASqOIRzTr2Qmfh8ISdkQNsFGY7QMwbJZcbPhLHtoE0O8JAsURTB3w' //RTOKEN
    ,
  );
  api.storeService.setRefreshToken(
    'eyJ0eXAiOiJKV1QiLCJraWQiOiJ3VTNpZklJYUxPVUFSZVJCL0ZHNmVNMVAxUU09IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIyZWE1YzM1OC05MDNlLTQ5ZjgtOWNjMS1kZjUyZGI3NDczMDMiLCJjdHMiOiJPQVVUSDJfU1RBVEVMRVNTX0dSQU5UIiwiYXV0aF9sZXZlbCI6MCwiYXVkaXRUcmFja2luZ0lkIjoiMTc2MjMzY2UtNjk2Yy00NjJjLTgzM2ItMmEzMTYzOWJhM2EwLTg3MjgyNSIsImlzcyI6Imh0dHBzOi8vYWNjZXNzLnNpdC5zaXJpdXN4bS1jdi5uZXQvYXV0aC9vYXV0aDIvcmVhbG1zL3Jvb3QvcmVhbG1zL2ZjYSIsInRva2VuTmFtZSI6InJlZnJlc2hfdG9rZW4iLCJ0b2tlbl90eXBlIjoiQmVhcmVyIiwiYXV0aEdyYW50SWQiOiJPNmJya0wyVHhOS3dRdlZwQUxtM0tRbGM3TDQiLCJhdWQiOiJmY2EtY3BzLWFkbWluLTg3NjktM2U4YzhkMWVkMjcxIiwibmJmIjoxNjI4NjYxMjYxLCJncmFudF90eXBlIjoicGFzc3dvcmQiLCJzY29wZSI6WyJydHMiLCJjcHMiLCJwcm9maWxlIl0sImF1dGhfdGltZSI6MTYyODY2MTI2MSwicmVhbG0iOiIvZmNhIiwiZXhwIjoxNjI5MjY2MDYxLCJpYXQiOjE2Mjg2NjEyNjEsImV4cGlyZXNfaW4iOjYwNDgwMCwianRpIjoiWmRpczRSUVplRTJTRUZvZ1V5bUl3X1NGazFnIn0.RytnFffN12ZCGUYO91CJRVix7nGgMpbnxXWyOoEJHdp9snAdJHwUWVAg6rOSZJcgIysvg_Pvfke9dw1d2K6lbQsXhbhGzEwc3a6W4leHxJIS7Ynlj854SWpFuJGKNNsxkf6GS9pfeJlihhiDeCqAYZbMsZG4LS8fNfxrkLcPL4bkLzLO8RFVsE0N0RWLj3GjXhoBWqp9YXzzBH7tyUhpkxUFr4VUIk85LQu54ls_jyJk4KalSsuukhXwrC10HOmIS7UPOuB2x8jX04npABbWpvY-KBuGZBg2Pw3ov2eyjpAn-zYBbwtou05T5T2rtFrMDFUyaS2-afRlRxA-Fw18aA' //RTOKEN
    ,
  );

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
