import set from 'lodash/set';
import { getVehicleContacts } from "@cv/portal-idm-lib/contact";
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  VehicleContactRequest,
} from "@cv/portal-idm-lib/contact/models";
import { ContactFilter } from '../types/';
import { formatContactArray } from '../formatters/';

export default class VehicleContactsService {
  private payload: VehicleContactRequest;

  constructor(environment = Environment.LOCAL) {
    this.payload = {
      environment: environment,
      headers: {
        Authorization: 'token',
      },
    };
  }

  async getContacts(filter: ContactFilter, vehicleId:string, locale, token) {
    set(this.payload, 'headers.Accept-Language', locale);
    set(this.payload, 'headers.Authorization', token);
    set(this.payload, 'pathParams.vehicleId', vehicleId);
    const response = await getVehicleContacts(this.payload);
    return {data: formatContactArray(response.data, filter)};
  }
}
