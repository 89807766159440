// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eopn6ULUG92hj-lZrtXv {\n  --link-active: var(--theme-color-link-active, inherit);\n  display: inline-block;\n  text-decoration: none;\n  color: inherit;\n}\n\n._3OCJCrfyZL_zUkngAM6s6z {\n  color: var(--link-active);\n  font-weight: bold;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/Link/Link.module.css"],"names":[],"mappings":"AAAA;EACE,sDAAsD;EACtD,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".Link {\n  --link-active: var(--theme-color-link-active, inherit);\n  display: inline-block;\n  text-decoration: none;\n  color: inherit;\n}\n\n.Link--active {\n  color: var(--link-active);\n  font-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Link": "eopn6ULUG92hj-lZrtXv",
	"Link--active": "_3OCJCrfyZL_zUkngAM6s6z"
};
export default ___CSS_LOADER_EXPORT___;
