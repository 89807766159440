// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1OjaBld7Yxc9elY0JR_jx2 {\n  display: block;\n}\n\n._3uCy6_wdjtYJ-wvw-WaBq {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\n._3BOMC6NjSly2EcZUtm57tl {\n  display: inline-block;\n}\n\n._1GEZcVjq60Yuj0nCduXXaX ._3BOMC6NjSly2EcZUtm57tl {\n  display: block;\n}\n\n._3QKL-AMOtsLnpifWrfxpNQ {\n  text-align: left;\n}\n\n._2Ons1rZtH0Y08LbkLESN40 {\n  text-align: center;\n}\n\n._1P5ltuHFIQXdwi6rge3qYG {\n  text-align: right;\n}\n\n.WBLSbCFb0MArfj6x_x0ws {\n  display: flex;\n  justify-content: space-between;\n}\n\n._1GEZcVjq60Yuj0nCduXXaX.WBLSbCFb0MArfj6x_x0ws {\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Navigation/Navigation.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".Navigation {\n  display: block;\n}\n\n.Navigation-list {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\n.Navigation-item {\n  display: inline-block;\n}\n\n.Navigation-list--vertical .Navigation-item {\n  display: block;\n}\n\n.Navigation-list--left {\n  text-align: left;\n}\n\n.Navigation-list--center {\n  text-align: center;\n}\n\n.Navigation-list--right {\n  text-align: right;\n}\n\n.Navigation-list--justify {\n  display: flex;\n  justify-content: space-between;\n}\n\n.Navigation-list--vertical.Navigation-list--justify {\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Navigation": "_1OjaBld7Yxc9elY0JR_jx2",
	"Navigation-list": "_3uCy6_wdjtYJ-wvw-WaBq",
	"Navigation-item": "_3BOMC6NjSly2EcZUtm57tl",
	"Navigation-list--vertical": "_1GEZcVjq60Yuj0nCduXXaX",
	"Navigation-list--left": "_3QKL-AMOtsLnpifWrfxpNQ",
	"Navigation-list--center": "_2Ons1rZtH0Y08LbkLESN40",
	"Navigation-list--right": "_1P5ltuHFIQXdwi6rge3qYG",
	"Navigation-list--justify": "WBLSbCFb0MArfj6x_x0ws"
};
export default ___CSS_LOADER_EXPORT___;
