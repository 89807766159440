import React, { useEffect, useState } from 'react';

import { ModelConnector } from '@components/ApiConnectorHOCs/';
import SubscriptionOverview, { SubscriptionOverviewProps } from './SubscriptionOverviewView';
import SubscriptionModelInfo from './SubscriptionModelInfo';

import { EligiblePackagesResponse, Variant } from '@cv/portal-cps-lib/subscription/subscription-management/models';

import { useApi } from '@api';
import { useDispatch } from 'react-redux';
import { OfferType, PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

import { setSubscribedServices } from '@redux/actions';
import { UpdateOperation } from '@cv/portal-idm-lib/user/user-update-profile/enums';
import { isEmpty } from 'lodash';
import { PORTAL_LABEL_DICTIONARY, PROVISIONING_COMPLETE } from '../VinStatusPage/constants';

type SubscriptionOverviewConnectorProps = SubscriptionOverviewProps;

function SubscriptionOverviewConnector({ data, ...restProps }: SubscriptionOverviewConnectorProps) {
  const api = useApi();
  const dispatch = useDispatch();
  const subs = api.storeService.getSubscribedServices();
  const [subscribedVehicleList, setSubscribedVehicleList] = useState<SubscribedServices[]>([]);
  const getVinSubscribedPackages = (vehicleIds: string[]) => {
    const apiListSubscribedVehicle = vehicleIds?.map((vehicleId) =>
      api.getSubscription({ vehicleId, excludeDefaultPkgs: true }),
    );

    Promise.all(apiListSubscribedVehicle)
      .then((results) => {
        if (results) {
          const _subscribed = results?.map((packages: any) => {
            return {
              vehicleId: packages?.data?.vehicle?.vehicleId,
              activeType: packages?.data?.services?.some(
                (subscribed: EligiblePackagesResponse) =>
                  subscribed.packageType === PackageType.Regular || subscribed.packageType === PackageType.Default,
              ),
              subscribedServiceIds: [].concat(...packages?.data?.services.map((service: any) => service.serviceIds)),
            } as SubscribedServices;
          });
          setSubscribedVehicleList(_subscribed);
          dispatch(setSubscribedServices(_subscribed));
          getVinEligiblePackages(_subscribed);
        } else {
        }
      })
      .catch((error) => {
        console.error('sxm subscribed vehicle status error', error);
      });
  };

  const getVinEligiblePackages = (vehicleList: SubscribedServices[]) => {
    const apiListEligibleVehicle = vehicleList
      ?.filter((vehicle) => !vehicle.activeType)
      ?.map((vehicle) => api.searchEligiblePackages({ vehicleId: vehicle.vehicleId }));
    // setApiStatus('loading');
    Promise.all(apiListEligibleVehicle)
      .then((results) => {
        if (results) {
          const _eligible = results?.map((packages: any) => {
            return {
              vehicleId: packages?.vehicleId,
              activeType: packages?.data?.some(
                (eligible: EligiblePackagesResponse) =>
                  eligible.packageType === PackageType.Regular &&
                  eligible?.variants.some((variant: Variant) =>
                    variant?.discounts?.some((disc) => disc.offerType === OfferType.Trial),
                  ),
              ),
            } as SubscribedServices;
          });
        }
      })
      .catch((error) => {
        console.error('sxm eligible vehicle status error', error);
      });
  };
  useEffect(() => {
    if (data) {
      const userId = api.storeService.getUserId();
      const locale = api.storeService.getLocale();

      api.getAccountDetails(locale, userId).then(({ data }) => {
        api.getVinSubscribedPackages(data?.vehicleIds).then((result) => {
          dispatch(setSubscribedServices(result));
          let vehicle = result?.find((data) => data.vin === vin);
          api.getVehicleDetails(locale, vehicle?.vehicleId).then((result) => {});
        });
      });
    }

    const time = new Date().toISOString();
    api
      .updateUserInfo([
        {
          operation: UpdateOperation.Replace,
          field: 'customField01',
          value: time,
        },
        {
          operation: UpdateOperation.Replace,
          field: 'customFieldLabel01',
          value: 'Last login time',
        },
      ])
      .then(({ data }) => {
        if (data) {
          getVinSubscribedPackages(data?.vehicleIds || []);
        } else {
        }
      })
  }, [data]);

  useEffect(() => {
    if (!subs?.length && api.storeService.getVehicleData()) {
      const goToWithVehicle = async (vehicleData: IVehicle) => {
        vehicleData.activeServices = await getActiveServices(vehicleData?.vehicleId);
        api.storeService.setVehicleData(vehicleData);
      };
    }
  }, [subs, api.storeService.getVehicleData()]);

  const getActiveServices = async (vehicleId: string) => {
    let activeServices: string[] = [];
    try {
      const { data } = await api.getTelematicServices(vehicleId, 'en-US'); // Response is always expected to be in en-US, no Translation is needed to get active services
      if (!isEmpty(data)) {
        const subscribedServices = subscribedVehicleList
          .filter((subscribedVehicle: SubscribedServices) => subscribedVehicle.vehicleId === vehicleId)
          .flatMap((vehicle: SubscribedServices) => vehicle.subscribedServiceIds);
        activeServices = data?.telematicsServiceDetails?.reduce(
          (serviceList: string[], serviceObj: TelematicsServiceDetail) => {
            if (
              serviceObj?.provisioningStatus === PROVISIONING_COMPLETE &&
              subscribedServices.includes(serviceObj.vehicleServiceId)
            ) {
              serviceList.push(serviceObj.vehicleServiceName);
            }
            return serviceList;
          },
          [],
        );
      }
    } catch (err) {
      console.log(err);
    }
    return activeServices;
  };

  //TODO: this needs to be wholly rewritten with pure view layer and Connector components accepting multiple APIs
  return (
    <SubscriptionOverview
      data={data}
      vehicleInfo={<SubscriptionModelInfo apiConnector={{ api: 'GET /vehicle/' }} />}
      {...restProps}
    />
  );
}

SubscriptionOverviewConnector.defaultProps = {
  apiConnector: {},
};

export default ModelConnector(SubscriptionOverviewConnector);
