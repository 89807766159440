import React from 'react';
import * as yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import Button from '@components/Button';
import InputSwitch from '@components/InputSwitch';
import { FormikInputField } from '@components/FormikFields';

import styles from './Forms.module.css';
import stylesInput from '@components/Input/Input.module.css';

type Values = {
  [key: string]: string | boolean;
};

type SpeedAlertFormProps = {
  label?: string;
  unit: string;
  description?: string;
  switchLabel?: string;
  onFormClose?: () => void;
  onFormConfirm?: (values: any) => void;
  initialValues: Values;
  errorMessage: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
};

export default function SpeedAlertForm({
  label = '',
  unit,
  description,
  switchLabel,
  onFormClose,
  onFormConfirm,
  initialValues,
  errorMessage,
  cancelButtonLabel,
  confirmButtonLabel,
}: SpeedAlertFormProps) {
  const validationSchema = yup.object().shape({
    speed: yup.number().min(16, errorMessage).max(160, errorMessage).required(errorMessage),
  });

  const onKeyPress = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Formik
      key={label}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={(values) => {
        onFormConfirm && onFormConfirm(values);
      }}
      validationSchema={validationSchema}
    >
      {(props: FormikProps<Values>) => (
        <Form onSubmit={props.handleSubmit} className={styles['form-speed']}>
          <div className={styles['form-speed-field']}>
            <FormikInputField
              name={'speed'}
              label={label}
              maxLength={3}
              onKeyPress={(event) => onKeyPress(event)}
              showErrorIcon={false}
              className={`${stylesInput['input-dark']} ${styles['form-speed-input']}`}
            />
            <div className={styles['form-speed-unit']}>{unit}</div>
          </div>

          <div className={styles['form-speed-desc']}>{description}</div>
          <InputSwitch
            type="wide"
            label={switchLabel}
            className={styles['input-switch--middle']}
            onChange={() => props.setFieldValue('inVehicleWarning', !initialValues.inVehicleWarning)}
          />
          <div className={styles['button-container']}>
            <Button variant="outlined" onClick={onFormClose}>
              {cancelButtonLabel}
            </Button>
            <Button
              variant="filled"
              className={styles['button-save']}
              disabled={props.isSubmitting}
              onClick={props.submitForm}
            >
              {confirmButtonLabel}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
