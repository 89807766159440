import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { es, enUS } from 'date-fns/locale';
import capitalize from 'lodash/capitalize';

import { formatDate } from '@utils/format-date';
import useFetchVehicleHealth from '@hooks/useFetchVehicleHealth';

import gasIcon from '@assets/gas_icon.svg';
import refreshIcon from '@assets/refresh.svg';

import styles from './Fuel.module.css';
import { RootState } from '../../reducers';

import { VehicleHealthResponse } from '@cv/portal-rts-lib/health/models';

export type FuelProps = {
  data: {
    fuelLeft: number;
    lastUpdated: number;
  };
  FuelStatusPrefixLabel: string;
  FuelStatusPostfixLabel: string;
  FuelUpdatePrefixLabel: string;
  FuelUpdatePostfixRefresh: string;
  FuelStatusNotAvailableLabel: string;
};

function Fuel(props: FuelProps) {
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer.locale);
  const { data: vehicleHealth } = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicleHealth);
  const { fetchVehicleHealth } = useFetchVehicleHealth();
  const convertToSpanish = locale === 'es-MX' ? { locale: es } : { locale: enUS };

  useEffect(() => {
    fetchVehicleHealth();
  }, []);

  const renderFuelStatus = (vehicleHealth: VehicleHealthResponse) => {
    const { fuelAutonomy } = vehicleHealth
    if (fuelAutonomy) {
      return (
        <div className={styles['FuelSection-wrapper']}>
          <span className={styles['Fuel-status-text-regular']}>{props.FuelStatusPrefixLabel}</span>
          <div className={styles['Fuel-label']}>
            {fuelAutonomy?.value} {fuelAutonomy?.UoM}
            <div className={styles['Fuel-postfix-label']}>{props.FuelStatusPostfixLabel}</div>
          </div>
        </div>
      );
    } else {
      return <div className={styles['FuelSection-wrapper']}>{props.FuelStatusNotAvailableLabel}</div>;
    }
  };

  const renderFuel = () => {
    if (vehicleHealth) {
      return (
        <div className={styles.FuelContainer}>
          <div className={styles['FuelSection']}>
            <img src={gasIcon} className={styles['GasIcon']} />
            { renderFuelStatus(vehicleHealth) }
          </div>
    
          <div className={styles['Separator']}></div>
    
          <div className={styles['FuelSection']}>
            <span className={styles['Fuel-status-text-bold']}>
              <span>{props.FuelUpdatePrefixLabel}</span>{' '}
              {capitalize(formatDate(vehicleHealth?.lastUpdatedTime, convertToSpanish))}
              {' | '}
              <span>{props.FuelUpdatePostfixRefresh}</span>
            </span>
            <img src={refreshIcon} className={styles['RefreshIcon']} onClick={fetchVehicleHealth} />
          </div>
        </div>
      );
    }

    return null
  }

  return renderFuel();
}

export default Fuel;
