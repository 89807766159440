import React from 'react';
import { Formik, Form, FormikProps, FormikValues } from 'formik';
import { FormikInputField, FormikPhoneField } from '@components/FormikFields';
import * as yup from 'yup';
import { BaseSchema } from 'yup';
import clsx from 'clsx';

import Button from '@components/Button';
import Grid from '@components/Grid';
import InputSelect from '@components/InputSelect';
import InputMasked from '@components/InputMasked';
import InputCheckbox from '@components/InputCheckbox';

import createYupSchema from '@utils/createYupSchema';
import createMask from '@utils/createMask';
import { markRemoved, isMarkedRemoved } from '@utils/form-removal-utils';

import ApiRequestPayload from '@types/ApiRequestPayload';

import styles from './EntryCreator.module.css';

type FormField = {
  fieldName: string;
  fieldType: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  fieldCheckboxLabel: string;
  fieldValues: Array<string>;
  fieldMask?: string;
  fieldValidation?: FormFieldValidation;
  hideWithField?: FormField;
  fieldPhoneCountry?: string;
};
type FormFieldValidation = {
  fieldRequired: boolean;
  fieldRequiredError?: string;
  fieldMin?: number;
  fieldMinError?: string;
  fieldMax?: number;
  fieldMaxError?: string;
  fieldMatches?: string;
  fieldMatchesError?: string;
  fieldValidateWithField: FormField;
  fieldValidateWithFieldMatch: boolean;
  fieldValidateWithFieldError?: string;
  fieldValidateIsSelectedCheckbox?: boolean;
};

interface CreatorFormProps {
  name?: string;
  title?: string;
  formFields?: Array<FormField>;
  initialValues?: ApiRequestPayload | FormikValues | null;
  formColumns?: '1' | '2' | '3';
  formLabelsDisplay?: boolean;
  validateOnBlur?: boolean;
  disableSubmitOnInvalid?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  onFormConfirm: (
    data: ApiRequestPayload | FormikValues,
    setFieldError?: (fieldName: string, message: string) => void,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => void;
  onFormClose: () => void;
}

type Values = {
  [index: string]: string;
};

const createYupFields = (formFields: Array<FormField>) => {
  let fieldValidationOptions: Array<object> = [];

  return formFields.map((field: FormikValues) => {
    if (field.fieldValidation) {
      const {
        fieldRequired,
        fieldRequiredError,
        fieldMin,
        fieldMinError,
        fieldMax,
        fieldMaxError,
        fieldMatches,
        fieldMatchesError,
        fieldValidateWithField,
        fieldValidateWithFieldMatch,
        fieldValidateWithFieldError,
        fieldValidateIsSelectedCheckbox,
      } = field.fieldValidation;

      const fieldValuesMatches = fieldValidateWithFieldMatch ? 'oneOf' : 'notOneOf';
      const isCheckbox = fieldValidateWithField?.fieldType === 'checkbox';

      const isFieldRequiredBasedOnCheckbox = isCheckbox && {
        type: 'when',
        params: [
          fieldValidateWithField.fieldName,
          (field: string, schema: BaseSchema) => {
            // convert boolean to string because checkbox value is 'true' or 'false
            return field === fieldValidateIsSelectedCheckbox.toString()
              ? schema.required(fieldRequiredError || '')
              : schema;
          },
        ],
      };
      const isFieldRequired = fieldRequired && { type: 'required', params: [fieldRequiredError || ''] };

      fieldValidationOptions = [
        isFieldRequiredBasedOnCheckbox || isFieldRequired || {},
        fieldMin ? { type: 'min', params: [Number(fieldMin), fieldMinError || ''] } : {},
        fieldMax ? { type: 'max', params: [Number(fieldMax), fieldMaxError || ''] } : {},
        fieldMatches ? { type: 'matches', params: [fieldMatches, fieldMatchesError || ''] } : {},
        fieldValidateWithField
          ? {
              type: fieldValuesMatches,
              params: [[yup.ref(fieldValidateWithField.fieldName)], [fieldValidateWithFieldError || '']],
            }
          : {},
      ];
    } else {
      fieldValidationOptions = [{ type: 'notRequired', params: [null] }];
    }

    return {
      id: field.fieldName,
      label: field.fieldLabel,
      placeholder: field.fieldLabel,
      type: field.fieldType,
      validationType: 'string',
      validations: fieldValidationOptions,
    };
  });
};

const CreatorForm = ({
  name,
  title,
  formFields = [],
  initialValues = null,
  formColumns = '1',
  formLabelsDisplay = true,
  validateOnBlur = true,
  disableSubmitOnInvalid = true,
  cancelLabel,
  submitLabel,
  onFormConfirm,
  onFormClose,
}: CreatorFormProps) => {
  let initialValuesData = {};
  if (!initialValues) {
    formFields.forEach((field) => (initialValuesData[field.fieldName] = ''));
  }

  const yupFields = createYupFields(formFields);
  const yupSchema = yupFields.reduce(createYupSchema, {});
  const validationSchema = yup.object().shape(yupSchema);

  const appendId = (values: FormikValues) =>
    Object.assign({}, values, initialValues?._id && { _id: initialValues._id });

  const parseEmptyValues = (values: FormikValues) => {
    // undefined values are unchanged since the form was opened
    // "" values were actually removed by the user
    const emptyFields = Object.keys(values).filter((key) => values[key] === '');
    if (emptyFields.length) {
      const removedValues = {};
      emptyFields.forEach((field) => {
        removedValues[field] = markRemoved(initialValues?.[field] || '');
      });
      return Object.assign({}, values, removedValues);
    }
    return values;
  };

  return (
    <div className={styles.CreatorForm}>
      {title && <h3>{title}</h3>}

      <Formik
        key={name}
        initialValues={initialValues || initialValuesData}
        validateOnBlur={validateOnBlur}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          onFormConfirm(appendId(parseEmptyValues(values)), setFieldError, setSubmitting);
        }}
        validationSchema={validationSchema}
      >
        {(props: FormikProps<Values>) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={styles['grid']} columns={formColumns}>
                {formFields.map(
                  (
                    {
                      fieldName,
                      fieldType,
                      phoneFormat,
                      fieldLabel,
                      fieldPlaceholder,
                      fieldCheckboxLabel,
                      fieldValues,
                      fieldMask,
                      fieldValidation,
                      hideWithField,
                      fieldPhoneCountry,
                    }: FormikValues,
                    i,
                  ) => {
                    const key = `${i}-${fieldName}`;

                    const hidingFieldName = hideWithField?.fieldName;
                    if (hidingFieldName && props.values[hidingFieldName]) {
                      return null;
                    }

                    // Static text
                    if (fieldType === 'static') {
                      const isLabelEmpty = fieldLabel.trim().length === 0;
                      const initialValue = initialValues?.[fieldName] || '';
                      const fieldValue = isMarkedRemoved(initialValue) ? '' : initialValue;
                      {
                        /* Trim needed for Empty Static Field, see: PORTAL Form Field  */
                      }
                      return (
                        <div
                          className={clsx(styles['field-static'], { [styles['field-static-empty']]: isLabelEmpty })}
                          key={key}
                        >
                          <div className={styles['field-static--label']}>{fieldLabel}</div>
                          <div className={styles['field-static--value']}>{fieldValue || fieldPlaceholder}</div>
                        </div>
                      );
                    }

                    const isDropdown = fieldType === 'dropdown';
                    const isPhone = fieldType === 'tel';
                    const fieldLabelValue = formLabelsDisplay ? fieldLabel : '';

                    if (isDropdown) {
                      const options = fieldValues.map((option: string) => {
                        const [value, label] = option.split('::');
                        if (label) {
                          return { value, label };
                        }
                        return { value: option, label: option };
                      });

                      return (
                        <FormikInputField
                          name={fieldName}
                          label={fieldLabelValue}
                          type={fieldType}
                          placeholder={fieldPlaceholder}
                          key={key}
                          InputComponent={InputSelect}
                          onChange={(e) => console.log(e)}
                          options={options}
                          styleType="primary"
                        />
                      );
                    }

                    if (!isDropdown) {
                      if (isPhone) {
                        return (
                          <FormikPhoneField
                            name={fieldName}
                            label={fieldLabelValue}
                            placeholder={fieldPlaceholder}
                            key={key}
                            fieldPhoneCountry={fieldPhoneCountry}
                            phoneFormat={phoneFormat}
                            form={{
                              errors: props.errors,
                              handleBlur: props.handleBlur,
                              setFieldValue: props.setFieldValue,
                              touched: props.touched,
                            }}
                          />
                        );
                      }

                      if (fieldMask) {
                        const arrayRegex = createMask(fieldMask);

                        return (
                          <FormikInputField
                            name={fieldName}
                            type={fieldType}
                            label={fieldLabelValue}
                            placeholder={fieldPlaceholder}
                            key={key}
                            mask={arrayRegex}
                            InputComponent={InputMasked}
                            showMaskOnHover
                          />
                        );
                      }

                      if (fieldType === 'checkbox') {
                        return (
                          <FormikInputField
                            name={fieldName}
                            type={fieldType}
                            label={fieldLabelValue}
                            placeholder={fieldCheckboxLabel || fieldPlaceholder}
                            key={key}
                            InputComponent={InputCheckbox}
                          />
                        );
                      }

                      return (
                        <FormikInputField
                          name={fieldName}
                          type={fieldType}
                          label={fieldLabelValue}
                          placeholder={fieldPlaceholder}
                          key={key}
                          minLength={fieldValidation?.fieldMin}
                          maxLength={fieldValidation?.fieldMax}
                        />
                      );
                    }
                  },
                )}
              </Grid>
            </Form>

            <div className={styles.ButtonContainer}>
              <Button variant="outlined" onClick={onFormClose}>
                {cancelLabel}
              </Button>
              <Button
                variant="filled"
                className={styles['Button-save']}
                disabled={(disableSubmitOnInvalid && (!props.dirty || !props.isValid)) || props.isSubmitting}
                onClick={props.submitForm}
              >
                {submitLabel}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreatorForm;
