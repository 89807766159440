import React from "react";
import clsx from "clsx";
import styles from "./Button.module.css";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?: "filled" | "outlined";
};

const Button = ({
  children,
  variant = "filled",
  className,
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={clsx(styles.button, styles[variant], className)}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
