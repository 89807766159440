import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from '@api';
import Loader from '@components/Loader/';
import ApiRequestPayload from '@types/ApiRequestPayload';
import { REDUCER_MAP, ACTION_MAP } from '@redux/dataMap';
import { StateItem } from '@redux/types';

type FormConnectorProps = {
  formConnector: {
    api: string,
    errorLabel?: string,
  },
}

const LOADING_STATUS = {
  INACTIVE: 'inactive',
  READY: 'ready',
  LOADING: 'loading',
  ERROR: 'error',
};

function FormConnector(WrappedComponent, props: FormConnectorProps) {
  return function(props) {
    const { formConnector } = props;
    if (formConnector) {
      const api = useApi();
      const operationType = formConnector.api.split(' ')[0];
      const modelKey = formConnector.api.split('/')[1];
      const foundAction: string | undefined = ACTION_MAP[modelKey];
      const [status, setStatus] = useState(LOADING_STATUS.INACTIVE);
      const [error, setError] = useState('');
      const locale: string | undefined = useSelector((reducers) => reducers[REDUCER_MAP.locale].locale);
      const dispatch = useDispatch();

      const onSubmit = async (submittedPayload: ApiRequestPayload) => {
        try {
          setStatus(LOADING_STATUS.LOADING);
          const response = await api.updateService(modelKey, operationType, submittedPayload);
          if (response) {
            const { data }: { data: ApiRequestPayload } = await api.fetchService(modelKey, locale);
            if (foundAction) {
              dispatch({type: foundAction, data});
            } else {
              console.warn('FormConnector could not find action at key', modelKey, ' - dispatch skipped');
            }
          }
          setStatus(LOADING_STATUS.READY);
        } catch (e) {
          setStatus(LOADING_STATUS.ERROR);
          setError(e.message);
        }
      };

      if (status === LOADING_STATUS.LOADING) {
        return <Loader />;
      } else if (status === LOADING_STATUS.ERROR) {
        console.error('Form Connector error', formConnector, error);
        return (
          <div className="error">
            { formConnector.errorLabel }
          </div>
        );
      } else {
        return (<WrappedComponent onSubmit={onSubmit} {...props} />);
      }
    }
    return (<WrappedComponent {...props} />);
  }
}

export default FormConnector;
