import React from 'react';

import Loader from './Loader';
import './LoaderBackdrop.css';

export function LoaderBackdrop() {
  return (
    <div className="Loader-backdrop">
      <Loader />
    </div>
  );
}
