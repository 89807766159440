import React from 'react';
import styles from './ScheduleServiceButton.module.css';
import clsx from 'clsx';

type ScheduleServiceButtonProps = {
  url: string;
  label: string;
  displayViewMode: string;
};

const ScheduleServiceButton = ({ url, label, displayViewMode }: ScheduleServiceButtonProps) => {
  const isWarningViewMode = displayViewMode === 'warning';

  return (
    <div
      className={clsx(styles['ScheduleServiceButtonContainer'], {
        [styles['ScheduleServiceButtonContainer--warning']]: isWarningViewMode,
      })}
    >
      <div
        onClick={() => window.open(url, '_blank')}
        className={clsx(styles['ScheduleServiceButton'], {
          [styles['ScheduleServiceButton--warning']]: isWarningViewMode,
        })}
      >
        {label}
      </div>
    </div>
  );
};

export default ScheduleServiceButton;
