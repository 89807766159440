import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { AgreementType, Country } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { DevicePrefLanguage } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';

import { useApi } from '@api';
import { PaymentMethodData } from '@api/types/index';
import { ModelConnector, FormConnector } from '@components/ApiConnectorHOCs/';
import { CreatorForm } from '@components/EntryCreator/';
import DynamicLink from '@components/DynamicLink';
import useToggle from '@hooks/useToggle';
import useDialogActions from '@redux/hooks/dialog';
import usePreferencesSelector from '@redux/selectors/preferences';
import ContentfulComponent from '@types/ContentfulComponent';

import CreditCardInfoView from './CreditCardInfoView';

type CreditCardInfoConnectorProps = {
  data: PaymentMethodData | null;
  editItemForm: ContentfulComponent;
  onSubmit: (data: PaymentMethodData) => Promise<void>;
  headerLabel: string;
  endingInLabel: string;
  expiresLabel: string;
};

function CreditCardInfoConnector({
  data,
  editItemForm,
  onSubmit,
  headerLabel,
  endingInLabel,
  expiresLabel,
}: CreditCardInfoConnectorProps) {
  const [isFormOpen, toggleForm] = useToggle(false);

  const api = useApi();
  const { mailingCountry } = useSelector(({ accountReducer }) => accountReducer?.account?.data || {});

  const openForm = () => {
    toggleForm(true);
  };

  const { showDialog } = useDialogActions();
  const globalPreferences = usePreferencesSelector();

  const validateExpirationDate = ({ expirationMonth, expirationYear }) => {
    const currentDate = new Date();
    const inputExpirationMonthYear = new Date(expirationYear, expirationMonth - 1);
    const todaysMonthYear = new Date(currentDate.getFullYear(), currentDate.getMonth());
    return inputExpirationMonthYear > todaysMonthYear;
  };

  const submitForm = async (data: PaymentMethodData, setFieldError: Function, setSubmitting: Function) => {
    if (validateExpirationDate(data)) {
      await onSubmit(data);
      toggleForm(false);
    } else {
      setSubmitting(false);
      let expirationMonth = editItemForm.formFields.find((formField) => formField.fieldName === 'expirationMonth');
      showDialog({
        message: expirationMonth.fieldValidation.fieldMatchesError,
        title: globalPreferences['errorHeaderLebel'],
      });
    }
  };

  useEffect(() => {
    let checkBoxElement = editItemForm.formFields.find((formField) => formField.fieldType === 'checkbox');
    //update only if checkBoxElement.fieldCheckboxLabel is not a react element. Initially it will be string, after updation it will be element
    if (!React.isValidElement(checkBoxElement?.fieldCheckboxLabel) && checkBoxElement?.fieldCheckboxLabel) {
      checkBoxElement.fieldCheckboxLabel = (
        <DynamicLink text={checkBoxElement?.fieldCheckboxLabel} handleLinkCallback={showAgreement} />
      );
    }
  }, []);

  const showAgreement = async (event) => {
    const className = event.target.className;
    if (className === 'agreement') {
      event.preventDefault();
      const {
        data: {
          payload: { agreementTemplateUrl },
        },
      } = await api.getEffectiveAgreement({
        telematicsProgramId: api.storeService.getVehicleData().telematicsProgramId,
        agreementType: AgreementType.Subscriber_info,
        make: api.storeService.getVehicleData().make,
        country: Country[capitalize(mailingCountry)],
        language: DevicePrefLanguage[api.storeService.getLocale().replace('en-', 'en').replace(/-.+$/, '')],
      });
      // @ts-ignore
      const win = window.open(agreementTemplateUrl, '_blank');
      win?.focus();
    }
  };

  if (!data) {
    return null;
  }

  if (isFormOpen) {
    return <CreatorForm onFormClose={toggleForm} onFormConfirm={submitForm} initialValues={data} {...editItemForm} />;
  }

  return (
    <CreditCardInfoView
      onEdit={openForm}
      headerLabel={headerLabel}
      endingInLabel={endingInLabel}
      expiresLabel={expiresLabel}
      {...data}
    />
  );
}

export default ModelConnector(FormConnector(CreditCardInfoConnector));
