import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sub } from 'date-fns';

import { useApi } from '@api';
import ContentRenderer from '@components/ContentRenderer';
import Select from '@components/InputSelect';
import AlertHistoryTable from './AlertHistoryTable';
import TabHeader from '../TabHeader';

import { ALERTS_ACTIONS } from '@redux/actions';

import styles from './AlertHistoryContent.module.css';
import { formatDateToISO } from '../Forms/utils';
import { cloneDeep } from 'lodash';
import { GetBreachResponse } from '@cv/portal-rts-lib/history';
import { clearMapBoundaries, clearMapPins } from '@utils/clearMapEntities';

type ContentRendererProps = {
  componentType: string;
};

type HistoryType = 'all' | 'speed' | 'boundary' | 'curfew' | 'valet';

type AlertHistoryContentProps = {
  title: string;
  emptyDescription: string;
  dropdownHoursLabel: string;
  dropdownDaysLabel: string;
  historyType: HistoryType;
  alertHistoryItemLabel: string;
  tableFirstColLabel: string;
  tableSecondColLabel: string;
  tableThirdColLabel: string;
  pagination: ContentRendererProps;
};

type MapApiCall = {
  [index: string]: {
    breachType: string;
    geofenceAlertType?: AlertType;
    label: string;
  };
};

type OnChangeProps = {
  type: string;
  value: string;
};

const alertHistoryOptions = (dropdownHoursLabel: string, dropdownDaysLabel: string) => [
  { label: `24 ${dropdownHoursLabel}`, value: 24, type: 'hours' },
  { label: `7 ${dropdownDaysLabel}`, value: 7, type: 'days' },
  { label: `14 ${dropdownDaysLabel}`, value: 14, type: 'days' },
  { label: `30 ${dropdownDaysLabel}`, value: 30, type: 'days' },
  { label: `90 ${dropdownDaysLabel}`, value: 90, type: 'days' },
];

export default function AlertHistoryContent({
  title,
  emptyDescription,
  dropdownHoursLabel,
  dropdownDaysLabel,
  historyType,
  alertHistoryItemLabel,
  tableFirstColLabel,
  tableSecondColLabel,
  tableThirdColLabel,
  pagination,
}: AlertHistoryContentProps) {
  const api = useApi();
  const breaches = useSelector(({ alertsReducer }) => alertsReducer.breaches);
  const map = useSelector(({ mapReducer }) => mapReducer.map);
  const dispatch = useDispatch();
  const initialPaginationPage = 1;
  const [currentPage, setCurrentPage] = useState(initialPaginationPage);

  const selectOptions = alertHistoryOptions(dropdownHoursLabel, dropdownDaysLabel);
  const weekHistoryOption = selectOptions[1];

  useEffect(() => {
    clearMapBoundaries(map);
    clearMapPins(map);
    dispatch({ type: 'SET_BOUNDARY_COORDINATES', boundaryCoordinates: null });
  }, [map]);

  const mapApiCall: MapApiCall = {
    all: {
      breachType: 'SPEED_ALERT,GEOFENCE,CURFEW_ALERT',
      label: alertHistoryItemLabel,
    },
    speed: {
      breachType: 'SPEED_ALERT',
      label: alertHistoryItemLabel,
    },
    boundary: {
      breachType: 'GEOFENCE',
      label: alertHistoryItemLabel,
    },
    curfew: {
      breachType: 'CURFEW_ALERT',
      label: alertHistoryItemLabel,
    },
    valet: {
      breachType: 'GEOFENCE',
      geofenceAlertType: 'VALET',
      label: alertHistoryItemLabel,
    },
  };

  const getAlertHistory = async (historyType: HistoryType, currentDate: Date, substractedDate: Date) => {
    try {
      const formattedCurrentDate = formatDateToISO(currentDate);
      const formattedSubstractedDate = formatDateToISO(substractedDate);
      const { breachType, geofenceAlertType } = mapApiCall[historyType];
      const { data } = await api.getBreaches(
        breachType,
        geofenceAlertType,
        formattedSubstractedDate,
        formattedCurrentDate,
      );
      // Uncomment once the postal address API is available
      // const breachesWithAddress = getAddresses(data);
      dispatch({ type: ALERTS_ACTIONS.SET_BREACH, data });
    } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      console.log('error api', err);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const { type, value } = weekHistoryOption;
    const substractedDate = sub(currentDate, { [type]: value });
    getAlertHistory(historyType, currentDate, substractedDate);
  }, []);

  const onPageChange = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const onDateChange = ({ type, value }: OnChangeProps) => {
    const currentDate = new Date();
    const substractedDate = sub(currentDate, { [type]: value });
    getAlertHistory(historyType, currentDate, substractedDate);
  };

  const renderSelectAlert = (
    <Select
      name="alert-history"
      isSearchable={false}
      styleType="primary"
      onChange={onDateChange}
      options={selectOptions}
      defaultValue={weekHistoryOption}
    />
  );

  const vehiclesPerPage = 5;
  const totalPages = breaches?.items.length > 0 ? Math.ceil(breaches.items.length / 5) : 1;
  const offset = (currentPage - 1) * vehiclesPerPage;
  const offsetData = breaches?.items.length > 0 ? breaches.items.slice(offset, offset + vehiclesPerPage) : [];

  return (
    <div className={styles['alert-history']}>
      <header className={styles['alert-history-header']}>
        <TabHeader label={title} Component={renderSelectAlert} />
      </header>
      <div className={styles['alert-history-content']}>
        {!breaches?.items.length && <div className={styles['alert-history-empty']}>{emptyDescription}</div>}

        {!!breaches?.items.length && (
          <AlertHistoryTable
            tableFirstColLabel={tableFirstColLabel}
            tableSecondColLabel={tableSecondColLabel}
            tableThirdColLabel={tableThirdColLabel}
            data={offsetData}
          />
        )}

        {totalPages > 1 && (
          <ContentRenderer
            name="pagination"
            content={[pagination]}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
}
