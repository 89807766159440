import { AccountData, ContactData, SubscriptionData, VehicleDetails, PaymentMethodData } from '@api/types/';
import { ACCOUNT_ACTIONS, VEHICLE_ACTIONS } from '../actions/';
import { ROOT_EVENTS } from '../../reducers';
import { SETTINGS_ACTIONS } from '../actions/';
import { StateItem } from '../types';

type AccountDataState = {
  account?: StateItem<AccountData>;
  contacts?: StateItem<ContactData[]>;
  drivers?: StateItem<ContactData[]>;
  subscription?: StateItem<SubscriptionData>;
  vehicle?: StateItem<VehicleDetails>;
  paymentMethod: StateItem<PaymentMethodData>;
  tenantId?: string;
};

type AccountDataAction = {
  type: ACCOUNT_ACTIONS;
  data: AccountData | ContactData[] | SubscriptionData;
};

export default (state: AccountDataState = {}, action: AccountDataAction) => {
  switch (action.type) {
    case ACCOUNT_ACTIONS.SET_ACCOUNT_DATA:
      return {
        ...state,
        account: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
        tenantId: action.data?.tenantId,
      };
    case ACCOUNT_ACTIONS.SET_CONTACTS:
      return {
        ...state,
        contacts: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_DRIVERS:
      return {
        ...state,
        drivers: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscription: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_VEHICLE_DETAILS:
    case VEHICLE_ACTIONS.SET_VEHICLE_DATA:
      return {
        ...state,
        vehicle: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_TENANT_ID:
      return {
        ...state,
        tenantId: action.data,
      };
    // force re-fetch of data after a change of locale
    case SETTINGS_ACTIONS.SET_LOCALE:
    case ROOT_EVENTS.CLEAR_CACHE:
    case ROOT_EVENTS.DESTROY_SESSION:
      return {
        ...state,
        account: null,
        drivers: null,
        contacts: null,
        subscription: null,
        vehicle: null,
        paymentMethod: null,
      };
    default:
      return state;
  }
};
