export const GET_POINTS_OF_INTEREST: string = 'GET_POINTS_OF_INTEREST';
export const SET_POINTS_OF_INTEREST: string = 'SET_POINTS_OF_INTEREST';
export const SET_VEHICLE_LOCATION: string = 'SET_VEHICLE_LOCATION';
export const SET_SELECTED_POINT_OF_INTEREST: string = 'SET_SELECTED_POINT_OF_INTEREST';
export const GET_FAVORITES: string = 'GET_FAVORITES';
export const SET_FAVORITES: string = 'SET_FAVORITES';
export const ADD_FAVORITE: string = 'ADD_FAVORITE';
export const REMOVE_FROM_FAVORITES: string = 'REMOVE_FROM_FAVORITES';
export const GET_SENT_TO_CAR: string = 'GET_SENT_TO_CAR';
export const SET_SENT_TO_CAR: string = 'SET_SENT_TO_CAR';
export const REMOVE_FROM_SENT_TO_CAR: string = 'REMOVE_FROM_SENT_TO_CAR';
export const SET_FOLDERS: string = 'SET_FOLDERS';
export const DELETE_FOLDERS: string = 'DELETE_FOLDERS';
export const SET_ROUTE_SUMMARY: string = 'SET_ROUTE_SUMMARY';
export const SET_MAP_CENTER: string = 'SET_MAP_CENTER';
export const SET_MAP: string = 'SET_MAP';

export const DOOR_ID: string = '1';
export const DOOR_ID_ALL: string = 'ALL';
export const FAIL: string = 'FAIL';
export const SET_JOURNEYS: string = 'SET_JOURNEYS';
export const REMOVE_JOURNEY: string = 'REMOVE_JOURNEY';
export const SET_USER_LOCATION: string = 'SET_USER_LOCATION';
export const RESET_USER_LOCATION: string = 'RESET_USER_LOCATION';

// Geofence radius limit in km
export const GEOFENCE_RADIUS_MIN = 2;
export const GEOFENCE_RADIUS_MAX = 150;

export const myCarFinderLabels: string[] = ['MY Car Finder', 'Localizar mi vehículo'];

export const Label_Message = {
  ContactingVehicle: 'contactingVehicle',
  InitiatingService: 'initiatingService',
  ServiceCompleted: 'serviceCompleted',
  UnabletoContactVehicle: 'unabletoContactVehicle',
  Unabletoconnecttonetwork: 'unabletoconnecttonetwork',
};

export enum RemoteCommand {
  DOOR_LOCK = 'doorLock',
  DOOR_UNLOCK = 'doorUnlock',
  HORN_LIGHT = 'hornLight',
  LIGHT = 'light',
  START_STOP_ENGINE = 'startStopEngine',
  STOP_ENGINE = 'stopEngine',
}

export const ServiceLabels = {
  ALL_REMOTE_LABELS: ['Remote Door Lock/Unlock', 'Remote Door Unlock', 'Remote Horn & Lights', 'Remote Engine Start / Stop'],
  REMOTE_LOCK_UNLOCK_LABELS: ['Remote Door Lock/Unlock', 'Remote Door Unlock'],
  REMOTE_VEHICLE_STATUS: ['Remote Vehicle Status'],
  STOLEN_VEHICLE_LOCATOR: ['Stolen Vehicle Locator']
}

export const lockStatusValues = ['unlocked', 'Desbloqueado', 'locked', 'Bloqueado'];
