import React from "react";
import { ContentfulImage } from "@cv/webframework-react-components";

import styles from "./Header.module.css";

type HeaderLogoProps = {
  logo: object;
  url?: string;
};

export default function HeaderLogo({ logo, url }: HeaderLogoProps) {
  return (
    <a href={url} className={styles["Header-brand"]} rel="noopener noreferrer">
      <ContentfulImage imageData={logo} />
    </a>
  );
}

HeaderLogo.defaultProps = {
  url: '/'
};
