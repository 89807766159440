import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CURFEW_ACTIONS, DIALOG_ACTIONS } from '@redux/actions/';

import { useApi } from '@api';
import { formatHours, mapDaysFromLang } from '../Forms/utils';
import ActiveView from '../ActiveView';
import AlertsCount from '../AlertsCount';
import Button from '@components/Button';
import CurfewForm from '../Forms/CurfewForm';
import ManageCardItem from '../ManageCardItem';
import TabHeader from '../TabHeader'
import TabInfo from '../TabInfo'

import { saveCurfewAlert } from '../Forms/utils';

import stylesMonitoring from '../../MonitoringAlerts/MonitoringAlerts.module.css';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { CurfewResponse } from '@cv/portal-rts-lib/curfews/models';
import { Status } from '@cv/portal-rts-lib/doors/enums';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { setLoadingStatus } from '@redux/actions/loading';
import { pollingTime } from '../constants';

const CurfewItemState = {
  ACTIVE: true,
  INACTIVE: false,
}

type TabCurfewProps = {
  alertSetLabel: string;
  alertSetPreposition: string;
  cancelButton: string;
  cancelConfirm: string;
  dayLabel: string;
  daysSelectDayEndLabel: string;
  daysSelectDayStartLabel: string;
  daysValidationErrorMessage: string;
  defaultViewButtonLabel: string;
  editViewTitle: string;
  editFormViewTitle: string;
  fieldErrorMessage: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  infoBoxContent: string;
  switchDescription: string;
  tabDescription: string;
  title: string;
  weekDays: string;
  weekLabel: string;
  createSuccessMsg: string;
  createErrorMsg: string;
  updateSuccessMsg: string;
  updateErrorMsg: string;
  deleteSuccessMsg: string;
  deleteErrorMsg: string;
  successDialogHeader: string;
  errorDialogHeader: string;
  nameErrorMsg: string;
  formNameError: string;
  defaultViewContent: string;
}

function TabCurfew({
  alertSetLabel,
  alertSetPreposition,
  cancelButton,
  cancelConfirm,
  dayLabel,
  daysSelectDayEndLabel,
  daysSelectDayStartLabel,
  daysValidationErrorMessage,
  defaultViewButtonLabel,
  editViewTitle,
  editFormViewTitle,
  fieldErrorMessage,
  fieldLabel,
  fieldPlaceholder,
  infoBoxContent,
  switchDescription,
  tabDescription,
  title,
  weekDays,
  weekLabel,
  createSuccessMsg,
  createErrorMsg,
  updateSuccessMsg,
  updateErrorMsg,
  deleteSuccessMsg,
  deleteErrorMsg,
  successDialogHeader,
  errorDialogHeader,
  nameErrorMsg,
  formNameError,
  defaultViewContent,
}: TabCurfewProps) {
  const api = useApi();
  const [view, setView] = useState('default');
  const curfew = useSelector(({ curfewReducer }) => curfewReducer.curfew);
  const dispatch = useDispatch();
  const [selectedCurfew, setSelectedCurfew] = useState(null);
  const curfewsLimit = 2;
  const locale = useSelector(({ settingsReducer }) => settingsReducer.locale);
  let curfewId = '';

  //TODO: verify that 'status' and 'inVehicleWarning' values should be updated and send in request.

  const defaultFormValues = {
    name: '',
    startTime: '10:00PM',
    endTime: '6:00AM',
    dayOfTheWeek: weekDays.split(' ').splice(0, 5),
    status: CurfewItemState.INACTIVE,
    inVehicleWarning: false,
    allDay: false
  };

  // TEMP THIS NEEDS TO BE HANDLED EITHER IN THE LIBRARY OR RTS
  // WE CURRENTLY GET DAYS BACK FROM RTS IN ENGLISH NO MATTER THE LOCALE
  const convertDays = (days: Array<string>) => {
    if (locale === 'es-MX') {
      const daysDict = {
        'SU': 'DO',
        'MO': 'LU',
        'TU': 'MA',
        'WE': 'MI',
        'TH': 'JU',
        'FR': 'VI',
        'SA': 'SA',
      }

      return days.map((day: string) => {
        return daysDict[day];
      })
    }
    return days;
  }

  const mapFormInitialValues = ({ id, name, schedules: [schedule] }) => ({
    name: name,
    startTime: `${formatHours(`${schedule.startDate} ${schedule.startTime}`, 'h:mma')}`,
    endTime: `${formatHours(`${schedule.endDate} ${schedule.endTime}`, 'h:mma')}`,
    dayOfTheWeek: convertDays(schedule.recurrenceRules[0].dayOfTheWeek),
    inVehicleWarning: CurfewItemState[curfew?.svcRequests.find((request) => request.id === id)?.requestedState],
    allDay: false
  });

  const getCurfews = async () => {
    try {
      const { data } = await api.getCurfewsService();
      dispatch({ type: CURFEW_ACTIONS.GET_CURFEWS, data })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCurfews();
  }, []);

  const cardLabel = (curfew) => {
    const { name, schedules } = curfew;
    const [schedule] = schedules;
    let { startDate, startTime, endDate, endTime } = schedule;
    const daysOfWeek = mapDaysFromLang(schedules[0].recurrenceRules[0].dayOfTheWeek, locale, true).join(' ');

    return (
      <>
        <div className={stylesMonitoring['card-label-name']}>{name}</div>
        <div className={stylesMonitoring['card-label-days']}>{daysOfWeek}</div>
        <div className={stylesMonitoring['card-label-hours']}>{`${formatHours(`${startDate} ${startTime}`)} - ${formatHours(`${endDate} ${endTime}`)} `}</div>
      </>
    )
  };

  const onItemEdit = (curfew) => {
    setSelectedCurfew(curfew);
    setView('editItem');
  }

  const updateCurfew = async (formValues, selectedCurfew, setSubmitting, setStatus) => {
    const updatedCurfew = saveCurfewAlert(formValues, curfew, { curfew: selectedCurfew });
    if (updatedCurfew) {
      try {
        dispatch(setLoadingStatus(true));
        await api.updateCurfewsService(updatedCurfew);
        curfewId = selectedCurfew.lastSvcReqId;
        startPolling(updateSuccessMsg, updateErrorMsg);
      } catch(error) {
        dispatch({ type: CURFEW_ACTIONS.GET_CURFEWS, data: null })
        getCurfews();
        dispatch(setLoadingStatus(false));
        setStatus && setStatus({success: false});
        setSubmitting && setSubmitting(false);
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: updateErrorMsg, title: errorDialogHeader } });
      }
    }
  }

  const validationCallback = (response: APIResponse<CurfewResponse>) => {
    const curfew = response.data.svcRequests.find(item => item.id === curfewId);
    const curfewStatus = curfew?.status;

    if (curfewStatus?.includes(Status.SUCCESS) || curfew === undefined) {
      dispatch({ type: CURFEW_ACTIONS.GET_CURFEWS, data: response.data });
      return PollingStatus.SUCCESS;
    }

    if (curfewStatus?.includes(Status.FAILED)) {
      return PollingStatus.ERROR;
    }

    return PollingStatus.PENDING;
  }

  const successfulCallback = (message: string) => {
    onFormClose();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: successDialogHeader } });
  }

  const errorCallback = (message: string) => {
    onFormClose();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: errorDialogHeader } });
  }

  const startPolling = (successMsg: string, errorMsg: string) => {
    initiatePolling({
      pollingFunc: api.getCurfewsService.bind(api),
      validationCallback,
      successCallback: successfulCallback.bind(this, successMsg),
      errorCallback: errorCallback.bind(this, errorMsg),
      timeout: pollingTime,
    });
  };

  const createCurfew = async (formValues, setSubmitting, setStatus) => {
    const createdCurfew = saveCurfewAlert(formValues, curfew, null);
    if (createdCurfew) {
      try {
        dispatch(setLoadingStatus(true));
        const { data: curfewResponse } = await api.createCurfewsService(createdCurfew);
        curfewId = curfewResponse.svcReqId;
        startPolling(createSuccessMsg, createErrorMsg);
      } catch (err) {
        setStatus({success: false});
        setSubmitting(false);
        dispatch(setLoadingStatus(false));
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: createErrorMsg, title: errorDialogHeader } });
      }
    }
  }

  const onFormConfirm = (formValues, setSubmitting, setStatus) => {
    const nameRepeated = curfew.curfews.find((item) => item.name === formValues.name);
    if (nameRepeated) {
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: nameErrorMsg, title: errorDialogHeader } });
    } else {
      formValues.dayOfTheWeek =  mapDaysFromLang(formValues.dayOfTheWeek, locale);
      if (!!selectedCurfew) {
        updateCurfew(formValues, selectedCurfew, setSubmitting, setStatus);
      } else {
        createCurfew(formValues, setSubmitting, setStatus)
      }
    }
  }

  const onItemDelete = async (selectedCurfew) => {
    try {
      dispatch(setLoadingStatus(true));
      await api.deleteCurfewsService(selectedCurfew);
      curfewId = selectedCurfew.id;
      startPolling(deleteSuccessMsg, deleteErrorMsg);
    } catch (err) {
      dispatch(setLoadingStatus(false));
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: deleteErrorMsg, title: errorDialogHeader } });
    }
  }

  const onFormClose = () => {
    setSelectedCurfew(null);
    setView('default');
    dispatch(setLoadingStatus(false));
  }
  
  const toggleCurfewState = (selectedCurfew) => {
    const { lastKnownState } = selectedCurfew;
    const updatedState = lastKnownState === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const getCurfewValues = mapFormInitialValues(selectedCurfew);

    const updateCurfewValues = { ...getCurfewValues, lastKnownState: updatedState };

    updateCurfew(updateCurfewValues, selectedCurfew);
  }

  const onChangeItemWarning = (selectedCurfew) => {
    const { inVehicleWarning } = selectedCurfew;
    const getCurfewValues = mapFormInitialValues(selectedCurfew);
    const updateCurfewValues = { ...getCurfewValues, inVehicleWarning: !inVehicleWarning };

    updateCurfew(updateCurfewValues, selectedCurfew);
  }

  const renderCurfewForm = (curfew = null) => {
    const initialValues = curfew ? mapFormInitialValues(curfew) : defaultFormValues;
    return (
      <CurfewForm
      label={fieldLabel}
      description={tabDescription}
      placeholder={fieldPlaceholder}
      errorMessage={fieldErrorMessage}
      initialValues={initialValues}
      switchLabel={switchDescription}
      onFormConfirm={onFormConfirm}
      onFormClose={onFormClose}
      tabDescription={tabDescription}
      days={weekDays}
      cancelButtonLabel={cancelButton}
      confirmButtonLabel={cancelConfirm}
      dayLabel={dayLabel}
      daysSelectDayEndLabel={daysSelectDayEndLabel}
      daysSelectDayStartLabel={daysSelectDayStartLabel}
      daysValidationErrorMessage={daysValidationErrorMessage}
      weekLabel={weekLabel}
      formNameError={formNameError}
    />
    )
  }

  const curfewAlertCount = curfew?.curfews?.length || 0;
  const renderAlertsCount = () => (!!curfewAlertCount &&
    <AlertsCount label={alertSetLabel} countCurrent={curfew.curfews.length} countMax={curfewsLimit} preposition={alertSetPreposition} />
  );

  const variant = curfew?.curfews?.length >= curfewsLimit ? 'outlined' : 'filled';
  const defaultContent = curfew?.curfews?.length ? '' : defaultViewContent;

  return (
    <>
      <ActiveView currentView={view} activeView={'default'}>
        <TabHeader label={title} content={defaultContent} onClickInfo={() => setView('tabInfo')} onEdit={() => setView('editList')} displayEditButton={curfewAlertCount > 0}/>
        <div className={stylesMonitoring['card-list']}>
          {curfew?.curfews?.map((curfew) => {
            const isChecked = curfew.lastKnownState === 'ACTIVE'
            return <ManageCardItem
              key={curfew.id}
              label={cardLabel(curfew)}
              handlers="switch"
              isChecked={isChecked}
              onSwitchToggle={() => toggleCurfewState(curfew)}
            />
          })}
        </div>
        <div className={stylesMonitoring['button-container--curfew']}>
          <Button variant={variant} onClick={() => setView('addItem')} disabled={curfew?.curfews?.length >= curfewsLimit}>
            {defaultViewButtonLabel}
          </Button>
        </div>
        {renderAlertsCount()}
      </ActiveView>

      <ActiveView currentView={view} activeView={'editList'}>
        <TabHeader label={title} onEdit={() => setView('default')} onClickInfo={() => setView('tabInfo')} />
        <div className={stylesMonitoring['card-list']}>
          {curfew?.curfews?.map((curfew) => {
            return <ManageCardItem
              key={curfew.id}
              label={cardLabel(curfew)}
              handlers="edit"
              onSwitchToggle={() => onChangeItemWarning(curfew)}
              onItemEdit={() => onItemEdit(curfew)}
              onItemRemove={() => onItemDelete(curfew)}
            />
          })}
        </div>
        <div className={stylesMonitoring['button-container--curfew']}>
          <Button variant="outlined" onClick={() => setView('default')}>
            {cancelButton}
          </Button>
        </div>
        {renderAlertsCount()}
      </ActiveView>

      <ActiveView currentView={view} activeView={'addItem'}>
        <TabHeader label={editViewTitle} />
        {renderCurfewForm()}
      </ActiveView>

      <ActiveView currentView={view} activeView={'editItem'}>
        <TabHeader label={editFormViewTitle} />
        {selectedCurfew && renderCurfewForm(selectedCurfew)}
      </ActiveView>

      <ActiveView currentView={view} activeView={'tabInfo'}>
        <TabInfo onClose={() => setView('default')}>
          <TabHeader label={title} content={infoBoxContent} showInfoIcon />
        </TabInfo>
      </ActiveView >
    </>
  )
}
export default TabCurfew;

