import set from "lodash/set";
import { Environment } from "@cv/portal-common-lib/ajax/constants";
import { GetLocationTrackerByVehicleId, GetServiceRequestId, GetCurrentVehicleLocation } from "@cv/portal-rts-lib/location-tracker";
import {
  GetLocationTracker,
  GetLocationTrackerResponse,
  GetCurrentVehicleLocationRequest,
  GetCurrentVehicleLocationResponse,
} from "@cv/portal-rts-lib/location-tracker/models";
// import { VehicleRequest } from "@cv/portal-cps-lib/vehicle/vehicle-management/models";

export default class VehicleContactsService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getVehicleLocation(accessToken: string, vehicleId: string): Promise<GetLocationTrackerResponse> {
    const request: GetLocationTracker = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
      },
      pathParams: {
        vehicleId,
      },
    };
    const response = (await GetLocationTrackerByVehicleId(request)) as {
      data: GetLocationTrackerResponse;
    };

    return response.data || {};
  }

  async getServiceRequestId(accessToken: string, vehicleId: string) {
    const request: GetLocationTracker = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
      },
      pathParams: {
        vehicleId,
      },
      data: {}
    };
    const response = (await GetServiceRequestId(request)) as {
      data: GetLocationTrackerResponse;
    };

    return response.data || {};
  }

  async getCurrentLocation(accessToken: string, vehicleId: string, svcReqId: string) {
    const request: GetCurrentVehicleLocationRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
      },
      pathParams: {
        vehicleId,
        svcReqId,
      },
      data: {}
    };
    const response = (await GetCurrentVehicleLocation(request) as {
      data: GetCurrentVehicleLocation
    });

    return response.data || {};
  }
}
