import { AccountData, useApi } from '@api';
import { AccountStatus, PhoneType } from '@cv/portal-idm-lib/enums';
import { AccountType, Currency } from '@cv/portal-idm-lib/user/user-create/enums';
import { AgreementStatus, AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import {
  CancelReason,
  CancellationPolicy,
  PackageType,
} from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import CreateAccount, { CreateAccountFormData } from './CreateAccount';
import EmailSearch, { EmailSearchDialog, EmailSearchFormData } from './EmailSearch';
import { LinkedUser, User, Vehicle } from './types';
import { OrderPayload, PackageToAdd } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import React, { useEffect, useState } from 'react';
import TermsAndConditions, { TermsAndConditionsDeclined } from '@components/TermsAndConditions';
import VinSearch, { VinSearchFormData } from './VinSearch';
import {
  findFreePackageVariant,
  findFreeProductInCurrentPackages,
  findFreeProductInEligiblePackages,
  isActivePackage,
  isPreRdrPackage,
  isVehicleOwner,
  isPrimarySubscriber,
  isRdrDefault,
  vehicleOwnerRoleId,
  primarySubscriberRoleId,
} from './utils';
import useLabels, { Label } from '@hooks/useLabels';

import { CardView as Card } from '@components/Card';
import { DevicePrefLanguage } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';
import Dialog from '@components/Dialog';
import EnrollStatus from './EnrollStatus';
import { LoaderBackdrop } from '@components/Loader';
import { RichTextContainerProps } from '@components/RichTextContainer';
import ServicesOverview from '@components/ServiceOverview';
import UnlinkSuccess from './UnlinkSuccess';
import VinStatus from './VinStatus';
import { format } from 'date-fns-tz';
import formatISO from 'date-fns/formatISO';
import styles from './LinkVehicle.module.css';
import { useLocation } from 'react-router-dom';

type LinkVehicleProps = {
  labels: { content: Label[] }[];
  servicesOverview: RichTextContainerProps[];
  termsAndConditions: {
    asset: {
      file: {
        url: string;
        contentType: string;
      };
    };
  }[];
  createAccountForm: object;
  currency: Currency;
  billingPostalAddress1: string;
  billingPostalAddress2: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingCountry: string;
};

const LinkVehicle = ({
  labels,
  servicesOverview,
  termsAndConditions,
  createAccountForm,
  currency,
  billingPostalAddress1,
  billingPostalAddress2,
  billingCity,
  billingStateProvince,
  billingPostalCode,
  billingCountry,
}: LinkVehicleProps) => {
  const dict = useLabels(labels.map((labelsSet) => labelsSet.content).flat());
  const api = useApi();
  const location = useLocation();
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('loading');
  const [error, setError] = useState<{ title?: string; text?: string; fatal?: boolean } | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [linkedVehicleOwners, setLinkedVehicleOwners] = useState<LinkedUser[]>([]);
  const [linkedUsers, setLinkedUsers] = useState<LinkedUser[]>([]);
  const [isVinActive, setIsVinActive] = useState(false);
  const [unlinkSuccess, setUnlinkSuccess] = useState(false);
  const [activeStep, setActiveStep] =
    useState<
      | 'vinSearch'
      | 'vinStatus'
      | 'servicesOverview'
      | 'termsAndConditions'
      | 'termsAndConditionsDeclined'
      | 'emailSearch'
      | 'createAccount'
      | 'enrollStatusSuccess'
      | 'enrollStatusFailure'
    >('vinSearch');

  useEffect(() => {
    const exchangeToken = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('tk') || '';
      try {
        await api.authenticateWithToken(token);
        setStatus('idle');
      } catch (e) {
        setStatus('error');
      }
    };

    exchangeToken();
  }, [api, location]);

  const handleVinSearch = async ({ vin }: VinSearchFormData) => {
    const vinError = {
      title: dict.getPrimary('errorVinInvalidTitle', 'Invalid VIN'),
      text: dict.getPrimary('errorVinInvalidText'),
    };

    try {
      setStatus('loading');

      // Query vehicle by VIN in IDM
      const {
        data: { result, resultCount },
      } = await api.queryVehicle({ filter: { vin }, fields: ['users'] });

      if (!resultCount) {
        setError(vinError);
        throw new Error('Vehicle not found');
      }

      const { vehicleId, tenantId, users = [] } = result[0];

      // CPS requests require tenant id to be provided
      api.storeService.setTenantId(tenantId);

      const {
        data: { type, allVehicleServiceDetails, telematicsProgramId, make, model, year, color, trimLevel },
      } = await api.getVehicleDetails(undefined, vehicleId);

      // Check if VIN is eligible for enrollment
      if (!allVehicleServiceDetails.isDeviceLinked) {
        setError(vinError);
        throw new Error('Device is not linked to a vehicle');
      }

      if (type?.toLowerCase() === 'rental') {
        setError(vinError);
        throw new Error('Rental vehicle is not eligible to enroll');
      }

      // Find any users with VehicleOwner role linked to the vehicle
      const vehicleOwners = users.filter((u) => Boolean(u.refProperties?.roles?.find(isVehicleOwner)));
      if (vehicleOwners.length) {
        const vehicleOwnerLinkedUsers: LinkedUser[] = vehicleOwners
          .map((user) =>
            user.refProperties.roles
              .filter(isVehicleOwner)
              .map((role) => ({ userId: user.refResourceId, roleId: role.roleId })),
          )
          .flat();

        // Find other users with PrimarySubscriber or RDRDefault roles linked to the vehicle
        const otherLinkedUsers: LinkedUser[] = users
          .map((user) =>
            user.refProperties.roles
              .filter((role) => isPrimarySubscriber(role) || isRdrDefault(role))
              .map((role) => ({ userId: user.refResourceId, roleId: role.roleId })),
          )
          .flat();

        // Check if VIN is active
        const subscriptions = await Promise.all(
          vehicleOwnerLinkedUsers.map((user) => {
            return api.getSubscription({ vehicleId, userId: user.userId });
          }),
        );

        // VIN is active if there are any active subscriptions (excluding pre-RDR package)
        setIsVinActive(
          subscriptions.some(
            (subscription) => subscription.data.subscribedPackages.filter((pkg) => isActivePackage(pkg)).length > 0,
          ),
        );

        setLinkedVehicleOwners(vehicleOwnerLinkedUsers);
        setLinkedUsers(otherLinkedUsers);
      } else {
        setIsVinActive(false);
        setLinkedVehicleOwners([]);
        setLinkedUsers([]);
      }

      setVehicle({ vin, vehicleId, tenantId, telematicsProgramId, make, model, year, color, trimLevel });
      setActiveStep('vinStatus');
      setStatus('idle');
    } catch (e) {
      setStatus('error');
    }
  };

  const handleEmailSearch = async ({ email }: EmailSearchFormData) => {
    try {
      setStatus('loading');
      const { data } = await api.queryAccount({ filter: { email } });

      if (data.resultCount) {
        // This will trigger email search dialog
        // to confirm using existing account or create a new one
        setUser(data.result[0]);
      } else {
        setUser({ email });
        setActiveStep('createAccount');
      }

      setStatus('idle');
    } catch (e) {
      setStatus('error');
    }
  };

  const handleCancel = () => {
    setActiveStep('vinSearch');
    setVehicle(null);
    setStatus('idle');
    setError(null);
  };

  const handleStatusChange = async () => {
    if (!isVinActive) {
      setActiveStep('servicesOverview');
      return;
    }

    // Unlink flow
    try {
      if (!vehicle) {
        throw new Error('Vehicle is not set');
      }

      setStatus('loading');
      const { vehicleId } = vehicle;

      // Cancel all current subscriptions
      const cancelSubscriptions = linkedVehicleOwners.map(({ userId }) => {
        return api.getSubscription({ vehicleId, userId }).then(({ data }) => {
          if (data.subscribedPackages.length) {
            const order: OrderPayload = {
              userId,
              declineDefaultPackage: true,
              removeAllPackages: {
                cancelReason: CancelReason.Cancelled_sold,
                cancellationPolicy: CancellationPolicy.Effective_date,
              },
            };

            return api.createOrder({ vehicleId, ...order });
          }
        });
      });
      await Promise.all(cancelSubscriptions);

      // Unlink users
      const unlinkUsers = [...linkedVehicleOwners, ...linkedUsers].map(({ userId, roleId }) =>
        api.unlinkVehicle({ vehicleId, userId, roleId }),
      );
      await Promise.all(unlinkUsers);

      // Begin the link flow
      setIsVinActive(false);
      setLinkedVehicleOwners([]);
      setLinkedUsers([]);
      setUnlinkSuccess(true);
      setStatus('idle');
    } catch (e) {
      setError({
        title: dict.getPrimary('errorUnlinkTitle'),
        text: dict.getPrimary('errorUnlinkText'),
      });
      setStatus('error');
    }
  };

  const handleUnlinkSuccessContinue = () => {
    setUnlinkSuccess(false);
    setActiveStep('servicesOverview');
  };

  const handleServicesOverviewContinue = () => {
    setActiveStep('termsAndConditions');
  };

  const handleTermsAndConditionsDecline = () => {
    setActiveStep('termsAndConditionsDeclined');
  };

  const handleTermsAndConditionsAccept = () => {
    setUser(null);
    setActiveStep('emailSearch');
  };

  const handleEmailSearchDialogSubmit = (mode: 'current' | 'new') => {
    if (mode === 'new') {
      setUser((state) => state && { email: state.email });
    }

    setActiveStep('createAccount');
  };

  const handleEmailSearchDialogCancel = () => {
    setUser(null);
  };

  const handleAccountCreate = async (formData: CreateAccountFormData) => {
    try {
      if (!vehicle) {
        throw new Error('Vehicle is not set');
      }

      setStatus('loading');

      const { vehicleId, tenantId } = vehicle;

      // Create account if not exists
      let userDetails: AccountData;
      if (!user?._id) {
        const { data } = await api.createAccount({
          ...formData,
          tenantId,
          userName: formData.email,
          accountType: AccountType.Person,
          accountStatus: AccountStatus.active,
          primaryPhoneType: PhoneType.Home,
          currency,
          billingPostalAddress1,
          billingPostalAddress2,
          billingCity,
          billingStateProvince,
          billingPostalCode,
          billingCountry,
        });
        userDetails = data;
      } else {
        const { data } = await api.getAccountDetails(undefined, user._id);
        userDetails = data;
      }

      const userId = userDetails._id;

      // Remove PRE-RDR package from VIN
      const subscriptions = (
        await Promise.all(
          linkedVehicleOwners.map(({ userId: vehicleOwnerUserId }) => {
            return api.getSubscription({ vehicleId, userId: vehicleOwnerUserId });
          }),
        )
      )
        .flat()
        .filter(({ data: subscription }) => subscription.subscribedPackages.some(isPreRdrPackage));

      if (subscriptions.length) {
        const orders = subscriptions.reduce<Promise<unknown>[]>((acc, { data: subscription }) => {
          return acc.concat(
            subscription.subscribedPackages
              .filter((pkg) => isPreRdrPackage(pkg))
              .map((pkg) =>
                api.createOrder({
                  vehicleId,
                  userId: pkg.userId,
                  declineDefaultPackage: true,
                  packagesToRemove: [
                    {
                      cancelEffectiveDate: formatISO(Date.now(), { representation: 'date' }),
                      cancelReason: CancelReason.Cancelled_sold,
                      cancellationPolicy: CancellationPolicy.Effective_date,
                      subscriptionPackageId: pkg.subscriptionPackageId,
                    },
                  ],
                }),
              ),
          );
        }, []);

        await Promise.all(orders);
      }

      // Unlink from all previous Vehicle Owners, Primary subscribers or RDR default
      const unlinkPrevUsers = [...linkedVehicleOwners, ...linkedUsers].map((user) =>
        api.unlinkVehicle({ vehicleId, userId: user.userId, roleId: user.roleId }),
      );
      await Promise.all(unlinkPrevUsers);

      // Link vehicle to user
      // This is need to be done prior to searching for eligible packages
      await api.linkVehicle({ vehicleId, userId, roleId: vehicleOwnerRoleId });
      await api.linkVehicle({ vehicleId, userId, roleId: primarySubscriberRoleId });

      // Make sure there are free products in current subscribed products, perform enrollment otherwise
      const { data: currentPackages } = await api.getSubscription({ vehicleId, userId });
      const currentFreeProduct = findFreeProductInCurrentPackages(currentPackages);

      // If subscription does not exist yet, create an order
      if (!currentFreeProduct) {
        const { data: eligiblePackages } = await api.searchEligiblePackages({ vehicleId, userId });
        const freeProductRegular = findFreeProductInEligiblePackages({ packages: eligiblePackages });

        if (!freeProductRegular) {
          throw new Error('No eligible packages found');
        }

        const freeProductAddon = findFreeProductInEligiblePackages({
          packages: eligiblePackages,
          type: PackageType.Add_on,
        });
        const variantRegular = findFreePackageVariant(freeProductRegular);
        const variantAddon = freeProductAddon ? findFreePackageVariant(freeProductAddon) : null;

        const packagesToAdd: PackageToAdd[] = [
          {
            autoRenew: false,
            packageVariantId: variantRegular?.id || '',
          },
        ];

        if (variantAddon) {
          packagesToAdd.push({
            autoRenew: false,
            packageVariantId: variantAddon.id,
            parentId: variantRegular?.id,
          });
        }

        // Create an order
        await api.createOrder({ vehicleId, userId, packagesToAdd });

        const {
          data: { subscribedPackages },
        } = await api.getSubscription({
          vehicleId,
          userId,
          excludeDefaultPkgs: true,
        });

        const pkgs = subscribedPackages.map((pkg) => ({
          serviceName: dict.getPrimary('packagePrefixLabel', 'NissanConnect Services ') + pkg.marketingName,
          startDate: format(new Date(pkg.startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(pkg.termServiceEndDate), 'yyyy-MM-dd'),
          price: '$0.00', //assumption is agreement will be executed for trial enrollment
          currLabel: dict.getPrimary('currencyLabel', 'Mex$'),
        }));

        const { vin, telematicsProgramId, tenantId, make, model, year, color, trimLevel } = vehicle;

        const {
          email,
          mailingCountry,
          homeAddress: { street, unit, city, zip, state },
          userName: { givenName, fathersName, mothersName },
          primaryPhone,
          secondaryPhone,
        } = userDetails;

        await api.createAgreement({
          telematicsProgramId,
          tenantId,
          make,
          country: mailingCountry,
          language: DevicePrefLanguage[api.storeService.getLocale().replace('en-', 'en').replace(/-.+$/, '')],
          userId: userId,
          vin,
          state,
          sendNotification: false,
          userInfo: {
            firstName: givenName,
            exeFirstName: givenName,
            lastName: fathersName,
            exeLastName: fathersName,
            surname2: mothersName,
            exeDate: format(new Date(), 'yyyy-MM-dd', { timeZone: 'America/Chicago' }),
            exeTime: format(new Date(), 'HH:mm a z', { timeZone: 'America/Chicago' }),
            address: street,
            aptNo: unit,
            city,
            state,
            zip,
            primaryContactNumber: primaryPhone.number,
            eveningPhone: secondaryPhone.number,
            dayTimePhone: '',
            mobilePhone: primaryPhone.number,
            emailAddress: email,
            preferredElectronicMethod: 'email',
            make,
            model,
            modelYear: year,
            vehicleColor: color,
            trim: trimLevel,
            vin,
            dealershipName: '',
            securityQuestion: dict.getPrimary('securityQuestion', 'Last 6 digits of your VIN and home zip code'),
            agreementDate: format(new Date(), 'yyyy-MM-dd', { timeZone: 'America/Chicago' }),
            pkgs,
            totalPrice: '$0.00', //assumption is agreement will be executed for trial enrollment
            taxes: '$0.00'
          },
          agreementMode: AgreementStatus.Execute,
          agreementType: AgreementType.Subscriber_info,
        });
      }

      setActiveStep('enrollStatusSuccess');
      setStatus('idle');
    } catch (e) {
      setError({
        title: dict.getPrimary('createAccountErrorTitle', 'Enrollment error'),
      });
      setStatus('error');
    }
  };

  return (
    <Card type="main" className={styles.card}>
      {status === 'loading' && <LoaderBackdrop />}
      {status === 'error' && (
        <Dialog
          title={error?.title || dict.getPrimary('error', 'Error')}
          labelOk={error?.fatal ? '' : dict.getPrimary('errorOk', 'OK')}
          onOk={handleCancel}
          classes={{ body: styles.error }}
        >
          {error?.text || dict.getPrimary('errorText')}
        </Dialog>
      )}
      {unlinkSuccess && (
        <UnlinkSuccess
          labelUnlinkSuccessTitle={dict.getPrimary('unlinkSuccessTitle', 'Unlink success!')}
          labelUnlinkSuccessText={dict.getPrimary('unlinkSuccessText', 'You have successfully unlinked this vehicle')}
          labelContinue={dict.getPrimary('continue', 'Continue')}
          onContinue={handleUnlinkSuccessContinue}
        />
      )}
      {activeStep === 'vinSearch' && (
        <VinSearch
          labelTitle={dict.getPrimary('dealerVinSearchTitle', 'Dealer enrollment')}
          labelInput={dict.getPrimary('enterVin', 'Enter Customer VIN.')}
          labelInputPlaceholder={dict.getPrimary('vinNumber', 'VIN Number')}
          labelSubmit={dict.getPrimary('submit', 'Submit')}
          labelErrorVinRequired={dict.getPrimary('errorVinRequired', 'Vin is required')}
          labelErrorVinInvalid={dict.getPrimary('errorVinInvalid', 'Please enter valid VIN')}
          onSubmit={handleVinSearch}
        />
      )}
      {activeStep === 'vinStatus' && vehicle && (
        <VinStatus
          vin={vehicle.vin}
          isActive={isVinActive}
          labelTitle={dict.getPrimary('vinStatusTitle', 'Dealer enrollment')}
          labelVinActiveStatus={dict.getPrimary('vinActiveStatus', 'Active')}
          labelVinActiveDescription={dict.getPrimary(
            'vinActiveDescription',
            'VIN is active, choose "UNLINK NOW" to remove this VIN from the current account.',
          )}
          labelVinInactiveStatus={dict.getPrimary('vinInactiveStatus', 'Not Active')}
          labelVinInactiveDescription={dict.getPrimary(
            'vinInactiveDescription',
            'VIN is inactive, choose "UNLINK NOW" to remove this VIN from the current account.',
          )}
          labelAccountInactive={dict.getPrimary('accountInactive', 'VIN is not active, begin enrollment.')}
          labelVin={dict.getPrimary('vin', 'VIN')}
          labelStatus={dict.getPrimary('status', 'Status')}
          labelBack={dict.getPrimary('back', 'Back')}
          labelUnlink={dict.getPrimary('unlinkNow', 'Unlink now')}
          labelEnroll={dict.getPrimary('enrollNow', 'Enroll now')}
          onCancel={handleCancel}
          onOk={handleStatusChange}
        />
      )}
      {activeStep === 'servicesOverview' && vehicle && (
        <ServicesOverview
          labelTitle={dict.getPrimary('servicesOverviewTitle', 'Services overview')}
          labelContinue={dict.getPrimary('continue', 'Continue')}
          content={servicesOverview[0]}
          onContinue={handleServicesOverviewContinue}
        />
      )}
      {activeStep === 'termsAndConditions' && vehicle && (
        <TermsAndConditions
          labelTitle={dict.getPrimary('termsConditionsTitle', 'Terms and conditions')}
          labelTermsConditionsInstructions={dict.getPrimary('termsConditionsInstructions')}
          labelDecline={dict.getPrimary('decline', 'Decline')}
          labelAgreeTerms={dict.getPrimary('agreeTerms', 'Agree terms')}
          labelDeclineConfirmationTitle={dict.getPrimary('declineConfirmationTitle', 'Are you sure?')}
          labelDeclineConfirmationNote={dict.getPrimary('declineConfirmationNote', 'Please note:')}
          labelDeclineConfirmationDescription={dict.getPrimary(
            'declineConfirmationDescription',
            'By declining terms, you will not activate your vehicle identification number.',
          )}
          labelDeclineConfirmation={dict.getPrimary(
            'declineConfirmation',
            'Are you sure you want to decline Terms & Conditions?',
          )}
          labelDeclineYes={dict.getPrimary('declineYes', 'Yes, decline')}
          labelDeclineNo={dict.getPrimary('declineNo', 'No, continue')}
          contentUrl={
            (termsAndConditions[0]?.asset.file.contentType === 'text/html' && termsAndConditions[0]?.asset.file.url) ||
            ''
          }
          onDecline={handleTermsAndConditionsDecline}
          onAccept={handleTermsAndConditionsAccept}
        />
      )}
      {activeStep === 'termsAndConditionsDeclined' && vehicle && (
        <TermsAndConditionsDeclined
          labelTitle={dict.getPrimary('termsConditionsDeclinedTitle', 'Declined terms & conditions')}
          labelText={dict.getPrimary('termsConditionsDeclinedText')}
          labelDealerInstructions={dict.getPrimary('dealerInstructions', 'Dealer Instructions:')}
          labelCloseWindow={dict.getPrimary('closeWindow', 'Please close the window.')}
        />
      )}
      {activeStep === 'emailSearch' && vehicle && (
        <EmailSearch
          labelTitle={dict.getPrimary('emailSearchTitle', 'Dealer enrollment')}
          labelInput={dict.getPrimary('enterEmail', 'Enter Customer VIN.')}
          labelInputPlaceholder={dict.getPrimary('email', 'VIN Number')}
          labelSubmit={dict.getPrimary('submit', 'Submit')}
          labelErrorEmailRequired={dict.getPrimary('errorEmailRequired', 'Vin is required')}
          labelErrorEmailInvalid={dict.getPrimary('errorEmailInvalid', 'Please enter valid VIN')}
          onSubmit={handleEmailSearch}
        />
      )}
      {activeStep === 'emailSearch' && user && (
        <EmailSearchDialog
          email={user.email}
          labelEmailSearchDialogTitle={dict.getPrimary('emailSearchDialogTitle', 'We found your account')}
          labelEmailSearchDialogText={dict.getPrimary('emailSearchDialogText')}
          labelEmailSearchDialogCurrentUser={dict.getPrimary('emailLinkWithCurrent', 'Link with current email address')}
          labelEmailSearchDialogNewUser={dict.getPrimary('emailLinkWithNew', 'Link with the new email address')}
          labelSubmit={dict.getPrimary('submit', 'Submit')}
          labelCancel={dict.getPrimary('cancel', 'Cancel')}
          onSubmit={handleEmailSearchDialogSubmit}
          onCancel={handleEmailSearchDialogCancel}
        />
      )}
      {activeStep === 'createAccount' && user && vehicle && (
        <CreateAccount
          vin={vehicle.vin}
          user={user}
          formModel={createAccountForm}
          labelTitle={dict.getPrimary('createAccount', 'Create Account')}
          labelVin={dict.getPrimary('vin', 'VIN')}
          onSubmit={handleAccountCreate}
          onBack={handleTermsAndConditionsAccept}
        />
      )}
      {['enrollStatusSuccess', 'enrollStatusFailure'].includes(activeStep) && vehicle && (
        <EnrollStatus
          vin={vehicle.vin}
          success={activeStep === 'enrollStatusSuccess'}
          labelSuccessTitle={dict.getPrimary('createAccountSuccessTitle', 'Enrollment success')}
          labelErrorTitle={dict.getPrimary('createAccountErrorTitle', 'Enrollment error')}
          labelSuccessText={dict.getPrimary('createAccountSuccessText')}
          labelErrorText={dict.getPrimary('createAccountErrorText')}
          labelDealerInstructions={dict.getPrimary('dealerInstructions', 'Dealer Instructions:')}
          labelDealerInstructionsText={dict.getPrimary('createAccountSuccessInstruction')}
          labelVin={dict.getPrimary('vin', 'VIN')}
        />
      )}
    </Card>
  );
};

export default LinkVehicle;
