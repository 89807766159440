// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DcEdMEPMhPWaH7nhlbgYS {\n  position: relative;\n  margin: 1rem 0 0;\n  padding-bottom: 1rem;\n  border-bottom: 1px solid var(--theme-color-border);\n  font-size: var(--theme-paragraph-font-size);\n  line-height: 1.2rem;\n}\n\n._-4suPXWnYq7PfAqhjM6pO {\n  font-weight: 600;\n  color: var(--theme-color-grey-34);\n}\n\nspan._-4suPXWnYq7PfAqhjM6pO {\n  font-weight: 400;\n  margin: 0 0.5rem;\n}\n\n._1VOH07yycP9CnImXEsS_Xj {\n  top: -0.5rem;\n  border: none;\n  padding: 0;\n  background-color: transparent;\n  position: absolute;\n  right: -0.75rem;\n  cursor: pointer;\n}\n\n._2s4EX9rd1ZR-LdIbzr79xC {\n  font-size: 1rem;\n  color: var(--theme-color-primary);\n}\n", "",{"version":3,"sources":["webpack://./src/components/CreditCardInfo/CreditCardInfo.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,kDAAkD;EAClD,2CAA2C;EAC3C,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iCAAiC;AACnC","sourcesContent":[".CreditCardInfo-container {\n  position: relative;\n  margin: 1rem 0 0;\n  padding-bottom: 1rem;\n  border-bottom: 1px solid var(--theme-color-border);\n  font-size: var(--theme-paragraph-font-size);\n  line-height: 1.2rem;\n}\n\n.CreditCardInfo-label {\n  font-weight: 600;\n  color: var(--theme-color-grey-34);\n}\n\nspan.CreditCardInfo-label {\n  font-weight: 400;\n  margin: 0 0.5rem;\n}\n\n.CreditCardInfo-button {\n  top: -0.5rem;\n  border: none;\n  padding: 0;\n  background-color: transparent;\n  position: absolute;\n  right: -0.75rem;\n  cursor: pointer;\n}\n\n.CreditCardInfo-icon {\n  font-size: 1rem;\n  color: var(--theme-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreditCardInfo-container": "DcEdMEPMhPWaH7nhlbgYS",
	"CreditCardInfo-label": "_-4suPXWnYq7PfAqhjM6pO",
	"CreditCardInfo-button": "_1VOH07yycP9CnImXEsS_Xj",
	"CreditCardInfo-icon": "_2s4EX9rd1ZR-LdIbzr79xC"
};
export default ___CSS_LOADER_EXPORT___;
