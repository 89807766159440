import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALERTS_ACTIONS, DIALOG_ACTIONS } from '@redux/actions/';

import Button from '@components/Button';
import ActiveView from '../ActiveView';
import AlertsCount from '../AlertsCount';
import ManageCardItem from '../ManageCardItem';
import SpeedAlertForm from '../Forms/SpeedAlertForm'
import TabHeader from '../TabHeader'
import TabInfo from '../TabInfo'
import { useApi } from "@api";
import { saveSpeedAlert } from '../Forms/utils';


import stylesMonitoring from '../../MonitoringAlerts/MonitoringAlerts.module.css';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { Status } from '@cv/portal-rts-lib/doors/enums';
import { DrivingBehaviorResponse, DrivingBehaviorResult } from '@cv/portal-rts-lib/alerts/models';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { setLoadingStatus } from '@redux/actions/loading';
import { pollingTime } from '../constants';

type AlertData = {
  speed: any;
  lastKnownState: any;
  inVehicleWarning: boolean;
  UoM?: string;
};

type ExpectedStore = {
  alertsReducer: {
    drivingBehavior: {
      drivingBehavior: Array<DrivingBehaviorResponse> // NOT A TYPO
    }
  }
};

type TabSpeedProps = {
  alertSetLabel: string;
  alertSetPreposition: string;
  cancelButton: string;
  confirmButton: string;
  defaultViewContent: string;
  defaultViewButtonLabel: string;
  editViewButtonLabel: string;
  editViewTitle: string;
  fieldDescription?: string;
  fieldErrorMessage: string;
  fieldLabel: string;
  fieldUnit: string;
  formViewTitle: string;
  infoBoxContent: string;
  switchDescription: string;
  title: string;
  createSuccessMsg: string;
  createErrorMsg: string;
  updateSuccessMsg: string;
  updateErrorMsg: string;
  deleteSuccessMsg: string;
  deleteErrorMsg: string;
  successDialogHeader: string;
  errorDialogHeader: string;
  defaultSpeedValue: string;
}

export default function TabSpeed({
  alertSetLabel,
  alertSetPreposition,
  cancelButton,
  confirmButton,
  defaultViewContent,
  defaultViewButtonLabel,
  editViewButtonLabel,
  editViewTitle,
  fieldDescription,
  fieldErrorMessage,
  fieldLabel,
  fieldUnit,
  formViewTitle,
  infoBoxContent,
  switchDescription,
  title,
  createSuccessMsg,
  createErrorMsg,
  updateSuccessMsg,
  updateErrorMsg,
  deleteSuccessMsg,
  deleteErrorMsg,
  successDialogHeader,
  errorDialogHeader,
  defaultSpeedValue
}: TabSpeedProps) {
  const alertsLimit = 3
  let speedAlertId = '';
  const api = useApi();
  const [view, setView] = useState('default');
  const [selectedAlert, setSelectedAlert] = useState<DrivingBehaviorResponse | null>(null);
  const speedAlert = useSelector(({ alertsReducer }: ExpectedStore) => alertsReducer.drivingBehavior);
  const dispatch = useDispatch();

  const initialValues = {
    speed: defaultSpeedValue,
    inVehicleWarning: false,
  };

  const getAlerts = async () => {
    try {
      const { data } = await api.getAlertService();
      dispatch({ type: ALERTS_ACTIONS.GET_ALERTS, data });
    } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      console.log('error api', err);
    }
  };

  useEffect(() => {
      getAlerts()
  }, []);

  const onItemEdit = (alert: DrivingBehaviorResponse) => {
    setSelectedAlert(alert);
    setView('editItem');
  }

  const updateAlert = async (formValues: AlertData, selectedAlert?: DrivingBehaviorResponse) => {
    const updatedAlert = saveSpeedAlert(formValues, { drivingBehavior: selectedAlert });
    if (updatedAlert) {
      try {
        dispatch(setLoadingStatus(true));
        await api.updateAlertService(updatedAlert);
        speedAlertId = selectedAlert?.lastSvcReqId || '';
        startPolling(updateSuccessMsg, updateErrorMsg);
      } catch (e) {
        dispatch(setLoadingStatus(false));
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: updateErrorMsg, title: errorDialogHeader } });
      }
    }
  }

  const validationCallback = (response: APIResponse<DrivingBehaviorResult>) => {
    const speedAlert = response.data.svcRequests.find(item => item.id === speedAlertId);
    const speedAlertStatus = speedAlert?.status;

    if (speedAlertStatus?.includes(Status.SUCCESS) || speedAlert === undefined) {
      dispatch({ type: ALERTS_ACTIONS.GET_ALERTS, data: response.data });
      return PollingStatus.SUCCESS;
    }

    if (speedAlertStatus?.includes(Status.FAILED)) {
      return PollingStatus.ERROR;
    }

    return PollingStatus.PENDING;
  }

  const successCallback = (message: string) => {
    onFormClose();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: successDialogHeader } });
  }

  const errorCallback = (message: string) => {
    getAlerts();
    onFormClose();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: errorDialogHeader } });
  }

  const startPolling = (successMsg: string, errorMsg: string) => {
    initiatePolling({
      pollingFunc: api.getAlertService.bind(api),
      validationCallback,
      successCallback: successCallback.bind(this, successMsg),
      errorCallback: errorCallback.bind(this, errorMsg),
      timeout: pollingTime,
    });
  }

  const createAlert = async (formValues: AlertData) => {
    const createdAlert = saveSpeedAlert(formValues, null);
    if (createdAlert) {
      try {
        dispatch(setLoadingStatus(true));
        const {data: addAlertResponse} = await api.createAlertService(createdAlert);
        // TODO:
        speedAlertId = addAlertResponse.svcReqId;
        startPolling(createSuccessMsg, createErrorMsg);
      } catch (err) {
        dispatch(setLoadingStatus(false));
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: createErrorMsg, title: errorDialogHeader } });
      }
    }
  };

  const onItemDelete = async (selectedAlert: DrivingBehaviorResponse) => {
    try {
      dispatch(setLoadingStatus(true));
      await api.deleteAlertService(selectedAlert);
      speedAlertId = selectedAlert.lastSvcReqId;
      startPolling(deleteSuccessMsg, deleteErrorMsg);
    } catch (err) {
      dispatch(setLoadingStatus(false));
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: deleteErrorMsg, title: errorDialogHeader } });
    }
  }

  const onFormClose = () => {
    setSelectedAlert(null);
    setView('default');
    dispatch(setLoadingStatus(false));
  }

  const onFormConfirm = (formValues: AlertData) => {
    const updatedFormValues = { ...formValues, UoM: fieldUnit };
    if (!!selectedAlert) {
      updateAlert(updatedFormValues, selectedAlert);
    } else {
      createAlert(updatedFormValues)
    }
  }

  const onChangeItemWarning = (selectedAlert: DrivingBehaviorResponse, lastKnownState: string) => {
    const updateInitialValues = { ...initialValues, speed: selectedAlert.config[0].value, lastKnownState };
    updateAlert(updateInitialValues, selectedAlert);
  }

  const renderCardItems = () => {
    const isEditView = view === 'editList';

    return (
      <div className={stylesMonitoring['card-list']}>
        {speedAlert?.drivingBehavior?.map((alert) => {
          const { config: [configItem], lastKnownState } = alert;
          const label = `${configItem.value} ${fieldUnit}`;
          const isChecked = lastKnownState === 'ACTIVE'
          const oppositeState = isChecked ? 'INACTIVE' : 'ACTIVE';

          return <ManageCardItem
            key={label}
            label={label}
            handlers={isEditView ? 'edit' : 'switch'}
            isChecked={isChecked}
            onSwitchToggle={!isEditView ? () => onChangeItemWarning(alert, oppositeState) : undefined}
            onItemEdit={isEditView ? (() => onItemEdit(alert)) : undefined}
            onItemRemove={isEditView ? (() => onItemDelete(alert)) : undefined}
          />
        })}
      </div>
    )
  }

  const speedAlertCount = speedAlert?.drivingBehavior?.length || 0;
  const renderAlertCount = () => (
    !!speedAlert?.drivingBehavior && <AlertsCount label={alertSetLabel} countCurrent={speedAlertCount} countMax={alertsLimit} preposition={alertSetPreposition} />
  );

  return (
    <>
      <ActiveView currentView={view} activeView={'default'}>
        <TabHeader label={title} content={!speedAlertCount ? defaultViewContent : ''} onClickInfo={() => setView('tabInfo')} onEdit={() => setView('editList')} displayEditButton={speedAlertCount > 0} />
        {renderCardItems()}
        <div className={stylesMonitoring['button-container']}>
          <Button variant="filled" onClick={() => setView('editItem')} disabled={speedAlert?.drivingBehavior?.length >= alertsLimit}>
            {defaultViewButtonLabel}
          </Button>
        </div>
        {renderAlertCount()}
      </ActiveView>
      <ActiveView currentView={view} activeView={'editList'}>
        <TabHeader label={editViewTitle} onEdit={() => setView('default')} />
        {renderCardItems()}
        <div className={stylesMonitoring['button-container']}>
          <Button variant="outlined" onClick={() => setView('default')}>
            {editViewButtonLabel}
          </Button>
        </div>
        {renderAlertCount()}
      </ActiveView>
      <ActiveView currentView={view} activeView={'editItem'}>
        <TabHeader label={formViewTitle} />
        <SpeedAlertForm
          unit={fieldUnit}
          label={fieldLabel}
          description={fieldDescription}
          switchLabel={switchDescription}
          errorMessage={fieldErrorMessage}
          initialValues={initialValues}
          onFormConfirm={onFormConfirm}
          onFormClose={onFormClose}
          cancelButtonLabel={cancelButton}
          confirmButtonLabel={confirmButton}
        />
      </ActiveView>
      <ActiveView currentView={view} activeView={'tabInfo'}>
        <TabInfo onClose={() => setView('default')}>
          <TabHeader label={title} content={infoBoxContent} showInfoIcon />
        </TabInfo>
      </ActiveView>
    </>
  )
}

