import React from "react";

import { FormConnector, ModelConnector } from '@components/ApiConnectorHOCs/'
import useToggle from "@hooks/useToggle";
import ApiRequestPayload from '@types/ApiRequestPayload';
import CreatorButton from "./CreatorButton";
import CreatorForm from "./CreatorForm";
import styles from "./EntryCreator.module.css";

interface EntryCreatorProps {
  form: Object;
  formConnector: {
    api: string;
  };
  apiConnector: {
    api: string;
  };
  data: Array<ApiRequestPayload> | ApiRequestPayload,
  buttonLabelOpen: string;
  onSubmit: (data: ApiRequestPayload) => void;
  itemLimit?: number;
}

const EntryCreator = ({ buttonLabelOpen, form, onSubmit, data, itemLimit }: EntryCreatorProps) => {
  const [isFormOpen, toggleForm] = useToggle(false);
  const isItemLimitMet = itemLimit && Array.isArray(data) && data.length >= itemLimit;

  if (isItemLimitMet) {
    return null;
  }

  return (
    <div className="EntryCreator">
      {!isFormOpen && (
        <CreatorButton label={buttonLabelOpen} onClick={toggleForm} />
      )}
      {isFormOpen && (
        <CreatorForm
          onFormClose={toggleForm}
          onFormConfirm={onSubmit}
          {...form}
        />
      )}
    </div>
  );
};

export default ModelConnector(FormConnector(EntryCreator));
