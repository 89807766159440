import React from 'react';
import ContentfulComponent from '@types/ContentfulComponent';
import COMPONENT_MAPPINGS from '@components/component-mappings';

type ContentRendererProps = {
  name: string;
  content: Array<ContentfulComponent>;
  [key: string]: any;
};

function ContentRenderer({
  name,
  content = [],
  ...restProps
}: ContentRendererProps): React.ReactElement {
  const contentKey = name.split(' ').join('-').toLowerCase();
  return <>{(
    content
      // THIS IS TEMP - AVOIDS ERRORS BEING THROWN WHEN COMPONENT MAPPING MAY BE OUT OF DATE
      .filter((item) => Boolean(COMPONENT_MAPPINGS[item.componentType]))
      .map((item, i) => {
        return React.createElement(
          // TEMP to prevent typescript error
          COMPONENT_MAPPINGS[item.componentType] as any,
          {
            key: `${contentKey}-${i}`,
            ...restProps,
            ...item,
          }
        );
      })
  )}</>;
}

export default ContentRenderer;
