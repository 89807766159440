// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IjCC0O_v1AhvEyLzAaxH_ {\n  --card-color-border-top: var(--manage-subscription-border-color);\n  width: 100%;\n  margin: 1rem auto;\n}\n\n@media (min-width: 768px) {\n  .IjCC0O_v1AhvEyLzAaxH_ {\n    width: 83.33%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/SubscriptionWrapper/SubscriptionWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,gEAAgE;EAChE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".subscription-wrapper {\n  --card-color-border-top: var(--manage-subscription-border-color);\n  width: 100%;\n  margin: 1rem auto;\n}\n\n@media (--viewport-s) {\n  .subscription-wrapper {\n    width: 83.33%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscription-wrapper": "IjCC0O_v1AhvEyLzAaxH_"
};
export default ___CSS_LOADER_EXPORT___;
