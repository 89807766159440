import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from '@api';
import { useTheme } from '@components/Theme';
import { CardView } from '@components/Card';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import {
  AddOnsWebContent,
  CommonWebContent,
  DiscountWebContent,
  EligiblePackagesWebContent,
  ManageSubscription,
  OfferDetails,
  PackagesWebContent,
  PromoPackagesWebContent,
  SubscribedPackagesWebContent,
  SubscriptionProps,
  TrialPackagesWebContent,
} from '@cv/portal-components-lib';

import config from '@config/config';
import { Label } from '@hooks/useLabels';
import { ROOT_EVENTS } from '@app/reducers';
import usePreferencesSelector from '@redux/selectors/preferences';

import styles from './SubscriptionWrapper.module.css';

type SubscriptionWrapperProps = {
  labels: {
    back: Label;
    ok: Label;
    continue: Label;
    currentSubscription: Label;
    enterNewCard: Label;
    useCardOnFile: Label;
    expires: Label;
    halfYearly: Label;
    month: Label;
    monthly: Label;
    years: Label;
    months: Label;
    days: Label;
    subscription: Label;
    paymentInfo: Label;
    paymentTermsAndConditions: Label;
    quarterly: Label;
    renew: Label;
    currentPackage: Label;
    saveAndContinue: Label;
    skipAndContinue: Label;
    inactivePackageHeader: Label;
    premiumTrialPackageHeader: Label;
    selectOrPlusTrialPackageHeader: Label;
    conveniencePaidPackageHeader: Label;
    securityPaidPackageHeader: Label;
    premiumPaidPackageHeader: Label;
    packagesDescription: Label;
    year: Label;
    yearly: Label;
    noPackagesAvailable: Label;
    packageUpgrade: Label;
    addServices: Label;
    cancel: Label;
    cancelSubscriptions: Label;
    reviewOrder: Label;
    paymentMethod: Label;
    edit: Label;
    billingAddress: Label;
    cardNumberEnding: Label;
    paymentError: Label;
    orderSummary: Label;
    starts: Label;
    cancelEffective: Label;
    refund: Label;
    subscriptionSummary: Label;
    subTotal: Label;
    packagePrice: Label;
    totalTax: Label;
    totalAmount: Label;
    discount: Label;
    paymentDate: Label;
    subscribeNow: Label;
    prorationLegal: Label;
    taxLegalPayNow: Label;
    taxLegalPayLater: Label;
    add: Label;
    added: Label;
    addOnHeader: Label;
    addOnDescription: Label;
    creditCardInfo: Label;
    required: Label;
    cardNumber: Label;
    expiration: Label;
    cardholderName: Label;
    addressLineOne: Label;
    zipCode: Label;
    thisIsRequiredField: Label;
    invalidInput: Label;
    orderSuccessful: Label;
    successOrderMsg: Label;
    successOrderEmail: Label;
    currencyLabel: Label;
    privacyPolicy: Label;
    trialPackageLegalText: Label;
    paidPackageLegalText: Label;
    paymentTerms: Label;
    summaryFooterCheckbox: Label;
    reviewOrderTerms: Label;
    done: Label;
    noThanksContinueTrialEnrollLabel: Label;
    trialPackageHeader: Label;
    trialLabel: Label;
    legalText: Label;
    discountHeader: Label;
    offerDetailsHeader: Label;
    offerDetailsOneMonth: Label;
    offerDetailsTwelveMonths: Label;
    regularlyLabel: Label;
    nowLabel: Label;
    paidInFullLabel: Label;
    offerDetailsLabel: Label;
    getThisDealLabel: Label;
    discountPackageHeader: Label;
    discountPackageDescription: Label;
    discountLegalText: Label;
  };
};

const environmentEnum = config.getEnvironmentEnum();
const siteId: string = config.get('INAUTH_SITE_ID') || '';
const collectorUrl: string = config.get('COLLECTOR_URL') || '';
const source: string = config.getOemValue('SOURCE') || '';


const SubscriptionWrapper = ({ labels }: SubscriptionWrapperProps) => {
  const dispatch = useDispatch();
  const preferences = usePreferencesSelector();

  const {
    mailingCountry,
    userName: {
      givenName,
      fathersName,
    },
    homeAddress: {
      street,
      unit,
      city,
      state,
      zip,
    },
    billingId,
    tenantId,
    email,
    primaryPhone,
  } = useSelector(
    ({ accountReducer }) => accountReducer.account?.data || { userName: {}, homeAddress: {} },
  );
  const theme = useTheme();
  const api = useApi();

  const billingAddress = {
    address1: street,
    address2: unit || '',
    city: city,
    state: state,
    postalCode: zip,
    country: mailingCountry,
  };

  const userDetails = {
    userId: api.storeService.getUserId(),
    firstName: givenName,
    lastName: fathersName,
    email,
    phone: primaryPhone,
    billingId,
    billingAddress,
  };

  const vehicleDetails = {
    vehicleId: api.storeService.getVehicleData().vehicleId,
    make: api.storeService.getVehicleData().make,
    telematicsProgramId: api.storeService.getVehicleData().telematicsProgramId,
    vin: api.storeService.getVehicleData().vin,
  };

  const transactionDetails = {
    siteId,
    source,
    collectorUrl,
  };

  const subscriptionProps: SubscriptionProps = {
    correlationId: '',
    salesChannel: SalesChannel.Subscriber,
    accessToken: api.storeService.getAccessToken(),
    locale: api.storeService.getLocale(),
    env: environmentEnum,
    tenantId,
    userDetails,
    vehicleDetails,
    transactionDetails,
    customerCareNumber: preferences.customerCareNumber,
    privacyPolicyUrl: preferences.privacyPolicyURL,
    supportedCreditCards: preferences.supportedCreditCards?.split(','),
    onSubscriptionConfirm: () => {
      dispatch({ type: ROOT_EVENTS.CLEAR_CACHE });
    },
  };

  const componentTheme = {
    color: theme.get('color-primary'),
    backgroundColor: theme.get('manage-subscription-text-color'),
  };

  const subscribedPackagesWebContent: SubscribedPackagesWebContent = {
    expiresLabel: labels.expires.primary,
    renewLabel: labels.renew.primary,
    subscriptionLabel: labels.subscription.primary,
    startsLabel: labels.starts.primary,
    cancelEffectiveLabel: labels.cancelEffective.primary,
  };

  const eligiblePackagesWebContent: EligiblePackagesWebContent = {
    // TODO: Create key-value pairs in contentful and fetch
    termsLabel: [
      { key: 1, value: labels.monthly.primary },
      { key: 3, value: labels.quarterly.primary },
      { key: 6, value: labels.halfYearly.primary },
      { key: 12, value: labels.yearly.primary },
    ],
    perTermsLabel: [
      { key: 1, value: labels.month.primary },
      { key: 3, value: `3${labels.months.primary}` },
      { key: 6, value: `6${labels.months.primary}` },
      { key: 12, value: labels.year.primary },
    ],
    noPackagesAvailableLabel: labels.noPackagesAvailable.primary,
    packageUpgradeText: labels.packageUpgrade.primary,
  };

  const offerDetails: OfferDetails = {
    header: labels.offerDetailsHeader.primary,
    // TODO: Create key-value pairs in contentful and fetch
    offerDetail: [
      {
        key: 'fixed_amount-1months',
        value: labels.offerDetailsOneMonth.primary,
      },
      {
        key: 'fixed_amount-12months',
        value: labels.offerDetailsTwelveMonths.primary,
      },
      {
        key: '25-percentage-1months',
        value: labels.offerDetailsOneMonth.primary,
      },
      {
        key: '50-percentage-12months',
        value: labels.offerDetailsTwelveMonths.primary,
      },
    ],
  };

  const discountWebContent: DiscountWebContent = {
    header: labels.discountHeader.primary,
    offerDetails: offerDetails,
    regularlyLabel: labels.regularlyLabel.primary,
    nowLabel: labels.nowLabel.primary,
    paidInFullLabel: labels.paidInFullLabel.primary,
    offerDetailsLabel: labels.offerDetailsLabel.primary,
    getThisDealLabel: labels.getThisDealLabel.primary,
  };

  const trialPackagesWebContent: TrialPackagesWebContent = {
    header: labels.trialPackageHeader.primary,
    trialLabel: labels.trialLabel.primary,
    legalText: labels.legalText.primary,
  };

  const promoPackagesWebContent: PromoPackagesWebContent = {
    header: labels.discountPackageHeader.primary,
    description: labels.discountPackageDescription.primary,
    discount: discountWebContent,
    legalText: labels.discountLegalText.primary,
  };

  const packagesWebContent: PackagesWebContent = {
    // TODO: Create key-value pairs in contentful and fetch
    packagesHeaderLabel: [
      { key: 'Inactive', value: labels.inactivePackageHeader.primary },
      { key: 'PremiumTrial', value: labels.premiumTrialPackageHeader.primary },
      { key: 'SelectTrial', value: labels.selectOrPlusTrialPackageHeader.primary },
      { key: 'PlusTrial', value: labels.selectOrPlusTrialPackageHeader.primary },
      { key: 'Convenience', value: labels.conveniencePaidPackageHeader.primary },
      { key: 'Security', value: labels.securityPaidPackageHeader.primary },
      { key: 'Premium', value: labels.premiumPaidPackageHeader.primary },
    ],
    packagesDescription: labels.packagesDescription.primary,
    currentSubscriptionLabel: labels.currentSubscription.primary,

    packagePrefixLabel: preferences.serviceName,
    currentPackageLabel: labels.currentPackage.primary,
    addServicesButtonLabel: labels.addServices.primary,

    cancelSubscriptions: labels.cancelSubscriptions.primary,
    trialPackageLegalText: labels.trialPackageLegalText?.primary || '',
    packageLegalText: labels.paidPackageLegalText.primary,

    subscribedPackagesWebContent: subscribedPackagesWebContent,
    eligiblePackagesWebContent: eligiblePackagesWebContent,
  };

  const addOnsWebContent: AddOnsWebContent = {
    addOnHeaderLabel: labels.addOnHeader.primary,
    addOnDescription: labels.addOnDescription.primary,
  };

  const commonWebContent: CommonWebContent = {
    addButtonLabel: labels.add.primary,
    addedButtonLabel: labels.added.primary,
    yearsLabel: labels.years.primary,
    monthsLabel: labels.months.primary,
    daysLabel: labels.days.primary,
    backButtonLabel: labels.back.primary,
    editLinkLabel: labels.edit.primary,
    okButtonLabel: labels.ok.primary,
    cancelLinkLabel: labels.cancel.primary,
    continueButtonLabel: labels.continue.primary,
    currencyLabel: labels.currencyLabel.primary,
    skipAndContinueButtonLabel: labels.skipAndContinue.primary,
    saveAndContinueButtonLabel: labels.saveAndContinue.primary,
  };

  const webContent = {
    theme: componentTheme,

    // Trial
    trialPackagesWebContent: trialPackagesWebContent,
    // Promo
    promoPackagesWebContent: promoPackagesWebContent,
    // Packages
    packagesWebContent: packagesWebContent,
    // Addons
    addOnsWebContent: addOnsWebContent,
    // Common
    commonWebContent: commonWebContent,

    // Payment
    paymentInfoLabel: labels.paymentInfo.primary,
    creditCardInfoLabel: labels.creditCardInfo.primary,
    requiredFieldLabel: labels.required.primary,
    cardNumberFieldLabel: labels.cardNumber.primary,
    expirationFieldLabel: labels.expiration.primary,
    cardholderNamedFieldLabel: labels.cardholderName.primary,
    addressLine1FieldLabel: labels.addressLineOne.primary,
    zipCodeFieldLabel: labels.zipCode.primary,
    enterNewCardLabel: labels.enterNewCard.primary,
    useCardOnFileLabel: labels.useCardOnFile.primary,
    paymentTermsAndConditions: `${labels.paymentTermsAndConditions.primary}`,
    requiredFieldErrorMsg: labels.thisIsRequiredField.primary,
    invalidInputFieldErrorMsg: labels.invalidInput.primary,
    paymentErrorMsg: labels.paymentError.primary,
    paymentTerms: labels.paymentTerms?.primary || '',

    // Review Order
    reviewOrderLabel: labels.reviewOrder.primary,
    paymentMethodLabel: labels.paymentMethod.primary,
    cardNumberEndingLabel: labels.cardNumberEnding.primary,
    billingAddressLabel: labels.billingAddress.primary,
    orderSummaryLabel: labels.orderSummary.primary,
    subscriptionSummaryLabel: labels.subscriptionSummary.primary,
    subTotalLabel: labels.subTotal.primary,
    packagePriceLabel: labels.packagePrice.primary,
    refundLabel: labels.refund.primary,
    discountLabel: labels.discount.primary,
    totalTaxLabel: labels.totalTax.primary,
    totalAmountLabel: labels.totalAmount.primary,
    paymentDateLabel: labels.paymentDate.primary,
    prorationLegal: labels.prorationLegal.primary,
    taxLegalPayNow: labels.taxLegalPayNow.primary,
    taxLegalPayLater: labels.taxLegalPayLater.primary,
    subscribeNowButtonLabel: labels.subscribeNow.primary,
    reviewOrderTerms: labels.summaryFooterCheckbox?.primary || '',

    // Confirm Order
    confirmOrderHeaderLabel: labels.orderSuccessful.primary,
    confirmOrderDescriptionLabel: labels.successOrderMsg.primary,
    confirmOrderEmailLabel: labels.successOrderEmail.primary,
    doneButtonLabel: labels.done.primary,

    // Privacy Policy
    privacyPolicyLinkLabel: labels.privacyPolicy.primary,
  };

  return (
    <CardView type='main' className={styles['subscription-wrapper']}>
      <ManageSubscription subscriptionProps={subscriptionProps} content={webContent} />
    </CardView>
  );
};

export default SubscriptionWrapper;



