import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage';

import mapReducer from '@components/Map/reducer';
import accountReducer from '@redux/reducers/account';
import settingsReducer from '@redux/reducers/settings';
import accessTokenReducer from '@redux/reducers/token-access';
import refreshTokenReducer from '@redux/reducers/token-refresh';
import userReducer from '@redux/reducers/user';
import vehicleReducer from '@redux/reducers/vehicle';
import curfewReducer from '@redux/reducers/curfew';
import alertsReducer from '@redux/reducers/alerts';
import geofenceReducer from '@redux/reducers/geofence';
import loadingReducer from '@redux/reducers/loading';
import boundaryReducer from '@redux/reducers/boundaries';
import dialogReducer from '@redux/reducers/dialog';
import stolenVehicleReducer from '@redux/reducers/stolen-vehicle';
import preferencesReducer from '@redux/reducers/preferences';

export enum ROOT_EVENTS {
  CLEAR_CACHE = 'CLEAR_CACHE',
  DESTROY_SESSION = 'DESTROY_SESSION',
}

const mapReducerConfig = {
  key: 'mapReducer',
  storage,
  blacklist: ['pointsOfInterest', 'selectedPointOfInterest', 'map', 'userLocation', 'vehicleLocation'],
};
const accountReducerConfig = {
  key: 'accountReducer',
  storage: storageSession,
};

const settingsReducerConfig = {
  key: 'settingsReducer',
  storage,
};

const accessTokenReducerConfig = {
  key: 'accessTokenReducer',
  storage,
};

const refreshTokenReducerConfig = {
  key: 'refreshTokenReducer',
  storage: storageSession,
};

const userReducerConfig = {
  key: 'userReducer',
  storage,
};

const vehicleReducerConfig = {
  key: 'vehicleReducer',
  storage: storageSession,
};

const alertsReducerConfig = {
  key: 'alertsReducer',
  storage,
};

const curfewReducerConfig = {
  key: 'curfewReducer',
  storage,
};

const geofenceReducerConfig = {
  key: 'geofenceReducer',
  storage
};

const loadingReducerConfig = {
  key: 'loadingReducer',
  storage,
  blacklist: ['isLoading'],
};

const boundaryReducerConfig = {
  key: 'boundaryReducer',
  storage,
  blacklist: ['boundaryCoordinates', 'radius', 'multipleBoundaryCoordinates'],
};

const dialogReducerConfig = {
  key: 'dialogReducer',
  storage,
};

const svlReducerConfig = {
  key: 'stolenVehicleReducer',
  storage,
};

const preferencesConfig = {
  key: 'preferencesReducer',
  storage,
};

const appReducer = combineReducers({
  mapReducer: persistReducer(mapReducerConfig, mapReducer),
  accountReducer: persistReducer(accountReducerConfig, accountReducer),
  settingsReducer: persistReducer(settingsReducerConfig, settingsReducer),
  accessTokenReducer: persistReducer(accessTokenReducerConfig, accessTokenReducer),
  refreshTokenReducer: persistReducer(refreshTokenReducerConfig, refreshTokenReducer),
  userReducer: persistReducer(userReducerConfig, userReducer),
  vehicleReducer: persistReducer(vehicleReducerConfig, vehicleReducer),
  curfewReducer: persistReducer(curfewReducerConfig, curfewReducer),
  alertsReducer: persistReducer(alertsReducerConfig, alertsReducer),
  geofenceReducer: persistReducer(geofenceReducerConfig, geofenceReducer),
  loadingReducer: persistReducer(loadingReducerConfig, loadingReducer),
  boundaryReducer: persistReducer(boundaryReducerConfig, boundaryReducer),
  dialogReducer: persistReducer(dialogReducerConfig, dialogReducer),
  stolenVehicleReducer: persistReducer(svlReducerConfig, stolenVehicleReducer),
  preferencesReducer: persistReducer(preferencesConfig, preferencesReducer),
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === ROOT_EVENTS.DESTROY_SESSION) {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>
export default rootReducer;
