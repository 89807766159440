import { flatten, invoke } from 'lodash';
import config from '@config/config';
import { UserCreatePayload } from '@cv/portal-idm-lib/user/user-create/models';
import { ResetPinPayload, ResetPinByKBAPayload } from '@cv/portal-idm-lib/pin/models';
import { OrderPayload, EligiblePackagesResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import {
  UpdateSecurityQuestionsByPinRequest,
  SetupPinAndSecurityQuestionsRequest,
} from '@cv/portal-idm-lib/security-questions-pin/models';
import { UpdateCurfewPayload, CreateCurfewPayload } from '@cv/portal-rts-lib/curfews/models';
import { AddDestination, UpdateDestination } from '@cv/portal-rts-lib/v0/information/destinations/models';
import { ContactTypes } from '@cv/portal-idm-lib/contact/enums';
import { SearchVehiclePayload } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { QueryFilterKeys } from '@cv/portal-idm-lib/models';
import { ContactFormData, AccountFormData, PaymentMethodData, AddVinData, SubscribedServices } from './types/';
import ApiRequestPayload from '@app/types/ApiRequestPayload';
import {
  AccountDetailsService,
  AccountUpdateService,
  VehicleContactsService,
  SubscribedPackagesService,
  AccountCreationService,
  AccountLinkService,
  VehicleStatusService,
  MockService,
  VehicleDetailsService,
  VehicleLocationService,
  DoorLockUnlockService,
  HornLightsService,
  EngineStartStopService,
  AlertsService,
  CurfewService,
  GeofenceService,
  VehicleContactUpdateService,
  StoreService,
  PinService,
  SecurityQuestionsService,
  PasswordService,
  HeadUnitsService,
  PoiService,
  SecurityPinService,
  FolderService,
  DestinationService,
  JourneyService,
  AuthService,
  VehicleLinkService,
  AgreementService,
  RoleService,
  StolenVehicleLocatorService,
  NotificationPreferencesService,
  PaymentMethodsService,
  VinService,
  TelematicServices,
} from './services';
import { Journey } from '@cv/portal-rts-lib/v0/information/journey';
import { AddJourney } from '@cv/portal-rts-lib/v0/information/journey/models';
import { UpdateValetPayload } from '@cv/portal-rts-lib/v0/monitor/geofences/models';
import { preferencesData } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models';
import { CreateGeofencePayload, UpdateGeofencePayload } from '@cv/portal-rts-lib/geofences/models';
import { UpdatePasswordPayload } from '@cv/portal-idm-lib/password/models';
import { CreateAgreementPayload, GetEffectiveAgreement } from '@cv/portal-cps-lib/agreements/agreement-service/models';
import { IVehicle } from '@redux/actions';
import { UserUpdatePayload } from '@cv/portal-idm-lib/user/user-update-profile/models';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { Modify } from '@cv/portal-common-lib';
import { DoorUnlockRequestPayload } from './services/DoorLockUnlockService';
import { HornLightsRequestPayload } from './services/HornLightsService';
import { EngineStartStopRequestPayload } from './services/EngineStartStopService';
import { CreateAlertsRequest, DrivingBehaviorByVehicleId } from '@cv/portal-rts-lib/alerts/models';
import { DistanceUnit } from '@cv/portal-rts-lib/v0/enums';

const environmentEnum = config.getEnvironmentEnum();

const mockService = new MockService();
const authService = new AuthService();

const accountDetailsService = new AccountDetailsService(environmentEnum);
const vehicleContactsService = new VehicleContactsService(environmentEnum);
const vehicleLocationService = new VehicleLocationService(environmentEnum);
const subscribedPackagesService = new SubscribedPackagesService(environmentEnum);
const accountUpdateService = new AccountUpdateService(environmentEnum);
const paymentMethodService = new PaymentMethodsService(environmentEnum);
const accountCreationService = new AccountCreationService(environmentEnum);
const accountLinkService = new AccountLinkService(environmentEnum);
const vehicleStatusService = new VehicleStatusService(environmentEnum);
const doorLockUnlockService = new DoorLockUnlockService(environmentEnum);
const hornLightsService = new HornLightsService(environmentEnum);
const engineStartStopService = new EngineStartStopService(environmentEnum);
const vehicleDetailsService = new VehicleDetailsService(environmentEnum);
const alertsService = new AlertsService(environmentEnum);
const curfewService = new CurfewService(environmentEnum);
const geofenceService = new GeofenceService(environmentEnum);
const vehicleContactUpdateService = new VehicleContactUpdateService(environmentEnum);
const pinService = new PinService(environmentEnum);
const securityQuestionsService = new SecurityQuestionsService(environmentEnum);
const passwordService = new PasswordService(environmentEnum);
const headUnitsService = new HeadUnitsService(environmentEnum);
const securityPinService = new SecurityPinService(environmentEnum);
const poiService = new PoiService(environmentEnum);
const folderService = new FolderService(environmentEnum);
const destinationService = new DestinationService(environmentEnum);
const journeyService = new JourneyService(environmentEnum);
const vehicleLinkService = new VehicleLinkService(environmentEnum);
const agreementService = new AgreementService(environmentEnum);
const roleService = new RoleService(environmentEnum);
const notificationPreferencesService = new NotificationPreferencesService(environmentEnum);
const stolenVehicleLocatorService = new StolenVehicleLocatorService(environmentEnum);
const vinService = new VinService(environmentEnum);
const telematicServices = new TelematicServices(environmentEnum);

export default class Api {
  constructor(public storeService: StoreService) {}

  createAccount(data: UserCreatePayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return accountCreationService.createUser({ data, accessToken, locale });
  }

  userRoleLink({ userId, roleId, tenantId }: { userId: string; roleId?: string; tenantId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return accountLinkService.userRoleLink({ userId, roleId, tenantId, accessToken, locale });
  }

  updateUserInfo(data: UserUpdatePayload[], locale?: string, userId?: string) {
    return accountDetailsService.updateUserInfo({
      userId: userId || this.storeService.getUserId() || '84cbc023-0965-4ba5-95f2-5b2d141be882',
      locale: locale || this.storeService.getLocale() || 'fr-CA',
      accessToken: this.storeService.getAccessToken(),
      data,
    });
  }

  getAccountDetails(locale?: string, userId?: string) {
    return accountDetailsService.getAccountDetails({
      userId: userId || this.storeService.getUserId() || '84cbc023-0965-4ba5-95f2-5b2d141be882',
      locale: locale || this.storeService.getLocale() || 'fr-CA',
      accessToken: this.storeService.getAccessToken(),
    });
  }

  queryAccount({ filter, fields, tenantId = '' }: { filter: QueryFilterKeys; fields?: string[]; tenantId?: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return accountDetailsService.queryAccount({ filter, fields, accessToken, locale, tenantId });
  }

  getVehicleLocation(accessToken: string) {
    /*if (environmentEnum === Environment.LOCAL) {
      return mockService.getVehicleLocation(3000);
    }*/

    return vehicleLocationService.getVehicleLocation(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getServiceRequestId(accessToken: string) {
    return vehicleLocationService.getServiceRequestId(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getCurrentVehicleLocation(accessToken: string, svcReqId: string) {
    return vehicleLocationService.getCurrentLocation(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
      svcReqId,
    );
  }

  getNotificationPreferences() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const userId = this.storeService.getUserId();
    const locale = this.storeService.getLocale();
    return notificationPreferencesService.getPreferences({ accessToken, locale, vin, userId });
  }

  updateNotificationPreferences(data: preferencesData) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const userId = this.storeService.getUserId();
    const locale = this.storeService.getLocale();
    return notificationPreferencesService.updatePreferences({ accessToken, locale, vin, userId, ...data });
  }

  getSubscription({
    vehicleId,
    userId,
    excludeDefaultPkgs = false,
  }: {
    vehicleId?: string;
    userId?: string;
    excludeDefaultPkgs?: boolean;
  }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale() || 'fr-CA';
    const tenantId = this.storeService.getTenantId();
    return subscribedPackagesService.getSubscription({
      userId: userId || this.storeService.getUserId() || '84cbc023-0965-4ba5-95f2-5b2d141be882',
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId || '2660C690-EBD5-11EB-8E86-0A58A9FEAC2A',
      accessToken,
      locale,
      tenantId,
      excludeDefaultPkgs,
    });
  }

  getAgreement() {
    return agreementService.getAgreement(
      this.storeService.getLocale(),
      this.storeService.getAccessToken(),
      this.storeService.getUserId(),
      this.storeService.getVehicleData().vin,
    );
  }

  searchEligiblePackages({ vehicleId, userId }: { vehicleId?: string; userId?: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return subscribedPackagesService.searchEligiblePackages({
      userId: userId || this.storeService.getUserId(),
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      accessToken,
      locale,
      tenantId,
    });
  }

  createOrder({
    vehicleId,
    userId,
    ...rest
  }: {
    vehicleId?: string;
    userId?: string;
  } & OrderPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return subscribedPackagesService.createOrder({
      userId: userId || this.storeService.getUserId(),
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      accessToken,
      locale,
      tenantId,
      ...rest,
    });
  }

  createAgreement(data: CreateAgreementPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return agreementService.createAgreement({ data, accessToken, locale });
  }

  getEffectiveAgreement(data: GetEffectiveAgreement) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return agreementService.getEffectiveAgreement({ data, accessToken, locale });
  }

  getContacts(locale: string, accessToken: string) {
    return vehicleContactsService.getContacts(
      { type: ContactTypes.EmergencyContact },
      this.storeService.getVehicleData().vehicleId || '2660C690-EBD5-11EB-8E86-0A58A9FEAC2A',
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getDrivers(locale: string, accessToken: string) {
    return vehicleContactsService.getContacts(
      { type: ContactTypes.AdditionalDriver },
      this.storeService.getVehicleData().vehicleId || '2660C690-EBD5-11EB-8E86-0A58A9FEAC2A',
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getPaymentMethod(locale: string, accessToken: string) {
    return paymentMethodService.getPaymentMethods(
      this.storeService.getUserId(),
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getByString(path: string) {
    return mockService.getByString(path);
  }

  fetchService(serviceName: string, locale: string) {
    return invoke(this.services.GET, serviceName, locale);
  }

  addContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.postContact(
      data,
      { type: ContactTypes.EmergencyContact },
      accessToken,
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getUserId(),
      this.storeService.getTenantId(),
    );
  }

  addDriver(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.postContact(
      data,
      { type: ContactTypes.AdditionalDriver },
      accessToken,
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getUserId(),
      this.storeService.getTenantId(),
    );
  }

  changeAccount(data: AccountFormData) {
    const accessToken = this.storeService.getAccessToken();
    return accountUpdateService.patchAccount(data, accessToken);
  }

  changeContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.patchContact(data, accessToken);
  }

  changeDriver(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.patchContact(data, accessToken);
  }

  changePaymentMethod(data: PaymentMethodData) {
    const accessToken = this.storeService.getAccessToken();
    const userId = this.storeService.getUserId();
    return paymentMethodService.updatePaymentMethod(data, userId, accessToken);
  }

  deleteContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.deleteContact(data, accessToken);
  }

  updateService(serviceName: string, operation: string, data?: ApiRequestPayload) {
    return invoke(this.services, `${operation.toUpperCase()}.${serviceName}`, data);
  }

  services = {
    GET: {
      contacts: this.getContacts,
      drivers: this.getDrivers,
      subscription: this.getSubscription,
      account: this.getAccountDetails,
      vehicle: this.getVehicleDetails,
      vehicleHealth: this.getVehicleHealth,
      storeService: this.storeService,
      myCarLocation: this.getVehicleLocation,
      paymentMethod: this.getPaymentMethod,
    },
    POST: {
      contacts: this.addContact.bind(this),
      drivers: this.addDriver.bind(this),
    },
    PUT: {
      account: this.changeAccount.bind(this),
      contacts: this.changeContact.bind(this),
      drivers: this.changeDriver.bind(this),
      paymentMethod: this.changePaymentMethod.bind(this),
    },
    DELETE: {
      contacts: this.deleteContact.bind(this),
      drivers: this.deleteContact.bind(this),
    },
  };

  resetVehicleData() {
    return headUnitsService.wipeVehicle(
      this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getPoiItems(searchTerm: string, maxReturned?: number) {
    // WE NEED TO DETERMINE THE VALUES FOR THIS - THERE IS NO EXAMPLE IN THE CURRENT ANGULAR IMPLEMENTATION
    const distance = {
      unit: DistanceUnit.Kilometer,
      value: 30,
    };
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const coordinates = this.storeService.getMapCenter();
    const data = {
      searchString: searchTerm,
      geoNear: {
        coordinates,
        distance,
      },
      maxReturned,
    };
    const id_token = this.storeService.getIdToken();

    return poiService.poiSearch({ accessToken, data, vin, id_token });
  }

  getAutocompleteAddress(searchTerm: string) {
    return mockService.getAutocompleteAddress(searchTerm);
  }

  searchDestinations(destination: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const id_token = this.storeService.getIdToken();

    const data = {
      searchString: destination,
    };
    return poiService.poiSearch({ accessToken, data, vin, id_token });
  }

  getDestinations(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return destinationService.getDestinations({
      accessToken,
      vin,
      folderId,
      channelId,
    });
  }

  addDestination(data: AddDestination) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();
    return destinationService.addDestination({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  deleteDestination(data: UpdateDestination) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return destinationService.deleteDestination({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  getFavorites() {
    return mockService.getFavorites();
  }

  getSentToCar() {
    return mockService.getSentToCar();
  }

  getVehicleHealth() {
    return vehicleStatusService.getHealthInformation(
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getLocale(),
    );
  }

  makeRemoteDoorAPICall(req: DoorUnlockRequestPayload) {
    return doorLockUnlockService.makeRemoteDoorAPICall(
      req,
      this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getVehicleDetails(locale?: string, vehicleId?: string, include?: string) {
    return vehicleDetailsService.getVehicleDetails({
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId || '2660C690-EBD5-11EB-8E86-0A58A9FEAC2A',
      subscribedServices: this.storeService.getSubscribedServices(),
      accessToken: this.storeService.getAccessToken(),
      locale: locale || this.storeService.getLocale() || 'fr-CA',
      tenantId: this.storeService.getTenantId(),
      include,
    });
  }

  getVehiclesData(vehicleIds: string[]) {
    const vehiclesDetailRequests = vehicleIds.map((vehicleIds) => this.getVehicleDetails(undefined, vehicleIds));
    return Promise.all(vehiclesDetailRequests).then((results) =>
      results?.map(
        (el) =>
          ({
            vin: el?.data?.vin,
            active: el?.data?.active,
            make: el?.data?.make,
            model: el?.data?.model,
            year: el?.data?.year,
            vehicleId: el?.data?.vehicleId,
            telematicsProgramId: el?.data?.telematicsProgramId,
            activeServices: [],
            services: el?.data?.services,
          } as IVehicle),
      ),
    );
  }
  getVinSubscribedPackages = (vehicleIds: string[]) => {
    const subscribedPackagesRequests = vehicleIds?.map((vehicleId) =>
      this.getSubscription({ vehicleId, excludeDefaultPkgs: true }),
    );
    return Promise.all(subscribedPackagesRequests)
      .then((results) =>
        results?.map(
          (packages) =>
            ({
              vin: packages?.data?.vehicle?.vin,
              vehicleId: packages?.data?.vehicle?.vehicleId,
              activeType: packages?.data?.services?.some(
                (subscribed) =>
                  subscribed.packageType === PackageType.Regular || subscribed.packageType === PackageType.Default,
              ),
              subscribedServiceIds: flatten(packages?.data?.services.map((service) => service.serviceIds)),
            } as SubscribedServices),
        ),
      )
      .catch((error) => {
        console.error('sxm subscribed vehicle status error', error);
      });
  };

  searchVehicle({
    vin,
    activeCriteria,
    deviceId,
    telematicsProgramId,
  }: Optional<SearchVehiclePayload, 'activeCriteria'>) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return vehicleDetailsService.searchVehicle({
      vin,
      activeCriteria,
      deviceId,
      telematicsProgramId,
      accessToken,
      locale,
      tenantId,
    });
  }

  queryVehicle({ filter, fields }: { filter: QueryFilterKeys; fields?: string[] }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return vehicleDetailsService.queryVehicle({ filter, fields, accessToken, locale });
  }

  linkVehicle({ vehicleId, userId, roleId }: { vehicleId: string; userId: string; roleId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return vehicleLinkService.linkVehicle({ vehicleId, userId, roleId, accessToken, locale });
  }

  unlinkVehicle({ vehicleId, userId, roleId }: { vehicleId: string; userId: string; roleId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return vehicleLinkService.unlinkVehicle({ vehicleId, userId, roleId, accessToken, locale });
  }

  makeHornLightsAPICall(request: HornLightsRequestPayload) {
    return hornLightsService.makeHornLightsAPICall(
      request,
      this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  makeEngineStartStopCall(request: EngineStartStopRequestPayload) {
    return engineStartStopService.makeEngineStartStopCall(
      request,
      this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getBreaches(type: string, alertType: string, startDateTime: string, endDateTime: string) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    // return mockService.getBreaches(); // Mock service for local development
    return alertsService.getBreaches({ vehicleId, accessToken, type, alertType, startDateTime, endDateTime });
  }

  getPostalAddress(coordinates: { latitude: number; longitude: number }) {
    const vin = this.storeService.getVehicleData().vin;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return alertsService.getPostalAddress({ vin, accessToken, idToken, coordinates });
  }

  getAlertService() {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    return alertsService.getAlerts({ vehicleId, accessToken });
  }
  updateAlertService(data: DrivingBehaviorByVehicleId) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    return alertsService.updateAlerts({ vehicleId, accessToken, data });
  }
  createAlertService(data: CreateAlertsRequest) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    return alertsService.createAlerts({ vehicleId, accessToken, data });
  }
  deleteAlertService(data: { lastSvcReqId: string }) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    return alertsService.deleteAlerts({ vehicleId, accessToken, data });
  }

  getCurfewsService() {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    return curfewService.getCurfews({ vehicleId, accessToken });
  }
  updateCurfewsService(data: UpdateCurfewPayload) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    return curfewService.updateCurfews({ vehicleId, accessToken, data });
  }
  createCurfewsService(data: CreateCurfewPayload) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    return curfewService.createCurfews({ vehicleId, accessToken, data });
  }
  deleteCurfewsService(data: { id: string }) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    return curfewService.deleteCurfews({ vehicleId, accessToken, data });
  }

  getGeofence() {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    return geofenceService.getGeofence({ accessToken, vehicleId });
  }

  createGeofence(data: CreateGeofencePayload) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    return geofenceService.createGeofence({ accessToken, vehicleId, data });
  }

  updateGeofence(data: UpdateGeofencePayload) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    return geofenceService.updateGeofence({ accessToken, vehicleId, data });
  }

  deleteGeofence(geofenceId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    return geofenceService.deleteGeofence({ accessToken, vehicleId, geofenceId });
  }

  getFolders() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return folderService.getFolders({ accessToken, vin });
  }

  getJourney(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return journeyService.getJourney({
      accessToken,
      vin,
      folderId,
      channelId,
    });
  }

  updateJourney(data: Journey) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return journeyService.updateJourney({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  addJourney(data: AddJourney) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return journeyService.addJourney({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  deleteJourney(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return journeyService.deleteJourney({
      accessToken,
      id_token,
      vin,
      folderId,
      channelId,
    });
  }

  getJourneys() {
    return mockService.getJourneys();
  }

  removeJourney() {
    return mockService.removeJourney();
  }

  getVehicleConditionCarInfo() {
    return mockService.getVehicleConditionCarInfo();
  }

  updatePin(data: ResetPinPayload) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return pinService.pinUpdate({ data, userId, accessToken, locale });
  }

  resetPin(data: ResetPinByKBAPayload) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return pinService.pinReset({ data, userId, accessToken, locale });
  }

  getPasswordData() {
    const userId = this.storeService.getUserId() || '84cbc023-0965-4ba5-95f2-5b2d141be882';
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale() || 'fr-CA';
    return passwordService.getPasswordData({ userId, accessToken, locale });
  }

  updatePasswordData(data: UpdatePasswordPayload | {}, userId: string, tenantId: string) {
    const accessToken = this.storeService.getAccessToken();
    return passwordService.updatePassword({ data, userId, accessToken, tenantId });
  }

  updateSecurityQuestions(data: UpdateSecurityQuestionsByPinRequest['data']) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return securityQuestionsService.updateSecurityQuestions({
      data,
      userId,
      accessToken,
      locale,
    });
  }

  setupSecurityPin(data: SetupPinAndSecurityQuestionsRequest['data']) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    return securityPinService.setupSecurityPin({ data, userId, accessToken });
  }

  storeTokenResponse(response: { data: { access_token: string; refresh_token: string; id_token: string } }) {
    const { access_token, refresh_token, id_token } = response.data;
    this.storeService.setAccessToken(access_token);
    this.storeService.setRefreshToken(refresh_token);
    this.storeService.setIdToken(id_token);
    return response;
  }

  authenticateWithToken(token: string) {
    return authService.exchangeToken({ token }).then((response) => {
      this.storeTokenResponse(response);
    });
  }

  getAccessToken({ code, verifier }: { code: string; verifier: string }) {
    return authService.getAccessToken({ code, verifier }).then((response) => {
      this.storeTokenResponse(response);
    });
  }

  refreshToken() {
    const refreshToken = this.storeService.getRefreshToken();
    return authService.refreshToken({ refreshToken }).then((response) => {
      const { access_token, id_token } = response.data;
      this.storeService.setAccessToken(access_token);
      this.storeService.setIdToken(id_token);
      return response;
    });
  }

  getUserInfo() {
    const accessToken = this.storeService.getAccessToken();
    return authService.getUserInfo({ accessToken });
  }

  queryRole({ filter, fields }: { filter: QueryFilterKeys; fields?: string[] }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return roleService.queryRole({ filter, fields, accessToken, locale });
  }

  getSvl(vehicleId: string) {
    return stolenVehicleLocatorService.getSvl(
      this.storeService.getLocale(),
      this.storeService.getAccessToken(),
      vehicleId || this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
    );
  }

  addVin(data: AddVinData) {
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const tenantId = this.storeService.getTenantId();

    return vinService.addVin({ data, locale, userId, tenantId });
  }

  removeVin({ vin }: { vin: string }) {
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const tenantId = this.storeService.getTenantId();

    return vinService.removeVin({ vin, locale, userId, tenantId });
  }

  getTelematicServices(vehicleId: string, locale: string) {
    const accessToken = this.storeService.getAccessToken();
    return telematicServices.getTelematicServices({
      vehicleId,
      accessToken,
      locale,
    });
  }
}
