import Card from './Card';
import InfoBox from './InfoBox';
import RichTextContainer from './RichTextContainer';
import ExpandingList from './ExpandingList';
import OneColumnSection from './OneColumnSection';
import TwoColumnSection from './TwoColumnSection';
import AppLink from './AppLink';
import Link from './Link';
import Navigation from './Navigation';
import EntryCreator from './EntryCreator';
import MarketingContainer from './MarketingContainer';
import { Journey, Security, Subscription } from './Services';
import SubscriptionOverview from './SubscriptionOverview';
import HeroBanner from './HeroBanner';
import Disclaimer from './Disclaimer';
import RegistrationForm from './RegistrationForm';
import SecurityInfoBox from './SecurityInfoBox';
import ResetDataInfoBox from './ResetDataInfoBox';
import Login from './Login/Login';
import LoginCallBack from './Login/LoginCallBack';
import NotificationPreferences from './NotificationPreferences';
import ContentfulGrid from './ContentfulGrid';
import Fuel from './Fuel';
import LabelDictionary from './LabelDictionary';
import SubscriptionWrapper from './SubscriptionWrapper';
import LinkVehicle from './LinkVehicle';
import VinStatusPage from './VinStatusPage/VinStatusPage';
import { Tab, TabItem } from './Tab';
import { TabCurfew, TabValet, TabSpeed, TabBoundary } from './Map/MonitoringAlerts';
import DoorsAndTires from './DoorsAndTires';
import { VehicleCondition, VehicleConditionItem } from './VehicleCondition';
import { AlertHistoryContent } from './Map/MonitoringAlerts';
import Pagination from './Pagination';
import AgreementLink from './AgreementLink';
import Faq from './Faq';
import Contacts from './Contacts';
import VehicleImageContainer from './VehicleImageContainer/VehicleImageContainer';
import { VehicleImage } from './VehicleImage';
import CreditCardInfo from './CreditCardInfo';
import ResetPassword from './ResetPassword';
import UpdatePassword from './UpdatePassword';
import WarningBanner from '@components/WarningBanner';

const COMPONENT_MAPPINGS = {
  portalInfoBox: InfoBox,
  portalInfoCard: Card,
  portalAppLink: AppLink,
  portalLink: Link,
  portalNavigation: Navigation,
  portalRichText: RichTextContainer,
  portalExpandingList: ExpandingList,
  oneColumnSection: OneColumnSection,
  twoColumnSection: TwoColumnSection,
  portalMarketingSection: MarketingContainer,
  portalJourneySection: Journey,
  portalSecuritySection: Security,
  portalSubscriptionSection: Subscription,
  portalSubscriptionOverview: SubscriptionOverview,
  portalNewItemCreator: EntryCreator,
  portalHeroBanner: HeroBanner,
  portalDisclaimer: Disclaimer,
  portalUnauthorizedUserForm: RegistrationForm,
  portalSecurityInfoBox: SecurityInfoBox,
  portalResetPersonalDataInfoBox: ResetDataInfoBox,
  portalRegistrationForm: RegistrationForm,
  portalNotificationPreferences: NotificationPreferences,
  portalGrid: ContentfulGrid,
  portalLoginForm: Login,
  portalLoginCallBackForm: LoginCallBack,
  portalVehicleStatusFuel: Fuel,
  portalLabelDictionary: LabelDictionary,
  portalLinkVehicle: LinkVehicle,
  portalVinStatusPage: VinStatusPage,
  portalTab: Tab,
  portalTabItem: TabItem,
  portalSpeedAlertTab: TabSpeed,
  portalBoundaryAlertTab: TabBoundary,
  portalCurfewAlertTab: TabCurfew,
  portalValetAlertTab: TabValet,
  portalVehicleStatusDoorsAndTires: DoorsAndTires,
  portalAlertHistoryTab: AlertHistoryContent,
  portalPagination: Pagination,
  portalTextWithLink: AgreementLink,
  portalVehicleWarningBannerSection: WarningBanner,
  portalVehicleImageContainer: VehicleImageContainer,
  portalVehicleImage: VehicleImage,
  portalVehicleStatusConditionSection: VehicleCondition,
  portalVehicleStatusConditionCard: VehicleConditionItem,
  portalContacts: Contacts,
  portalCCInfo: CreditCardInfo,
  resetPassword: ResetPassword,
  setPassword: UpdatePassword,

  // --- DYNAMIC COMPONENTS (LABEL-DICTIONARY COMPONENT SECTION)
  Subscription: SubscriptionWrapper,
  FAQ: Faq,
};

export type ComponentMapping = keyof typeof COMPONENT_MAPPINGS;

export default COMPONENT_MAPPINGS;
