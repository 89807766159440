import { useMemo, useRef } from 'react';

import axios from 'axios';
import config from '@config/config';
import { isEmpty } from 'lodash';
import { useApi } from '@api';
import useDialogActions from '@redux/hooks/dialog';
import { useDispatch } from 'react-redux';
import usePreferencesSelector from '@redux/selectors/preferences';

const useInterceptor = (errorsInterpolation: Record<string, string>) => {
  const apiErrorsEnabled = Boolean(config.get('apiErrorsEnabled', true));
  if (!apiErrorsEnabled) {
    return;
  }
  const interceptorId = useRef(-1);
  const dispatch = useDispatch();
  const { showDialog } = useDialogActions();
  const globalPreferences = usePreferencesSelector();
  const api = useApi();

  useMemo(() => {
    // LOGIN IS DISABLED ON DEALER PORTAL
    const loginEnabled = Boolean(config.get('loginEnabled', true));

    // Don't handle authentication errors on certain pages
    const authExcludePath = config.get<string[]>('authExcludePath') || [];

    const { origin, pathname } = window.location;
    // Remove previous interceptor to "refresh" variables values
    // interceptor behave as singleton, if you have any variable outside - it will
    // take only the data on the moment it was initialized (so all values would be "baked"/stored)
    axios.interceptors.response.eject(interceptorId.current);
    interceptorId.current = axios.interceptors.response.use(
      (data) => data,
      (error) => {
        const { status, config: axiosConfig } = error.response;
        const idmApiProxyBaseUrl = config.get<string>('IDM_API_PROXY_BASE_URL') || '';
        const requestUrl = new URL(axiosConfig?.url || '');
        // Special handling is needed for the shim requests only
        // Proxied requests should land to a normal error handling
        const isApiProxyRequest = requestUrl.origin.startsWith(idmApiProxyBaseUrl)
          && requestUrl.pathname.startsWith('/v1');

        if (status === 401 && !authExcludePath.includes(pathname) && loginEnabled) {
          if (error.config.retry) {
            dispatch({ type: 'DESTROY_SESSION' });
            window.location.href = `${origin}/login`;
            return;
          }
          return api.refreshToken().then((response) => {
            const { access_token, id_token, token_type } = response.data;
            error.config.headers.Authorization = `${token_type} ${access_token}`;
            if ('id_token' in error.config.headers) {
              error.config.headers.id_token = id_token;
            }
            return axios.request({ ...error.config, retry: true });
          }).catch(() => {
            dispatch({ type: 'DESTROY_SESSION' });
            window.location.href = `${origin}/login`;
          });
        } else if (isApiProxyRequest) {
          const { pooErrorMessage, pooErrorTitle } = globalPreferences;
          const apiProxyPath = requestUrl.pathname.slice(3);
          const enrollmentPaths = ['/user/enroll', '/proof-of-ownership'];
          if (enrollmentPaths.includes(apiProxyPath)) {
            showDialog({ message: pooErrorMessage, title: pooErrorTitle });
            throw error;
          }
        } else {
          const { message, code, detail } = error.response?.data || { message: '', code: '' };
          const excludeErrorDialog = config.get<Array<string>>('excludeErrorDialog');
          const suppressErrorDialogUrls = config.get<Array<string>>('suppressErrorDialogUrls');
          const failedPolicyRequirements = detail?.failedPolicyRequirements?.[0] || null;
          const failedPolicyCode = failedPolicyRequirements?.policyRequirements?.[0]?.policyRequirement || '';
          const failedPolicyProperty = failedPolicyRequirements?.property;
          const isErrorExcluded = excludeErrorDialog?.some((error: string) => {
            const getErrorProperty = error.split('__')?.[1] || '';
            const hasErrorProperty = getErrorProperty && failedPolicyProperty?.includes(getErrorProperty);

            const excludedCode = hasErrorProperty
              ? `${code}:${failedPolicyCode}__${getErrorProperty}`
              : `${code}:${detail?.code}`;

            return excludedCode.includes(error);
          });

          if (isErrorExcluded) {
            throw error;
          }
          const suppressError = suppressErrorDialogUrls?.some((url: string) =>
            error?.response?.config?.url?.match(url),
          );
          if (suppressError) {
            throw error;
          }
          let interpolatedMessage;
          const errorCodeKey = Object.keys(globalPreferences).find(key => key.split(',')?.includes(code));
          const errorCodesToIgnore = globalPreferences['errorCodesToIgnore']?.split(',');
          if (errorCodeKey && globalPreferences[errorCodeKey]) {
            interpolatedMessage = globalPreferences[errorCodeKey]
          } else if (errorCodesToIgnore && errorCodesToIgnore.includes(code)) {
            interpolatedMessage = message
          } else {
            interpolatedMessage = globalPreferences['defaultErrorMsg']
          }
          if (interpolatedMessage?.includes('{{') && !isEmpty(errorsInterpolation)) {
            interpolatedMessage = interpolatedMessage.replace(
              /{{([^{}]*)}}/g,
              (match: string, foundKey: string) => errorsInterpolation[foundKey] || match,
            );
          }
          if (interpolatedMessage) {
            showDialog({ message: interpolatedMessage, title: globalPreferences['errorHeaderLebel'] });
          }
        }
      },
    );
  }, [dispatch, config, globalPreferences]);
};

export default useInterceptor;
