import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { SETTINGS_ACTIONS } from '@redux/actions/';
import config from '@config/config';

type LanguagePickerProps = {
  languagePreference: {
    languages: string[];
  };
};

const LanguagePicker = ({ languagePreference }: LanguagePickerProps) => {
  const currentLocale = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const dispatch = useDispatch();
  const environmentEnum = config.getEnvironmentEnum();
  const permittedEnvironments = [Environment.LOCAL, Environment.DEVINT, Environment.SIT];

  const LanguagePicker = (e) => {
    dispatch({ type: SETTINGS_ACTIONS.SET_LOCALE, data: e.target.value })
  }

  if (!permittedEnvironments.includes(environmentEnum)) {
    return null;
  }

  return (
    <select
      value={currentLocale}
      onChange={(e) => LanguagePicker(e)}
      style={{
        position: 'absolute',
        right: '-4rem',
      }}
    >
      {
        languagePreference?.languages?.map((e) => <option key={`lang-${e}`}>{e}</option>)
      }
    </select>
  );
};

export default LanguagePicker;
