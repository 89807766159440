import React, { ReactNode } from 'react';
import { Switch as BaseSwitch, Route, Redirect, useLocation } from 'react-router-dom';
import useLabels from '@hooks/useLabels';
import useRenderPage from '@hooks/useRenderPage';
import { filter } from 'lodash';
import { Helmet } from 'react-helmet';
import flattenChildren from 'react-flatten-children';
import Login from '@components/Login/Login';
import Logout from '@components/Login/Logout';
import LoginCallBack from '../Login/LoginCallBack';
import NotFound from '@components/NotFound';
import AppWrapper from './AppWrapper';
import ContentRenderer from '@components/ContentRenderer';
import VinStatusPage from '@components/VinStatusPage';
import { ServiceOverviewPage } from '@components/ServiceOverview';
import { TermsAndConditionsPage } from '@components/TermsAndConditions';
import AccountEnrollment from '@components/AccountEnrollment/AccountEnrollment';
import VinSearch from '@components/VinSearch/VinSearch';
import { PageContent } from './AppWrapper';

import { SecureRoute } from './SecureRoute';
import { useNavigation } from '@components/Navigation';

const Switch = ({ children }: { children: ReactNode }) => <BaseSwitch>{flattenChildren(children)}</BaseSwitch>;
type AppRouterProps = {
  isLoginEnabled: boolean;
  pageContent?: PageContent;
};
function AppRouter({ isLoginEnabled, pageContent = {} }: AppRouterProps) {
  const { pagesList = [], navigationMachine } = pageContent;
  const location = useLocation();
  const RenderPage = useRenderPage(pageContent);

  const { paths } = useNavigation();

  const filterPageContentByPath = (path: string) =>
    filter(pagesList, ['path', path]).map((page) => page.contentSections)[0] || [];

  const currentPage = pagesList?.find((page) => {
    const availablePaths = page.path.split(', ');
    const correctLocation = location.pathname === 'home' ? '/' : location.pathname.replace('/', '');
    return availablePaths.includes(correctLocation);
  });
  const labels = useLabels(currentPage?.pageTitle);
  const titles = labels.getAllPrimaries();
  const [pageTitle] = Object.values(titles) || [''];

  return (
    <>
      {pageTitle && <Helmet defaultTitle={pageTitle as string} />}

      <Switch>
        {isLoginEnabled && (
          <>
            <Route exact path="/">
              <Redirect to="/profile" />
            </Route>
            <Route
              exact
              path="/registration"
              component={() => (
                <ContentRenderer name="registration" content={filterPageContentByPath('registration')} />
              )}
            />
            <Route exact path="/login" component={Login} />
            <Route exact path="/loginCallBack" component={LoginCallBack} />
            <Route exact path="/resetPassword" render={(props) => <RenderPage {...props} name="resetPassword" />} />
            <Route exact path="/set-password" render={(props) => <RenderPage {...props} name="setPassword" />} />
            <SecureRoute path="/logout" Component={Logout} />
            <SecureRoute
              path="/vinStatus"
              Component={VinStatusPage}
              componentProps={{ contentSections: filterPageContentByPath('vinStatus') }}
            />
            <SecureRoute path="/vinSearch" Component={VinSearch} />
            <SecureRoute
              path="/serviceOverview"
              Component={ServiceOverviewPage}
              componentProps={{ content: filterPageContentByPath('serviceOverview') }}
            />
            <SecureRoute
              path="/termsConditions"
              Component={TermsAndConditionsPage}
              componentProps={{ content: filterPageContentByPath('termsConditions') }}
            />
            <SecureRoute path="/accountEnrollment" Component={AccountEnrollment} componentProps={{ pagesList }} />
          </>
        )}
        {!isLoginEnabled && (
          <Route exact path="/">
            <Redirect to={'profile'} />
          </Route>
        )}
        <SecureRoute path={paths.map((path) => path.url)} Component={AppWrapper} componentProps={{ pageContent }} />

        <Route component={NotFound} />
      </Switch>
    </>
  );
}
export default AppRouter;
