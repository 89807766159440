import React, { ReactNode } from 'react';

import styles from './SubscriptionOverview.module.css';
import { formatDateByLocale } from '@cv/portal-common-lib/utils';

export type SubscriptionOverviewProps = {
  data: {
    vehicle: {
      name: string;
      vin: string;
    };
    services: {
      name: string;
      expiry: string;
    }[];
    defaultPkg: {
      expiry: string;
    };
  };
  vehicleInfo: ReactNode;
  labelExpiry: string;
  packagePrefixLabel: string;
  labelTrialExpiry: string;
};

function SubscriptionOverview({
  data,
  labelExpiry,
  packagePrefixLabel,
  labelTrialExpiry,
  vehicleInfo,
  ...restProps
}: SubscriptionOverviewProps) {
  return (
    <div className={styles.SubscriptionOverview} {...restProps}>
      {vehicleInfo}
      {data?.services.map((service) => (
        <div key={service.name} className={styles['SubscriptionOverview-service']}>
          <div className={styles['SubscriptionOverview-service-name']}>
            {packagePrefixLabel}
            {service.name}
          </div>
          <div className={styles['SubscriptionOverview-service-expiry']}>
            {labelExpiry} {formatDateByLocale(service.expiry)}
          </div>
        </div>
      ))}
      {data?.defaultPkg?.expiry && (
        <div className={styles['SubscriptionOverview-default-service']}>
          <div className={styles['SubscriptionOverview-service-expiry']}>
            {labelTrialExpiry}
            {formatDateByLocale(data?.defaultPkg?.expiry)}
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionOverview;
