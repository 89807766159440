import React from 'react';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { useSelector } from 'react-redux';

import { Breach } from '@cv/portal-rts-lib/history/models/history';

import styles from './AlertHistoryContent.module.css';

type AlertHistoryTableProps = {
  tableFirstColLabel: string;
  tableSecondColLabel: string;
  tableThirdColLabel: string;
  data: Breach[];
};

export default function AlertHistoryTable({
  tableFirstColLabel,
  tableSecondColLabel,
  tableThirdColLabel,
  data,
}: AlertHistoryTableProps) {
  const locale: string = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const dateFormatOptions = locale === 'es-MX' ? { locale: es } : null;

  return (
    <table className={styles['alert-history-table']}>
      <thead className={styles['alert-history-table-head']}>
        <tr className={styles['alert-history-table-row']}>
          <th className={styles['alert-history-table-head-cell']}>{tableFirstColLabel}</th>
          <th className={styles['alert-history-table-head-cell']}>{tableSecondColLabel}</th>
          <th className={styles['alert-history-table-head-cell']}>{tableThirdColLabel}</th>
        </tr>
      </thead>
      <tbody className={styles['alert-history-table-body']}>
        {data.map(({ alertId, name, breachDateTime }: Breach) => {
          const parseIsoStartDateTime = parseISO(breachDateTime);
          const formattedStartDate = format(parseIsoStartDateTime, 'MM/dd/yyyy', dateFormatOptions);
          const formattedStartTime = format(parseIsoStartDateTime, 'h:mm a');

          return (
            <tr className={styles['alert-history-table-body-row']} key={`${name}-${alertId}`}>
              <td className={styles['alert-history-table-data-cell']}>
                {/* <div>{alertLabel}</div> */}
                <div>{name}</div>
              </td>

              {/* TODO: replace hardcoded location */}
              <td className={styles['alert-history-table-data-cell']}>
                <div>8428 Sterling St</div>
                <div>Irving, TX, US, 75036</div>
              </td>
              <td className={styles['alert-history-table-data-cell']}>
                <div>{formattedStartDate}</div>
                <div>{formattedStartTime}</div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
