import { getFolders } from '@cv/portal-rts-lib/v0/information/folders';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { FoldersRequest } from '@cv/portal-rts-lib/v0/information/folders/models';

type GetFoldersRequestProps = {
  accessToken: string;
  vin: string;
};

const convertSpanishFoldersToEnglish = (folders: Array<{ folderName: string }>) => {
  return folders.map((folder) => {
    if (folder.folderName === "Planificador de viaje") {
      folder.folderName = "Journey Planner"
    }
    return folder;
  })
}

export default class PoiService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getFolders({ accessToken, vin }: GetFoldersRequestProps) {
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      data: {}
    } as FoldersRequest;

    const response = await getFolders(request);
    const data = convertSpanishFoldersToEnglish(response.data)
    return { data };
  }
}
