import { getOemName } from '@utils/oem';

const oemName = getOemName();

const tenantId: { [key: string]: string } = {
  NISSAN: 'nissanmx',
  INFINITI: 'infinitimx',
};

const getTenantId = () => {
  return tenantId[oemName] || '';
};

export { getTenantId };
