import React, { useState } from 'react';
import at from 'lodash/at';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

import useToggle from '@hooks/useToggle';
import ApiRequestPayload from '@types/ApiRequestPayload';
import { ModelConnector, FormConnector, RemovalConnector } from '@components/ApiConnectorHOCs/';
import InfoBoxEditableWrapper from './InfoBoxEditableWrapper';

type DataConnector = {
  label: string;
  fields: Array<string>;
  formInputName?: string;
  alternativeText?: string;
  alternativeTextCondition?: string;
  valueType?: "regular" | "tel";
  phoneFormat?: "national" | "international";
};

type InfoBoxConnectorProps = {
  data: ApiRequestPayload | Array<ApiRequestPayload>;
  infoItems: Array<DataConnector>;
  numberOfColumns: '2' | '3';
  editItemForm?: Object;
  formConnector?: {
    api: string;
  };
  onSubmit?: (data: ApiRequestPayload) => Promise<void>;
  onRemove?: (data: ApiRequestPayload) => Promise<void>;
  formInitialValues?: Array<DataConnector>;
  removalTitle?: string;
  removalQuestion?: string;
  removalConfirmationLabel?: string;
  removalCancelLabel?: string;
  afterSubmit?: () => void;
  afterCancel?: () => void;
};

const dummyFormatter = <T extends unknown>(value: T): T => value;

function InfoBoxConnector({
  data,
  infoItems,
  editItemForm,
  numberOfColumns,
  formInitialValues = [],
  onSubmit,
  onRemove,
  removalTitle,
  removalQuestion,
  removalConfirmationLabel,
  removalCancelLabel,
  afterSubmit,
  afterCancel,
  ...rest
}: InfoBoxConnectorProps) {
  const [isFormOpen, toggleForm] = useToggle(false);
  const [formIndex, setFormIndex] = useState(-1);

  console.log(infoItems);

  const openForm = (index?: number = 0) => {
    toggleForm(true);
    setFormIndex(index);
  };

  const submitForm = async (data: ApiRequestPayload) => {
    if (afterSubmit) {
      afterSubmit();
    }
    if (onSubmit) {
      await onSubmit(data);
    }
    toggleForm(false);
  };

  const removeItem = async (data: ApiRequestPayload) => {
    toggleForm(false);
    if (onRemove) {
      await onRemove(data);
    }
  };

  const cancelForm = () => {
    toggleForm(false);
    if (afterCancel) {
      afterCancel();
    }
  };

  const getInfoItem = (item: DataConnector, data: ApiRequestPayload) => {
    const value = item.alternativeTextCondition && data[item.alternativeTextCondition]
      ? item.alternativeText
      : at(data, item.fields);

    const formatter = item?.valueType === "tel" 
      ? item?.phoneFormat === "national" 
        ? formatPhoneNumber : formatPhoneNumberIntl
      : dummyFormatter;

    return ({
      label: item.label,
      value: Array.isArray(value) 
        ? value.map(val => formatter(val) || val).join(' ') 
        : formatter(value),
    });
  };

  const getFormValues = (data: ApiRequestPayload, formBindings: Array<DataConnector>) => {
    const result = {};
    formBindings.forEach((binding) => {
      const values = at(data, binding.fields);
      result[binding.formInputName] = values.length > 1 ? values.join(' ') : values[0];
    });
    result._id = data._id;
    return result;
  };

  if (Array.isArray(data)) {
    const resultArray = data.map((record: ApiRequestPayload) => infoItems.map((item) => getInfoItem(item, record)));
    const initialValuesArray = data.map((record: ApiRequestPayload) => getFormValues(record, formInitialValues));
    return resultArray.map((result, i) => (
      <InfoBoxEditableWrapper
        isFormOpen={isFormOpen && i === formIndex}
        isEditable={!!editItemForm}
        isRemovable={!!onRemove}
        onFormOpen={openForm}
        onFormClose={cancelForm}
        onFormConfirm={submitForm}
        onRemove={removeItem.bind(null, initialValuesArray[i])}
        formInitialValues={initialValuesArray[i]}
        displayedValues={result}
        numberOfColumns={numberOfColumns}
        modalHeader={removalTitle}
        modalText={removalQuestion}
        modalConfirm={removalConfirmationLabel}
        modalCancel={removalCancelLabel}
        showPreview={true}
        index={i}
        key={`infoBoxContent-${i}`}
        {...editItemForm}
        {...rest}
      />
    ));
  }

  const resultArray = infoItems.map((item) => getInfoItem(item, data));
  const initialValues = getFormValues(data, formInitialValues);
  return (
    <InfoBoxEditableWrapper
      isFormOpen={isFormOpen}
      isEditable={!!editItemForm}
      isRemovable={!!onRemove}
      onFormOpen={openForm}
      onFormClose={cancelForm}
      onFormConfirm={submitForm}
      onRemove={removeItem.bind(null, initialValues)}
      formInitialValues={initialValues}
      displayedValues={resultArray}
      numberOfColumns={numberOfColumns}
      modalHeader={removalTitle}
      modalText={removalQuestion}
      modalConfirm={removalConfirmationLabel}
      modalCancel={removalCancelLabel}
      showPreview={false}
      {...editItemForm}
      {...rest}
    />
  );
}

export default ModelConnector(FormConnector(RemovalConnector(InfoBoxConnector)));
