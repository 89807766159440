import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import lock from '@assets/lock.svg';
import unlock from '@assets/unlock-door-status.svg';
import lockUnavailable from '@assets/lock-unavailable-status.svg';
import tirePressure from '@assets/tire_pressure.svg';
import tirePressureUnavailable from '@assets/tire_pressure_unavailable.svg';

import styles from './DoorsAndTires.module.css';
import { useSelector } from 'react-redux';
import { Position, MilType, LockType } from '@cv/portal-rts-lib/health/enums';
import wireFrameModels from '@utils/wire-frame-model';

export type DoorsAndTiresProps = {
  DoorsLabel: string;
  TiresLabel: string;
  PressureUnavailableLabel: string;
  CheckPressureLabel: string;
  PressureOkLabel: string;
  DoorsUnavailableLabel:string
};

type Lock = {
  type: string,
  status: string
}

type PositionType = {
  position: string;
  status: string;
};

function DoorsAndTires(props: DoorsAndTiresProps) {
  const [vehicleImages, setVehicleImages] = useState<any>([]);
  const {
    vehicle,
    vehicleHealth: { data: vehicleStatus },
  } = useSelector(({ vehicleReducer }) => vehicleReducer);

  const getDoorAndTireVar = (key: 'locks' | 'mil' | 'tyres', type: string) => {
    if (vehicleStatus && vehicleStatus[key]) {
      if (key === 'tyres') {
        return vehicleStatus[key].find((item: PositionType) => item.position === type);
      }
      return vehicleStatus[key].find((item: Lock) => item.type === type);
    }

    return null;
  };

  const frontLeftTire = getDoorAndTireVar('tyres', Position.Front_Left);
  const frontRightTire = getDoorAndTireVar('tyres', Position.Front_Right);
  const rearLeftTire = getDoorAndTireVar('tyres', Position.Rear_Left);
  const rearRightTire = getDoorAndTireVar('tyres', Position.Rear_Right);

  const renderTirePressureValue = (value: string) => {
    return <span>{value}</span>;
  };

  const renderTirePressureElement = (status: string, type: 'icon' | 'label') => {
    switch (type) {
      case 'icon':
        switch (status) {
          case '0':
          case '1':
            return tirePressure;
          default:
            return tirePressureUnavailable;
        }
      case 'label':
        switch (status) {
          case '0':
            return props.PressureOkLabel;
          case '1':
            return props.CheckPressureLabel;
          default:
            return props.PressureUnavailableLabel;
        }
    }
  };

  const getCarPartImages = () => {
    const wireFrames = wireFrameModels(vehicle?.model?.toUpperCase());
    return Promise.all(wireFrames).then((images) => {
      const imgs = images.map((img) => img.default);
      setVehicleImages(imgs);
    });
  };

  useEffect(() => {
    getCarPartImages();
  }, []);

  const renderEngineHoodCarPart = () => {
    const engineHoodLock = getDoorAndTireVar('locks', LockType.Engine_Hood);
    return engineHoodLock?.type && engineHoodLock?.status === 'open' ? (
      <img src={vehicleImages[1]} className={styles['CarImage']} />
    ) : null;
  };

  const renderHatchCarPart = () => {
    const hatchLock = getDoorAndTireVar('locks', LockType.Hatch);
    return hatchLock?.type && hatchLock?.status === 'open' ? (
      <img src={vehicleImages[2]} className={styles['CarImage']} />
    ) : null;
  };

  const renderFrontLeftDoorCarPart = () => {
    const frontLeftDoorLock = getDoorAndTireVar('locks', LockType.Door_Front_Left);
    return frontLeftDoorLock?.type && frontLeftDoorLock?.status === 'open' ? (
      <img src={vehicleImages[3]} className={styles['CarImage']} />
    ) : (
      <img src={vehicleImages[4]} className={styles['CarImage']} />
    );
  };

  const renderFrontRightDoorCarPart = () => {
    const frontRightDoorLock = getDoorAndTireVar('locks', LockType.Door_Front_Right);
    return frontRightDoorLock?.type && frontRightDoorLock?.status === 'open' ? (
      <img src={vehicleImages[3]} className={clsx(styles['CarImage'], styles['ImageInverted'])} />
    ) : (
      <img src={vehicleImages[4]} className={clsx(styles['CarImage'], styles['ImageInverted'])} />
    );
  };

  const renderRearLeftDoorCarPart = () => {
    const rearLeftDoorLock = getDoorAndTireVar('locks', LockType.Door_Rear_Left);
    return rearLeftDoorLock?.type && rearLeftDoorLock?.status === 'open' ? (
      <img src={vehicleImages[5]} className={styles['CarImage']} />
    ) : (
      <img src={vehicleImages[6]} className={styles['CarImage']} />
    );
  };

  const renderRearRightDoorCarPart = () => {
    const rearRightDoorLock = getDoorAndTireVar('locks', LockType.Door_Rear_Right);
    return rearRightDoorLock?.type && rearRightDoorLock?.status === 'open' ? (
      <img src={vehicleImages[5]} className={clsx(styles['CarImage'], styles['ImageInverted'])} />
    ) : (
      <img src={vehicleImages[6]} className={clsx(styles['CarImage'], styles['ImageInverted'])} />
    );
  };

  const tiresPressureStatus = getDoorAndTireVar('mil', MilType.Tyre_Pressure);
  const overallLockStatus = getDoorAndTireVar('locks', LockType.Overrall_Lock);

  const renderDoorLockIcon = () => {
    switch (overallLockStatus?.status) {
      case 'unlocked':
      case 'Desbloqueado':
        return <img src={unlock} className={styles['DoorsAndTiresIcon']} />;
      case 'locked':
      case 'Bloqueado':
        return <img src={lock} className={styles['DoorsAndTiresIcon']} />;
      default:
        return <img src={lockUnavailable} className={styles['DoorsAndTiresIcon']} />;
    }
  };

  return (
    <div className={styles['DoorsAndTiresContainer']}>
      <div className={styles['DoorsAndTiresSection']}>
        <div className={styles['DoorsAndTiresSubSection']}>
          {renderDoorLockIcon()}
          <span>{props.DoorsLabel}</span>
          <span>{overallLockStatus?.status || props.DoorsUnavailableLabel}</span>
        </div>

        <div className={styles['Horizontal-separator']}></div>

        <div className={styles['DoorsAndTiresSubSection']}>
         <img
            src={renderTirePressureElement( tiresPressureStatus ? tiresPressureStatus.status : '', 'icon' )}
            className={styles['DoorsAndTiresIcon']}
          />
          <div>{props.TiresLabel}</div>
          <div className={styles['Pressure']}>
            {renderTirePressureElement( tiresPressureStatus ? tiresPressureStatus.status : '', 'label')}
          </div>
        </div>
      </div>

      <div className={styles['Separator']}></div>

      <div className={clsx(styles['TiresSection-pressure'], styles['TiresSection-pressure--justify-end'])}>
        <span className={clsx({ [styles['TiresSection-pressure--high']]: frontLeftTire?.status !== '0' })}>
          {frontLeftTire?.value ? renderTirePressureValue(frontLeftTire?.value) : '0'}
        </span>
        <span className={clsx({ [styles['TiresSection-pressure--high']]: rearLeftTire?.status !== '0' })}>
          {rearLeftTire?.value ? renderTirePressureValue(rearLeftTire?.value) : '0'}
        </span>
      </div>

      <div className={styles['CarSection']}>
        <img src={vehicleImages[0]} className={styles['CarImage']} />

        {LockType.Engine_Hood && renderEngineHoodCarPart()}
        {LockType.Hatch && renderHatchCarPart()}
        {LockType.Door_Front_Left && renderFrontLeftDoorCarPart()}
        {LockType.Door_Front_Right && renderFrontRightDoorCarPart()}
        {LockType.Door_Rear_Left && renderRearLeftDoorCarPart()}
        {LockType.Door_Rear_Right && renderRearRightDoorCarPart()}
      </div>

      <div className={clsx(styles['TiresSection-pressure'], styles['TiresSection-pressure--justify-start'])}>
        <span className={clsx({ [styles['TiresSection-pressure--high']]: frontRightTire?.status !== '0' })}>
          {frontRightTire?.value ? renderTirePressureValue(frontRightTire?.value) : '0'}
        </span>
        <span className={clsx({ [styles['TiresSection-pressure--high']]: rearRightTire?.status !== '0' })}>
          {rearRightTire?.value ? renderTirePressureValue(rearRightTire?.value) : '0'}
        </span>
      </div>
    </div>
  );
}

export default DoorsAndTires;
