type DataType = {
  apiConnector?: any;
  componentType: string;
  contentList: Array<any>;
  location: string;
  name: string;
};

type LabelContent = {
    labelKey: string;
    labelValue: string;
}

const filterLabelDictionary = (content: Array<DataType>, location: string) => {
  const labelsContent = content
    ?.filter((item: any) => item.location === location)[0]
    ?.contentList?.find(({ componentType }) => componentType === 'portalLabelDictionary');
  return labelReducer(labelsContent);
};

const filterPortalApiConnector = (content: Array<DataType>, location: string) => {
  return content
    ?.filter((item: any) => item.location === location)[0]
    ?.apiConnector
}

const labelReducer = (labelsContent: any) => {
  const labels = labelsContent?.content?.reduce(
    (acc: Record<string, string>, labelContent: LabelContent) => ({
      ...acc,
      [labelContent.labelKey]: labelContent.labelValue,
    }),
    {} as Record<string, string>,
  );
  return labels || {};
};

export { filterLabelDictionary, labelReducer, filterPortalApiConnector };
