import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@components/Theme';
import { FaInfoCircle } from 'react-icons/fa';
import { format } from 'date-fns';
import clsx from 'clsx';

import BingMap from './Map';
import MapWindowHome from './MapWindowHome';
import MapWindowDestinations from './MapWindowDestinations';
import MapWindowMonitor from './MapWindowMonitor';
import useToggle from '@hooks/useToggle';
import MapWindowToggle from "./MapWindowToggle";
import nissanCarIcon from '@assets/nissan-car-location-selected.png';
import infinitiCarIcon from '@assets/infiniti-car-location-selected.png';

import { useApi } from '@api';
import { myCarFinderLabels } from './constants';
import { getContentContext } from '@components/App/AppWrapper';
import { useNavigation } from '@components/Navigation';
import { filterLabelDictionary } from '@utils/filter-label-dictionary';
import { PointOfInterest } from './types';
import { clearMapPins, clearMapBoundaries } from '@utils';

const iconForTheme = {
  nissan: nissanCarIcon,
  infiniti: infinitiCarIcon,
};

const MapWrapper = React.memo(() => {
  const { currentPath } = useNavigation();
  const location = currentPath.page;

  const [collapsed, toggleMapWindow] = useToggle(false);
  const content: any = getContentContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const api = useApi();
  const [pushPins, addToPushPins] = useState<PointOfInterest[]>();
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [ , saveDirectionsManager] = useState(null)
  const pins = useSelector(({ mapReducer }) => mapReducer.pointsOfInterest);
  const userLocation = useSelector(({ mapReducer }) => mapReducer.userLocation);
  const vehicleLocation = useSelector(({ mapReducer }) => mapReducer.vehicleLocation);
  const boundaryCoordinates = useSelector(({ boundaryReducer }) => boundaryReducer.boundaryCoordinates);
  const multipleBoundaryCoordinates = useSelector(({ boundaryReducer }) => boundaryReducer.multipleBoundaryCoordinates);
  const locale = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);

  // These labels are living in Portal Map Overlay Destinations Content since the map wrapper sits over top of multiple pages
  const vehicleLocationTextOptions = filterLabelDictionary(content, 'destinations');
  const { FindVehicleText, FindingVehicleText, FoundVehicleText } = vehicleLocationTextOptions;

  const [vehicleLocationText, setVehicleLocationText] = useState(FindVehicleText);

  // make sure SearchManager is loaded and available before calling the callback fn
  const withSearchManager = (Maps, callback) => {
    if (window.bingMapsLoaded && !window.Microsoft?.Maps?.Search?.SearchManager) {
      // load search manager, then run the callback
      Maps.loadModule('Microsoft.Maps.Search', callback);
    } else {
      callback();
    }
  };

  const setPinToCenter = (location?: object) => {
    // Set a pin to the map and center the view around it
    if (location && window.bingMapsLoaded) {
      const Maps = window.Microsoft.Maps;
      const { latitude, longitude, formattedAddress, lastUpdated } = location;

      const center = {
        center: { latitude, longitude },
        options: {
          anchor: new Maps.Point(12, 39),
          icon: iconForTheme[theme.get('name', 'infiniti').toLowerCase()],
          enableClickedStyle: true,
        },
        metadata: {
          title: `${vehicle.make} ${vehicle.model}`,
          description: `${formattedAddress} <br/> ${lastUpdated}`,
        },
      };
      const pins = new Array(center);
      setPins(pins);
    }
  };

  const showUserLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      dispatch({ type: 'SET_USER_LOCATION', userLocation: position.coords })
    });
  };

  const getCarLocation = async (label: string) => {
    try {
      const { svcReqId } = await api.getServiceRequestId(api.storeService.getAccessToken());
      const { locations: [location] = [], svcRequests: [requestInfo] } = await api.getCurrentVehicleLocation(api.storeService.getAccessToken(), svcReqId);
      const { latitude, longitude } = location.coordinates;
      const lastUpdated = format(new Date(requestInfo.statusChangedTime), 'dd MMMM, yyyy, @ hh:mm a')

      const Maps = window.Microsoft.Maps;
      withSearchManager(Maps, () => {
        const searchManager = new Maps.Search.SearchManager(map);
        const reverseGeocodeRequestOptions = {
          location: new Maps.Location(latitude, longitude),
          callback: (answer: { address: { formattedAddress: string }}) => {
            const vehicleLocation = { latitude, longitude, formattedAddress: answer.address.formattedAddress, lastUpdated }
            dispatch({ type: 'SET_USER_LOCATION', userLocation: null })
            dispatch({ type: 'SET_VEHICLE_LOCATION', vehicleLocation });
            setVehicleLocationText(label);
            setPinToCenter(vehicleLocation);
          }
        };
        searchManager.reverseGeocode(reverseGeocodeRequestOptions);
      });
      } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      console.log('ERROR', err);
      showUserLocation();
    }
  };

  useEffect(() => {
    // When the map is loaded get the vehicle location if it hasn't already been gotten
    if (map) {
      getCarLocation(FindVehicleText);
    }
  }, [map]);

  useEffect(() => {
    setPins(pins);
  }, [pins]);

  const setPins = (pins: PointOfInterest[]) => {
    addToPushPins(pins);
  };

  useEffect(() => {
    resetMap();
  }, [location]);

  const handleCarLocation = async (e: any) => {
    e.target.classList.add('loading');
    setVehicleLocationText(FindingVehicleText);
    await getCarLocation(FoundVehicleText);
    e.target.classList.remove('loading');
  };

  const handleBoundaryChange = (radius: number) => {
    const radiusByLocale = locale === 'en-US' ? radius / 1.60934 : radius;
    dispatch({
      type: 'SET_RADIUS',
      radius: radiusByLocale,
    });
  };

  const handleRouteSummary = (summary: any) => {
    dispatch({
      type: 'SET_ROUTE_SUMMARY',
      routeSummary: summary,
    });
  };

  const setMapCenter = (mapCenter: object) => {
    dispatch({
      type: 'SET_MAP_CENTER',
      mapCenter: mapCenter,
    });
  };

  const handleViewChange = (e: any) => {
    if (e.location) {
      const { latitude, longitude } = e.location;
      setMapCenter({ latitude, longitude });
    }
  };

  const setDirectionsManager = (directionsManager: any) => {
    saveDirectionsManager(directionsManager);
  }

  const renderMap = () => {
    if (map) {
      const center = map.getCenter();
      dispatch({ type: "SET_MAP", map });
      setMapCenter(center);
    }
    const boundaryColor = content
      .find((item: object) => item.location === 'destinations')
      .contentList[0].content.find((item: object) => item.name === 'Boundary Color');
    const mapDirections = location === 'destinations' ? directions : null;
    const unit = locale === 'es-MX' ? 'km' : 'miles';
    const hasCarFinderService = vehicle?.activeServices?.some((service: string) => myCarFinderLabels.includes(service));

    return (
      <div style={{ display: 'flex' }}>
        <BingMap
          setMap={setMap}
          height="570px"
          width="100%"
          mapOptions={{
            showMapTypeSelector: false,
            enableInertia: false,
            maxZoom: 16,
            navigationBarOrientation: 'horizontal',
          }}
          viewOptions={{
            center: boundaryCoordinates,
          }}
          pushPins={pushPins}
          directions={mapDirections}
          location={location}
          boundaryLocation={boundaryCoordinates}
          multipleBoundaryLocations={multipleBoundaryCoordinates}
          handleBoundaryChange={handleBoundaryChange}
          userLocation={userLocation}
          setRouteSummary={handleRouteSummary}
          unit={unit}
          handleViewChange={handleViewChange}
          setManagerOfDirections={setDirectionsManager}
          boundaryColor={boundaryColor?.labelValue}
        />
        {vehicle?.activeServices && hasCarFinderService && (
          <div>
            <div className="map__car-location-description">
              <FaInfoCircle /> {vehicleLocationText}
            </div>
            <button className="map__car-location-button" onClick={handleCarLocation}></button>
          </div>
        )}
      </div>
    );
  };

  const resetMap = () => {
    dispatch({ type: 'RESET_MULTIPLE_BOUNDARY_COORDINATES' });
    dispatch({ type: 'SET_BOUNDARY_COORDINATES', boundaryCoordinates: null });
    clearMapPins(map);
    clearMapBoundaries(map);
    setDirections(null);
    if (vehicleLocation && map) {
      setPinToCenter(vehicleLocation);
      map.setView({ center: vehicleLocation, zoom: 16 });
    }
    if (!vehicleLocation) {
      showUserLocation();
    }
  }

  const renderMapWindow = () => {
    // TODO: pass prop to MapWindowHome and MapWindowDestinations when content type and fields are ready.
    const getContentByLocation = content.find((item: any) => item.location === location);
    const formatSecurityPinContent = () => {
      const modalContent = content
        ?.filter((item: any) => item.location === location)[0]
        ?.contentList?.find(({ componentType }: any) => componentType === 'portalSecurityPinModal');
      return modalContent;
    };

    if (location === 'home') {
      const labels = filterLabelDictionary(content, location);
      return (
        <MapWindowHome labels={labels} securityPinContent={formatSecurityPinContent()} resetMap={resetMap} />
      );
    }

    if (location === 'destinations') {
      const destinationLabels = filterLabelDictionary(content, location);
      return <MapWindowDestinations labels={destinationLabels} setDirections={setDirections} resetMap={resetMap} setPinToCenter={setPinToCenter} />;
    }

    if (location === 'monitor') {
      return <MapWindowMonitor content={getContentByLocation} />;
    }
  };

  return (
    <div className="MapWrapper">
      {/* BING MAPS RENDERS ROUTE INFORMATION - WE HIDE THIS AND PULL THE HTML TO DISPLAY ROUTE INFO */}
      <div className="itinerary-list-container">
        <div id="directionsItinerary"></div>
      </div>
      <div className="map-wrapper-inner">
        {renderMap()}
        <div className={clsx('map-overlay', { 'map-overlay--collapsed': collapsed })}>
          {renderMapWindow()}
          <MapWindowToggle collapsed={collapsed} onToggle={toggleMapWindow} />
        </div>
      </div>
    </div>
  );
});

export default MapWrapper;
