import React from 'react';

import { ComponentMapping } from '@components/component-mappings';
import ContentRenderer from '@components/ContentRenderer';
import useLabels, { Label } from '@hooks/useLabels';

export { Label } from '@hooks/useLabels';

export type LabelDictionaryProps = {
  name: string;
  component?: ComponentMapping;
  content: Label[];
};

function LabelDictionary({ name, component, content }: LabelDictionaryProps) {
  const labels = useLabels(content);

  if (!component) {
    return null;
  }

  return <ContentRenderer name={name} content={[{ componentType: component, labels: labels.getAll() }]} />;
}

export default LabelDictionary;
