import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { useTheme } from '@components/Theme';

import './MapWindow.css';

type TimerProps = {
  labels: any;
  updatedTimerValue: (result?: any) => void;
};

const MapCountDownTimer = ({ labels, updatedTimerValue }: TimerProps) => {
  const theme = useTheme();
  const timerTheme = {
    color: theme.get('count-down-window-timer-color'),
    trailColor: theme.get('count-down-window-timer-trail-color'),
  };
  const { color, trailColor } = timerTheme;

  const timerProps = {
    isPlaying: true,
    size: 95,
    strokeWidth: 4,
    trailColor,
  };

  const renderTime = ({ remainingTime }: any) => {
    const min = Math.ceil(remainingTime / 60);
    if (remainingTime === 0) {
      updatedTimerValue(remainingTime);
    }
    return (
      <div className="map-window-count-timer">
        <span className="map-window-time_left">{labels?.timeLeft}</span>
        <span className="map-window-time-mins">{min}</span>
        <span className="map-window-time-mins-label">{labels?.min}</span>
        <span className="map-window-time-stop-label">{labels?.pressToStop}</span>
      </div>
    );
  };
  return (
    <CountdownCircleTimer {...timerProps} colors={color} duration={600}>
      {renderTime}
    </CountdownCircleTimer>
  );
};

export default MapCountDownTimer;
