// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ui3HpUymWvH8F7O6f_TX1 {\n  --button-background-color: var(--theme-color-primary, #c71444);\n\n  display: block;\n  text-transform: uppercase;\n  cursor: pointer;\n  line-height: 1.5;\n  padding: 0.375rem 0.75rem;\n  opacity: 1;\n  border: 1px solid var(--button-background-color);\n  font-family: inherit;\n}\n\n.Ui3HpUymWvH8F7O6f_TX1:disabled {\n  opacity: 0.5;\n  cursor: default;\n}\n\n._24HBLQ2uUTtag-NfCb0FLu {\n  color: var(--button-background-color);\n  background-color: transparent;\n}\n\n._33wAR04Su2STqJXWdGQXDl {\n  color: var(--theme-color-white);\n  background-color: var(--button-background-color);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.css"],"names":[],"mappings":"AAAA;EACE,8DAA8D;;EAE9D,cAAc;EACd,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,UAAU;EACV,gDAAgD;EAChD,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,qCAAqC;EACrC,6BAA6B;AAC/B;;AAEA;EACE,+BAA+B;EAC/B,gDAAgD;AAClD","sourcesContent":[".button {\n  --button-background-color: var(--theme-color-primary, #c71444);\n\n  display: block;\n  text-transform: uppercase;\n  cursor: pointer;\n  line-height: 1.5;\n  padding: 0.375rem 0.75rem;\n  opacity: 1;\n  border: 1px solid var(--button-background-color);\n  font-family: inherit;\n}\n\n.button:disabled {\n  opacity: 0.5;\n  cursor: default;\n}\n\n.outlined {\n  color: var(--button-background-color);\n  background-color: transparent;\n}\n\n.filled {\n  color: var(--theme-color-white);\n  background-color: var(--button-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Ui3HpUymWvH8F7O6f_TX1",
	"outlined": "_24HBLQ2uUTtag-NfCb0FLu",
	"filled": "_33wAR04Su2STqJXWdGQXDl"
};
export default ___CSS_LOADER_EXPORT___;
