import { VehicleResponse, VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { VehicleDetails } from '../types/vehicleDetails';
import { SubscribedServices } from '../types';

type VehicleFormatter = (data: VehicleResponse, subscribedServices: SubscribedServices[]) => VehicleDetails;

export const formatVehicleDetails: VehicleFormatter = (data, subscribedServices = []) => {
  const vehicleServices: VehicleServiceDetails[] = [...data?.allVehicleServiceDetails.vehicleServiceDetails].sort(
    (serviceObj1: VehicleServiceDetails, serviceObj2: VehicleServiceDetails) =>
      (serviceObj1.displayOrder ? serviceObj1.displayOrder : 999) -
      (serviceObj2.displayOrder ? serviceObj2.displayOrder : 999),
  ); //Few of services don't have displayOrder, setting to max displayOrder
  return {
    ...data,
    services:
      vehicleServices.reduce((serviceList: string[], serviceObj: VehicleServiceDetails) => {
        if (
          serviceObj.customerFacing &&
          checkIsSubscribed(subscribedServices, data.vehicleId, serviceObj.vehicleServiceId) &&
          serviceList.indexOf(serviceObj.marketingName) == -1
        ) {
          serviceList.push(serviceObj.marketingName);
        }
        return serviceList;
      }, []) || [],
  };
};

const checkIsSubscribed = (subscribedServices: SubscribedServices[], vehicleId: string, serviceId: string) => {
  return subscribedServices
    .filter((subscribedService) => subscribedService.vehicleId === vehicleId)
    .flatMap((subscribedService) => subscribedService.subscribedServiceIds)
    ?.includes(serviceId);
};
