import { TOKEN_EVENTS } from '@redux/actions/token';

export interface ITokenState {
  refreshToken?: string;
}

const initState: ITokenState = {
  refreshToken: '',
};

export interface ITokenAction {
  type: TOKEN_EVENTS;
  payload: any;
}

export default (state: ITokenState = initState, action: ITokenAction) => {
  switch (action.type) {
    case TOKEN_EVENTS.SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload
      };
    default:
      return state;
  }
};
