// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3F7sVRn_ai2vF9SP2Id3wv {\n  background: #eee;\n}\n\n._17_RwJ0ukhip2pDRcB16iY {\n  font-size: 16px;\n}\n\n.CPx0say-6hukvlPcle7xq > *:not(:first-child) {\n  margin-top: 25px;\n}\n\n.CPx0say-6hukvlPcle7xq p {\n  margin: 0;\n  font-size: 12px;\n  line-height: 1.42857143;\n  letter-spacing: 0.14px;\n}\n\n.CPx0say-6hukvlPcle7xq p:not(:last-child) {\n  margin-bottom: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Faq/Faq.modules.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".accordion {\n  background: #eee;\n}\n\n.accordion-title {\n  font-size: 16px;\n}\n\n.root > *:not(:first-child) {\n  margin-top: 25px;\n}\n\n.root p {\n  margin: 0;\n  font-size: 12px;\n  line-height: 1.42857143;\n  letter-spacing: 0.14px;\n}\n\n.root p:not(:last-child) {\n  margin-bottom: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "_3F7sVRn_ai2vF9SP2Id3wv",
	"accordion-title": "_17_RwJ0ukhip2pDRcB16iY",
	"root": "CPx0say-6hukvlPcle7xq"
};
export default ___CSS_LOADER_EXPORT___;
