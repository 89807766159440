import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ContentRenderer from "@components/ContentRenderer";
import MapWrapper from "@components/Map/MapWrapper";
import { GlobalTheme } from "@components/Theme";
import { HeaderProps } from "@components/Header";
import config from "@config/config";
import { isServiceSubscribed } from "@utils/checkIsServiceSubscribed";

import './App.css';
import { DialogWithCloser } from '../Dialog';
import { GlobalPreferencesContent } from '@components/GlobalPreferences';
import { useNavigation } from '@components/Navigation';

export type PageContent = {
  theme?: GlobalTheme[];
  header?: HeaderProps['content'][];
  mapOverlayContainer?: Array<any>;
  contentSections?: Array<any>;
  footerContent?: Array<any>;
  pagesList?: Array<any>;
  globalPreferences?: GlobalPreferencesContent[];
  navigationMachine?: {
    id: string;
    states: object;
    initial: string;
  };
};

export type AppWrapperProps = {
  pageContent?: PageContent;
  history: any;
  location: any;
  send: any;
  paths: Array<string>;
};

const ContentContext = React.createContext({});
export const getContentContext = () => React.useContext(ContentContext);

const PAGES_WITH_MAP = ['home', 'monitor', 'destinations'];

const AppWrapper = React.memo(({ history, location, pageContent = {} }: AppWrapperProps) => {
  const { mapOverlayContainer = [], pagesList } = pageContent;
  const isMapEnabled = config.get<boolean>('mapEnabled', true);
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);

  const { paths, currentPath } = useNavigation();

  // On load if the path is not the initial state then update the state
  useEffect(() => {
    updateNavState(location.pathname);
  }, []);

  const updateNavState = (pathname: string) => {
    if (pathname !== location.pathname && paths.some(path => path.url === pathname)) {
      history.push(pathname);
    }
  };

  const renderPages = () => {
    const correctLocation = currentPath.page === 'home' ? '/' : currentPath.page;

    if (pagesList) {
      return pagesList.map((page: any) => {
        const availablePaths = page.path.split(', ');
        if (availablePaths.includes(correctLocation)) {
          return (
            <React.Fragment key={page.path}>
              {page.serviceTags && !isServiceSubscribed(page.serviceTags, vehicle?.activeServices) && (
                <DialogWithCloser {...page.modalContent} />
              )}
              <ContentRenderer name={page.name} content={page.contentSections} key={page.name} />
            </React.Fragment>
          );
        }
      });
    }
  };

  const renderMapWrapper = () => {
    const visible = PAGES_WITH_MAP.includes(currentPath.page);
    return isMapEnabled && visible ? <MapWrapper /> : null;
  };

  return (
    <ContentContext.Provider value={mapOverlayContainer}>
      {renderMapWrapper()}
      {renderPages()}
    </ContentContext.Provider>
  );
});

export default AppWrapper;
