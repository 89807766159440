import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { es } from 'date-fns/locale';

import { Tab, TabItem } from './TempDestinationTab';
import ModalContainer from '@components/ModalContainer/';
import useIsoCountries from '@hooks/useIsoCountries';

import pharmacy from '../../assets/pharm.svg';
import bluePin from '../../assets/pin.png';
import markerIcon from '../../assets/Pin_select.png';

import { enterKeyPressed, itemInFavorites, formatPins } from '../../utils';

import { SavedLocation } from './types';

import { useApi } from '@api';
import { setLoadingStatus } from '@redux/actions/loading';

import { CgClose, CgSearch } from 'react-icons/cg';
import { BiDollar } from 'react-icons/bi';
import { RiGasStationLine, RiRestaurantFill, RiShoppingBagLine } from 'react-icons/ri';
import {
  REMOVE_FROM_FAVORITES,
  SET_FAVORITES,
  SET_POINTS_OF_INTEREST,
  SET_SELECTED_POINT_OF_INTEREST,
  SET_SENT_TO_CAR,
} from './constants';
import FavoriteButton from '@components/FavoriteButton';
import './MapWindow.css';
import config from '@config/config';
import { getOemName } from '@utils/oem';

type MapWindowDestinationsOverlayProps = {
  labels: {
    DestinationsBodyText: string;
    DestinationsTitle: string;
    FavoritesTab: string;
    InputPlaceholder: string;
    SentToCarTab: string;
    SentToCarModalTitle: string;
    SentToCarModalBody: string;
    SendToCarButton: string;
    PoiSearchErrorBody: string;
    PoiSearchErrorTitle: string;
    SentToCarPreposition: string;
    PoiSearchErrorLine1: string;
    PoiSearchErrorLine2: string;
  };
  resetMap: () => void;
};

export default function MapWindowDestinationsOverlay({ labels, resetMap }: MapWindowDestinationsOverlayProps) {
  const dispatch = useDispatch();
  const api = useApi();
  const countries = useIsoCountries();
  const [descriptionDisplayed, setDescriptionDisplayed] = useState(true);
  const [poiItemsDisplayed, setPoiItemsDisplayed] = useState(false);
  const [destinationInput, setDestinationInput] = useState('');
  const [hideAllTabs, setHideAllTabs] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [poiError, setPoiError] = useState(false);
  const poiItems = useSelector(({ mapReducer }) => mapReducer.pointsOfInterest);
  const selectedPointOfInterest = useSelector(({ mapReducer }) => mapReducer.selectedPointOfInterest);
  const favorites = useSelector(({ mapReducer }) => mapReducer.favorites);
  const folders = useSelector(({ mapReducer }) => mapReducer.folders);
  const sentToCar = useSelector(({ mapReducer }) => mapReducer.sentToCar);
  const locale = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const oemsToSendChannelIdInDestinationReq = config.get<Array<string>>('oemsToSendChannelIdInDestinationReq');
  const oemName = getOemName();

  useEffect(() => {
    resetMap();
  }, []);

  const {
    DestinationsBodyText,
    DestinationsTitle,
    FavoritesTab,
    InputPlaceholder,
    SentToCarTab,
    SentToCarModalTitle,
    SentToCarModalBody,
    SendToCarButton,
    SentToCarPreposition,
    PoiSearchErrorLine1,
    PoiSearchErrorLine2,
  } = labels;

  const convertSearchTerms = (term: string) => {
    const conversion: object = {
      pharmacy: 'Farmacias',
      Shopping: 'Compras',
      Restaurants: 'Restaurantes',
      atm: 'Cajero automático',
    };
    if (term in conversion) {
      return conversion[term];
    }

    return term;
  };

  const searchPoi = async (searchTerm: string) => {
    try {
      dispatch(setLoadingStatus(true));
      const res = await api.getPoiItems(searchTerm, 20);
      if (res.data) {
        setPoiError(false);
        dispatch({
          type: 'SET_POINTS_OF_INTEREST',
          pointsOfInterest: formatPins(res.data, dispatch, favorites),
        });
        const convertedSearchTerm = convertSearchTerms(searchTerm);
        setDescriptionDisplayed(false);
        setDestinationInput(convertedSearchTerm);
        setHideAllTabs(true);
        setPoiItemsDisplayed(true);
      } else {
        setDescriptionDisplayed(false);
        setHideAllTabs(true);
        setPoiError(true);
      }
    } catch (err) {
      // TO DO IMPLEMENT ERROR HANDLING
      console.log('ERROR', err);
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };

  const renderPoiIcons = () => {
    const icons = [
      { icon: RiGasStationLine, onClick: () => searchPoi('gas'), font: true },
      { icon: pharmacy, onClick: () => searchPoi('pharmacy'), font: false },
      { icon: RiShoppingBagLine, onClick: () => searchPoi('Shopping'), font: true },
      {
        icon: RiRestaurantFill,
        onClick: () => searchPoi('Restaurants'),
        font: true,
      },
      { icon: BiDollar, onClick: () => searchPoi('atm'), font: true },
    ];

    return icons.map(({ icon, onClick, font }, i) => {
      return (
        <button
          className="map-window-destinations__poi--button"
          key={`poi--button--${i}`}
          onClick={() => {
            onClick();
            setPoiItemsDisplayed(true);
          }}
        >
          {font ? (
            React.createElement(icon, {
              className: 'map-window-destinations__poi--icon',
              size: '14px',
            })
          ) : (
              <img src={icon} className="map-window-destinations__poi--icon" />
            )}
        </button>
      );
    });
  };

  const addToFavorites = async (pinId: string) => {
    const favoritesFolder = folders.filter((folder) => folder.folderName === 'Favorites')[0];
    const myDestinationsFolder = folders.find((folder) => folder.folderName === 'MY DESTINATIONS');

    if (favoritesFolder || myDestinationsFolder) {
      const folderId = myDestinationsFolder ? myDestinationsFolder.folderId : favoritesFolder.folderId;
      const folderName = myDestinationsFolder ? myDestinationsFolder.folderName : favoritesFolder.folderName;
      const favItem = poiItems.find((item) => item.pinId === pinId) || sentToCar.find((item) => item.id === pinId);
      favItem.folderId = folderId;
      favItem.folderName = folderName;
      favItem.channelName = 'FAVORITES';
      if (oemsToSendChannelIdInDestinationReq?.includes(oemName)) {
        const channel = myDestinationsFolder?.channels
          ?.find((channel) => channel.channelName.toUpperCase() === 'FAVORITES')
        if (channel) {
          favItem.channelId = channel.channelId;
        }
      }

      delete favItem.phoneNumber;

      if (favItem) {
        try {
          dispatch(setLoadingStatus(true));
          const res = await api.addDestination(favItem);
          const { data: folders } = await api.getFolders();

          dispatch({ type: 'SET_FOLDERS', folders });
          const favoritesFolder = folders.filter((folder) => folder.folderName === 'Favorites')[0];
          const myDestinationsFolder = folders.find((folder) => folder.folderName === 'MY DESTINATIONS');

          const channels = myDestinationsFolder
            ? myDestinationsFolder.channels
              .filter((channel) => channel.channelName === 'FAVORITES')
              .map((item) => item.channelId)
            : favoritesFolder.channels.map((channel) => channel.channelId);

          const updatedFavortes = await Promise.all(
            channels.map(async (channelId: string) => {
              const { data: favorites } = await api.getDestinations(folderId, channelId);
              return favorites;
            }),
          );


          dispatch({ type: SET_FAVORITES, favorites: updatedFavortes.flat() });
        } catch (err) {
          console.log('ERROR', err);
        } finally {
          dispatch(setLoadingStatus(false));
        }
      }
    }
  };

  const sendToCarHandler = async (pinId: string) => {
    const recentsFolder = folders.find((folder) => folder.folderName === 'Recents');
    const myDestinationsFolder = folders.find((folder) => folder.folderName === 'MY DESTINATIONS');

    if (recentsFolder || myDestinationsFolder) {
      const locationToSend = poiItems.find((item) => item.pinId === pinId);

      locationToSend.folderId = myDestinationsFolder ? myDestinationsFolder.folderId : recentsFolder.folderId;
      locationToSend.folderName = myDestinationsFolder ? myDestinationsFolder.folderName : recentsFolder.folderName;
      locationToSend.channelName = 'RECENTS';
      if (oemsToSendChannelIdInDestinationReq?.includes(oemName)) {
        const channel = myDestinationsFolder?.channels
          ?.find((channel) => channel.channelName?.toUpperCase() === 'RECENTS')
        if (channel) {
          locationToSend.channelId = channel.channelId;
        }
      }

      if (locationToSend) {
        // Temp solution. Change hardcoded locale string when country from getDestinations
        // returns localized name. At the moment it returns country in Spanish only.
        const getCountryCode =
          countries.getAlpha2Code(locationToSend.geoAddress.address.country, 'es') ||
          countries.getAlpha2Code(locationToSend.geoAddress.address.country, 'en');

        const location = cloneDeep(locationToSend);
        const { country } = location.geoAddress.address;
        if (country.length !== 2) {
          location.geoAddress.address.country = getCountryCode;
        }
        delete location.phoneNumber;

        try {
          dispatch(setLoadingStatus(true));
          const res = await api.addDestination(location);

          const { data: folders } = await api.getFolders();
          dispatch({ type: 'SET_FOLDERS', folders });

          const recentsFolder = folders.find((folder) => folder.folderName === 'Recents');
          const myDestinationsFolder = folders.find((folder) => folder.folderName === 'MY DESTINATIONS');

          const channels = myDestinationsFolder
            ? myDestinationsFolder?.channels
              ?.filter((channel) => channel.channelName.toUpperCase() === 'RECENTS')
              .map((item) => item.channelId)
            : recentsFolder?.channels.map((channel) => channel.channelId);
          const folderId = myDestinationsFolder ? myDestinationsFolder.folderId : recentsFolder?.folderId;
          const updatedLocations = await Promise.all(
            channels.map(async (channelId: string) => {
              const { data: locations } = await api.getDestinations(folderId, channelId);
              return locations;
            }),
          );
          dispatch({ type: SET_SENT_TO_CAR, sentToCar: updatedLocations.flat() });
          setShowConfirmationModal(true);
        } catch (err) {
          console.log('ERROR', err);
        } finally {
          dispatch(setLoadingStatus(false));
        }
      }
    }
  };

  const removeFromFavorites = async (pinId: string) => {
    const itemToDelete =
      favorites.find((item) => item.id === pinId) ||
      itemInFavorites(
        poiItems.find((item) => item.pinId === pinId),
        favorites,
      );
    if (itemToDelete) {
      try {
        dispatch(setLoadingStatus(true));
        const { folderId, channelName, channelId, folderName, id } = itemToDelete;
        const data = { folderId, channelName, channelId, folderName, destinations: new Array(id) };
        await api.deleteDestination(data);
        dispatch({
          type: REMOVE_FROM_FAVORITES,
          pinId: id,
        });
      } catch (err) {
        console.log('ERROR', err);
      } finally {
        dispatch(setLoadingStatus(false));
      }
    }
  };

  const renderLocationCard = ({
    left: { lineOne, lineTwo, lineThree },
    right: { button, favClickHandler, variant, pinId, fillColor },
  }) => {
    return (
      <div key={pinId} className="map-window-destinations__poi-list--item">
        <div className="map-window-destinations__poi-list--item-left">
          <h2 className="map-window-destinations__poi-list--item-left-line-1">{lineOne}</h2>
          <h3 className="map-window-destinations__poi-list--item-left-line-2">{lineTwo}</h3>
          <h3 className="map-window-destinations__poi-list--item-left-line-2">{lineThree}</h3>
        </div>
        <div className="map-window-destinations__poi-list--item-right">
          <FavoriteButton
            onClickHandler={() => {
              favClickHandler(pinId);
            }}
            icon={{
              variant,
              fillColor,
            }}
          />
          {button && (
            <button
              className="map-window-destinations__poi-list--item-right-button"
              onClick={() => sendToCarHandler(pinId)}
            >
              {button}
            </button>
          )}
        </div>
      </div>
    );
  };

  const onMouseEnterOrLeaveHandler = (pinId, poiIndex, icon) => {
    dispatch({
      type: SET_SELECTED_POINT_OF_INTEREST,
      selectedPointOfInterest: pinId,
      poiIndex,
      icon,
    });
  };

  const renderPoiListItems = () => {
    if (poiItems && poiItemsDisplayed) {
      return poiItems.map((item: any, poiIndex) => {
        const variant = itemInFavorites(item, favorites) ? 'filled' : 'outlined';
        const { city = '', state = '', postalCode = '', country = '' } = item.geoAddress.address;
        const address = [city, state, postalCode, country].filter(Boolean).join(', ');
        const backgroundColor = item.pinId === selectedPointOfInterest ? '#E3E2DF' : 'white';

        const left = {
          lineOne: item.name,
          lineTwo: address,
          lineThree: item.phoneNumber || '',
        };

        const right = {
          favClickHandler: itemInFavorites(item, favorites) ? removeFromFavorites : addToFavorites,
          pinId: item.pinId,
          variant,
          button: SendToCarButton,
          fillColor: 'var(--theme-color-grey-c1)',
        };

        return (
          <div
            key={item.pinId}
            onMouseEnter={() => onMouseEnterOrLeaveHandler(item.pinId, poiIndex, markerIcon)}
            onMouseLeave={() => onMouseEnterOrLeaveHandler(null, poiIndex, bluePin)}
            style={{ backgroundColor: backgroundColor }}
            id={item.pinId}
          >
            {renderLocationCard({ left, right })}
          </div>
        );
      });
    }
    return null;
  };

  const renderPoiList = () => {
    if (poiError) {
      return (
        <div className="poi-error-wrapper">
          <p className="poi-error-text">{PoiSearchErrorLine1}</p>
          <p className="poi-error-text">{PoiSearchErrorLine2}</p>
        </div>
      );
    }

    if (poiItems.length) {
      return (
        <div id="poi_list" className="map-window-destinations__poi-list">
          {renderPoiListItems()}
        </div>
      );
    }
    return null;
  };

  const renderDescription = () => {
    if (descriptionDisplayed && !poiItems.length) {
      return (
        <div>
          <h1 className="map-window-destinations__body--header">{DestinationsTitle}</h1>
          <p className="map-window-destinations__body--paragraph">{DestinationsBodyText}</p>
        </div>
      );
    }
  };

  const renderSavedLocations = (type: string) => {
    const locations = type === 'favorites' ? favorites : sentToCar;

    return locations.map((item: SavedLocation) => {
      const inFavorites = itemInFavorites(item, favorites);
      const pinId = inFavorites ? inFavorites.id : item.id;
      const variant = inFavorites ? 'filled' : 'outlined';
      const { city = '', state = '', country = '' } = item.geoAddress?.address || {};
      const address = [city, state, country].filter(Boolean).join(', ');

      const left = {
        lineOne: item.name,
        lineTwo: item.geoAddress ? item.geoAddress.address.street : '',
        lineThree: address,
      };
      const right = {
        favClickHandler: inFavorites ? removeFromFavorites : addToFavorites,
        pinId,
        button: '',
        variant,
        fillColor: type === 'favorites' ? 'var(--theme-color-primary)' : 'var(--theme-color-grey-c1)',
      };
      return renderLocationCard({ left, right });
    });
  };

  const renderFavorites = () => {
    if (!poiItemsDisplayed && favorites) {
      return (
        <div className="map-window-destinations__body">
          <div className="map-window-destinations__poi-list">{renderSavedLocations('favorites')}</div>
        </div>
      );
    }

    return null;
  };

  const renderSentToCar = () => {
    if (!poiItemsDisplayed && sentToCar) {
      return (
        <div className="map-window-destinations__body">
          <div className="map-window-destinations__poi-list">{renderSavedLocations('sent to car')}</div>
        </div>
      );
    }

    return null;
  };

  const onCloseDestinationInput = () => {
    dispatch({
      type: SET_POINTS_OF_INTEREST,
      pointsOfInterest: [],
    });
    setDestinationInput('');
    setDescriptionDisplayed(true);
    setHideAllTabs(true);
    resetMap();
  };

  const showCloseButton = () => {
    if (!!destinationInput) {
      return (
        <div className="map-window-destinations__body--input-close-wrapper">
          <button className="map-window-destinations__body--input-search-button" onClick={onCloseDestinationInput}>
            <CgClose />
          </button>
          <span className="map-window-destinations__body--input-divider">|</span>
        </div>
      );
    }
  };

  const showSubtabs = () => {
    setPoiItemsDisplayed(false);
    setDescriptionDisplayed(false);
    setHideAllTabs(false);
  };

  const renderDestinationBody = () => {
    const convertToSpanish = locale === 'es-MX' ? { locale: es } : null;
    const currentDateTime = format(new Date(), 'LLLL d, yyyy - h:mm aaa', convertToSpanish);
    // add preposition for spanish date, english will come as undefined
    const formattedDateTime = currentDateTime.replace('-', SentToCarPreposition || '');
    return (
      <div className="map-window-destinations__body">
        <div className="map-window-destinations__body--input-wrapper">
          <input
            className="map-window-destinations__body--input"
            type="text"
            placeholder={InputPlaceholder}
            value={destinationInput}
            onChange={(e) => setDestinationInput(e.target.value)}
            onKeyPress={(e) => {
              if (enterKeyPressed(e)) {
                searchPoi(destinationInput);
              }
            }}
          />
          {showCloseButton()}
          <button
            className="map-window-destinations__body--input-search-button"
            onClick={() => searchPoi(destinationInput)}
          >
            <CgSearch />
          </button>
        </div>
        <div className="map-window-destinations__poi">{renderPoiIcons()}</div>
        <Tab type="secondary" selectedTab="none" hideAllTabs={hideAllTabs}>
          <TabItem data-label={FavoritesTab} onClick={showSubtabs}>
            {renderFavorites()}
          </TabItem>
          <TabItem data-label={SentToCarTab} onClick={showSubtabs}>
            {renderSentToCar()}
          </TabItem>
        </Tab>
        {renderPoiList()}
        {renderDescription()}
        <ModalContainer
          show={showConfirmationModal}
          header={{ text: SentToCarModalTitle, position: 'center' }}
          onCloseHandler={() => setShowConfirmationModal(false)}
          size="fl"
        >
          <>
            <div className="text-center">
              <p>{`${SentToCarModalBody} ${formattedDateTime}`}</p>
              <button className="map-window-destinations__body--button" onClick={() => setShowConfirmationModal(false)}>
                Ok
              </button>
            </div>
          </>
        </ModalContainer>
      </div>
    );
  };

  return <div>{renderDestinationBody()}</div>;
}
