import React, { CSSProperties } from 'react';

import SettingsGridRow from './SettingsGridRow';

import styles from './SettingsGrid.module.css';

export type SettingsGridProps = {
  fields: Record<string, string>;
  data?: Record<string, string | boolean>[];
  mode?: 'view' | 'edit';
  indexing?: string;
  onChange?: (id: string, name: string, value: boolean) => void;
};

function SettingsGrid({ data = [], fields, mode = 'view', onChange, indexing = 'id' }: SettingsGridProps) {
  const style: CSSProperties = {
    ['--settings-grid-columns' as string]: Object.keys(fields).length - 1,
  };

  return (
    <div className={styles.SettingsGrid} style={style}>
      <SettingsGridRow data={fields} fields={fields} isHead />
      {data.map((row, index) => (
        <SettingsGridRow
          key={String(row[indexing]) + index}
          indexing={indexing}
          fields={fields}
          data={row}
          mode={mode}
          onChange={onChange}
        />
      ))}
    </div>
  );
}

export default SettingsGrid;
