import React from 'react';
import { useField } from 'formik';
import Field, { FieldProps } from '@components/Field';
import Input, { InputProps } from '@components/Input';
import nextId from '@utils/next-id';

import styles from './FormikFields.module.css';

type FormikInputFieldProps = Pick<FieldProps, 'classes'>
  & Omit<InputProps, 'name'>
  & Omit<InputProps, 'helpers'>
  & {
    label?: string;
    helpCallout?: React.ReactNode;
    InputComponent?: React.ComponentType<{ id: string }>;
    name: string;
    mask?: (string | RegExp)[];
    showMask?: boolean;
    showMaskOnHover?: boolean;
    options?: any;
    styleType?: string;
    isSearchable?: boolean;
  };

const FormikInputField = ({
  label,
  helpCallout,
  InputComponent = Input,
  classes,
  ...props
}: FormikInputFieldProps) => {
  const [field, meta, helpers] = useField(props);

  if (!props.name) {
    throw new Error(
      'You need to specify name for your formikInputFiled component'
    );
  }

  const parseErrorMsg = (message: string|boolean) => {
    if (!message || typeof message !== 'string') {
      return message;
    }
    const splitMsg = message.split('\n');
    if (splitMsg.length > 1) {
      return <> {splitMsg.map((error, i) => <div key={`err-${i}`} className={styles["error-spacer"]}>{error}</div>)} </ >;
    }
    return message;
  };

  const errorMessage = meta.touched && meta.error;
  const { id = nextId() } = props;

  return (
    <Field
      label={label}
      helpCallout={helpCallout}
      error={!!errorMessage}
      helpText={parseErrorMsg(errorMessage)}
      classes={classes}
      htmlFor={id}
    >
      <InputComponent showErrorIcon {...field} helpers={helpers} {...props} id={id} />
    </Field>
  );
};

export default FormikInputField;
