import React, { useEffect, useState } from "react";
import clsx from 'clsx';

import styles from './Tab.module.css';

type TabProps = {
  children: React.ReactElement<ChildProps>[];
  type: 'primary' | 'secondary' | 'tertiary'
  selectedTab?: 'none' | string;
  className?: string;
  hideAllTabs?: boolean;
}

type ChildProps = {
  activeTab: string;
  label: string;
  ['data-label']: string;
  children: React.ReactNode;
  onClick: (label: string) => void;
  type: string;
}

const Tab = ({
  children,
  type = 'primary',
  selectedTab,
  className,
  hideAllTabs
}: TabProps) => {
  const [activeTab, setActiveTab] = useState(selectedTab);
  const tabClassName = clsx(styles.TabContainer, styles[`TabContainer--${type}`], className);

  const onClickTabItem = (tab: string, callback?: Function) => {
    setActiveTab(tab);
    callback && callback();
  };

  useEffect(() => {
    if (hideAllTabs) {
      setActiveTab('none');
    }
  }, [hideAllTabs])

  return (
    <div className={tabClassName}>
      <ul className={styles.TabList}>
        {children && React.Children.map(children, (child) => {
          const label = child.props['data-label'];

          if (React.isValidElement(child)) {
            return (
              <li>
                {React.cloneElement(child, { activeTab, key: label, label, onClick: () => onClickTabItem(label, child.props.onClick), type })}
              </li>
            )
          }
        })}
      </ul>
      <div className={styles.TabContent}>
        {children && React.Children.map(children, (child) => {
          if (child.props['data-label'] !== activeTab) return null;
          return child.props.children;
        })}
      </div>
    </div>
  )
}

export default Tab;
