import qs from 'qs';
import { APIRequest, APIResponse } from '@cv/portal-common-lib/ajax/models';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import { callHttpRequestMethod } from '@cv/portal-common-lib/utils';
import config from '@config/config';

const userInfoUrl: string = config.getOemValue('USER_INFO_URL') || '';

const accessTokenUrl: string = config.getOemValue('ACCESS_TOKEN_URL') || '';
const accessTokenClientId: string = config.getOemValue('LOGIN_CLIENT_ID') || '';
const accessTokenRedirectUri: string = config.getOemValue('LOGIN_REDIRECT') || '';

const exchangeTokenUrl: string = config.get('EXCHANGE_TOKEN_URL') || '';
const exchangeTokenClientId: string = config.getOemValue('DEALER_CLIENT_ID') || '';

type GetAccessTokenArgs = {
  code: string;
  verifier: string;
}

type RefreshTokenArgs = {
  refreshToken: string;
}

type GetUserInfoArgs = {
  accessToken: string;
}

type ExchangeTokenArgs = {
  token: string;
}

type TokenResponse = {
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: string;
}

type UserInfoResponse = {
  sub: string;
}

export default class AuthService {
  constructor() {}

  getAccessToken({ code, verifier }: GetAccessTokenArgs): Promise<APIResponse<TokenResponse>> {
    const request: APIRequest = {
      url: accessTokenUrl,
      method: HttpMethodEnum.POST,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
      },
      data: qs.stringify({
        grant_type: 'authorization_code',
        code,
        client_id: accessTokenClientId,
        redirect_uri: accessTokenRedirectUri,
        code_verifier: verifier,
      }),
    };

    return callHttpRequestMethod(request);
  }

  refreshToken({ refreshToken }: RefreshTokenArgs): Promise<APIResponse<TokenResponse>> {
    const request: APIRequest = {
      url: accessTokenUrl,
      method: HttpMethodEnum.POST,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
      },
      data: qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: accessTokenClientId,
      }),
    };

    return callHttpRequestMethod(request);
  }

  getUserInfo({ accessToken }: GetUserInfoArgs): Promise<APIResponse<UserInfoResponse>> {
    const request: APIRequest = {
      url: userInfoUrl,
      method: HttpMethodEnum.POST,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    return callHttpRequestMethod(request);
  }

  exchangeToken({ token }: ExchangeTokenArgs): Promise<APIResponse<TokenResponse>> {
    const request: APIRequest = {
      url: exchangeTokenUrl,
      method: HttpMethodEnum.POST,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'cv-client-id': exchangeTokenClientId,
        'cv-oem-auth-token': token,
      },
    };

    return callHttpRequestMethod(request);
  }
}
