import React from 'react';

import CardView from './CardView';
import ContentRenderer from '@components/ContentRenderer';

type CardConnectorProps = {
  name: string,
  content: Array<{ componentType: string }>
}

function CardConnector({name, content}: CardConnectorProps) {
  return (
    <CardView>
      <ContentRenderer name={name} content={content} />
    </CardView>
  );
}

CardConnector.defaultProps = {
  content: [],
};

export default CardConnector;
