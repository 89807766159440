import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { FaCheck } from 'react-icons/fa';
import RoundToggleButton from '@components/RoundToggleButton';
import ControlLabel from '@components/ControlLabel';

import styles from './SettingsGrid.module.css';

export type SettingsGridRowProps = {
  fields: Record<string, string>;
  data: Record<string, string | boolean>;
  isHead?: boolean;
  mode?: 'edit' | 'view';
  indexing?: string;
  onChange?: (id: string, name: string, value: boolean) => void;
};

function SettingsGridRow({
  data,
  fields,
  isHead = false,
  mode = 'view',
  indexing = 'id',
  onChange,
}: SettingsGridRowProps) {
  const renderCheckbox = (id: string, name: string, value: boolean): ReactNode => {
    if (mode !== 'edit') {
      return (
        <div className={clsx(styles['SettingsGrid-checkbox'], { [styles['SettingsGrid-checkbox--checked']]: value })}>
          <FaCheck />
        </div>
      );
    }

    return (
      <ControlLabel
        label=""
        control={
          <RoundToggleButton
            name={name}
            checked={value}
            onToggle={(checked) => {
              if (onChange) {
                onChange(id, name, checked);
              }
            }}
            classes={{
              button: styles['SettingsGrid-checkbox--button'],
              input: styles['SettingsGrid-checkbox--input'],
            }}
          >
            <FaCheck />
          </RoundToggleButton>
        }
      />
    );
  };

  return (
    <div className={clsx(styles['SettingsGrid-row'], { [styles['SettingsGrid-head']]: isHead })}>
      {Object.keys(fields).map((name) => (
        <div className={styles['SettingsGrid-cell']} key={name}>
          {typeof data[name] === 'boolean'
            ? renderCheckbox(String(data[indexing]), name, data[name] as boolean)
            : data[name]}
        </div>
      ))}
    </div>
  );
}

export default SettingsGridRow;
