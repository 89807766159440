// @ts-nocheck
import { State } from '@cv/portal-rts-lib/enums';
import { Frequency } from '@cv/portal-rts-lib/doors/enums';
import cloneDeep from 'lodash/cloneDeep';
import {
  parse as parseDate,
  parseISO,
  format as formatDate,
  add as addTime,
  differenceInDays,
  intervalToDuration,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { CreateCurfewPayload } from '@cv/portal-rts-lib/curfews/models';
import { CreateAlertsRequest } from '@cv/portal-rts-lib/alerts/models/alerts';
import { AlertTypes, ResourceState } from '@cv/portal-rts-lib/alerts/enums';
import { UpdateGeofencePayload, CreateGeofencePayload } from '@cv/portal-rts-lib/geofences/models';
import { AlertType, GeofenceType } from '@cv/portal-rts-lib/geofences/enums';

const curfewAlertObject: CreateCurfewPayload = {
  curfew: {
    state: State.Active,
    name: '',
    inVehicleWarning: false,
    schedules: [
      {
        startDate: '',
        recurrenceRules: [
          {
            freq: Frequency.DAILY,
            dayOfTheWeek: [],
          },
        ],
        startTime: '',
        endDate: '',
        endTime: '',
      },
    ],
  },
};
const speedAlert: CreateAlertsRequest = {
  drivingBehavior: {
    inVehicleWarning: false,
    schedules: [
      {
        startDate: '',
      },
    ],
    config: [
      {
        type: AlertTypes.SPEED_ABSOLUTE,
        value: '75',
        UoM: '',
      },
    ],
    state: ResourceState.ACTIVE,
  },
};

function twelveTo24(hr: number, meridiem: string) {
  hr %= 12;
  if (meridiem === 'PM') {
    hr += 12;
  }
  return hr;
}

export function formatHours(date: string, formatter = 'h:mm a') {
  if (!date) return '-';
  return formatDate(utcToZonedTime(parseISO(date), 'UTC'), formatter);
}

export function formatDateAndTime(date, hr, min, meridian) {
  const datetime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), twelveTo24(hr, meridian), min, 0));
  return datetime.toISOString();
}

export function formatDateAndTimeByTime(hr, min, meridian) {
  const now = new Date();
  return formatEndDateAndTime(now.getFullYear(), now.getMonth(), now.getDate(), hr, min, meridian);
}

export function formatEndDateAndTime(y, m, d, hr, min, meridian) {
  return new Date(y, m, d, twelveTo24(hr, meridian), min).toISOString();
}

export function setDurationTime(start: Date, end: Date) {
  const wholeDays = differenceInDays(end, start);
  const duration = intervalToDuration({start, end});
  return `P${wholeDays}DT${duration.hours}H${duration.minutes}M${duration.seconds}S`;
}

export function saveCurfewAlert(curfewAlertForm, curfew, selectedCurfew) {
  let nameExists = false;
  const newAlertName = curfewAlertForm.name;
  const isAllDay = curfewAlertForm.allDay;

  for (let i = 0; i < curfew?.curfews?.length; i++) {
    if (curfew.curfews[i].name === newAlertName && !selectedCurfew) {
      nameExists = true;
      break;
    } else {
      nameExists = false;
    }
  }

  if (nameExists) {
    return false; // Handle error when curfew name already exist
  }
  let startDate = new Date();
  let endDate = new Date();

  if (isAllDay) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);
  } else {
    startDate = parseDate(curfewAlertForm.startTime, 'h:mma', startDate);
    endDate = parseDate(curfewAlertForm.endTime, 'h:mma', endDate);
    if (startDate > endDate) {
      endDate = addTime(endDate, {days: 1});
    }
  }
  const duration = setDurationTime(startDate, endDate);

  let updatedCurfew;

  if (selectedCurfew) {
    updatedCurfew = { ...selectedCurfew };
    updatedCurfew.id = selectedCurfew.lastSvcReqId;
  } else {
    updatedCurfew = cloneDeep(curfewAlertObject);
  }

  Object.assign(updatedCurfew.curfew, {
    inVehicleWarning: curfewAlertForm.inVehicleWarning,
    name: curfewAlertForm.name,
    state: curfewAlertForm.lastKnownState || 'ACTIVE',
  });
  Object.assign(updatedCurfew.curfew.schedules[0], {
    startDate: formatDate(startDate, 'yyyy-MM-dd'),
    endDate: formatDate(endDate, 'yyyy-MM-dd'),
    startTime: formatDate(startDate, "HH:mm:ss.SSS'Z'"),
    endTime: formatDate(endDate, "HH:mm:ss.SSS'Z'"),
    duration,
  });
  Object.assign(updatedCurfew.curfew.schedules[0].recurrenceRules[0], {
    dayOfTheWeek: curfewAlertForm.dayOfTheWeek,
    freq: curfewAlertForm.dayOfTheWeek.length !== 7 ? Frequency.WEEKLY : Frequency.DAILY,
  });

  return updatedCurfew;
}

export function saveSpeedAlert(speedAlertForm, selectedAlert) {
  const formattedStartDateTime = formatDate(new Date(), 'yyyy-MM-dd');

  let updatedAlert;

  if (selectedAlert) {
    updatedAlert = { ...selectedAlert };
    updatedAlert.drivingBehavior = {
      ...updatedAlert.drivingBehavior,
      id: selectedAlert.drivingBehavior.lastSvcReqId,
      state: speedAlertForm.lastKnownState || selectedAlert.drivingBehavior.lastKnownState,
    };
  } else {
    updatedAlert = cloneDeep(speedAlert);
  }

  updatedAlert.drivingBehavior.config[0].type = AlertTypes.SPEED_ABSOLUTE;
  updatedAlert.drivingBehavior.config[0].value = +speedAlertForm.speed;
  updatedAlert.drivingBehavior.config[0].UoM = speedAlertForm.UoM;
  updatedAlert.drivingBehavior.schedules[0] = {
    startDate: formattedStartDateTime,
  };
  updatedAlert.drivingBehavior.inVehicleWarning = speedAlertForm.inVehicleWarning;

  return updatedAlert;
}

type SaveAlertProps = {
  valetRadio: string
  formUnit: string
  latitude?: number | undefined
  longitude?: number | undefined
  selected: boolean
  id?: string
}

type SaveUpdatedValetAlertProps = {
  valetRadio: string
  formUnit: string
  latitude: number | undefined
  longitude: number | undefined
  selected: boolean
  id: string
}
export function saveValetAlert({ valetRadio, formUnit, latitude = 1.1, longitude = 1.1, selected, id }: SaveAlertProps) {
  const updatedValet: CreateGeofencePayload = {
    geofence: {
      name: 'valet',
      inVehicleWarning: id ? selected : false,
      ...(id && { id }),
      geometry: {
        radius: {
          UoM: formUnit,
          value: valetRadio,
        },
        center: {
          latitude,
          longitude,
          UoM: 'DEGREES',
        },
      },
      alertType: AlertType.Valet,
      type: GeofenceType.Circular,
      state: selected ? State.Active : State.Inactve,
    },
  };

  return updatedValet;
}

export function saveUpdatedValetAlert({ valetRadio, formUnit, latitude = 1.1, longitude = 1.1, selected, id }: SaveUpdatedValetAlertProps) {
  const updatedValet: UpdateGeofencePayload = {
    geofence: {
      name: 'valet',
      inVehicleWarning: id ? selected : false,
      ...(id && { id }),
      geometry: {
        radius: {
          UoM: formUnit,
          value: valetRadio,
        },
        center: {
          latitude,
          longitude,
          UoM: 'DEGREES',
        },
      },
      alertType: AlertType.Valet,
      type: GeofenceType.Circular,
      state: selected ? State.Active : State.Inactve,
    },
  };

  return updatedValet;
}

export function formatDateToISO(date: Date) {
  return date.toISOString();
}

export function mapDaysFromLang(days: string[], locale: string, reverseMapping = false) {
  let mappingDays;
  let mapping = (day: string) => mappingDays[day] || day;
  if (locale === 'es-MX') {
    mappingDays = {
      DO: 'SU',
      LU: 'MO',
      MA: 'TU',
      MI: 'WE',
      JU: 'TH',
      VI: 'FR',
      SA: 'SA',
    };
  } else {
    return days;
  }

  if (reverseMapping) {
    const dayEntries = Object.entries(mappingDays);
    mapping = (day: string) => {
      const keyEntry = dayEntries.find((dayEntry) => dayEntry.indexOf(day) !== -1);
      return keyEntry ? keyEntry[0] : day;
    };
  }

  return days.map(mapping);
}
