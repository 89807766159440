import { Store } from "redux";
import { setAccessToken, setRefreshToken, setIdToken } from "@redux/actions/token";
import { setTenantId } from "@redux/actions/account";
import { IVehicle, setVehicleData, setUserId } from "@redux/actions";

export default class StoreService {
  constructor(private store: Store) {}

  getAccessToken() {
    return this.store.getState()?.accessTokenReducer?.accessToken;
  }

  getIdToken() {
    return this.store.getState()?.accessTokenReducer.id_token;
  }

  getRefreshToken() {
    return this.store.getState()?.refreshTokenReducer?.refreshToken;
    }

  setAccessToken(accessToken: string) {
    this.store.dispatch(setAccessToken(accessToken));
  }

  setIdToken(idToken: string) {
    this.store.dispatch(setIdToken(idToken));
  }

  setRefreshToken(refreshToken: string) {
    this.store.dispatch(setRefreshToken(refreshToken));
  }

  setUserID(userId: string) {
    this.store.dispatch(setUserId(userId));
  }

  getUserId() {
    return this.store.getState()?.userReducer?.userId;
  }

  getTenantId() {
    return this.store.getState()?.accountReducer?.tenantId;
  }

  setTenantId(tenantId: string) {
    return this.store.dispatch(setTenantId(tenantId));
  }

  setVehicleData(vehicleData: IVehicle) {
    this.store.dispatch(setVehicleData(vehicleData));
  }

  getVehicleData() {
    return this.store.getState()?.vehicleReducer?.vehicle;
  }

  getLocale() {
    return this.store.getState()?.settingsReducer?.locale;
  }

  getSubscribedServices() {
    return this.store.getState()?.userReducer?.subscribedServices;
  }

  getMapCenter() {
    return this.store.getState()?.mapReducer.mapCenter;
  }
}
