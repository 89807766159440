import {
  EligiblePackagesResponse,
  SubscribedPackage,
  SubscribedPackagesResponse,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { SubscriptionData } from '../types/';

type SubscriptionFormatter = (
  data: SubscribedPackagesResponse,
  defaultPkgs: EligiblePackagesResponse[],
) => SubscriptionData;

export const formatSubscription: SubscriptionFormatter = (data, defaultPkgs) => {
  const subscribedPkgs = deduplicatePkgs(data?.subscribedPackages);
  return {
    ...data,
    vehicle: {
      vin: data?.vin,
      vehicleId: data?.vehicleId,
    },
    services: subscribedPkgs?.map((pack: SubscribedPackage) => ({
      name: pack.marketingName,
      shortDescription: pack.shortDescription,
      expiry: pack.endDate.split('-').join('/'),
      autoRenew: pack.autoRenew,
      active: pack.active,
      subscriptionPackageId: pack.subscriptionPackageId,
      packageType: pack.packageType,
      serviceIds: pack.products?.flatMap((product) => product.serviceIds),
    })),
    defaultPkg: {
      expiry: defaultPkgs?.[0]?.variants[0]?.endDate?.split('-')?.join('/'),
    },
  };
};

const deduplicatePkgs = (subscribedPkgs: SubscribedPackage[]) => {
  return Object.values(
    subscribedPkgs.reduce((subscribedPkgsCopy, subscribedPkg: SubscribedPackage) => {
      const key = subscribedPkg.marketingName?.toUpperCase();
      if (!subscribedPkgsCopy[key] || subscribedPkgsCopy[key].endDate < subscribedPkg.endDate) {
        subscribedPkgsCopy[key] = subscribedPkg;
      }
      return subscribedPkgsCopy;
    }, {} as Record<string, SubscribedPackage>),
  );
};
