import { DOOR_ID, RemoteCommand } from "@components/Map/constants";
import { Environment } from "@cv/portal-common-lib/ajax/constants";
import { isSuccessHttpCode } from "@cv/portal-common-lib/ajax/helper";
import { updateEngineByVehicleId, getEngineByVehicleId } from "@cv/portal-rts-lib";
import { EngineState } from "@cv/portal-rts-lib/engines/enums";
import { EngineByVehicleIdRequest, GetEngineRequest } from "@cv/portal-rts-lib/engines/models/engines";
import initiatePolling, { PollingStatus } from "@utils/polling";

export type EngineStartStopRequestPayload = {
    validationCallback: () => PollingStatus;
    successHandlerCallback: () => void;
    errorHandlerCallback: (result?: object) => void;
    remoteCommand?: string;
}
export default class EngineStartStopService {
    private environment: Environment;
    constructor(environment = Environment.DEVINT) {
        this.environment = environment
    }
    makeEngineStartStopCall(payload: EngineStartStopRequestPayload, accessToken: string, vehicleId: string) {
        const updateRequest = {
            environment: this.environment,
            pathParams: {
                vehicleId: vehicleId,
            },
            headers: {
                Authorization: accessToken,
            },
            data: {
                engine: {
                    state: payload.remoteCommand === RemoteCommand.START_STOP_ENGINE ? EngineState.START : EngineState.STOP,
                    id: DOOR_ID,
                },
            },
        } as EngineByVehicleIdRequest;

        const request = {
            environment: this.environment,
            pathParams: {
                vehicleId: vehicleId,
            },
            headers: {
                Authorization: accessToken,
            },
        } as GetEngineRequest;
        return updateEngineByVehicleId(updateRequest)
            .then((result) => {
                isSuccessHttpCode(result.status) ? initiatePolling({
                  pollingFunc: getEngineByVehicleId.bind(null, request),
                  validationCallback: payload.validationCallback,
                  successCallback: payload.successHandlerCallback,
                  errorCallback: payload.errorHandlerCallback,
                }) : payload.errorHandlerCallback();
            }).catch((err) => {
                payload.errorHandlerCallback(err);
            });
    }
}
