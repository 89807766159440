import {
  ACCOUNT_ACTIONS,
  VEHICLE_ACTIONS,
  CURFEW_ACTIONS,
  ALERTS_ACTIONS,
  GEOFENCE_ACTIONS,
  PREFERENCES_ACTIONS,
} from './actions/';

/*
  The purpose of this file is to map the data as referenced by ApiConnector to the correct reducers.
  This way the ApiConnector can call useSelector on different reducers dynamically.

  The logic here is:
    IF data from reducers[REDUCER_MAP[key]][key] is not available
    THEN call api.getService(key)
    THEN dispatch action with type: ACTION.MAP[key] to populate that reducer

   EXAMPLE
   - a component needs to use 'drivers' model, so key = 'drivers'
   - REDUCER_MAP['drivers'] = 'accountReducer'
   - accountReducer['drivers'] is empty or outdated
   - call api.getService('drivers'), get formatted response from middleware
   - ACTION_MAP['drivers'] = SET_DRIVERS
   - dispatch action { type: SET_DRIVERS, data: response }

  So with a single key, the ApiConnector can handle the whole flow without having knowledge
  of actual reducers or actions.
  However, this means that keys in both REDUCER_MAP and ACTION_MAP must be
  THE SAME as the keys in serviceMap in /api/Api.ts AND the same as the keys of relevant data in actual reducer store.
 */

export const REDUCER_MAP = {
  contacts: 'accountReducer',
  drivers: 'accountReducer',
  subscription: 'accountReducer',
  account: 'accountReducer',
  locale: 'settingsReducer',
  accessToken: 'accessTokenReducer',
  refreshToken: 'refreshTokenReducer',
  user: 'userReducer',
  curfew: 'curfewReducer',
  alerts: 'alertsReducer',
  geofence: 'geofenceReducer',
  vehicle: 'accountReducer',
  paymentMethod: 'accountReducer',
  preferences: 'preferencesReducer',
};

export const ACTION_MAP = {
  contacts: ACCOUNT_ACTIONS.SET_CONTACTS,
  drivers: ACCOUNT_ACTIONS.SET_DRIVERS,
  subscription: ACCOUNT_ACTIONS.SET_SUBSCRIPTIONS,
  account: ACCOUNT_ACTIONS.SET_ACCOUNT_DATA,
  vehicle: ACCOUNT_ACTIONS.SET_VEHICLE_DETAILS,
  paymentMethod: ACCOUNT_ACTIONS.SET_PAYMENT_METHOD,
  curfew: CURFEW_ACTIONS.GET_CURFEWS,
  alerts: ALERTS_ACTIONS.GET_ALERTS,
  geofence: GEOFENCE_ACTIONS.GET_GEOFENCE,
  preferences: PREFERENCES_ACTIONS.SET_PREFERENCES,
};
