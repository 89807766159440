import React, { useEffect } from 'react';
import CryptoJS from 'crypto-js';
import config from '@config/config';
import milliseconds from 'date-fns/milliseconds';

import getCookie from '@utils/get-cookie';
import { LoaderBackdrop } from '@components/Loader';

const Login = () => {
  useEffect(() => {
    onRequestUrl();
  }, []);

  const onRequestUrl = () => {
    const verifier = base64URLEncode(CryptoJS.lib.WordArray.random(50));
    const challenge = base64URLEncode(CryptoJS.SHA256(verifier));

    // TODO: restore localStorage after testing the cookie setup
    document.cookie = `verifier=${verifier};`;

    const uri = `${config.getOemValue('LOGIN_URL')}?response_type=${config.getOemValue(
      'LOGIN_TYPE',
    )}&client_id=${config.getOemValue('LOGIN_CLIENT_ID')}&realm=${config.getOemValue(
      'LOGIN_REALM',
    )}&redirect_uri=${config.getOemValue(
      'LOGIN_REDIRECT',
    )}&code_challenge=${challenge}&code_challenge_method=S256&state=456`;

    console.log('sxm  uri: ', uri);

    /**
     * This part is related to possible issue with firefox:
     * https://bugzilla.mozilla.org/show_bug.cgi?id=1536797
     *
     * We are trying to check if localStorage is set properly before we redirect user
     * to the next page.
     *
     * was done by ticket: https://jira.atxg.com/browse/PORTAL-868
     */
    let interval = setInterval(() => {
      if (verifier === getCookie('verifier')) {
        clearInterval(interval);
        window.location.replace(uri);
      }
    }, 100);

    setInterval(() => {
      clearInterval(interval);
      throw new Error('There is some issue with browser cookies');
    }, milliseconds({ seconds: 10 }));
  };

  const base64URLEncode = (words: any) => {
    return CryptoJS.enc.Base64.stringify(words).replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
  };

  return <LoaderBackdrop />;
};

export default Login;
