import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from '@api';
import Loader from '@components/Loader/';
import ApiRequestPayload from '@types/ApiRequestPayload';
import { REDUCER_MAP, ACTION_MAP } from '@redux/dataMap';
import { StateItem } from '@redux/types';

type RemovalConnectorProps = {
  removalConnector: {
    api: string;
    errorLabel?: string;
  };
};

const LOADING_STATUS = {
  INACTIVE: 'inactive',
  READY: 'ready',
  LOADING: 'loading',
  ERROR: 'error',
};

function RemovalConnector(WrappedComponent, props: RemovalConnectorProps) {
  return function (props) {
    const { removalConnector } = props;
    if (removalConnector) {
      const api = useApi();
      const modelKey = removalConnector.api.split('/')[1];
      const foundAction: string | undefined = ACTION_MAP[modelKey];
      const [status, setStatus] = useState(LOADING_STATUS.INACTIVE);
      const [error, setError] = useState('');
      const dispatch = useDispatch();
      const locale: string | undefined = useSelector((reducers) => reducers[REDUCER_MAP.locale].locale);
      const token: string | undefined = useSelector((reducers) => reducers[REDUCER_MAP.accessToken].accessToken);

      const onRemove = async (submittedPayload: ApiRequestPayload) => {
        try {
          setStatus(LOADING_STATUS.LOADING);
          const response = await api.updateService(modelKey, 'DELETE', submittedPayload);
          if (response) {
            const { data }: { data: ApiRequestPayload } = await api.fetchService(modelKey, locale, token);
            if (foundAction) {
              dispatch({ type: foundAction, data });
            } else {
              console.warn('RemovalConnector could not find action at key', modelKey, ' - dispatch skipped');
            }
          }
          setStatus(LOADING_STATUS.READY);
        } catch (e) {
          setStatus(LOADING_STATUS.ERROR);
          setError(e.message);
        }
      };

      if (status === LOADING_STATUS.LOADING) {
        return <Loader />;
      } else if (status === LOADING_STATUS.ERROR) {
        console.error('RemovalConnector error', removalConnector, error);
        return <div className="error">{removalConnector.errorLabel}</div>;
      } else {
        return <WrappedComponent onRemove={onRemove} {...props} />;
      }
    }
    return <WrappedComponent {...props} />;
  };
}

export default RemovalConnector;
