import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import { ContentfulImage } from '@cv/webframework-react-components';
import ModalContainer from '@components/ModalContainer/';
import MoreIcon from '@assets/more-icon.svg';
import StatusSuccessIcon from '@assets/status_icons.svg';
import StatusUnavailableIcon from '@assets/unavailable_mils.svg';
import StatusWarningIcon from '@assets/status_warning_icon.svg';
import { VehicleImageContainer } from '@components/VehicleImageContainer';
import clsx from 'clsx';
import styles from './VehicleCondition.module.css';
import { useSelector } from 'react-redux';
import { useVehicleConditionContext } from './VehicleConditionContext';

type VehicleConditionItemProps = {
  icon: ContentfulFile;
  label: string;
  contentBoxType: 'non expandable' | 'expandable' | 'default' | 'info';
  statusUnavailableLabel: string;
  statusSuccessLabel: string;
  statusWarningLabel: string;
  statusWarningDescription?: string;
  moreLabel: string;
  vehicleYearLabel: string;
  vehicleModelLabel: string;
  description: string;
  apiKey: string;
  imageContainer: {
    images: Array<data>;
  };
};
type data = {
  image: ContentfulFile;
  name: string;
  tags: string[];
};

function VehicleConditionItem({
  icon,
  label,
  apiKey,
  description,
  contentBoxType,
  statusUnavailableLabel,
  statusSuccessLabel,
  statusWarningLabel,
  statusWarningDescription,
  vehicleYearLabel,
  vehicleModelLabel,
  moreLabel,
  imageContainer,
}: VehicleConditionItemProps) {
  const { vehicleData, isExpandAll, displayViewMode } = useVehicleConditionContext();
  const [isExpanded, toggleExpanded] = useState<boolean>(false);
  const [isConfirmationModalVisible, toggleConfirmationModal] = useState<boolean>(false);
  const { data: vehicleStatus } = useSelector(({ vehicleReducer }) => vehicleReducer.vehicleHealth);
  const { account } = useSelector(({ accountReducer }) => accountReducer);
  const tenantId = account?.data?.tenantId;

  const milStatusChecker = vehicleStatus?.mil?.filter((item) => item.type === apiKey);
  const milStatus = (milStatusChecker && milStatusChecker[0]?.status) || null;

  const isMilstatusWarning = milStatus === '1';
  const isDefaultViewMode = displayViewMode === 'default';
  const isWarningViewMode = displayViewMode === 'warning';
  const isExpandable = contentBoxType === 'expandable';

  const defaultViewModeHidden = isMilstatusWarning && isDefaultViewMode && isExpandable;
  const warningViewModeHidden = !isMilstatusWarning && isWarningViewMode && isExpandable;

  useEffect(() => {
    toggleExpanded(isExpandAll);
  }, [isExpandAll]);

  const renderWarningDescriptionWithBrand = () => {
    if (statusWarningDescription) {
      const regex = /{{brand}}/i;

      return tenantId === 'nissanmx'
        ? statusWarningDescription.replace(regex, 'Nissan')
        : statusWarningDescription.replace(regex, 'Infiniti');
    }
  };

  useEffect(() => {
    renderWarningDescriptionWithBrand();
  });

  const renderChevron = () => {
    return isExpanded ? (
      <FaChevronUp className={styles['VehicleConditionItem-expand']} onClick={() => toggleExpanded(!isExpanded)} />
    ) : (
      <FaChevronDown className={styles['VehicleConditionItem-expand']} onClick={() => toggleExpanded(!isExpanded)} />
    );
  };

  const renderMoreButton = () => {
    return (
      isExpandable && (
        <span className={styles['VehicleConditionItem-more']} onClick={() => toggleConfirmationModal(true)}>
          <img className={styles['VehicleConditionItem-more-icon']} src={MoreIcon} alt={moreLabel} />
          <p className={styles['VehicleConditionItem-more-text']}>{moreLabel}</p>
        </span>
      )
    );
  };

  const renderVehicleInfo = () => {
    return !isExpandable && contentBoxType !== 'non expandable' ? (
      <div className={styles['VehicleConditionItem-vehicle-content']}>
        {<VehicleImageContainer images={imageContainer?.[0]?.images} />}
        <div className={styles['VehicleConditionItem-description-wrapper']}>
          <p
            className={styles['VehicleConditionItem-vehicle-description']}
          >{`${vehicleData?.make} ${vehicleData?.model}`}</p>
          <p className={styles['VehicleConditionItem-vehicle-description']}>
            {vehicleYearLabel}: {vehicleData?.year}
          </p>
          <p className={styles['VehicleConditionItem-vehicle-description']}>
            {vehicleModelLabel}: {vehicleData?.model}
          </p>
          <p className={styles['VehicleConditionItem-vehicle-description']}>VIN: {vehicleData?.vin}</p>
        </div>
      </div>
    ) : null;
  };

  const getStatusElement = (status: string, type: 'icon' | 'label') => {
    switch (type) {
      case 'icon':
        switch (status) {
          case '0':
            return StatusSuccessIcon;
          case '1':
            return StatusWarningIcon;
          default:
            return StatusUnavailableIcon;
        }
      case 'label':
        switch (status) {
          case '0':
            return statusSuccessLabel;
          case '1':
            return statusWarningLabel;
          default:
            return statusUnavailableLabel;
        }
    }
  };

  return (
    <div
      className={clsx(styles['VehicleConditionItem'], {
        [styles['VehicleConditionItem--hidden']]: defaultViewModeHidden || warningViewModeHidden,
        [styles['VehicleConditionItem--warning']]: isWarningViewMode,
      })}
    >
      <div className={styles['VehicleConditionItem-header']}>
        <div className={styles['VehicleConditionItem-title']}>
          <span className={styles['VehicleConditionItem-icon-default']}>
            <ContentfulImage imageData={icon} />
          </span>
          <h2 className={styles['VehicleConditionItem-name']}>{label}</h2>
        </div>

        {contentBoxType === 'non expandable' && (
          <span className={styles['VehicleConditionItem-label']}>
            {vehicleStatus?.odometer?.value && vehicleStatus?.odometer?.UoM
              ? `${vehicleStatus?.odometer?.value} ${vehicleStatus?.odometer?.UoM}`
              : statusUnavailableLabel}
          </span>
        )}

        {isExpandable && (
          <div className={styles['VehicleConditionItem-status']}>
            <img
              className={styles['VehicleConditionItem-icon-status']}
              src={getStatusElement(milStatus, 'icon')}
              alt="status_icon"
            />
            <h2 className={styles['VehicleConditionItem-status-name']}>{getStatusElement(milStatus, 'label')}</h2>
          </div>
        )}

        {isExpandable && renderChevron()}
      </div>

      {isExpanded && isExpandable && (
        <div className={styles['VehicleConditionItem-content']}>
          <p className={styles['VehicleConditionItem-description']}>
            {isDefaultViewMode ? getStatusElement(milStatus, 'label') : renderWarningDescriptionWithBrand()}
          </p>
          {renderMoreButton()}
        </div>
      )}

      {renderVehicleInfo()}

      <ModalContainer
        show={isConfirmationModalVisible}
        header={{ text: '', position: 'center', showTopBorder: false }}
        onCloseHandler={() => toggleConfirmationModal(false)}
      >
        <>
          <div className={styles['VehicleConditionItem-modal-header']}>
            <ContentfulImage imageData={icon} />
            <span className={styles['VehicleConditionItem-modal-label']}>{label}</span>
          </div>
          <div className={styles['Separator']}></div>
          <div className={styles['VehicleConditionItem-modal-description']}>{description}</div>
        </>
      </ModalContainer>
    </div>
  );
}

export default VehicleConditionItem;
