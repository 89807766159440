import React from 'react';

const ContrasenaHelpCallout = () => (
  <div>
    <ol>
      <li>La contraseña debe tener un mínimo de 8 caracteres.</li>
      <li>
        La contraseña no puede incluir 3 caracteres iguales consecutivos que estén también en el correo electrónico.{' '}
      </li>
      <li>
        Las contraseñas pueden incluir:
        <ul>
          <li>Letras mayúsculas: A-Z</li>
          <li>Letras minúsculas: a-z</li>
          <li>Números: 0-9</li>
          <li>Caracteres especiales: ~ ! @ # $ % ^ &amp; * ( ) _ - + = [ ] {} | / ; : ’ ' &lt; . , &gt; ? " \</li>
          <li>Restricción: Debe contener al menos 2 números (0-9) y 2 letras mayúsculas (A-Z)</li>
        </ul>
      </li>
    </ol>
  </div>
);

export default ContrasenaHelpCallout;
