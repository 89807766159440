export default {
  loginEnabled: false,
  mapEnabled: false,
  headerNavigationEnabled: false,
  headerUserEnabled: false,
  apiErrorsEnabled: false,
  suppressErrorDialogUrls: [
    '/telematicsservices/v1/vehicles\/.*\/locations/tracker/locate'
  ]
} as const;
