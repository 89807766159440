import { TOKEN_EVENTS } from '@redux/actions/token';

export interface ITokenState {
  accessToken?: string;
  id_token?: string;
}

const initState: ITokenState = {
  accessToken: '',
  id_token: '',
};

export interface ITokenAction {
  type: TOKEN_EVENTS;
  payload: any;
}

export default (state: ITokenState = initState, action: ITokenAction) => {
  switch (action.type) {
    case TOKEN_EVENTS.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      };
    case TOKEN_EVENTS.SET_ID_TOKEN:
      return {
        ...state,
        id_token: action.payload
      };
    default:
      return state;
  }
};
