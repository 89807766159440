import React from 'react';
import { DIALOG_ACTIONS } from '@redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { DialogState } from '@redux/reducers/dialog';
import Dialog from './Dialog';

const ModalReduxWrapper = () => {
  const { show, configs } = useSelector(({ dialogReducer }: { dialogReducer: DialogState }) => dialogReducer);
  const { title, message } = configs;

  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch({ type: DIALOG_ACTIONS.CLOSE });
  };

  return show ? (
    <Dialog title={title} onOk={closeDialog} labelOk="OK">
      {message}
    </Dialog>
  ) : null;
};

export default ModalReduxWrapper;
