import React, { ElementType } from 'react';
import { Route, Redirect } from 'react-router-dom';

import config from '@config/config';
import { useApi } from '@api';
import { isDealerPortal } from '@utils/index';

type SecureRouteProps = {
  Component: ElementType;
  componentProps?: any;
  path: string | Array<string>;
};

export const SecureRoute = ({ Component, componentProps, path }: SecureRouteProps) => {
  const { pathname } = window.location;
  const api = useApi();
  const isAuthenticated = api.storeService.getAccessToken() && api.storeService.getRefreshToken();
  const authExcludePath = config.get<string[]>('authExcludePath') || [];
  if (!isAuthenticated && !isDealerPortal && !authExcludePath.includes(pathname)) {
    return <Redirect to="/login" />;
  }

  return <Route exact path={path} render={(routerProps) => <Component {...routerProps} {...componentProps} />} />;
};
