// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1zQSRriyNjfojzufXItuSN {\n  height: 25px;\n  width: 86px;\n}\n\n._3xSjw30o1yVs5xUXXTkHoB {\n  display: inline-block;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AppLink/AppLink.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,YAAY;EACZ,WAAW;AACb","sourcesContent":[".wrapper {\n  height: 25px;\n  width: 86px;\n}\n\n.url {\n  display: inline-block;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_1zQSRriyNjfojzufXItuSN",
	"url": "_3xSjw30o1yVs5xUXXTkHoB"
};
export default ___CSS_LOADER_EXPORT___;
