// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2lu9CGDlYCMf1cVAkck_ZL {\n  cursor: text;\n}\n\n._2lu9CGDlYCMf1cVAkck_ZL a {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DynamicLink/DynamicLink.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".Dynamiclink-container {\n  cursor: text;\n}\n\n.Dynamiclink-container a {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Dynamiclink-container": "_2lu9CGDlYCMf1cVAkck_ZL"
};
export default ___CSS_LOADER_EXPORT___;
