enum TitanModels {
    TITAN = 'TITAN'
}

enum SuvModels {
    QX50 = 'QX50',
    QX60 = 'QX60',
    QX80 = 'QX80',
    QX30 = 'QX30',
    JX = 'JX',
    QX56 = 'QX56',
    KICKS = 'KICKS',
}

enum SedanModels {
    Q50 = 'Q50',
    Q60 = 'Q60',
    Q70 = 'Q70',
    Q80 = 'Q80',
    SENTRA = 'SENTRA',
}

const wireFrameModels = (model: string) => {
    const imagePromiseArray: Promise<any>[] = [];
    const carParts = [
        'base',
        'hood_open',
        'tailgate_open',
        'door_front_open',
        'door_front_closed',
        'door_rear_open',
        'door_rear_closed',
    ];
    carParts.forEach((part) => {
        if ((Object.values(SuvModels) as string[]).includes(model)) {
            imagePromiseArray.push(import(`@assets/suv/suv_${part}@2x.png`));
        } else if ((Object.values(SedanModels) as string[]).includes(model)) {
            imagePromiseArray.push(import(`@assets/sedan/sedan_${part}@2x.png`));
        } else if ((Object.values(TitanModels) as string[]).includes(model)) {
            imagePromiseArray.push(import(`@assets/truck/truck_${part}@2x.png`));
        } else {
            imagePromiseArray.push(import(`@assets/default/default_${part}@2x.png`));
        }
    });
    return imagePromiseArray;
}
export default wireFrameModels
