import { Modify } from '@cv/portal-common-lib';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { createGeofence, deleteGeofence, getGeofence, updateGeofence } from "@cv/portal-rts-lib/geofences";
import { CreateGeofenceRequest, DeleteGeofenceRequest, GetGeofenceRequest, UpdateGeofenceRequest, CreateGeofencePayload, UpdateGeofencePayload } from "@cv/portal-rts-lib/geofences/models";

type GeofenceRequestProps = {
  vehicleId: string;
  accessToken: string;
}

type CreateGeofenceRequestProps = Modify<
  GeofenceRequestProps,
  {
    data: CreateGeofencePayload
  }
>

type UpdateGeofenceRequestProps = Modify<
  GeofenceRequestProps,
  {
    data: UpdateGeofencePayload
  }
>

type GetValetRequestProps = {
  accessToken: string;
  vehicleId: string;
}

type DeleteGeofenceRequestProps = {
  accessToken: string;
  vehicleId: string;
  geofenceId: string;
}

export default class GeofenceService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment
  }

  async getGeofence({accessToken, vehicleId}: GetValetRequestProps) {
    const request: GetGeofenceRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId
      },
      headers: {
        Authorization: accessToken,
      },
    };
    return await getGeofence(request);
  }

  async createGeofence({accessToken, vehicleId, data}: CreateGeofenceRequestProps) {
    const request: CreateGeofenceRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId
      },
      headers: {
        Authorization: accessToken,
      },
      data
    };
    return await createGeofence(request);
  }

  async updateGeofence({accessToken, vehicleId, data}: UpdateGeofenceRequestProps) {
    const request: UpdateGeofenceRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId
      },
      headers: {
        Authorization: accessToken,
      },
      data
    };
    return await updateGeofence(request);
  }

  async deleteGeofence({accessToken, vehicleId, geofenceId}: DeleteGeofenceRequestProps) {
    const request: DeleteGeofenceRequest = {
      environment: this.environment,
      pathParams: {
        vehicleId,
        geofenceId,
      },
      headers: {
        Authorization: accessToken,
      }
    };
    return await deleteGeofence(request);
  }
}
