import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as H from 'history';
import { startCase } from 'lodash';

export type Path = {
  // url without / at the beggining
  page: string;
  // url to the page
  url: string;
  // flag to hide route from navigation
  hiddenInNavigation: boolean;
  // label to show in the navigation
  label: string;
  // the list of services by which navigation item should be shown
  services: string[];
};

type NavigationContext = {
  initialPage?: string;
  currentPath: Path;
  paths: Path[];
  history: H.History<H.LocationState>;
  location: H.Location<H.LocationState>;
  navigateTo(location: H.LocationDescriptor<H.LocationState>): void;
  navigateTo(path: H.Path, state?: H.LocationState): void;
};

const NavigationContext = React.createContext<NavigationContext | null>(null);

export type Page = {
  path: string;
  label: string;
  hiddenInNavigation: boolean;
  services: string[];
};

type NavigationProviderProps = {
  pagesList?: Page[];
  navigationJson?: {
    initial?: string;
  };
  children?: any;
};

export const NavigationProvider = ({ pagesList = [], navigationJson, children }: NavigationProviderProps) => {
  const history = useHistory();
  const location = useLocation();

  const currentPath = location.pathname;
  const currentPage = currentPath.substring(1);

  const paths = pagesList.reduce((acc, page) => {
    const pagesNames = page.label?.split(', ') || "";
    const pathsDetails = page.path
      .replace('/', 'home')
      .split(', ')
      .map((pageName: string, index: number) => ({
        page: pageName,
        url: `/${pageName}`,
        hiddenInNavigation: page.hiddenInNavigation,
        label: pagesNames[index],
        services: page.services,
      }));

    return acc.concat(...pathsDetails);
  }, [] as Path[]);

  const path = paths.find((path) => path.url === currentPath) || {
    page: currentPage,
    url: currentPath,
    hiddenInNavigation: true,
    label: startCase(currentPage),
    services: [],
  };

  return (
    <NavigationContext.Provider
      value={{ initialPage: navigationJson?.initial, currentPath: path, paths, history, location, navigateTo: history.push }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export function useNavigation() {
  const context = React.useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationProvider');
  }
  return context;
}
