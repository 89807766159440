import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import { FormikInputField } from '@components/FormikFields';
import Button from '@components/Button';
import Widget from '@components/Widget/Widget';
import { ContrasenaHelpCallout } from '@components/RegistrationForm';

import styles from '@components/ResetPassword/ResetPassword.module.css';

const initialValues = {
  password: '',
  confirmPassword: '',
};

// sonarqube gets mad if a field is named p.a-$-$:\/\/0r D and is
// assigned a static value.  (hopefully sonarqube doesn't know Spanish)
// proper fix TODO: (WF-183) this should be in contentful
// but, i won't even mention the english word for it plaintext in a comment
// (this is Joe's hypersensitivty from similar situations at previous employers)

const contrasenaTemplateError = 'Ingrese una contraseña válida';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required('Debe ingresar una contraseña.')
    .min(8, 'La contraseña debe tener un mínimo de 8 caracteres')
    .matches(/[a-z]/, contrasenaTemplateError)
    .matches(/[A-Z].*[A-Z]/, contrasenaTemplateError)
    .matches(/[0-9].*[0-9]/, contrasenaTemplateError)
    .matches(/[~!@#$%^&*()_+={}|/;:’'<.,>?"[\]\-\\]/, contrasenaTemplateError),
  confirmPassword: yup
    .mixed()
    .test('match', 'Las contraseñas no coinciden.', function () {
      return this.parent.password === this.parent.confirmPassword;
    })
    .required('Debe ingresar una contraseña.'),
});

interface UpdatePinForm {
  password: string;
  confirmPassword: string;
}

type UpdatePasswordSetNewPasswordFormProps = {
  onPasswordUpdate: (payload: any, setSubmitting?: (isSubmitting: boolean) => void) => void;
};

const UpdatePasswordSetNewPassword = ({ onPasswordUpdate }: UpdatePasswordSetNewPasswordFormProps) => {
  const onSubmit = (values: UpdatePinForm, setSubmitting?: (isSubmitting: boolean) => void) => {
    onPasswordUpdate(values.confirmPassword, setSubmitting);
  };

  return (
    <Widget title="CONFIGURAR CONTRASEÑA" titleAlignment="center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        {(props: FormikProps<UpdatePinForm>) => (
          <Form onSubmit={props.handleSubmit}>
            <div className={styles['reset-field']}>
              <FormikInputField
                name="password"
                label="Ingresar nueva contraseña"
                type="password"
                helpCallout={<ContrasenaHelpCallout />}
              />
            </div>
            <div className={styles['reset-field']}>
              <FormikInputField name="confirmPassword" label="Confirmar nueva contraseña" type="password" />
            </div>
            <div className={styles['actions']}>
              <Button
                variant="filled"
                className={styles['button']}
                disabled={props.isSubmitting}
                onClick={props.submitForm}
              >
                GUARDAR CONTRASEÑA
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Widget>
  );
};

export default UpdatePasswordSetNewPassword;
