type PointerEvents = {
  POINTER_DOWN: {
    pointer: string;
    touch: string;
  };
  POINTER_UP: {
    pointer: string;
    touch: string;
  };
};

type EventListenerProps = {
  target: Element | Document | Window;
  event: PointerEvents["POINTER_DOWN"] | PointerEvents["POINTER_UP"];
  callback: (event: Event) => void;
};

export const POINTER_EVENTS: PointerEvents = {
  POINTER_DOWN: {
    pointer: "pointerdown",
    touch: "touchstart",
  },
  POINTER_UP: {
    pointer: "pointerup",
    touch: "touchend",
  },
};

export function addPointerEventListener(target: EventListenerProps["target"], event: EventListenerProps["event"], callback: EventListenerProps["callback"]) {
  if (window.PointerEvent) {
    target.addEventListener(event.pointer, callback);
  } else {
    target.addEventListener(event.touch, callback);
  }
}

export function removePointerEventListener(target: EventListenerProps["target"], event: EventListenerProps["event"], callback: EventListenerProps["callback"]) {
  target.removeEventListener(event.pointer, callback);
  target.removeEventListener(event.touch, callback);
}
