export enum VEHICLE_ACTIONS {
  SET_VEHICLE_DATA = 'SET_VEHICLE_DATA',
  SET_VEHICLE_HEALTH = 'SET_VEHICLE_HEALTH'
}

export interface IVehicle {
  vin: string;
  active: boolean;
  make: string;
  model: string;
  year: string;
  vehicleId: string;
  telematicsProgramId: string;
  activeServices: string[];
}

export const setVehicleData = (data: IVehicle) => {
  return {
    type: VEHICLE_ACTIONS.SET_VEHICLE_DATA,
    data,
  };
};
