import React, { useState } from 'react';
import { omit } from 'lodash';
import Accordion from '@components/Accordion';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import styles from './Faq.modules.css';

type FaqProps = {
  labels: {
    [key: string]: {
      primary: string;
      secondary?: string;
    }
  }
}

const Faq = ({ labels }: FaqProps) => {
  const [openedAccordion, setOpen] = useState<string>('');

  const toggleAccordion = (title: string) => {
    setOpen((prevTitle) => {
      if (prevTitle === title) {
        return '';
      }

      return title;
    });
  };

  return (
    <div className={styles.root}>
      <h2>{labels.title.primary}</h2>
      {Object.values(omit(labels, 'title')).map(({ primary: title, secondary: text = "" }) => {
        return (
          <Accordion
            key={title}
            title={title}
            text={<ReactMarkdown remarkPlugins={[gfm]} linkTarget="_blank">{text}</ReactMarkdown>}
            open={title === openedAccordion}
            onChange={() => toggleAccordion(title)}
            className={styles.accordion}
            classes={{
              title: styles['accordion-title'],
            }}
          />
        );
      })}
    </div>
  );
};

export default Faq;
