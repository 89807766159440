import { AccountData } from "../../api/types";

export enum ACCOUNT_ACTIONS {
  SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA',
  SET_DRIVERS = 'SET_DRIVERS',
  SET_CONTACTS = 'SET_CONTACTS',
  SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
  ADD_DRIVER = 'ADD_DRIVER',
  ADD_CONTACT = 'ADD_CONTACT',
  UPDATE_DRIVER = 'UPDATE_DRIVER',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  SET_VEHICLE_DETAILS = 'SET_VEHICLE_DETAILS',
  SET_TENANT_ID = 'SET_TENANT_ID',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
}

export const setAccountData = (data: AccountData) => {
  return {
    type: ACCOUNT_ACTIONS.SET_ACCOUNT_DATA,
    data,
  };
};

export const setTenantId = (data: string) => {
  return {
    type: ACCOUNT_ACTIONS.SET_TENANT_ID,
    data,
  };
};
