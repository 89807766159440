import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import ContentRenderer from '@components/ContentRenderer';
import useToggle from '@hooks/useToggle';
import { useApi } from '../../api';

import styles from './Header.module.css';

type HeaderUser = {
  userPrefix?: string;
  navigation?: object;
  logoutLabel?: string;
  pagesToDisplayLogout?: string[];
  pagesToHideTcLinkInUserDropDown?: string[];
  pagesToHideDropdown?: string[];
};

export default function HeaderUser({
  userPrefix = '',
  navigation,
  logoutLabel,
  pagesToDisplayLogout = [],
  pagesToHideTcLinkInUserDropDown = [],
  pagesToHideDropdown = [],
}: HeaderUser) {
  const history = useHistory();
  const { account } = useSelector(({ accountReducer }) => accountReducer);
  const userLabel = `${userPrefix} ${account?.data?.userName?.givenName || ''}`;
  const [isDropdownVisible, toggleIsDropdownVisible] = useToggle(false);
  const api = useApi();
  const isAuthenticated = api.storeService.getAccessToken();
  const location = useLocation();
  const [showLogoutLabel, setShowLogoutLabel] = useToggle(false);
  const [showTCinDropdown, setShowTCinDropdown] = useToggle(false);
  const [forceHidden, setForceHidden] = useToggle(false);

  useEffect(() => {
    setShowLogoutLabel(logoutLabel && pagesToDisplayLogout.includes(location.pathname));
    setShowTCinDropdown(!(pagesToHideTcLinkInUserDropDown.includes(location.pathname)));
    setForceHidden(pagesToHideDropdown.includes(location.pathname));
  }, [location]);

  if (!navigation) {
    return <div className={styles['Header-user']}>{userLabel}</div>;
  }

  const handleLogout = () => {
    history.push('/logout');
  };

  return (
    <>
      {!forceHidden && isAuthenticated && account?.data?.name && (
        <Fragment>
          <button type="button" onClick={toggleIsDropdownVisible} className={styles['Header-user']}>
            {userLabel}
          </button>

          <div className={clsx(styles['Header-dropdown'], { [styles.show]: isDropdownVisible })}>
            {showTCinDropdown && (<ContentRenderer
              name="userMenuNavigation"
              content={[navigation]}
              className={styles['Header-dropdown-navigation']}
            />)}
            {showLogoutLabel && (
              <div className={styles['Header-dropdown-logout']} onClick={handleLogout}>
                {logoutLabel}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </>
  );
}
