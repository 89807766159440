import * as yup from 'yup';

const passwordValidationError = 'Ingrese una contraseña válida'; //NOSONAR

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Debe ingresar una dirección de correo electrónico.')
    .email('Ingrese un correo electrónico válido'),
  password: yup
    .string()
    .trim()
    .required('Debe ingresar una contraseña.')
    .min(8, 'La contraseña debe tener un mínimo de 8 caracteres')
    .matches(/[a-z]/, passwordValidationError)
    .matches(/[A-Z].*[A-Z]/, passwordValidationError)
    .matches(/[0-9].*[0-9]/, passwordValidationError)
    .matches(/[~!@#$%^&*()_+={}|/;:’'<.,>?"[\]\-\\]/, passwordValidationError)
    .when(['email'], (email: string = '', schema: any) => {
      if (!email) {
        return schema;
      }
      // Check if there is 3 repeating characters
      const regex = /([a-zA-Z0-9])\1{2,}/g;
      const found = email.match(regex);

      return found
        ? schema.test('contains_3_letters', passwordValidationError, (value: string) => {
            if (!value) {
              return true;
            }

            // Check if user has typed 3 repeating characters same as in email
            return !found.some((letters) => value.includes(letters));
          })
        : schema;
    }),
  name: yup
    .string()
    .trim()
    .required('Debe ingresar un nombre.')
    .min(2, 'El nombre debe tener al menos 2 caracteres alfanuméricos.')
    .matches(/^([a-zA-Z\- '0-9]*)$/, "Solo se permite usar letras, espacios, apóstrofo (') y guion (-)."),
  surname: yup
    .string()
    .trim()
    .required('Debe ingresar un Apellido Paterno')
    .min(2, 'El apellido paterno debe tener al menos 2 caracteres alfanuméricos.')
    .matches(/^([a-zA-Z\- '0-9]*)$/, "Solo se permite usar letras, espacios, apóstrofo (') y guion (-)."),
  secondSurname: yup
    .string()
    .trim()
    .required('Debe ingresar un Apellido Materno')
    .min(2, 'El apellido materno debe tener al menos 2 caracteres alfanuméricos.')
    .matches(/^([a-zA-Z\- '0-9]*)$/, "Solo se permite usar letras, espacios, apóstrofo (') y guion (-)."),
});

export { validationSchema };
