import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { labelReducer } from '@utils/filter-label-dictionary';
import { ErrorBoundary } from 'react-error-boundary';
import useInterceptor from '../../api/interceptor';
import loadAppDynamics from '../../loadAppDynamics';
import { ThemeProvider } from '@components/Theme';
import Header from '@components/Header';
import Footer from '@components/Footer';
import config from '@config/config';
import ErrorPage from '@components/ErrorPage';
import { PageContent } from './AppWrapper';
import AppRouter from './AppRouter';
import ReduxDialog from '@components/Dialog/ReduxDialog';
import GlobalPreferences from '@components/GlobalPreferences';
import { LoaderBackdrop } from '@components/Loader';
import { useSelector } from 'react-redux';
import { NavigationProvider } from '@components/Navigation';
export type AppProps = {
  pageContent?: PageContent;
};
function App({ pageContent = {} }: AppProps) {
  const { header = [], footerContent = [], globalPreferences = [], pagesList, navigationMachine } = pageContent;
  const [headerContent] = header;
  const [globalPreferencesContent] = globalPreferences;
  const loginEnabled = Boolean(config.get('loginEnabled', true));
  const headerNavigationEnabled = Boolean(config.get('headerNavigationEnabled', true));
  const headerUserEnabled = Boolean(config.get('headerUserEnabled', true));
  const appDynamicsEnabled = Boolean(config.get('APP_DYNAMICS_ENABLED', false));
  const appDynamicsKey = config.getOemValue('APP_DYNAMICS_KEY');
  if (appDynamicsEnabled) {
    loadAppDynamics(appDynamicsKey);
  }
  useInterceptor(labelReducer(pageContent.apiErrors));
  const isLoading = useSelector(({ loadingReducer }) => loadingReducer.isLoading);
  return (
    <Router>
      <NavigationProvider pagesList={pagesList} navigationJson={navigationMachine}>
        {globalPreferencesContent && <GlobalPreferences content={globalPreferencesContent} />}
        <ThemeProvider themes={pageContent.theme}>
          <div className="App">
            {isLoading && <LoaderBackdrop />}
            {headerContent && (
              <Header content={headerContent} withNavigation={headerNavigationEnabled} withUser={headerUserEnabled} />
            )}
            <ErrorBoundary FallbackComponent={ErrorPage}>
              <AppRouter isLoginEnabled={loginEnabled} pageContent={pageContent} />
            </ErrorBoundary>
            <Footer content={footerContent} />
            <ReduxDialog />
          </div>
        </ThemeProvider>
      </NavigationProvider>
    </Router>
  );
}
export default App;
