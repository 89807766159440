import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { CardView } from '@components/Card';
import Button from '@components/Button';
import { useHistory } from 'react-router-dom';
import { VehicleResponse } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { useApi } from '@api';
import { useDispatch } from 'react-redux';

import styles from './AccountEnrollment.module.css';
import { IVehicle } from '@redux/actions';
import { isEmpty } from 'lodash';
import { TelematicsServiceDetail } from '@cv/portal-cps-lib/vehicle/service-management/models/search-telematics-service';
import { PROVISIONING_COMPLETE } from '../VinStatusPage/constants';
import { setSubscribedServices } from '@redux/actions';
import { ServiceLabels, myCarFinderLabels } from '@components/Map/constants';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';

type SuccessPageProps = {
  vin: string;
  labels: Record<string, string>;
};

const SuccessPage = ({ vin, labels }: SuccessPageProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const api = useApi();
  const [vehicleDetails, setVehicleDetails] = useState<Partial<IVehicle>>({});
  const [subscribedServiceIds, setSubscribedServiceIds] = useState<String[]>([]);
  useEffect(() => {
    const userId = api.storeService.getUserId();
    const locale = api.storeService.getLocale();
  
    api.getAccountDetails(locale, userId).then(({ data }) => {
      api.getVinSubscribedPackages(data?.vehicleIds).then((result) => {
        dispatch(setSubscribedServices(result));
        let vehicle = result.find((data) => data.vin === vin);
        setSubscribedServiceIds(vehicle?.subscribedServiceIds);
        api.getVehicleDetails(locale, vehicle?.vehicleId).then((result) => {
          setVehicleDetails(result?.data || {});
        });
      });
    });
  }, []);

  const handleGoHome = async () => {
    const activeServices = await getActiveServices(vehicleDetails?.vehicleId);
    let path;
    if (!isServiceSubscribed([...ServiceLabels.ALL_REMOTE_LABELS, ...ServiceLabels.REMOTE_VEHICLE_STATUS, ...myCarFinderLabels], activeServices)) {
      path = 'profile';
    }else{
      path = 'home'
    }
    api.storeService.setVehicleData({
      vin,
      vehicleId: vehicleDetails.vehicleId || '',
      active: true,
      make: vehicleDetails?.make,
      model: vehicleDetails?.model,
      year: vehicleDetails?.year,
      activeServices: activeServices,
      telematicsProgramId: vehicleDetails?.telematicsProgramId,
      services: vehicleDetails?.services
    });
    history.push(`/${path}`);
  };

  const getActiveServices = async (vehicleId: string) => {
    let activeServices: string[] = [];
    try {
      const { data } = await api.getTelematicServices(vehicleId, 'en-US'); // Response is always expected to be in en-US, no Translation is needed to get active services
      if (!isEmpty(data)) {
        activeServices = data?.telematicsServiceDetails?.reduce(
          (serviceList: string[], serviceObj: TelematicsServiceDetail) => {
            if (
              serviceObj?.provisioningStatus === PROVISIONING_COMPLETE &&
              subscribedServiceIds.includes(serviceObj.vehicleServiceId)
            ) {
              serviceList.push(serviceObj.vehicleServiceName);
            }
            return serviceList;
          },
          [],
        );
      }
    } catch (err) {
      console.log(err);
    }
    return activeServices;
  };

  return (
    <CardView
      type="main"
      className={styles['success-page-container']}
      classes={{ inner: styles['success-page-inner-container'] }}
    >
      <div className={styles['success-page-content']}>
        <h3 className={styles['success-page-title']}>
          <FaCheckCircle className={styles['success-page-icon']} />
          <p>{labels.successTitle}</p>
        </h3>
        <p className={styles['success-page-details']}>{labels.successDescription}</p>
        <Button onClick={handleGoHome} disabled={!vehicleDetails.vehicleId}>
          {labels.successButton}
        </Button>
      </div>
    </CardView>
  );
};

export default SuccessPage;
