
export const timeOptions = [
  { label: '12:00AM', value: '12:00AM' },
  { label: '1:00AM', value: '1:00AM' },
  { label: '2:00AM', value: '2:00AM' },
  { label: '3:00AM', value: '3:00AM' },
  { label: '4:00AM', value: '4:00AM' },
  { label: '5:00AM', value: '5:00AM' },
  { label: '6:00AM', value: '6:00AM' },
  { label: '7:00AM', value: '7:00AM' },
  { label: '8:00AM', value: '8:00AM' },
  { label: '9:00AM', value: '9:00AM' },
  { label: '10:00AM', value: '10:00AM' },
  { label: '11:00AM', value: '11:00AM' },
  { label: '12:00PM', value: '12:00PM' },
  { label: '1:00PM', value: '1:00PM' },
  { label: '2:00PM', value: '2:00PM' },
  { label: '3:00PM', value: '3:00PM' },
  { label: '4:00PM', value: '4:00PM' },
  { label: '5:00PM', value: '5:00PM' },
  { label: '6:00PM', value: '6:00PM' },
  { label: '7:00PM', value: '7:00PM' },
  { label: '8:00PM', value: '8:00PM' },
  { label: '9:00PM', value: '9:00PM' },
  { label: '10:00PM', value: '10:00PM' },
  { label: '11:00PM', value: '11:00PM' },
];

export const pollingTime = 211000;
