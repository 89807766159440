import React, { useEffect, useState } from 'react';

import Button from '@components/Button';
import InputSwitch from '@components/InputSwitch';
import InputRange from '@components/InputRange';
import TabHeader from '../TabHeader';
import ActiveView from '../ActiveView';
import { useApi } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { ALERTS_ACTIONS, DIALOG_ACTIONS, GEOFENCE_ACTIONS } from '@redux/actions/';

import styles from '../../MonitoringAlerts/MonitoringAlerts.module.css';
import { saveValetAlert, saveUpdatedValetAlert } from '../Forms/utils';
import { SET_VEHICLE_LOCATION } from '@components/Map/constants';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { Status } from '@cv/portal-rts-lib/doors/enums';
import { setLoadingStatus } from '@redux/actions/loading';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { GetGeofenceResponse } from '@cv/portal-rts-lib/geofences/models';
import { pollingTime } from '../constants';
import { RootState } from '../../../../reducers';

type SliderUnit = 'Mile' | 'Kilometer';

type TabValetProps = {
  cancelButton: string;
  confirmButton: string;
  defaultViewContent: string;
  formUnit: SliderUnit;
  sliderDescription: string;
  sliderMaxValue: string;
  sliderMinValue: string;
  sliderStepValue: string;
  sliderUnit: string;
  switchButtonLabel: string;
  switchLabelUnit: string;
  switchLabel: string;
  title: string;
  createSuccessMsg: string;
  createErrorMsg: string;
  updateErrorMsg: string;
  successDialogHeader: string;
  errorDialogHeader: string;
};

export default function TabValet({
  cancelButton,
  confirmButton,
  defaultViewContent,
  formUnit,
  sliderDescription,
  sliderMaxValue,
  sliderMinValue,
  sliderStepValue,
  sliderUnit,
  switchButtonLabel,
  switchLabelUnit,
  switchLabel,
  title,
  createSuccessMsg,
  createErrorMsg,
  updateErrorMsg,
  successDialogHeader,
  errorDialogHeader,
}: TabValetProps) {
  const api = useApi();
  const valet = useSelector(({ geofenceReducer }: RootState) => geofenceReducer.valet);
  const initialSliderValue = valet?.geometry?.radius?.value || sliderMinValue;
  const [radiusValue, setRadiusValue] = useState(initialSliderValue);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(valet?.state === 'ACTIVE' || false);
  const [valetRadio, setValetRadio] = useState<string>(initialSliderValue.toString());
  const [view, setView] = useState('default');
  let valetAlertId = '';

  const getGeofence = async () => {
    try {
      const response = await api.getGeofence();
      const valetData = response.data.geofences.find((item) => item.alertType === 'VALET');
      if (valetData) {
        dispatch({ type: GEOFENCE_ACTIONS.GET_VALET, data: valetData });
      } else {
        dispatch({ type: GEOFENCE_ACTIONS.RESET_VALET })
      }
    } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      dispatch({ type: GEOFENCE_ACTIONS.RESET_VALET })
      console.log('error api', err);
    }
  };

  useEffect(() => {
    getGeofence();
  }, []);

  useEffect(() => {
    setSelected(valet.state === 'ACTIVE');
    setRadiusValue(valet.geometry.radius.value);
  }, [valet]);

  const getVehicleLocation = async () => {
    try {
      const { locations: [location] = [] } = await api.getVehicleLocation(api.storeService.getAccessToken());
      dispatch({ type: SET_VEHICLE_LOCATION, vehicleLocation: location });
      return location;
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeValet = () => {
    setSelected(!selected);
    saveValet(valet?.lastSvcReqId);
  };

  const validationCallback = (response: APIResponse<GetGeofenceResponse>) => {
    const valet = response.data.svcRequests.find((item) => item.id === valetAlertId);
    const valetData = response.data.geofences.filter((item) => item.alertType === 'VALET');
    const valetStatus = valet?.status;
    const isSuccess = valetStatus?.includes(Status.SUCCESS);

    if (isSuccess || !response.data.geofences.length) {
      if (isSuccess) {
        dispatch({ type: GEOFENCE_ACTIONS.GET_VALET, data: valetData[0] });
      }
      return PollingStatus.SUCCESS;
    }

    if (valetStatus?.includes(Status.FAILED)) {
      return PollingStatus.ERROR;
    }

    return PollingStatus.PENDING;
  };

  const successCallback = () => {
    onFormClose();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: createSuccessMsg, title: successDialogHeader } });
  };

  const errorCallback = () => {
    onFormClose();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: createErrorMsg, title: errorDialogHeader } });
  };

  const onFormClose = () => {
    setView('default');
    setRadiusValue(valetRadio);
    dispatch(setLoadingStatus(false));
  };

  const saveValet = async (valetId = '') => {
    const vehicleLocation = await getVehicleLocation();
    const saveValet = {
      valetRadio,
      formUnit,
      latitude: vehicleLocation?.coordinates?.latitude,
      longitude: vehicleLocation?.coordinates?.longitude,
      selected: !selected,
      ...(valetId && { id: valetId }),
    }
    const updateValet = {
      valetRadio,
      formUnit,
      latitude: vehicleLocation?.coordinates?.latitude,
      longitude: vehicleLocation?.coordinates?.longitude,
      selected: !selected,
      id: valetId
    }
    const valetPayload = saveValetAlert(saveValet);
    if (!valetId) {
      dispatch(setLoadingStatus(true));
      const { data: valet } = await api.createGeofence(valetPayload);
      valetAlertId = valet.svcReqId;
      initiatePolling({
        pollingFunc: api.getGeofence.bind(api),
        validationCallback,
        successCallback,
        errorCallback,
        timeout: pollingTime,
      });
    } else {
      dispatch(setLoadingStatus(true));
      const updatedValetPayload = saveUpdatedValetAlert(updateValet)
      try {
        await api.updateGeofence(updatedValetPayload);
        valetAlertId = valetId;
        initiatePolling({
          pollingFunc: api.getGeofence.bind(api),
          validationCallback,
          successCallback,
          errorCallback,
          timeout: pollingTime,
        });
      } catch (error) {
        dispatch(setLoadingStatus(false));
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: updateErrorMsg, title: errorDialogHeader } });
      }
    }
  };

  const onConfirm = () => {
    saveValet(valet?.lastSvcReqId);
  };

  return (
    <>
      <ActiveView currentView={view} activeView={'default'}>
        <TabHeader
          label={title}
          onEdit={() => setView('editItem')}
          content={defaultViewContent}
          displayEditButton={true}
        />
        <InputSwitch
          type="wide"
          checked={selected}
          label={`${switchButtonLabel} ${radiusValue} ${switchLabelUnit}`}
          className={styles['form-valet-switch']}
          onChange={onChangeValet}
        />
      </ActiveView>
      <ActiveView currentView={view} activeView={'editItem'}>
        <TabHeader label={title} content={defaultViewContent} displayEditButton={false} />
        <InputRange
          value={Number(valetRadio)}
          min={Number(sliderMinValue)}
          max={Number(sliderMaxValue)}
          step={Number(sliderStepValue)}
          unit={sliderUnit}
          onChange={(e) => setValetRadio(e.toString())}
          label={switchLabel}
          labelUnit={switchLabelUnit}
          hint={sliderDescription}
          showMinMaxValues
        />
        <div className={styles['button-container']}>
          <Button variant="outlined" onClick={onFormClose}>
            {cancelButton}
          </Button>
          <Button variant="filled" onClick={onConfirm}>
            {confirmButton}
          </Button>
        </div>
      </ActiveView>
    </>
  );
}
