import { SETTINGS_ACTIONS } from '../actions/';

type SettingsState = {
  locale: string;
};

type SettingsAction = {
  type: SETTINGS_ACTIONS;
  data: string;
};
const initState: SettingsState = {
  locale: 'fr-CA',
};

export default (state: SettingsState = initState, action: SettingsAction) => {
  switch (action.type) {
    case SETTINGS_ACTIONS.SET_LOCALE:
      return {
        ...state,
        locale: action.data,
      };
    default:
      return state;
  }
};
