import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getCookie } from "../../utils";
import { useApi } from "../../api";
import { LoaderBackdrop } from '@components/Loader';
import { ROOT_EVENTS } from '../../reducers';
import { useDispatch } from 'react-redux';

const LoginCallBack = () => {
  const history = useHistory();
  const api = useApi();
  const [apiStatus, setApiStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const dispatch = useDispatch();

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get('code') || '';
    const verifier = getCookie('verifier') || '';
    console.log('sxm login call back code: ', code);
    setApiStatus('loading')
    api.getAccessToken({ code, verifier, }).then(() => {
      setApiStatus('idle');
      history.push('/vinStatus');
    }).catch((e) => {
      console.error(e);
      setApiStatus('error');
      clearValues();
    });
  }, []);

  const clearValues = () => {
    dispatch({ type: ROOT_EVENTS.DESTROY_SESSION });
    history.push('/login');
  }

  return (
    <Fragment>
      {apiStatus === 'loading' && <LoaderBackdrop></LoaderBackdrop>}
    </Fragment>)
};
export default LoginCallBack;
