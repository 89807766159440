import React from 'react';

const NotFound = () => {
  // THIS IS TEMP UNTIL THERE IS A DESIGN FOR 404 HANDLING
  return (
    <div className="text-center">
      <h1>404 Not Found</h1>
    </div>
  );
};

export default NotFound;
