import { DIALOG_ACTIONS } from '../actions/';

export type DialogConfigs = {
  title?: string;
  message: string;
};

export type DialogState = {
  show: boolean;
  configs: Partial<DialogConfigs>;
};

type DialogAction = {
  type: DIALOG_ACTIONS;
  data: DialogConfigs;
};

const initialState = {
  show: false,
  configs: {},
};

export default (state: DialogState = initialState, action: DialogAction) => {
  switch (action.type) {
    case DIALOG_ACTIONS.SHOW:
      const { title, message } = action.data;

      return {
        ...state,
        show: true,
        configs: { title, message },
      };
    case DIALOG_ACTIONS.CLOSE:
      return {
        ...state,
        show: false,
        configs: {},
      };
    default:
      return state;
  }
};
