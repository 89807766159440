import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@types/ContentfulComponent';

import styles from './Tab.module.css';
import TabContent from './TabContent';

type TabDefaultItemProps = {
  label: string, tabItemContent: Array<any>
}

type TabProps = {
  tabType: 'primary' | 'secondary' | 'tertiary'
  selectedTab?: 'none' | string;
  className?: string;
  hideAllTabs?: boolean;
  tabDefaultItem?: TabDefaultItemProps;
  tabItemList?: Array<ContentfulComponent>
  serviceTags?: string[];
}

const Tab = ({
  tabDefaultItem,
  className,
  hideAllTabs,
  tabType = 'primary',
  tabItemList,
  serviceTags
}: TabProps) => {
  const selectedTab = tabDefaultItem?.label.toLowerCase() || 'none';
  const [activeTab, setActiveTab] = useState(selectedTab);
  const tabClassName = clsx(styles.TabContainer, styles[`TabContainer--${tabType}`], className);
  const activeTabContent = tabItemList?.find((item) => item.label.toLowerCase() === activeTab.toLowerCase());

  const onClickTabItem = (tab: string, callback?: Function) => {
    setActiveTab(tab);
    callback && callback();
  };

  useEffect(() => {
    if (hideAllTabs) {
      setActiveTab('none');
    }
  }, [hideAllTabs])

  return (
    <div className={tabClassName}>
      <ul className={styles.TabList}>
        {tabItemList?.length && (
          <ContentRenderer
            name="Tab item"
            content={tabItemList}
            tabType={tabType}
            activeTab={activeTab}
            serviceTags={serviceTags}
            onClick={(label: string) => onClickTabItem(label)}
          />
        )}
      </ul>
      {activeTabContent?.tabItemContent && (
        <TabContent content={activeTabContent.tabItemContent} className={className} />
      )}
    </div >
  )
}

export default Tab;
