import React from "react";
import { ContentfulConnect } from "@cv/webframework-react-components";
import { connect } from 'react-redux';
import isEmpty from "lodash/isEmpty";

import config from "@config/config";
import App, { AppProps } from "./App";
import { getOemName } from "@utils/oem";

const filterTags = (pageContent: object, oemName: string) => {
  const filterPageContent = (obj: any): any => {
    if (typeof obj !== "object" || obj == null) {
      return obj;
    }

    const valueExcludedByTags = (value: string, tags: string[] | undefined) => {
      if (!Array.isArray(tags)) {
        // only exclude via an array of tags
        return false;
      }
      if (isEmpty(tags)) {
        // no tags means include all
        return false;
      }
      // excluded if not explicitly listed, matching case-insensitively
      return !tags.find((member) => member.toLowerCase() === value.toLowerCase());
    };

    if (Array.isArray(obj)) {
      return obj.map(filterPageContent).filter((item) => !isEmpty(item));
    }

    const hasExcludingTags = valueExcludedByTags(oemName, obj?.tags)
      || valueExcludedByTags(config.getEnvironmentEnum(), obj?.envTags);

    if (hasExcludingTags) {
      return {};
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, filterPageContent(value)])
    );
  };

  return filterPageContent({ ...pageContent });
};

const ContentfulTagFilter = ({ pageContent = {} }: AppProps) => {
  const isContentLoaded = Boolean(Object.keys(pageContent).length);
  if (!isContentLoaded) {
    // TODO: Display loading indicator. We can't use LoaderBackdrop here because it relies on a theme
    return null;
  }

  const oemName = getOemName();
  const filteredPageContent = filterTags(pageContent, oemName);

  return <App pageContent={filteredPageContent} />;
};

export default connect(({ settingsReducer: { locale } }) => ({ locale }))(
  ContentfulConnect(ContentfulTagFilter, {
    pageContent: { id: config.get<string>('CONTENTFUL_PAGE_ID'), depth: 10 },
  })
);
