import React from 'react';
import useToggle from '@hooks/useToggle';
import { ContentfulRichText } from '@cv/webframework-react-components';
import Dialog from './Dialog';

type DialogWithCloserProps = {
  title?: string;
  body?: object;
  labelOk?: string;
}

function DialogWithCloser({ ...props }: DialogWithCloserProps) {

  const [showModal, toggleShowModal] = useToggle(true);

  return showModal ? (
    <Dialog {...props} onOk={toggleShowModal}>
      <div className="text-center">
        <ContentfulRichText document={props.body} />
      </div>
    </Dialog>
  ) : null;
}

export default DialogWithCloser;
