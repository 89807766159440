import React from "react";
import { ContentfulImage } from "@cv/webframework-react-components";

import styles from "./Header.module.css";

type HeaderServiceProps = {
  imageData: object;
};

export default function HeaderService({ imageData }: HeaderServiceProps) {
  return (
    <div className={styles["Header-service"]}>
      <ContentfulImage imageData={imageData} />
    </div>
  );
}
