type DefaultObject = {
  [key: string]: string;
};

type ClassNamesObject = {
  [key: string]: DefaultObject;
};

const adjSelAttrRegex = /((?:\.|#)[^\.\s#]+)((?:\.|#)[^\.\s#]+)/g;
const doubleColonPseudoElRegex = /(::)(before|after|first-line|first-letter|selection)/;
const singleColonPseudoElRegex = /([^:])(:)(before|after|first-line|first-letter|selection)/;
let singleColonForPseudoElements: boolean; // flag for older browsers

type CustomCSSStyleSheet = CSSStyleSheet & {
  cssRules?: CSSStyleRule[],
  rules?: CSSStyleRule[]
}

type Rule = {
  sheet: CustomCSSStyleSheet,
  index: number,
  style: CSSStyleDeclaration,
}

function getRules(sheet: CustomCSSStyleSheet, selector: string): Rule[] {
  const rules = sheet.cssRules || sheet.rules || [];
  const results = [];
  // Browsers report selectors in lowercase
  selector = selector.toLowerCase();
  for (let i = 0; i < rules.length; i++) {
    const selectorText = rules[i].selectorText;
    // Note - certain rules (e.g. @rules) don't have selectorText
    if (
      selectorText &&
      (selectorText == selector ||
        selectorText == swapAdjSelAttr(selector) ||
        selectorText == swapPseudoElSyntax(selector))
    ) {
      results.push({
        sheet: sheet,
        index: i,
        style: rules[i].style,
      });
    }
  }
  return results;
}

function addRule(sheet: CustomCSSStyleSheet, selector: string) {
  const rules = sheet.cssRules || sheet.rules || [];
  const index = rules.length;
  const pseudoElementRule = addPseudoElementRule(sheet, selector, rules, index);

  if (!pseudoElementRule) {
    addRuleToSheet(sheet, selector, index);
  }

  return {
    sheet: sheet,
    index: index,
    style: rules[index].style,
  };
}

function addRuleToSheet(sheet: CustomCSSStyleSheet, selector: string, index: number): void {
  if (sheet.insertRule) {
    sheet.insertRule(selector + " { }", index);
  } else {
    sheet.addRule(selector, undefined, index);
  }
}

// Handles single colon syntax for older browsers and bugzilla.mozilla.org/show_bug.cgi?id=949651
function addPseudoElementRule(
  sheet: CustomCSSStyleSheet,
  selector: string,
  rules: CSSRule[],
  index: number
): boolean {
  let doubleColonSelector;
  let singleColonSelector;

  if (doubleColonPseudoElRegex.exec(selector)) {
    doubleColonSelector = selector;
    singleColonSelector = toSingleColonPseudoElements(selector);
  } else if (singleColonPseudoElRegex.exec(selector)) {
    doubleColonSelector = toDoubleColonPseudoElements(selector);
    singleColonSelector = selector;
  } else {
    return false; // Not dealing with a pseudo element
  }

  if (!singleColonForPseudoElements) {
    // Assume modern browser and then check if successful
    addRuleToSheet(sheet, doubleColonSelector, index);
    if (rules.length <= index) {
      singleColonForPseudoElements = true;
    }
  }
  if (singleColonForPseudoElements) {
    addRuleToSheet(sheet, singleColonSelector, index);
  }

  return true;
}

function toDoubleColonPseudoElements(selector: string) {
  return selector.replace(
    singleColonPseudoElRegex,
    function (match, submatch1, submatch2, submatch3) {
      return submatch1 + "::" + submatch3;
    }
  );
}

function toSingleColonPseudoElements(selector: string) {
  return selector.replace(
    doubleColonPseudoElRegex,
    function (match, submatch1, submatch2) {
      return ":" + submatch2;
    }
  );
}

// IE9 stores rules with attributes (classes or ID's) adjacent in the opposite order as defined
// causing them to not be found, so this method swaps [#|.]sel1[#|.]sel2 to become [#|.]sel2[#|.]sel1
function swapAdjSelAttr(selector: string) {
  let swap = "";
  let lastIndex = 0;
  let match;

  while ((match = adjSelAttrRegex.exec(selector)) != null) {
    if (match[0] === "") break;
    swap += selector.substring(lastIndex, match.index);
    swap += selector.substr(match.index + match[1].length, match[2].length);
    swap += selector.substr(match.index, match[1].length);
    lastIndex = match.index + match[0].length;
  }
  swap += selector.substr(lastIndex);

  return swap;
}

// FF and older browsers store rules with pseudo elements using single-colon syntax
function swapPseudoElSyntax(selector: string) {
  if (doubleColonPseudoElRegex.exec(selector)) {
    return toSingleColonPseudoElements(selector);
  }
  return selector;
}

function setStyleProperties(rule: Rule, properties: DefaultObject) {
  for (let key in properties) {
    const value = properties[key];
    const importantIndex = value.indexOf("!important");

    // Modern browsers seem to handle overrides fine, but IE9 doesn't
    rule.style.removeProperty(key);
    if (importantIndex > 0) {
      rule.style.setProperty(key, value.substr(0, importantIndex), "important");
    } else {
      rule.style.setProperty(key, value);
    }
  }
}

function transformCamelCasedPropertyNames(oldProps: DefaultObject) {
  const newProps = {} as DefaultObject;
  for (let key in oldProps) {
    newProps[unCamelCase(key)] = oldProps[key];
  }
  return newProps;
}

function unCamelCase(str: string) {
  return str.replace(/([A-Z])/g, function (match: string, submatch: string) {
    return "-" + submatch.toLowerCase();
  });
}

// @ts-ignore
const head = document.head || document.getElementsByTagName("head")[0];
let defaultSheet: CustomCSSStyleSheet | null;

function _createSheet() {
  // @ts-ignore
  const styleNode = document.createElement("style");
  head.appendChild(styleNode);
  return styleNode.sheet as CustomCSSStyleSheet;
}

function _set(sheet: CustomCSSStyleSheet, selector: string, properties: DefaultObject) {
  properties = transformCamelCasedPropertyNames(properties);
  let rules = getRules(sheet, selector);
  if (!rules.length) {
    rules = [addRule(sheet, selector)];
  }
  for (let i = 0; i < rules.length; i++) {
    setStyleProperties(rules[i], properties);
  }
}

export default function (rules: ClassNamesObject) {
  if (!defaultSheet) {
    defaultSheet = _createSheet();
  }

  for (let key in rules) {
    _set(defaultSheet, key, rules[key]);
  }
}
