import React, { useState } from "react";
import clsx from "clsx";

import styles from "./RoundToggleButton.module.css";

type RoundToggleButtonProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  checked?: boolean;
  onToggle?: (
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  classes?: {
    button?: string;
    input?: string;
    selected?: string;
  };
};

const RoundToggleButton = ({
  children,
  checked,
  onChange,
  onToggle,
  classes = {},
  ...rest
}: RoundToggleButtonProps) => {
  const [isChecked, setChecked] = useState<boolean>(checked ?? false);

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Prevent using state if checked status controls outside
    if (checked === undefined) {
      setChecked(e.target.checked);
    }

    onChange && onChange(e);
    onToggle && onToggle(e.target.checked, e);
  };

  return (
    <span className={clsx(styles["round-toggle-button"], classes.button)}>
      <input
        {...rest}
        type="checkbox"
        checked={checked ?? isChecked}
        onChange={handleChecked}
      />
      <span
        className={clsx(styles["content"], classes.input, {
          [classes.selected ?? styles["selected"]]: checked ?? isChecked,
        })}
      >
        {children}
      </span>
    </span>
  );
};

export default RoundToggleButton;
