import { Environment } from "@cv/portal-common-lib/ajax/constants";
import { getOemName } from "@utils/oem";
import * as envs from "./envs";

const config = typeof CONFIG !== 'undefined' ? CONFIG : {};

export const get = <T extends unknown>(key: string, defaultValue: T | null = null): T | null => {
  if (key in config) {
    return config[key] as T;
  }

  const env = `${config.ENV?.toLowerCase()}Config` as keyof typeof envs;
  if (env in envs && key in envs[env]) {
    return envs[env][key as keyof typeof envs[typeof env]] as T;
  }

  return defaultValue;
};

export const getEnvironmentEnum = () => {
  const serviceEnv = get<keyof typeof Environment>('SERVICE_ENVIRONMENT') || Environment.LOCAL;
  const env = Environment[serviceEnv];
  if (env !== undefined) {
     return env;
  }
  return Environment.LOCAL;
};

export const getOemValue = (key: string) => get<string>(`${getOemName().trim()}_${key}`) || '';

export default {
  get,
  getEnvironmentEnum,
  getOemValue,
};
