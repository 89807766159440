import React from 'react';
import { useApi } from '@api';

import styles from './AgreementLink.module.css';

type PortalTextLinkProps = {
  name: string;
  linkText: string;
  regularText: string;
  textAlignment?: 'left' | 'right' | 'center' | 'justify';
};

function AgreementLink({ linkText, regularText, textAlignment }: PortalTextLinkProps) {
  const api = useApi();

  const getAgreement = async (event: any) => {
    event.preventDefault();
    try {
      const { data } = await api.getAgreement();
      if (data) {
        const { agreementUrl } = data.payload.filter(
          (response) => response.vin === api.storeService.getVehicleData().vin,
        )[0];
        const win = window.open(agreementUrl, '_blank');
        win?.focus();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={`${styles.AgreementLinkContainer} text-${textAlignment}`}>
      <p>
        {regularText}{' '}
        <button onClick={getAgreement} type="button">
          {linkText}
        </button>
      </p>
    </div>
  );
}

export default AgreementLink;
