import React from 'react';
import useToggle from '@hooks/useToggle';

import { useApi } from '@api';

import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@types/ContentfulComponent';

import VehicleConditionContainer from './VehicleConditionContainer';
import { VehicleConditionProvider } from './VehicleConditionContext';

type VehicleConditionConnectorProps = {
  name: any;
  content: ContentfulComponent;
  expandAllLabel: string;
  collapseAllLabel: any;
  scheduleServiceButtonLabel: string;
  scheduleServiceButtonUrl: string;
  displayViewMode: 'default' | 'warning';
};

function VehicleConditionConnector({
  name,
  expandAllLabel,
  collapseAllLabel,
  content,
  scheduleServiceButtonLabel,
  scheduleServiceButtonUrl,
  displayViewMode,
}: VehicleConditionConnectorProps) {
  const api = useApi();
  const vehicleData = api.storeService.getVehicleData();
  const [isExpandAll, toggleExpandAll] = useToggle(false);

  return (
    <VehicleConditionProvider data={{ vehicleData, isExpandAll, toggleExpandAll, displayViewMode }}>
      <VehicleConditionContainer
        expandAllLabel={expandAllLabel}
        collapseAllLabel={collapseAllLabel}
        scheduleServiceButtonLabel={scheduleServiceButtonLabel}
        scheduleServiceButtonUrl={scheduleServiceButtonUrl}
        displayViewMode={displayViewMode}
      >
        <ContentRenderer name={name} content={content} />
      </VehicleConditionContainer>
    </VehicleConditionProvider>
  );
}

export default VehicleConditionConnector;
