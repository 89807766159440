// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._32m67CjsM49bV6lN_kHVeG {\n  color: var(--theme-disclaimer-color-text);\n  text-transform: none;\n  width: 90%;\n  margin: 0 auto;\n  text-align: justify;\n}\n\n._1nF-CAZDfSK2F1rd1Xhvt4 {\n  line-height: 1.125rem;\n  margin-bottom: 1rem;\n}\n\n._32m67CjsM49bV6lN_kHVeG ._1nF-CAZDfSK2F1rd1Xhvt4 p {\n  font-size: 0.75rem;\n}\n\n._32m67CjsM49bV6lN_kHVeG ._1ncAOr5sDneYQ7hksj2dcl p {\n  font-size: 0.875rem;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Disclaimer/Disclaimer.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,oBAAoB;EACpB,UAAU;EACV,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".Disclaimer-container {\n  color: var(--theme-disclaimer-color-text);\n  text-transform: none;\n  width: 90%;\n  margin: 0 auto;\n  text-align: justify;\n}\n\n.Disclaimer-description {\n  line-height: 1.125rem;\n  margin-bottom: 1rem;\n}\n\n.Disclaimer-container .Disclaimer-description p {\n  font-size: 0.75rem;\n}\n\n.Disclaimer-container .Disclaimer-contact p {\n  font-size: 0.875rem;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Disclaimer-container": "_32m67CjsM49bV6lN_kHVeG",
	"Disclaimer-description": "_1nF-CAZDfSK2F1rd1Xhvt4",
	"Disclaimer-contact": "_1ncAOr5sDneYQ7hksj2dcl"
};
export default ___CSS_LOADER_EXPORT___;
