type EntityType = 'pin' | 'circle';

const clearMapEntities = (map: any, entityType: EntityType) => {
  if (window.Microsoft) {
    const { Maps } = window.Microsoft;
    const { Pushpin, Polygon } = Maps;
    const shape = {
      pin: Pushpin,
      circle: Polygon,
    };
    if (map?.entities) {
      for (let i = map.entities.getLength() - 1; i >= 0; i--) {
        const mapItem = map.entities.get(i);
        const vehicleLocationPin = entityType === 'pin' && mapItem._options.icon === "/assets/nissan-car-location-selected.5908960bcd6a09ed42133a32d5203186.png";
        if (mapItem instanceof shape[entityType] && !vehicleLocationPin) {
          map.entities.removeAt(i);
        }
      }
    }
  }
};

export const clearMapPins = (map: any) => clearMapEntities(map, 'pin');
export const clearMapBoundaries = (map: any) => clearMapEntities(map, 'circle');
