// checking if at least one mil status equals '1' to show warning banner
const checkWarningStatus = (milStatusData: Array<Object>) => {
  if (!milStatusData) return false;

  const existingMilTypes = ['LAMP_REQUEST', 'OIL_PRESSURE', 'AIR_BAG', 'BREAK_FLUID', 'ABS'];

  const milStatuses = milStatusData.filter((item) => existingMilTypes.includes(item.type));
  const checkWarning = milStatuses.some((item) => item.status === '1');

  return checkWarning;
};

export default checkWarningStatus;
