import { listPayments, updatePayments} from '@cv/portal-cps-lib/payment';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  PaymentMethodRequest,
  PaymentMethodResponse,
  UpdatePaymentMethodRequest,
  UpdatePaymentMethodPayload,
} from '@cv/portal-cps-lib/payment/payment-methods/models';

import { PaymentMethodData } from '../types/';
import { formatPaymentData, formatPaymentFormData } from '../formatters/';

export default class PaymentMethodsService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getPaymentMethods(userId:string, locale, token) {
    const payload : PaymentMethodRequest = {
      environment: this.environment,
      headers: {
        Authorization: token,
        'Accept-Language': locale,
      },
      data: {
        userId
      }
    };
    const { data } = await listPayments(payload);
    return { data: formatPaymentData(data as PaymentMethodResponse[]) };
  }

  async updatePaymentMethod(data: PaymentMethodData, userId:string, token: string) {
    const fullData: UpdatePaymentMethodPayload = formatPaymentFormData(data, userId);
    const payload: UpdatePaymentMethodRequest = {
      environment: this.environment,
      data: fullData,
      headers: {
        Authorization: token,
        'If-Match': '*',
      },
    };
    const response = await updatePayments(payload);
    return { data: response.data };
  }
}
