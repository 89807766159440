import React from 'react';

import ContentRenderer from '@components/ContentRenderer';

import styles from './Footer.module.css';

type FooterProps = {
  content: Array<{ componentType: string }>;
};

function Footer({ content }: FooterProps) {
  return (
    <footer className={styles['Footer-container']}>
      <div className={styles['Footer-wrapper']}>
        <ContentRenderer name="footer" content={content} />
      </div>
    </footer>
  );
}

export default Footer;
