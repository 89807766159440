import { IVehicle } from '@redux/actions';
import { USER_EVENTS } from '@redux/actions/user';
import { SubscribedServices } from '../../api/types';

export interface IUserState {
  userId?: string;
  vehicleIds?: string[];
  vehicleVins?: IVehicle[];
  isPinConfigured?: boolean;
  subscribedServices?: SubscribedServices[];
  pinLockedTimestamp?: number;
}

const initState: IUserState = {
  userId: '',
  vehicleIds: [],
  vehicleVins: [],
  isPinConfigured: false,
  subscribedServices: [],
  pinLockedTimestamp: 0,
};

export interface IUserAction {
  type: USER_EVENTS;
  payload: any;
}

export default (state: IUserState = initState, action: IUserAction) => {
  switch (action.type) {
    case USER_EVENTS.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case USER_EVENTS.SET_VEHICLE_IDS:
      return {
        ...state,
        vehicleIds: action.payload,
      };
    case USER_EVENTS.SET_VEHICLE_VINS:
      return {
        ...state,
        vehicleVins: action.payload,
      };
    case USER_EVENTS.SET_USER_PIN_STATUS:
      return {
        ...state,
        isPinConfigured: action.payload,
      };
    case USER_EVENTS.SET_SUBSCRIBED_SERVICES:
      return {
        ...state,
        subscribedServices: action.payload,
      };
    case USER_EVENTS.SET_USER_PIN_LOCKED_TIMESTAMP:
      return {
        ...state,
        pinLockedTimestamp: action.payload,
      };
    default:
      return state;
  }
};
