import { useDispatch } from 'react-redux';
import { PREFERENCES_ACTIONS, Preferences } from '../actions';

const useSetPreferences = () => {
  const dispatch = useDispatch();

  return (data: Preferences) => {
    dispatch({
      type: PREFERENCES_ACTIONS.SET_PREFERENCES,
      data,
    });
  };
};

export default useSetPreferences;
