import React from 'react';
import { ContentfulRichText } from '@cv/webframework-react-components';

import styles from './Disclaimer.module.css';

type DisclaimerProps = {
  description: object,
  contact?: object,
}

function Disclaimer({ description, contact }: DisclaimerProps) {
  return (
    <div className={styles["Disclaimer-container"]}>
      <div className={styles["Disclaimer-description"]}>
        <ContentfulRichText document={description} />
      </div>
      <div className={styles["Disclaimer-contact"]}>
        <ContentfulRichText document={contact} />
      </div>
    </div>
  );
}

export default Disclaimer;
