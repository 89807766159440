import { Environment } from "@cv/portal-common-lib/ajax/constants";
import { GetHealthInformationRequest, VehicleHealthResponse } from  '@cv/portal-rts-lib/health/models';
import { getHealthInformation } from  '@cv/portal-rts-lib/health';

export default class VehicleStatusService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getHealthInformation(accessToken: string, idToken: string, vehicleId: string, locale: string) {
    const request: GetHealthInformationRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token: idToken,
        'Accept-Language': locale,
      },
      pathParams: {
        vehicleId
      }
    };

    const response = (await getHealthInformation(request)) as {
      data: VehicleHealthResponse;
    };

    return response || {};
  }
}
