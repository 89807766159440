export default {
  loginEnabled: true,
  mapEnabled: true,
  headerNavigationEnabled: true,
  headerUserEnabled: true,
  apiErrorsEnabled: true,
  excludeErrorDialog: [
    '422:RESPONSE.ERROR.INPUT.INCORRECT_PIN',
    '422:RESPONSE.ERROR.INPUT.INCORRECT_KBA',
    '422:RESPONSE.ERROR.DATAERROR.PIN_LOCKED',
    '422:UNIQUE__username',
  ],
  authExcludePath: ['/login', '/logout', '/support'],
  suppressErrorDialogUrls: [
    '/telematicsservices/v1/vehicles\/.*\/locations/tracker/locate'
  ],
  oemsToSendChannelIdInDestinationReq: ['INFINITI']
} as const;
