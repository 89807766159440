import { SubscribedServices } from '../../api/types';
import { IVehicle } from './vehicle';

export enum USER_EVENTS {
  SET_USER_ID = 'SET_USER_ID',
  SET_VEHICLE_IDS = 'SET_VEHICLE_ID',
  SET_VEHICLE_VINS = 'SET_VEHICLE_VINS',
  SET_USER_PIN_STATUS = 'SET_USER_PIN_STATUS',
  SET_SUBSCRIBED_SERVICES = 'SET_SUBSCRIBED_SERVICES',
  SET_USER_PIN_LOCKED_TIMESTAMP = 'SET_USER_PIN_LOCKED_TIMESTAMP'
}

export const setUserId = (payload: string) => {
  return {
    type: USER_EVENTS.SET_USER_ID,
    payload,
  };
};

export const setVehicleIds = (payload: string[]) => {
  return {
    type: USER_EVENTS.SET_VEHICLE_IDS,
    payload,
  };
};

export const setVehicleVins = (payload: IVehicle[]) => {
  return {
    type: USER_EVENTS.SET_VEHICLE_VINS,
    payload,
  };
};

export const setUserPinStatus = (payload: boolean) => {
  return {
    type: USER_EVENTS.SET_USER_PIN_STATUS,
    payload,
  };
};

export const setSubscribedServices = (payload: SubscribedServices[]) => {
  return {
    type: USER_EVENTS.SET_SUBSCRIBED_SERVICES,
    payload,
  };
};

export const setUserPinLockedTimestamp = (payload: number) => {
  return {
    type: USER_EVENTS.SET_USER_PIN_LOCKED_TIMESTAMP,
    payload,
  };
};
