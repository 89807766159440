import React, { useState } from "react";

import Dialog from '@components/Dialog';

import styles from "./EmailSearch.module.css";

export type EmailSearchDialogProps = {
  email:string;
  labelEmailSearchDialogTitle: string;
  labelEmailSearchDialogText: string;
  labelEmailSearchDialogCurrentUser: string;
  labelEmailSearchDialogNewUser: string;
  labelCancel: string;
  labelSubmit: string;
  onCancel: () => void;
  onSubmit: (mode: 'current' | 'new') => void;
};

const EmailSearchDialog = ({
  email,
  labelEmailSearchDialogTitle,
  labelEmailSearchDialogText,
  labelEmailSearchDialogCurrentUser,
  labelEmailSearchDialogNewUser,
  labelCancel,
  labelSubmit,
  onCancel,
  onSubmit,
}: EmailSearchDialogProps) => {
  const [mode, setMode] = useState<'current' | 'new'>('current');
  const handleOk = () => {
    onSubmit(mode);
  };

  return (
    <Dialog
      title={labelEmailSearchDialogTitle}
      labelOk={labelSubmit}
      labelCancel={labelCancel}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <div className={styles.dialog}>
        <p>{labelEmailSearchDialogText}</p>
        <div>
          <div className={styles['input-radio']}>
            <input type="radio" name="mode" id="current" checked={mode === 'current'} onChange={() => setMode('current')} />
            <label htmlFor="current">
              <span>{labelEmailSearchDialogCurrentUser}</span>
              <strong>{email}</strong>
            </label>
          </div>
          <div className={styles['input-radio']}>
            <input type="radio" name="mode" id="new" checked={mode === 'new'} onChange={() => setMode('new')} />
            <label htmlFor="new">{labelEmailSearchDialogNewUser}</label>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default EmailSearchDialog;
