import React from 'react';
import clsx from 'clsx';

import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent, { ContentfulFile } from '@types/ContentfulComponent';

import styles from './MarketingContainer.module.css';

type MarketingContainerProps = {
  header: string;
  description?: string;
  image: ContentfulFile;
  button?: Array<ContentfulComponent>;
  appLinks?: Array<ContentfulComponent>;
  contentAlignment: "top" | "bottom";
  justifyContent: "left" | "center" | "right";
  contentDirection: "column" | "row";
  contentColor: "dark" | "light";
  appLinksDirection?: "column" | "row";
};

function MarketingContainer({
  header,
  description,
  image: {
    file: { url },
  },
  button,
  appLinks,
  contentAlignment,
  justifyContent,
  contentDirection,
  contentColor,
  appLinksDirection = "row",
}: MarketingContainerProps) {
  const backgroundImage = {
    backgroundImage: `url(${url})`,
  };

  const contentPosition = styles[`content-${contentAlignment}`];
  const contentPlacement = styles[`content-${justifyContent}`];
  const elementsPosition = styles[`direction-${contentDirection}`];

  return (
    <div className={styles["wrapper"]}>
      <div
        className={clsx(
          styles["marketing-wrapper"],
          contentPosition,
          contentPlacement
        )}
        style={backgroundImage}
      >
        <div
          className={clsx(
            styles["marketing-content"],
            elementsPosition,
            styles[`text-color-${contentColor}`]
          )}
        >
          <h1 className={styles["marketing-header"]}>{header}</h1>
          {description && (
            <p className={styles["marketing-description"]}>{description}</p>
          )}
          {button && (
            <div className={styles["marketingButtons-wrapper"]}>
              <button className={styles["marketing-button"]}>
                <ContentRenderer name="Link" content={button} />
              </button>
            </div>
          )}
          {appLinks && (
            <div
              className={clsx(
                styles["marketingLinks-wrapper"],
                styles[`direction-${appLinksDirection}`]
              )}
            >
              <ContentRenderer name="AppLink" content={appLinks} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MarketingContainer;
